@font-face {
    font-family: Ionicons;
    src: url(assets/fonts/ionicons.woff?v=2.0.0) format("woff");
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'DIN Pro';
    src: local('DIN Pro Regular'), local('DIN-Pro-Regular'),
        url(assets/fonts/DINPro.woff2) format('woff2'),
        url(assets/fonts/DINPro.woff) format('woff'),
        url(assets/fonts/DINPro.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
  }

 @font-face {
    font-family: 'Libel Suit';
    font-style: normal;
    font-weight: 400;
    src: local('Libel Suit'), local('LibelSuit-Regular'),
        url(assets/fonts/libel-suit.woff) format('woff'),
        url(assets/fonts/libel-suit.ttf) format('truetype');
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"),
    url(assets/fonts/robotolight.woff2) format("woff2");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  src: local("Roboto Black"), local("Roboto-Black"),
    url(assets/fonts/robotoblack.woff2) format("woff2");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}
@font-face {
  font-family: Ionicons;
  src: url(assets/fonts/ionicons.woff?v=2.0.0) format("woff");
  font-weight: 400;
  font-style: normal;
}
