.component-base {
  display: block;
}
* {
  -webkit-tap-highlight-color: transparent;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
}
body {
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: none;
  -webkit-user-select: none;
  background-color: #fff;
  font-size: 14px;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: 400;
}
* {
  box-sizing: border-box;
  outline: 0;
}
body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
  position: absolute;
}
body.platform-ios {
  font-family: -apple-system, "Helvetica Neue", Roboto, sans-serif;
}
a {
  cursor: pointer;
  color: #000;
  text-decoration: none;
}
.content {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;
}
.left {
  float: left;
}
.right {
  float: right;
}
.top {
  position: absolute;
  top: 0;
  left: 0;
}
.bottom {
  position: absolute;
  bottom: 0;
  left: 0;
}
.align-left {
  text-align: left;
}
.align-center {
  text-align: center;
}
.align-right {
  text-align: right;
}
.border {
  border-width: 1px;
  border-style: solid;
}
.hidden {
  display: none;
}
.opacity-90 {
  opacity: 0.9;
}
.opacity-80 {
  opacity: 0.8;
}
.opacity-70 {
  opacity: 0.7;
}
.opacity-60 {
  opacity: 0.6;
}
.opacity-50 {
  opacity: 0.5;
}
.opacity-40 {
  opacity: 0.4;
}
.opacity-30 {
  opacity: 0.3;
}
.opacity-20 {
  opacity: 0.2;
}
.opacity-10 {
  opacity: 0.1;
}
.line {
  width: 100%;
  height: 1px;
  display: block;
  clear: both;
}
.icon {
  font-size: 28px;
}
img,
video {
  max-width: 100%;
}
p {
  margin-top: 5px;
  margin-bottom: 5px;
}
.padding {
  padding: 10px;
}
.padding-top {
  padding-top: 10px;
}
.margin {
  margin: 10px;
}
span.padding {
  padding: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
}
.radius {
  border-radius: 4px;
}
.radius-big {
  border-radius: 22px;
}
.circle {
  border-radius: 50%;
}
.radius-left {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.radius-right {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.radius-top {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.shadow {
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
}
.space {
  width: 100%;
  height: 20px;
}
.space-big {
  width: 100%;
  height: 50px;
}
.space-huge {
  width: 100%;
  height: 100px;
}
.margin-bottom {
  margin-bottom: 10px;
}
.margin-top {
  margin-top: 10px;
}
.text-shadow {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
}
.text-small {
  font-size: 14px;
}
.text-small .icon,
.text-small.icon {
  font-size: 14px !important;
}
.icon-small {
  font-size: 14px !important;
}
.icon-big {
  font-size: 50px !important;
}
.icon-huge {
  font-size: 65px !important;
}
.icon-extra-huge {
  font-size: 120px !important;
}
.text-big {
  font-size: 22px;
}
.text-big .icon,
.text-big.icon {
  font-size: 22px !important;
}
.text-extra-huge {
  font-size: 65px;
}
.text-extra-huge .icon,
.text-extra-huge.icon {
  font-size: 65px !important;
}
.text-huge {
  font-size: 32px;
}
.text-huge .icon,
.text-huge.icon {
  font-size: 32px !important;
}
.text-light {
  font-weight: 300;
}
.text-bold {
  font-weight: 700;
}
.text-strong {
  font-weight: 800;
}
.float-bottom-right {
  position: fixed;
  right: 15px;
  bottom: 15px;
}
.float-bottom-left {
  position: fixed;
  left: 15px;
  bottom: 15px;
}
.float-bottom-center {
  position: fixed;
  margin: auto;
  left: 0;
  bottom: 15px;
}
.icon-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-circle-small {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-circle-small i {
  font-size: 15px;
}
.icon-circle-big {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-circle-big i {
  font-size: 70px;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.01;
  z-index: 9999;
  transition-duration: 280ms;
}
.backdrop.show {
  opacity: 0.4;
}
.mark {
  border-left-width: 8px !important;
}
.red {
  color: rgba(255, 255, 255, 0.9);
  background-color: #f44336 !important;
}
.red-100,
.red-200,
.red-300,
.red-50 {
  color: rgba(0, 0, 0, 0.8);
}
.red-50,
input[type="checkbox"].red-50.switch::after {
  background-color: #ffebee !important;
}
.red-100,
input[type="checkbox"].red-100.switch::after {
  background-color: #ffcdd2 !important;
}
.red-200,
input[type="checkbox"].red-200.switch::after {
  background-color: #ef9a9a !important;
}
.red-300,
input[type="checkbox"].red-300.switch::after {
  background-color: #e57373 !important;
}
.pink,
.red-400,
.red-500,
.red-600,
.red-700,
.red-800,
.red-900 {
  color: rgba(255, 255, 255, 0.9);
}
.red-400,
input[type="checkbox"].red-400.switch::after {
  background-color: #ef5350 !important;
}
.red-500,
input[type="checkbox"].red-500.switch::after {
  background-color: #f44336 !important;
}
.red-600,
input[type="checkbox"].red-600.switch::after {
  background-color: #e53935 !important;
}
.red-700,
input[type="checkbox"].red-700.switch::after {
  background-color: #d32f2f !important;
}
.red-800,
input[type="checkbox"].red-800.switch::after {
  background-color: #c62828 !important;
}
.red-900,
input[type="checkbox"].red-900.switch::after {
  background-color: #b71c1c !important;
}
.pink,
input[type="checkbox"].pink.switch::after {
  background-color: #e91e63 !important;
}
.pink-100,
.pink-200,
.pink-50 {
  color: rgba(0, 0, 0, 0.8);
}
.pink-50,
input[type="checkbox"].pink-50.switch::after {
  background-color: #fce4ec !important;
}
.pink-100,
input[type="checkbox"].pink-100.switch::after {
  background-color: #f8bbd0 !important;
}
.pink-200,
input[type="checkbox"].pink-200.switch::after {
  background-color: #f48fb1 !important;
}
.Pink-300,
.pink-400,
.pink-500,
.pink-600,
.pink-700,
.pink-800,
.pink-900,
.purple {
  color: rgba(255, 255, 255, 0.9);
}
.Pink-300,
input[type="checkbox"].Pink-300.switch::after {
  background-color: #f06292 !important;
}
.pink-400,
input[type="checkbox"].pink-400.switch::after {
  background-color: #ec407a !important;
}
.pink-500,
input[type="checkbox"].pink-500.switch::after {
  background-color: #e91e63 !important;
}
.pink-600,
input[type="checkbox"].pink-600.switch::after {
  background-color: #d81b60 !important;
}
.pink-700,
input[type="checkbox"].pink-700.switch::after {
  background-color: #c2185b !important;
}
.pink-800,
input[type="checkbox"].pink-800.switch::after {
  background-color: #ad1457 !important;
}
.pink-900,
input[type="checkbox"].pink-900.switch::after {
  background-color: #880e4f !important;
}
.purple,
input[type="checkbox"].purple.switch::after {
  background-color: #9c27b0 !important;
}
.purple-100,
.purple-200,
.purple-50 {
  color: rgba(0, 0, 0, 0.8);
}
.purple-50,
input[type="checkbox"].purple-50.switch::after {
  background-color: #f3e5f5 !important;
}
.purple-100,
input[type="checkbox"].purple-100.switch::after {
  background-color: #e1bee7 !important;
}
.purple-200,
input[type="checkbox"].purple-200.switch::after {
  background-color: #ce93d8 !important;
}
.deep-purple,
.purple-300,
.purple-400,
.purple-500,
.purple-600,
.purple-700,
.purple-800,
.purple-900 {
  color: rgba(255, 255, 255, 0.9);
}
.purple-300,
input[type="checkbox"].purple-300.switch::after {
  background-color: #ba68c8 !important;
}
.purple-400,
input[type="checkbox"].purple-400.switch::after {
  background-color: #ab47bc !important;
}
.purple-500,
input[type="checkbox"].purple-500.switch::after {
  background-color: #9c27b0 !important;
}
.purple-600,
input[type="checkbox"].purple-600.switch::after {
  background-color: #8e24aa !important;
}
.purple-700,
input[type="checkbox"].purple-700.switch::after {
  background-color: #7b1fa2 !important;
}
.purple-800,
input[type="checkbox"].purple-800.switch::after {
  background-color: #6a1b9a !important;
}
.purple-900,
input[type="checkbox"].purple-900.switch::after {
  background-color: #4a148c !important;
}
.deep-purple,
input[type="checkbox"].deep-purple.switch::after {
  background-color: #673ab7 !important;
}
.deep-purple-50 {
  color: rgba(0, 0, 0, 0.8);
  background-color: #ede7f6 !important;
}
.deep-purple-100 {
  color: rgba(0, 0, 0, 0.8);
  background-color: #d1c4e9 !important;
}
.deep-purple-200 {
  color: rgba(0, 0, 0, 0.8);
  background-color: #b39ddb !important;
}
.deep-purple-300,
.deep-purple-400,
.deep-purple-500,
.deep-purple-600,
.deep-purple-700,
.deep-purple-800,
.deep-purple-900,
.indigo {
  color: rgba(255, 255, 255, 0.9);
}
.deep-purple-300,
input[type="checkbox"].deep-purple-300.switch::after {
  background-color: #9575cd !important;
}
.deep-purple-400,
input[type="checkbox"].deep-purple-400.switch::after {
  background-color: #7e57c2 !important;
}
.deep-purple-500,
input[type="checkbox"].deep-purple-500.switch::after {
  background-color: #673ab7 !important;
}
.deep-purple-600,
input[type="checkbox"].deep-purple-600.switch::after {
  background-color: #5e35b1 !important;
}
.deep-purple-700,
input[type="checkbox"].deep-purple-700.switch::after {
  background-color: #512da8 !important;
}
.deep-purple-800,
input[type="checkbox"].deep-purple-800.switch::after {
  background-color: #4527a0 !important;
}
.deep-purple-900,
input[type="checkbox"].deep-purple-900.switch::after {
  background-color: #311b92 !important;
}
.indigo,
input[type="checkbox"].indigo.switch::after {
  background-color: #3f51b5 !important;
}
.indigo-100,
.indigo-200,
.indigo-50 {
  color: rgba(0, 0, 0, 0.8);
}
.indigo-50,
input[type="checkbox"].indigo-50.switch::after {
  background-color: #e8eaf6 !important;
}
.indigo-100,
input[type="checkbox"].indigo-100.switch::after {
  background-color: #c5cae9 !important;
}
.indigo-200,
input[type="checkbox"].indigo-200.switch::after {
  background-color: #9fa8da !important;
}
.blue,
.indigo-300,
.indigo-400,
.indigo-500,
.indigo-600,
.indigo-700,
.indigo-800,
.indigo-900 {
  color: rgba(255, 255, 255, 0.9);
}
.indigo-300,
input[type="checkbox"].indigo-300.switch::after {
  background-color: #7986cb !important;
}
.indigo-400,
input[type="checkbox"].indigo-400.switch::after {
  background-color: #5c6bc0 !important;
}
.indigo-500,
input[type="checkbox"].indigo-500.switch::after {
  background-color: #3f51b5 !important;
}
.indigo-600,
input[type="checkbox"].indigo-600.switch::after {
  background-color: #3949ab !important;
}
.indigo-700,
input[type="checkbox"].indigo-700.switch::after {
  background-color: #303f9f !important;
}
.indigo-800,
input[type="checkbox"].indigo-800.switch::after {
  background-color: #283593 !important;
}
.indigo-900,
input[type="checkbox"].indigo-900.switch::after {
  background-color: #1a237e !important;
}
.blue,
input[type="checkbox"].blue.switch::after {
  background-color: #2196f3 !important;
}
.blue-100,
.blue-200,
.blue-300,
.blue-400,
.blue-50 {
  color: rgba(0, 0, 0, 0.8);
}
.blue-50,
input[type="checkbox"].blue-50.switch::after {
  background-color: #e3f2fd !important;
}
.blue-100,
input[type="checkbox"].blue-100.switch::after {
  background-color: #bbdefb !important;
}
.blue-200,
input[type="checkbox"].blue-200.switch::after {
  background-color: #90caf9 !important;
}
.blue-300,
input[type="checkbox"].blue-300.switch::after {
  background-color: #64b5f6 !important;
}
.blue-400,
input[type="checkbox"].blue-400.switch::after {
  background-color: #42a5f5 !important;
}
.blue-500,
.blue-600,
.blue-700,
.blue-800,
.blue-900,
.light-blue {
  color: rgba(255, 255, 255, 0.9);
}
.blue-500,
input[type="checkbox"].blue-500.switch::after {
  background-color: #2196f3 !important;
}
.blue-600,
input[type="checkbox"].blue-600.switch::after {
  background-color: #1e88e5 !important;
}
.blue-700,
input[type="checkbox"].blue-700.switch::after {
  background-color: #1976d2 !important;
}
.blue-800,
input[type="checkbox"].blue-800.switch::after {
  background-color: #1565c0 !important;
}
.blue-900,
input[type="checkbox"].blue-900.switch::after {
  background-color: #0d47a1 !important;
}
.light-blue,
input[type="checkbox"].light-blue.switch::after {
  background-color: #03a9f4 !important;
}
.light-blue-100,
.light-blue-200,
.light-blue-300,
.light-blue-400,
.light-blue-50,
.light-blue-500 {
  color: rgba(0, 0, 0, 0.8);
}
.light-blue-50,
input[type="checkbox"].light-blue-50.switch::after {
  background-color: #e1f5fe !important;
}
.light-blue-100,
input[type="checkbox"].light-blue-100.switch::after {
  background-color: #b3e5fc !important;
}
.light-blue-200,
input[type="checkbox"].light-blue-200.switch::after {
  background-color: #81d4fa !important;
}
.light-blue-300,
input[type="checkbox"].light-blue-300.switch::after {
  background-color: #4fc3f7 !important;
}
.light-blue-400,
input[type="checkbox"].light-blue-400.switch::after {
  background-color: #29b6f6 !important;
}
.light-blue-500,
input[type="checkbox"].light-blue-500.switch::after {
  background-color: #03a9f4 !important;
}
.cyan,
.light-blue-600,
.light-blue-700,
.light-blue-800,
.light-blue-900 {
  color: rgba(255, 255, 255, 0.9);
}
.light-blue-600,
input[type="checkbox"].light-blue-600.switch::after {
  background-color: #039be5 !important;
}
.light-blue-700,
input[type="checkbox"].light-blue-700.switch::after {
  background-color: #0288d1 !important;
}
.light-blue-800,
input[type="checkbox"].light-blue-800.switch::after {
  background-color: #0277bd !important;
}
.light-blue-900,
input[type="checkbox"].light-blue-900.switch::after {
  background-color: #01579b !important;
}
.cyan,
input[type="checkbox"].cyan.switch::after {
  background-color: #00bcd4 !important;
}
.cyan-100,
.cyan-200,
.cyan-300,
.cyan-400,
.cyan-50,
.cyan-500,
.cyan-600 {
  color: rgba(0, 0, 0, 0.8);
}
.cyan-50,
input[type="checkbox"].cyan-50.switch::after {
  background-color: #e0f7fa !important;
}
.cyan-100,
input[type="checkbox"].cyan-100.switch::after {
  background-color: #b2ebf2 !important;
}
.cyan-200,
input[type="checkbox"].cyan-200.switch::after {
  background-color: #80deea !important;
}
.cyan-300,
input[type="checkbox"].cyan-300.switch::after {
  background-color: #4dd0e1 !important;
}
.cyan-400,
input[type="checkbox"].cyan-400.switch::after {
  background-color: #26c6da !important;
}
.cyan-500,
input[type="checkbox"].cyan-500.switch::after {
  background-color: #00bcd4 !important;
}
.cyan-600,
input[type="checkbox"].cyan-600.switch::after {
  background-color: #00acc1 !important;
}
.cyan-700,
.cyan-800,
.cyan-900,
.teal {
  color: rgba(255, 255, 255, 0.9);
}
.cyan-700,
input[type="checkbox"].cyan-700.switch::after {
  background-color: #0097a7 !important;
}
.cyan-800,
input[type="checkbox"].cyan-800.switch::after {
  background-color: #00838f !important;
}
.cyan-900,
input[type="checkbox"].cyan-900.switch::after {
  background-color: #006064 !important;
}
.teal,
input[type="checkbox"].teal.switch::after {
  background-color: #009688 !important;
}
.teal-100,
.teal-200,
.teal-300,
.teal-400,
.teal-50 {
  color: rgba(0, 0, 0, 0.8);
}
.teal-50,
input[type="checkbox"].teal-50.switch::after {
  background-color: #e0f2f1 !important;
}
.teal-100,
input[type="checkbox"].teal-100.switch::after {
  background-color: #b2dfdb !important;
}
.teal-200,
input[type="checkbox"].teal-200.switch::after {
  background-color: #80cbc4 !important;
}
.teal-300,
input[type="checkbox"].teal-300.switch::after {
  background-color: #4db6ac !important;
}
.teal-400,
input[type="checkbox"].teal-400.switch::after {
  background-color: #26a69a !important;
}
.green,
.teal-500,
.teal-600,
.teal-700,
.teal-800,
.teal-900 {
  color: rgba(255, 255, 255, 0.9);
}
.teal-500,
input[type="checkbox"].teal-500.switch::after {
  background-color: #009688 !important;
}
.teal-600,
input[type="checkbox"].teal-600.switch::after {
  background-color: #00897b !important;
}
.teal-700,
input[type="checkbox"].teal-700.switch::after {
  background-color: #00796b !important;
}
.teal-800,
input[type="checkbox"].teal-800.switch::after {
  background-color: #00695c !important;
}
.teal-900,
input[type="checkbox"].teal-900.switch::after {
  background-color: #004d40 !important;
}
.green,
input[type="checkbox"].green.switch::after {
  background-color: #4caf50 !important;
}
.green-100,
.green-200,
.green-300,
.green-400,
.green-50,
.green-500 {
  color: rgba(0, 0, 0, 0.8);
}
.green-50,
input[type="checkbox"].green-50.switch::after {
  background-color: #e8f5e9 !important;
}
.green-100,
input[type="checkbox"].green-100.switch::after {
  background-color: #c8e6c9 !important;
}
.green-200,
input[type="checkbox"].green-200.switch::after {
  background-color: #a5d6a7 !important;
}
.green-300,
input[type="checkbox"].green-300.switch::after {
  background-color: #81c784 !important;
}
.green-400,
input[type="checkbox"].green-400.switch::after {
  background-color: #66bb6a !important;
}
.green-500,
input[type="checkbox"].green-500.switch::after {
  background-color: #4caf50 !important;
}
.green-600,
.green-700,
.green-800,
.green-900,
.light-green {
  color: rgba(255, 255, 255, 0.9);
}
.green-600,
input[type="checkbox"].green-600.switch::after {
  background-color: #43a047 !important;
}
.green-700,
input[type="checkbox"].green-700.switch::after {
  background-color: #388e3c !important;
}
.green-800,
input[type="checkbox"].green-800.switch::after {
  background-color: #2e7d32 !important;
}
.green-900,
input[type="checkbox"].green-900.switch::after {
  background-color: #1b5e20 !important;
}
.light-green,
input[type="checkbox"].light-green.switch::after {
  background-color: #8bc34a !important;
}
.light-green-100,
.light-green-200,
.light-green-300,
.light-green-400,
.light-green-50,
.light-green-500,
.light-green-600 {
  color: rgba(0, 0, 0, 0.8);
}
.light-green-50,
input[type="checkbox"].light-green-50.switch::after {
  background-color: #f1f8e9 !important;
}
.light-green-100,
input[type="checkbox"].light-green-100.switch::after {
  background-color: #dcedc8 !important;
}
.light-green-200,
input[type="checkbox"].light-green-200.switch::after {
  background-color: #c5e1a5 !important;
}
.light-green-300,
input[type="checkbox"].light-green-300.switch::after {
  background-color: #aed581 !important;
}
.light-green-400,
input[type="checkbox"].light-green-400.switch::after {
  background-color: #9ccc65 !important;
}
.light-green-500,
input[type="checkbox"].light-green-500.switch::after {
  background-color: #8bc34a !important;
}
.light-green-600,
input[type="checkbox"].light-green-600.switch::after {
  background-color: #7cb342 !important;
}
.light-green-700,
.light-green-800,
.light-green-900,
.lime {
  color: rgba(255, 255, 255, 0.9);
}
.light-green-700,
input[type="checkbox"].light-green-700.switch::after {
  background-color: #689f38 !important;
}
.light-green-800,
input[type="checkbox"].light-green-800.switch::after {
  background-color: #558b2f !important;
}
.light-green-900,
input[type="checkbox"].light-green-900.switch::after {
  background-color: #33691e !important;
}
.lime,
input[type="checkbox"].lime.switch::after {
  background-color: #cddc39 !important;
}
.lime-100,
.lime-200,
.lime-300,
.lime-400,
.lime-50,
.lime-500,
.lime-600,
.lime-700,
.lime-800 {
  color: rgba(0, 0, 0, 0.8);
}
.lime-50,
input[type="checkbox"].lime-50.switch::after {
  background-color: #f9fbe7 !important;
}
.lime-100,
input[type="checkbox"].lime-100.switch::after {
  background-color: #f0f4c3 !important;
}
.lime-200,
input[type="checkbox"].lime-200.switch::after {
  background-color: #e6ee9c !important;
}
.lime-300,
input[type="checkbox"].lime-300.switch::after {
  background-color: #dce775 !important;
}
.lime-400,
input[type="checkbox"].lime-400.switch::after {
  background-color: #d4e157 !important;
}
.lime-500,
input[type="checkbox"].lime-500.switch::after {
  background-color: #cddc39 !important;
}
.lime-600,
input[type="checkbox"].lime-600.switch::after {
  background-color: #c0ca33 !important;
}
.lime-700,
input[type="checkbox"].lime-700.switch::after {
  background-color: #afb42b !important;
}
.lime-800,
input[type="checkbox"].lime-800.switch::after {
  background-color: #9e9d24 !important;
}
.lime-900,
.yellow {
  color: rgba(255, 255, 255, 0.9);
}
.lime-900,
input[type="checkbox"].lime-900.switch::after {
  background-color: #827717 !important;
}
.yellow,
input[type="checkbox"].yellow.switch::after {
  background-color: #ffeb3b !important;
}
.yellow-100,
.yellow-200,
.yellow-300,
.yellow-50,
.yellow-500,
.yellow-600,
.yellow-700,
.yellow-800,
.yellow-900 {
  color: rgba(0, 0, 0, 0.8);
}
.yellow-50,
input[type="checkbox"].yellow-50.switch::after {
  background-color: #fffde7 !important;
}
.yellow-100,
input[type="checkbox"].yellow-100.switch::after {
  background-color: #fff9c4 !important;
}
.yellow-200,
input[type="checkbox"].yellow-200.switch::after {
  background-color: #fff59d !important;
}
.yellow-300,
input[type="checkbox"].yellow-300.switch::after {
  background-color: #fff176 !important;
}
.yellow-400 {
  color: rgba(0, 0, 0, 0.8);
  background-color: #ffee58 !important;
}
.yellow-500,
input[type="checkbox"].yellow-500.switch::after {
  background-color: #ffeb3b !important;
}
.yellow-600,
input[type="checkbox"].yellow-600.switch::after {
  background-color: #fdd835 !important;
}
.yellow-700,
input[type="checkbox"].yellow-700.switch::after {
  background-color: #fbc02d !important;
}
.yellow-800,
input[type="checkbox"].yellow-800.switch::after {
  background-color: #f9a825 !important;
}
.yellow-900,
input[type="checkbox"].yellow-900.switch::after {
  background-color: #f57f17 !important;
}
.amber {
  color: rgba(255, 255, 255, 0.9);
  background-color: #ffc107 !important;
}
.amber-100,
.amber-200,
.amber-300,
.amber-50,
.amber-500,
.amber-600,
.amber-700,
.amber-800,
.amber-900 {
  color: rgba(0, 0, 0, 0.8);
}
.amber-50,
input[type="checkbox"].amber-50.switch::after {
  background-color: #fff8e1 !important;
}
.amber-100,
input[type="checkbox"].amber-100.switch::after {
  background-color: #ffecb3 !important;
}
.amber-200,
input[type="checkbox"].amber-200.switch::after {
  background-color: #ffe082 !important;
}
.amber-300,
input[type="checkbox"].amber-300.switch::after {
  background-color: #ffd54f !important;
}
.amber-400 {
  color: rgba(0, 0, 0, 0.8);
  background-color: #ffca28 !important;
}
.amber-500,
input[type="checkbox"].amber-500.switch::after {
  background-color: #ffc107 !important;
}
.amber-600,
input[type="checkbox"].amber-600.switch::after {
  background-color: #ffb300 !important;
}
.amber-700,
input[type="checkbox"].amber-700.switch::after {
  background-color: #ffa000 !important;
}
.amber-800,
input[type="checkbox"].amber-800.switch::after {
  background-color: #ff8f00 !important;
}
.amber-900,
input[type="checkbox"].amber-900.switch::after {
  background-color: #ff6f00 !important;
}
.orange {
  color: rgba(255, 255, 255, 0.9);
  background-color: #ff9800 !important;
}
.orange-100,
.orange-200,
.orange-300,
.orange-400,
.orange-50,
.orange-500,
.orange-600,
.orange-700 {
  color: rgba(0, 0, 0, 0.8);
}
.orange-50,
input[type="checkbox"].orange-50.switch::after {
  background-color: #fff3e0 !important;
}
.orange-100,
input[type="checkbox"].orange-100.switch::after {
  background-color: #ffe0b2 !important;
}
.orange-200,
input[type="checkbox"].orange-200.switch::after {
  background-color: #ffcc80 !important;
}
.orange-300,
input[type="checkbox"].orange-300.switch::after {
  background-color: #ffb74d !important;
}
.orange-400,
input[type="checkbox"].orange-400.switch::after {
  background-color: #ffa726 !important;
}
.orange-500,
input[type="checkbox"].orange-500.switch::after {
  background-color: #ff9800 !important;
}
.orange-600,
input[type="checkbox"].orange-600.switch::after {
  background-color: #fb8c00 !important;
}
.orange-700,
input[type="checkbox"].orange-700.switch::after {
  background-color: #f57c00 !important;
}
.deep-orange,
.orange-800,
.orange-900 {
  color: rgba(255, 255, 255, 0.9);
}
.orange-800,
input[type="checkbox"].orange-800.switch::after {
  background-color: #ef6c00 !important;
}
.orange-900,
input[type="checkbox"].orange-900.switch::after {
  background-color: #e65100 !important;
}
.deep-orange,
input[type="checkbox"].deep-orange.switch::after {
  background-color: #ff5722 !important;
}
.deep-orange-100,
.deep-orange-200,
.deep-orange-300,
.deep-orange-400,
.deep-orange-50 {
  color: rgba(0, 0, 0, 0.8);
}
.deep-orange-50,
input[type="checkbox"].deep-orange-50.switch::after {
  background-color: #fbe9e7 !important;
}
.deep-orange-100,
input[type="checkbox"].deep-orange-100.switch::after {
  background-color: #ffccbc !important;
}
.deep-orange-200,
input[type="checkbox"].deep-orange-200.switch::after {
  background-color: #ffab91 !important;
}
.deep-orange-300,
input[type="checkbox"].deep-orange-300.switch::after {
  background-color: #ff8a65 !important;
}
.deep-orange-400,
input[type="checkbox"].deep-orange-400.switch::after {
  background-color: #ff7043 !important;
}
.brown,
.deep-orange-500,
.deep-orange-600,
.deep-orange-700,
.deep-orange-800,
.deep-orange-900 {
  color: rgba(255, 255, 255, 0.9);
}
.deep-orange-500,
input[type="checkbox"].deep-orange-500.switch::after {
  background-color: #ff5722 !important;
}
.deep-orange-600,
input[type="checkbox"].deep-orange-600.switch::after {
  background-color: #f4511e !important;
}
.deep-orange-700,
input[type="checkbox"].deep-orange-700.switch::after {
  background-color: #e64a19 !important;
}
.deep-orange-800,
input[type="checkbox"].deep-orange-800.switch::after {
  background-color: #d84315 !important;
}
.deep-orange-900,
input[type="checkbox"].deep-orange-900.switch::after {
  background-color: #bf360c !important;
}
.brown,
input[type="checkbox"].brown.switch::after {
  background-color: #795548 !important;
}
.brown-100,
.brown-200,
.brown-50 {
  color: rgba(0, 0, 0, 0.8);
}
.brown-50,
input[type="checkbox"].brown-50.switch::after {
  background-color: #efebe9 !important;
}
.brown-100,
input[type="checkbox"].brown-100.switch::after {
  background-color: #d7ccc8 !important;
}
.brown-200,
input[type="checkbox"].brown-200.switch::after {
  background-color: #bcaaa4 !important;
}
.brown-300,
.brown-400,
.brown-500,
.brown-600,
.brown-700,
.brown-800,
.brown-900,
.grey {
  color: rgba(255, 255, 255, 0.9);
}
.brown-300,
input[type="checkbox"].brown-300.switch::after {
  background-color: #a1887f !important;
}
.brown-400,
input[type="checkbox"].brown-400.switch::after {
  background-color: #8d6e63 !important;
}
.brown-500,
input[type="checkbox"].brown-500.switch::after {
  background-color: #795548 !important;
}
.brown-600,
input[type="checkbox"].brown-600.switch::after {
  background-color: #6d4c41 !important;
}
.brown-700,
input[type="checkbox"].brown-700.switch::after {
  background-color: #5d4037 !important;
}
.brown-800,
input[type="checkbox"].brown-800.switch::after {
  background-color: #4e342e !important;
}
.brown-900,
input[type="checkbox"].brown-900.switch::after {
  background-color: #3e2723 !important;
}
.grey,
input[type="checkbox"].grey.switch::after {
  background-color: #9e9e9e !important;
}
.grey-100,
.grey-200,
.grey-300,
.grey-400,
.grey-50,
.grey-500 {
  color: rgba(0, 0, 0, 0.8);
}
.grey-50,
input[type="checkbox"].grey-50.switch::after {
  background-color: #fafafa !important;
}
.grey-100,
input[type="checkbox"].grey-100.switch::after {
  background-color: #f5f5f5 !important;
}
.grey-200,
input[type="checkbox"].grey-200.switch::after {
  background-color: #eee !important;
}
.grey-300,
input[type="checkbox"].grey-300.switch::after {
  background-color: #e0e0e0 !important;
}
.grey-400,
input[type="checkbox"].grey-400.switch::after {
  background-color: #bdbdbd !important;
}
.grey-500,
input[type="checkbox"].grey-500.switch::after {
  background-color: #9e9e9e !important;
}
.blue-grey,
.grey-600,
.grey-700,
.grey-800,
.grey-900 {
  color: rgba(255, 255, 255, 0.9);
}
.grey-600,
input[type="checkbox"].grey-600.switch::after {
  background-color: #757575 !important;
}
.grey-700,
input[type="checkbox"].grey-700.switch::after {
  background-color: #616161 !important;
}
.grey-800,
input[type="checkbox"].grey-800.switch::after {
  background-color: #424242 !important;
}
.grey-900,
input[type="checkbox"].grey-900.switch::after {
  background-color: #212121 !important;
}
.blue-grey,
input[type="checkbox"].blue-grey.switch::after {
  background-color: #607d8b !important;
}
.blue-grey-100,
.blue-grey-200,
.blue-grey-300,
.blue-grey-50 {
  color: rgba(0, 0, 0, 0.8);
}
.blue-grey-50,
input[type="checkbox"].blue-grey-50.switch::after {
  background-color: #eceff1 !important;
}
.blue-grey-100,
input[type="checkbox"].blue-grey-100.switch::after {
  background-color: #cfd8dc !important;
}
.blue-grey-200,
input[type="checkbox"].blue-grey-200.switch::after {
  background-color: #b0bec5 !important;
}
.blue-grey-300,
input[type="checkbox"].blue-grey-300.switch::after {
  background-color: #90a4ae !important;
}
.black,
.blue-grey-400,
.blue-grey-500,
.blue-grey-600,
.blue-grey-700,
.blue-grey-800,
.blue-grey-900 {
  color: rgba(255, 255, 255, 0.9);
}
.blue-grey-400,
input[type="checkbox"].blue-grey-400.switch::after {
  background-color: #78909c !important;
}
.transparent {
  background-color: rgba(0, 0, 0, 0) !important;
}
.blue-grey-500,
input[type="checkbox"].blue-grey-500.switch::after {
  background-color: #607d8b !important;
}
.blue-grey-600,
input[type="checkbox"].blue-grey-600.switch::after {
  background-color: #546e7a !important;
}
.blue-grey-700,
input[type="checkbox"].blue-grey-700.switch::after {
  background-color: #455a64 !important;
}
.blue-grey-800,
input[type="checkbox"].blue-grey-800.switch::after {
  background-color: #37474f !important;
}
.blue-grey-900,
input[type="checkbox"].blue-grey-900.switch::after {
  background-color: #263238 !important;
}
.black,
input[type="checkbox"].black.switch::after {
  background-color: #000 !important;
}
.black-opacity-90 {
  background-color: rgba(0, 0, 0, 0.9);
}
.black-opacity-70 {
  background-color: rgba(0, 0, 0, 0.7);
}
.black-opacity-50 {
  background-color: rgba(0, 0, 0, 0.5);
}
.black-opacity-30 {
  background-color: rgba(0, 0, 0, 0.3);
}
.black-opacity-10 {
  background-color: rgba(0, 0, 0, 0.1);
}
.white {
  color: rgba(0, 0, 0, 0.8);
  background-color: #fff !important;
}
.white-opacity-90 {
  background-color: rgba(255, 255, 255, 0.9);
}
.white-opacity-70 {
  background-color: rgba(255, 255, 255, 0.7);
}
.white-opacity-50 {
  background-color: rgba(255, 255, 255, 0.5);
}
.white-opacity-30 {
  background-color: rgba(255, 255, 255, 0.3);
}
.white-opacity-10 {
  background-color: rgba(255, 255, 255, 0.1);
}
.text-red,
i.red {
  color: #f44336;
}
.text-red-50,
i.red-50 {
  color: #ffebee;
}
.text-red-100,
i.red-100 {
  color: #ffcdd2;
}
.text-red-200,
i.red-200 {
  color: #ef9a9a;
}
.text-red-300,
i.red-300 {
  color: #e57373;
}
.text-red-400,
i.red-400 {
  color: #ef5350;
}
.text-red-500,
i.red-500 {
  color: #f44336;
}
.text-red-600,
i.red-600 {
  color: #e53935;
}
.text-red-700,
i.red-700 {
  color: #d32f2f;
}
.text-red-800,
i.red-800 {
  color: #c62828;
}
.text-red-900,
i.red-900 {
  color: #b71c1c;
}
.text-pink,
i.pink {
  color: #e91e63;
}
.text-pink-50,
i.pink-50 {
  color: #fce4ec;
}
.text-pink-100,
i.pink-100 {
  color: #f8bbd0;
}
.text-pink-200,
i.pink-200 {
  color: #f48fb1;
}
.text-Pink-300,
i.Pink-300 {
  color: #f06292;
}
.text-pink-400,
i.pink-400 {
  color: #ec407a;
}
.text-pink-500,
i.pink-500 {
  color: #e91e63;
}
.text-pink-600,
i.pink-600 {
  color: #d81b60;
}
.text-pink-700,
i.pink-700 {
  color: #c2185b;
}
.text-pink-800,
i.pink-800 {
  color: #ad1457;
}
.text-pink-900,
i.pink-900 {
  color: #880e4f;
}
.text-purple,
i.purple {
  color: #9c27b0;
}
.text-purple-50,
i.purple-50 {
  color: #f3e5f5;
}
.text-purple-100,
i.purple-100 {
  color: #e1bee7;
}
.text-purple-200,
i.purple-200 {
  color: #ce93d8;
}
.text-purple-300,
i.purple-300 {
  color: #ba68c8;
}
.text-purple-400,
i.purple-400 {
  color: #ab47bc;
}
.text-purple-500,
i.purple-500 {
  color: #9c27b0;
}
.text-purple-600,
i.purple-600 {
  color: #8e24aa;
}
.text-purple-700,
i.purple-700 {
  color: #7b1fa2;
}
.text-purple-800,
i.purple-800 {
  color: #6a1b9a;
}
.text-purple-900,
i.purple-900 {
  color: #4a148c;
}
.text-deep-purple,
i.deep-purple {
  color: #673ab7;
}
.text-deep-purple-50,
i.deep-purple-50 {
  color: #ede7f6;
}
.text-deep-purple-100,
i.deep-purple-100 {
  color: #d1c4e9;
}
.text-deep-purple-200,
i.deep-purple-200 {
  color: #b39ddb;
}
.text-deep-purple-300,
i.deep-purple-300 {
  color: #9575cd;
}
.text-deep-purple-400,
i.deep-purple-400 {
  color: #7e57c2;
}
.text-deep-purple-500,
i.deep-purple-500 {
  color: #673ab7;
}
.text-deep-purple-600,
i.deep-purple-600 {
  color: #5e35b1;
}
.text-deep-purple-700,
i.deep-purple-700 {
  color: #512da8;
}
.text-deep-purple-800,
i.deep-purple-800 {
  color: #4527a0;
}
.text-deep-purple-900,
i.deep-purple-900 {
  color: #311b92;
}
.text-indigo,
i.indigo {
  color: #3f51b5;
}
.text-indigo-50,
i.indigo-50 {
  color: #e8eaf6;
}
.text-indigo-100,
i.indigo-100 {
  color: #c5cae9;
}
.text-indigo-200,
i.indigo-200 {
  color: #9fa8da;
}
.text-indigo-300,
i.indigo-300 {
  color: #7986cb;
}
.text-indigo-400,
i.indigo-400 {
  color: #5c6bc0;
}
.text-indigo-500,
i.indigo-500 {
  color: #3f51b5;
}
.text-indigo-600,
i.indigo-600 {
  color: #3949ab;
}
.text-indigo-700,
i.indigo-700 {
  color: #303f9f;
}
.text-indigo-800,
i.indigo-800 {
  color: #283593;
}
.text-indigo-900,
i.indigo-900 {
  color: #1a237e;
}
.text-blue,
i.blue {
  color: #2196f3;
}
.text-blue-50,
i.blue-50 {
  color: #e3f2fd;
}
.text-blue-100,
i.blue-100 {
  color: #bbdefb;
}
.text-blue-200,
i.blue-200 {
  color: #90caf9;
}
.text-blue-300,
i.blue-300 {
  color: #64b5f6;
}
.text-blue-400,
i.blue-400 {
  color: #42a5f5;
}
.text-blue-500,
i.blue-500 {
  color: #2196f3;
}
.text-blue-600,
i.blue-600 {
  color: #1e88e5;
}
.text-blue-700,
i.blue-700 {
  color: #1976d2;
}
.text-blue-800,
i.blue-800 {
  color: #1565c0;
}
.text-blue-900,
i.blue-900 {
  color: #0d47a1;
}
.text-light-blue,
i.light-blue {
  color: #03a9f4;
}
.text-light-blue-50,
i.light-blue-50 {
  color: #e1f5fe;
}
.text-light-blue-100,
i.light-blue-100 {
  color: #b3e5fc;
}
.text-light-blue-200,
i.light-blue-200 {
  color: #81d4fa;
}
.text-light-blue-300,
i.light-blue-300 {
  color: #4fc3f7;
}
.text-light-blue-400,
i.light-blue-400 {
  color: #29b6f6;
}
.text-light-blue-500,
i.light-blue-500 {
  color: #03a9f4;
}
.text-light-blue-600,
i.light-blue-600 {
  color: #039be5;
}
.text-light-blue-700,
i.light-blue-700 {
  color: #0288d1;
}
.text-light-blue-800,
i.light-blue-800 {
  color: #0277bd;
}
.text-light-blue-900,
i.light-blue-900 {
  color: #01579b;
}
.text-cyan,
i.cyan {
  color: #00bcd4;
}
.text-cyan-50,
i.cyan-50 {
  color: #e0f7fa;
}
.text-cyan-100,
i.cyan-100 {
  color: #b2ebf2;
}
.text-cyan-200,
i.cyan-200 {
  color: #80deea;
}
.text-cyan-300,
i.cyan-300 {
  color: #4dd0e1;
}
.text-cyan-400,
i.cyan-400 {
  color: #26c6da;
}
.text-cyan-500,
i.cyan-500 {
  color: #00bcd4;
}
.text-cyan-600,
i.cyan-600 {
  color: #00acc1;
}
.text-cyan-700,
i.cyan-700 {
  color: #0097a7;
}
.text-cyan-800,
i.cyan-800 {
  color: #00838f;
}
.text-cyan-900,
i.cyan-900 {
  color: #006064;
}
.text-teal,
i.teal {
  color: #009688;
}
.text-teal-50,
i.teal-50 {
  color: #e0f2f1;
}
.text-teal-100,
i.teal-100 {
  color: #b2dfdb;
}
.text-teal-200,
i.teal-200 {
  color: #80cbc4;
}
.text-teal-300,
i.teal-300 {
  color: #4db6ac;
}
.text-teal-400,
i.teal-400 {
  color: #26a69a;
}
.text-teal-500,
i.teal-500 {
  color: #009688;
}
.text-teal-600,
i.teal-600 {
  color: #00897b;
}
.text-teal-700,
i.teal-700 {
  color: #00796b;
}
.text-teal-800,
i.teal-800 {
  color: #00695c;
}
.text-teal-900,
i.teal-900 {
  color: #004d40;
}
.text-green,
i.green {
  color: #4caf50;
}
.text-green-50,
i.green-50 {
  color: #e8f5e9;
}
.text-green-100,
i.green-100 {
  color: #c8e6c9;
}
.text-green-200,
i.green-200 {
  color: #a5d6a7;
}
.text-green-300,
i.green-300 {
  color: #81c784;
}
.text-green-400,
i.green-400 {
  color: #66bb6a;
}
.text-green-500,
i.green-500 {
  color: #4caf50;
}
.text-green-600,
i.green-600 {
  color: #43a047;
}
.text-green-700,
i.green-700 {
  color: #388e3c;
}
.text-green-800,
i.green-800 {
  color: #2e7d32;
}
.text-green-900,
i.green-900 {
  color: #1b5e20;
}
.text-light-green,
i.light-green {
  color: #8bc34a;
}
.text-light-green-50,
i.light-green-50 {
  color: #f1f8e9;
}
.text-light-green-100,
i.light-green-100 {
  color: #dcedc8;
}
.text-light-green-200,
i.light-green-200 {
  color: #c5e1a5;
}
.text-light-green-300,
i.light-green-300 {
  color: #aed581;
}
.text-light-green-400,
i.light-green-400 {
  color: #9ccc65;
}
.text-light-green-500,
i.light-green-500 {
  color: #8bc34a;
}
.text-light-green-600,
i.light-green-600 {
  color: #7cb342;
}
.text-light-green-700,
i.light-green-700 {
  color: #689f38;
}
.text-light-green-800,
i.light-green-800 {
  color: #558b2f;
}
.text-light-green-900,
i.light-green-900 {
  color: #33691e;
}
.text-lime,
i.lime {
  color: #cddc39;
}
.text-lime-50,
i.lime-50 {
  color: #f9fbe7;
}
.text-lime-100,
i.lime-100 {
  color: #f0f4c3;
}
.text-lime-200,
i.lime-200 {
  color: #e6ee9c;
}
.text-lime-300,
i.lime-300 {
  color: #dce775;
}
.text-lime-400,
i.lime-400 {
  color: #d4e157;
}
.text-lime-500,
i.lime-500 {
  color: #cddc39;
}
.text-lime-600,
i.lime-600 {
  color: #c0ca33;
}
.text-lime-700,
i.lime-700 {
  color: #afb42b;
}
.text-lime-800,
i.lime-800 {
  color: #9e9d24;
}
.text-lime-900,
i.lime-900 {
  color: #827717;
}
.text-yellow,
i.yellow {
  color: #ffeb3b;
}
.text-yellow-50,
i.yellow-50 {
  color: #fffde7;
}
.text-yellow-100,
i.yellow-100 {
  color: #fff9c4;
}
.text-yellow-200,
i.yellow-200 {
  color: #fff59d;
}
.text-yellow-300,
i.yellow-300 {
  color: #fff176;
}
.text-yellow-400,
i.yellow-400 {
  color: #ffee58;
}
.text-yellow-500,
i.yellow-500 {
  color: #ffeb3b;
}
.text-yellow-600,
i.yellow-600 {
  color: #fdd835;
}
.text-yellow-700,
i.yellow-700 {
  color: #fbc02d;
}
.text-yellow-800,
i.yellow-800 {
  color: #f9a825;
}
.text-yellow-900,
i.yellow-900 {
  color: #f57f17;
}
.text-amber,
i.amber {
  color: #ffc107;
}
.text-amber-50,
i.amber-50 {
  color: #fff8e1;
}
.text-amber-100,
i.amber-100 {
  color: #ffecb3;
}
.text-amber-200,
i.amber-200 {
  color: #ffe082;
}
.text-amber-300,
i.amber-300 {
  color: #ffd54f;
}
.text-amber-400,
i.amber-400 {
  color: #ffca28;
}
.text-amber-500,
i.amber-500 {
  color: #ffc107;
}
.text-amber-600,
i.amber-600 {
  color: #ffb300;
}
.text-amber-700,
i.amber-700 {
  color: #ffa000;
}
.text-amber-800,
i.amber-800 {
  color: #ff8f00;
}
.text-amber-900,
i.amber-900 {
  color: #ff6f00;
}
.text-orange,
i.orange {
  color: #ff9800;
}
.text-orange-50,
i.orange-50 {
  color: #fff3e0;
}
.text-orange-100,
i.orange-100 {
  color: #ffe0b2;
}
.text-orange-200,
i.orange-200 {
  color: #ffcc80;
}
.text-orange-300,
i.orange-300 {
  color: #ffb74d;
}
.text-orange-400,
i.orange-400 {
  color: #ffa726;
}
.text-orange-500,
i.orange-500 {
  color: #ff9800;
}
.text-orange-600,
i.orange-600 {
  color: #fb8c00;
}
.text-orange-700,
i.orange-700 {
  color: #f57c00;
}
.text-orange-800,
i.orange-800 {
  color: #ef6c00;
}
.text-orange-900,
i.orange-900 {
  color: #e65100;
}
.text-deep-orange,
i.deep-orange {
  color: #ff5722;
}
.text-deep-orange-50,
i.deep-orange-50 {
  color: #fbe9e7;
}
.text-deep-orange-100,
i.deep-orange-100 {
  color: #ffccbc;
}
.text-deep-orange-200,
i.deep-orange-200 {
  color: #ffab91;
}
.text-deep-orange-300,
i.deep-orange-300 {
  color: #ff8a65;
}
.text-deep-orange-400,
i.deep-orange-400 {
  color: #ff7043;
}
.text-deep-orange-500,
i.deep-orange-500 {
  color: #ff5722;
}
.text-deep-orange-600,
i.deep-orange-600 {
  color: #f4511e;
}
.text-deep-orange-700,
i.deep-orange-700 {
  color: #e64a19;
}
.text-deep-orange-800,
i.deep-orange-800 {
  color: #d84315;
}
.text-deep-orange-900,
i.deep-orange-900 {
  color: #bf360c;
}
.text-brown,
i.brown {
  color: #795548;
}
.text-brown-50,
i.brown-50 {
  color: #efebe9;
}
.text-brown-100,
i.brown-100 {
  color: #d7ccc8;
}
.text-brown-200,
i.brown-200 {
  color: #bcaaa4;
}
.text-brown-300,
i.brown-300 {
  color: #a1887f;
}
.text-brown-400,
i.brown-400 {
  color: #8d6e63;
}
.text-brown-500,
i.brown-500 {
  color: #795548;
}
.text-brown-600,
i.brown-600 {
  color: #6d4c41;
}
.text-brown-700,
i.brown-700 {
  color: #5d4037;
}
.text-brown-800,
i.brown-800 {
  color: #4e342e;
}
.text-brown-900,
i.brown-900 {
  color: #3e2723;
}
.text-grey,
i.grey {
  color: #9e9e9e;
}
.text-grey-50,
i.grey-50 {
  color: #fafafa;
}
.text-grey-100,
i.grey-100 {
  color: #f5f5f5;
}
.text-grey-200,
i.grey-200 {
  color: #eee;
}
.text-grey-300,
i.grey-300 {
  color: #e0e0e0;
}
.text-grey-400,
i.grey-400 {
  color: #bdbdbd;
}
.text-grey-500,
i.grey-500 {
  color: #9e9e9e;
}
.text-grey-600,
i.grey-600 {
  color: #757575;
}
.text-grey-700,
i.grey-700 {
  color: #616161;
}
.text-grey-800,
i.grey-800 {
  color: #424242;
}
.text-grey-900,
i.grey-900 {
  color: #212121;
}
.text-blue-grey,
i.blue-grey {
  color: #607d8b;
}
.text-blue-grey-50,
i.blue-grey-50 {
  color: #eceff1;
}
.text-blue-grey-100,
i.blue-grey-100 {
  color: #cfd8dc;
}
.text-blue-grey-200,
i.blue-grey-200 {
  color: #b0bec5;
}
.text-blue-grey-300,
i.blue-grey-300 {
  color: #90a4ae;
}
.text-blue-grey-400,
i.blue-grey-400 {
  color: #78909c;
}
.text-blue-grey-500,
i.blue-grey-500 {
  color: #607d8b;
}
.text-blue-grey-600,
i.blue-grey-600 {
  color: #546e7a;
}
.text-blue-grey-700,
i.blue-grey-700 {
  color: #455a64;
}
.text-blue-grey-800,
i.blue-grey-800 {
  color: #37474f;
}
.text-blue-grey-900,
i.blue-grey-900 {
  color: #263238;
}
.text-black,
i.black {
  color: #000;
}
.text-white,
i.white {
  color: #fff;
}
.border-red {
  border: 1px solid #f44336;
}
.border-red-50 {
  border: 1px solid #ffebee;
}
.border-red-100 {
  border: 1px solid #ffcdd2;
}
.border-red-200 {
  border: 1px solid #ef9a9a;
}
.border-red-300 {
  border: 1px solid #e57373;
}
.border-red-400 {
  border: 1px solid #ef5350;
}
.border-red-500 {
  border: 1px solid #f44336;
}
.border-red-600 {
  border: 1px solid #e53935;
}
.border-red-700 {
  border: 1px solid #d32f2f;
}
.border-red-800 {
  border: 1px solid #c62828;
}
.border-red-900 {
  border: 1px solid #b71c1c;
}
.border-pink {
  border: 1px solid #e91e63;
}
.border-pink-50 {
  border: 1px solid #fce4ec;
}
.border-pink-100 {
  border: 1px solid #f8bbd0;
}
.border-pink-200 {
  border: 1px solid #f48fb1;
}
.border-pink-300 {
  border: 1px solid #f06292;
}
.border-pink-400 {
  border: 1px solid #ec407a;
}
.border-pink-500 {
  border: 1px solid #e91e63;
}
.border-pink-600 {
  border: 1px solid #d81b60;
}
.border-pink-700 {
  border: 1px solid #c2185b;
}
.border-pink-800 {
  border: 1px solid #ad1457;
}
.border-pink-900 {
  border: 1px solid #880e4f;
}
.border-purple {
  border: 1px solid #9c27b0;
}
.border-purple-50 {
  border: 1px solid #f3e5f5;
}
.border-purple-100 {
  border: 1px solid #e1bee7;
}
.border-purple-200 {
  border: 1px solid #ce93d8;
}
.border-purple-300 {
  border: 1px solid #ba68c8;
}
.border-purple-400 {
  border: 1px solid #ab47bc;
}
.border-purple-500 {
  border: 1px solid #9c27b0;
}
.border-purple-600 {
  border: 1px solid #8e24aa;
}
.border-purple-700 {
  border: 1px solid #7b1fa2;
}
.border-purple-800 {
  border: 1px solid #6a1b9a;
}
.border-purple-900 {
  border: 1px solid #4a148c;
}
.border-deep-purple {
  border: 1px solid #673ab7;
}
.border-deep-purple-50 {
  border: 1px solid #ede7f6;
}
.border-deep-purple-100 {
  border: 1px solid #d1c4e9;
}
.border-deep-purple-200 {
  border: 1px solid #b39ddb;
}
.border-deep-purple-300 {
  border: 1px solid #9575cd;
}
.border-deep-purple-400 {
  border: 1px solid #7e57c2;
}
.border-deep-purple-500 {
  border: 1px solid #673ab7;
}
.border-deep-purple-600 {
  border: 1px solid #5e35b1;
}
.border-deep-purple-700 {
  border: 1px solid #512da8;
}
.border-deep-purple-800 {
  border: 1px solid #4527a0;
}
.border-deep-purple-900 {
  border: 1px solid #311b92;
}
.border-indigo {
  border: 1px solid #3f51b5;
}
.border-indigo-50 {
  border: 1px solid #e8eaf6;
}
.border-indigo-100 {
  border: 1px solid #c5cae9;
}
.border-indigo-200 {
  border: 1px solid #9fa8da;
}
.border-indigo-300 {
  border: 1px solid #7986cb;
}
.border-indigo-400 {
  border: 1px solid #5c6bc0;
}
.border-indigo-500 {
  border: 1px solid #3f51b5;
}
.border-indigo-600 {
  border: 1px solid #3949ab;
}
.border-indigo-700 {
  border: 1px solid #303f9f;
}
.border-indigo-800 {
  border: 1px solid #283593;
}
.border-indigo-900 {
  border: 1px solid #1a237e;
}
.border-blue {
  border: 1px solid #2196f3;
}
.border-blue-50 {
  border: 1px solid #e3f2fd;
}
.border-blue-100 {
  border: 1px solid #bbdefb;
}
.border-blue-200 {
  border: 1px solid #90caf9;
}
.border-blue-300 {
  border: 1px solid #64b5f6;
}
.border-blue-400 {
  border: 1px solid #42a5f5;
}
.border-blue-500 {
  border: 1px solid #2196f3;
}
.border-blue-600 {
  border: 1px solid #1e88e5;
}
.border-blue-700 {
  border: 1px solid #1976d2;
}
.border-blue-800 {
  border: 1px solid #1565c0;
}
.border-blue-900 {
  border: 1px solid #0d47a1;
}
.border-light-blue {
  border: 1px solid #03a9f4;
}
.border-light-blue-50 {
  border: 1px solid #e1f5fe;
}
.border-light-blue-100 {
  border: 1px solid #b3e5fc;
}
.border-light-blue-200 {
  border: 1px solid #81d4fa;
}
.border-light-blue-300 {
  border: 1px solid #4fc3f7;
}
.border-light-blue-400 {
  border: 1px solid #29b6f6;
}
.border-light-blue-500 {
  border: 1px solid #03a9f4;
}
.border-light-blue-600 {
  border: 1px solid #039be5;
}
.border-light-blue-700 {
  border: 1px solid #0288d1;
}
.border-light-blue-800 {
  border: 1px solid #0277bd;
}
.border-light-blue-900 {
  border: 1px solid #01579b;
}
.border-cyan {
  border: 1px solid #00bcd4;
}
.border-cyan-50 {
  border: 1px solid #e0f7fa;
}
.border-cyan-100 {
  border: 1px solid #b2ebf2;
}
.border-cyan-200 {
  border: 1px solid #80deea;
}
.border-cyan-300 {
  border: 1px solid #4dd0e1;
}
.border-cyan-400 {
  border: 1px solid #26c6da;
}
.border-cyan-500 {
  border: 1px solid #00bcd4;
}
.border-cyan-600 {
  border: 1px solid #00acc1;
}
.border-cyan-700 {
  border: 1px solid #0097a7;
}
.border-cyan-800 {
  border: 1px solid #00838f;
}
.border-cyan-900 {
  border: 1px solid #006064;
}
.border-teal {
  border: 1px solid #009688;
}
.border-teal-50 {
  border: 1px solid #e0f2f1;
}
.border-teal-100 {
  border: 1px solid #b2dfdb;
}
.border-teal-200 {
  border: 1px solid #80cbc4;
}
.border-teal-300 {
  border: 1px solid #4db6ac;
}
.border-teal-400 {
  border: 1px solid #26a69a;
}
.border-teal-500 {
  border: 1px solid #009688;
}
.border-teal-600 {
  border: 1px solid #00897b;
}
.border-teal-700 {
  border: 1px solid #00796b;
}
.border-teal-800 {
  border: 1px solid #00695c;
}
.border-teal-900 {
  border: 1px solid #004d40;
}
.border-green {
  border: 1px solid #4caf50;
}
.border-green-50 {
  border: 1px solid #e8f5e9;
}
.border-green-100 {
  border: 1px solid #c8e6c9;
}
.border-green-200 {
  border: 1px solid #a5d6a7;
}
.border-green-300 {
  border: 1px solid #81c784;
}
.border-green-400 {
  border: 1px solid #66bb6a;
}
.border-green-500 {
  border: 1px solid #4caf50;
}
.border-green-600 {
  border: 1px solid #43a047;
}
.border-green-700 {
  border: 1px solid #388e3c;
}
.border-green-800 {
  border: 1px solid #2e7d32;
}
.border-green-900 {
  border: 1px solid #1b5e20;
}
.border-light-green {
  border: 1px solid #8bc34a;
}
.border-light-green-50 {
  border: 1px solid #f1f8e9;
}
.border-light-green-100 {
  border: 1px solid #dcedc8;
}
.border-light-green-200 {
  border: 1px solid #c5e1a5;
}
.border-light-green-300 {
  border: 1px solid #aed581;
}
.border-light-green-400 {
  border: 1px solid #9ccc65;
}
.border-light-green-500 {
  border: 1px solid #8bc34a;
}
.border-light-green-600 {
  border: 1px solid #7cb342;
}
.border-light-green-700 {
  border: 1px solid #689f38;
}
.border-light-green-800 {
  border: 1px solid #558b2f;
}
.border-light-green-900 {
  border: 1px solid #33691e;
}
.border-lime {
  border: 1px solid #cddc39;
}
.border-lime-50 {
  border: 1px solid #f9fbe7;
}
.border-lime-100 {
  border: 1px solid #f0f4c3;
}
.border-lime-200 {
  border: 1px solid #e6ee9c;
}
.border-lime-300 {
  border: 1px solid #dce775;
}
.border-lime-400 {
  border: 1px solid #d4e157;
}
.border-lime-500 {
  border: 1px solid #cddc39;
}
.border-lime-600 {
  border: 1px solid #c0ca33;
}
.border-lime-700 {
  border: 1px solid #afb42b;
}
.border-lime-800 {
  border: 1px solid #9e9d24;
}
.border-lime-900 {
  border: 1px solid #827717;
}
.border-yellow {
  border: 1px solid #ffeb3b;
}
.border-yellow-50 {
  border: 1px solid #fffde7;
}
.border-yellow-100 {
  border: 1px solid #fff9c4;
}
.border-yellow-200 {
  border: 1px solid #fff59d;
}
.border-yellow-300 {
  border: 1px solid #fff176;
}
.border-yellow-400 {
  border: 1px solid #ffee58;
}
.border-yellow-500 {
  border: 1px solid #ffeb3b;
}
.border-yellow-600 {
  border: 1px solid #fdd835;
}
.border-yellow-700 {
  border: 1px solid #fbc02d;
}
.border-yellow-800 {
  border: 1px solid #f9a825;
}
.border-yellow-900 {
  border: 1px solid #f57f17;
}
.border-amber {
  border: 1px solid #ffc107;
}
.border-amber-50 {
  border: 1px solid #fff8e1;
}
.border-amber-100 {
  border: 1px solid #ffecb3;
}
.border-amber-200 {
  border: 1px solid #ffe082;
}
.border-amber-300 {
  border: 1px solid #ffd54f;
}
.border-amber-400 {
  border: 1px solid #ffca28;
}
.border-amber-500 {
  border: 1px solid #ffc107;
}
.border-amber-600 {
  border: 1px solid #ffb300;
}
.border-amber-700 {
  border: 1px solid #ffa000;
}
.border-amber-800 {
  border: 1px solid #ff8f00;
}
.border-amber-900 {
  border: 1px solid #ff6f00;
}
.border-orange {
  border: 1px solid #ff9800;
}
.border-orange-50 {
  border: 1px solid #fff3e0;
}
.border-orange-100 {
  border: 1px solid #ffe0b2;
}
.border-orange-200 {
  border: 1px solid #ffcc80;
}
.border-orange-300 {
  border: 1px solid #ffb74d;
}
.border-orange-400 {
  border: 1px solid #ffa726;
}
.border-orange-500 {
  border: 1px solid #ff9800;
}
.border-orange-600 {
  border: 1px solid #fb8c00;
}
.border-orange-700 {
  border: 1px solid #f57c00;
}
.border-orange-800 {
  border: 1px solid #ef6c00;
}
.border-orange-900 {
  border: 1px solid #e65100;
}
.border-deep-orange {
  border: 1px solid #ff5722;
}
.border-deep-orange-50 {
  border: 1px solid #fbe9e7;
}
.border-deep-orange-100 {
  border: 1px solid #ffccbc;
}
.border-deep-orange-200 {
  border: 1px solid #ffab91;
}
.border-deep-orange-300 {
  border: 1px solid #ff8a65;
}
.border-deep-orange-400 {
  border: 1px solid #ff7043;
}
.border-deep-orange-500 {
  border: 1px solid #ff5722;
}
.border-deep-orange-600 {
  border: 1px solid #f4511e;
}
.border-deep-orange-700 {
  border: 1px solid #e64a19;
}
.border-deep-orange-800 {
  border: 1px solid #d84315;
}
.border-deep-orange-900 {
  border: 1px solid #bf360c;
}
.border-brown {
  border: 1px solid #795548;
}
.border-brown-50 {
  border: 1px solid #efebe9;
}
.border-brown-100 {
  border: 1px solid #d7ccc8;
}
.border-brown-200 {
  border: 1px solid #bcaaa4;
}
.border-brown-300 {
  border: 1px solid #a1887f;
}
.border-brown-400 {
  border: 1px solid #8d6e63;
}
.border-brown-500 {
  border: 1px solid #795548;
}
.border-brown-600 {
  border: 1px solid #6d4c41;
}
.border-brown-700 {
  border: 1px solid #5d4037;
}
.border-brown-800 {
  border: 1px solid #4e342e;
}
.border-brown-900 {
  border: 1px solid #3e2723;
}
.border-grey {
  border: 1px solid #9e9e9e;
}
.border-grey-50 {
  border: 1px solid #fafafa;
}
.border-grey-100 {
  border: 1px solid #f5f5f5;
}
.border-grey-200 {
  border: 1px solid #eee;
}
.border-grey-300 {
  border: 1px solid #e0e0e0;
}
.border-grey-400 {
  border: 1px solid #bdbdbd;
}
.border-grey-500 {
  border: 1px solid #9e9e9e;
}
.border-grey-600 {
  border: 1px solid #757575;
}
.border-grey-700 {
  border: 1px solid #616161;
}
.border-grey-800 {
  border: 1px solid #424242;
}
.border-grey-900 {
  border: 1px solid #212121;
}
.border-blue-grey {
  border: 1px solid #607d8b;
}
.border-blue-grey-50 {
  border: 1px solid #eceff1;
}
.border-blue-grey-100 {
  border: 1px solid #cfd8dc;
}
.border-blue-grey-200 {
  border: 1px solid #b0bec5;
}
.border-blue-grey-300 {
  border: 1px solid #90a4ae;
}
.border-blue-grey-400 {
  border: 1px solid #78909c;
}
.border-blue-grey-500 {
  border: 1px solid #607d8b;
}
.border-blue-grey-600 {
  border: 1px solid #546e7a;
}
.border-blue-grey-700 {
  border: 1px solid #455a64;
}
.border-blue-grey-800 {
  border: 1px solid #37474f;
}
.border-blue-grey-900 {
  border: 1px solid #263238;
}
.border-black {
  border: 1px solid #000;
}
.border-white {
  border: 1px solid #fff;
}
i.icon {
  background: 0 0 !important;
}
.ion,
.ion-alert-circled:before,
.ion-alert:before,
.ion-android-add-circle:before,
.ion-android-add:before,
.ion-android-alarm-clock:before,
.ion-android-alert:before,
.ion-android-apps:before,
.ion-android-archive:before,
.ion-android-arrow-back:before,
.ion-android-arrow-down:before,
.ion-android-arrow-dropdown-circle:before,
.ion-android-arrow-dropdown:before,
.ion-android-arrow-dropleft-circle:before,
.ion-android-arrow-dropleft:before,
.ion-android-arrow-dropright-circle:before,
.ion-android-arrow-dropright:before,
.ion-android-arrow-dropup-circle:before,
.ion-android-arrow-dropup:before,
.ion-android-arrow-forward:before,
.ion-android-arrow-up:before,
.ion-android-attach:before,
.ion-android-bar:before,
.ion-android-bicycle:before,
.ion-android-boat:before,
.ion-android-bookmark:before,
.ion-android-bulb:before,
.ion-android-bus:before,
.ion-android-calendar:before,
.ion-android-call:before,
.ion-android-camera:before,
.ion-android-cancel:before,
.ion-android-car:before,
.ion-android-cart:before,
.ion-android-chat:before,
.ion-android-checkbox-blank:before,
.ion-android-checkbox-outline-blank:before,
.ion-android-checkbox-outline:before,
.ion-android-checkbox:before,
.ion-android-checkmark-circle:before,
.ion-android-clipboard:before,
.ion-android-close:before,
.ion-android-cloud-circle:before,
.ion-android-cloud-done:before,
.ion-android-cloud-outline:before,
.ion-android-cloud:before,
.ion-android-color-palette:before,
.ion-android-compass:before,
.ion-android-contact:before,
.ion-android-contacts:before,
.ion-android-contract:before,
.ion-android-create:before,
.ion-android-delete:before,
.ion-android-desktop:before,
.ion-android-document:before,
.ion-android-done-all:before,
.ion-android-done:before,
.ion-android-download:before,
.ion-android-drafts:before,
.ion-android-exit:before,
.ion-android-expand:before,
.ion-android-favorite-outline:before,
.ion-android-favorite:before,
.ion-android-film:before,
.ion-android-folder-open:before,
.ion-android-folder:before,
.ion-android-funnel:before,
.ion-android-globe:before,
.ion-android-hand:before,
.ion-android-hangout:before,
.ion-android-happy:before,
.ion-android-home:before,
.ion-android-image:before,
.ion-android-laptop:before,
.ion-android-list:before,
.ion-android-locate:before,
.ion-android-lock:before,
.ion-android-mail:before,
.ion-android-map:before,
.ion-android-menu:before,
.ion-android-microphone-off:before,
.ion-android-microphone:before,
.ion-android-more-horizontal:before,
.ion-android-more-vertical:before,
.ion-android-navigate:before,
.ion-android-notifications-none:before,
.ion-android-notifications-off:before,
.ion-android-notifications:before,
.ion-android-open:before,
.ion-android-options:before,
.ion-android-people:before,
.ion-android-person-add:before,
.ion-android-person:before,
.ion-android-phone-landscape:before,
.ion-android-phone-portrait:before,
.ion-android-pin:before,
.ion-android-plane:before,
.ion-android-playstore:before,
.ion-android-print:before,
.ion-android-radio-button-off:before,
.ion-android-radio-button-on:before,
.ion-android-refresh:before,
.ion-android-remove-circle:before,
.ion-android-remove:before,
.ion-android-restaurant:before,
.ion-android-sad:before,
.ion-android-search:before,
.ion-android-send:before,
.ion-android-settings:before,
.ion-android-share-alt:before,
.ion-android-share:before,
.ion-android-star-half:before,
.ion-android-star-outline:before,
.ion-android-star:before,
.ion-android-stopwatch:before,
.ion-android-subway:before,
.ion-android-sunny:before,
.ion-android-sync:before,
.ion-android-textsms:before,
.ion-android-time:before,
.ion-android-train:before,
.ion-android-unlock:before,
.ion-android-upload:before,
.ion-android-volume-down:before,
.ion-android-volume-mute:before,
.ion-android-volume-off:before,
.ion-android-volume-up:before,
.ion-android-walk:before,
.ion-android-warning:before,
.ion-android-watch:before,
.ion-android-wifi:before,
.ion-aperture:before,
.ion-archive:before,
.ion-arrow-down-a:before,
.ion-arrow-down-b:before,
.ion-arrow-down-c:before,
.ion-arrow-expand:before,
.ion-arrow-graph-down-left:before,
.ion-arrow-graph-down-right:before,
.ion-arrow-graph-up-left:before,
.ion-arrow-graph-up-right:before,
.ion-arrow-left-a:before,
.ion-arrow-left-b:before,
.ion-arrow-left-c:before,
.ion-arrow-move:before,
.ion-arrow-resize:before,
.ion-arrow-return-left:before,
.ion-arrow-return-right:before,
.ion-arrow-right-a:before,
.ion-arrow-right-b:before,
.ion-arrow-right-c:before,
.ion-arrow-shrink:before,
.ion-arrow-swap:before,
.ion-arrow-up-a:before,
.ion-arrow-up-b:before,
.ion-arrow-up-c:before,
.ion-asterisk:before,
.ion-at:before,
.ion-backspace-outline:before,
.ion-backspace:before,
.ion-bag:before,
.ion-battery-charging:before,
.ion-battery-empty:before,
.ion-battery-full:before,
.ion-battery-half:before,
.ion-battery-low:before,
.ion-beaker:before,
.ion-beer:before,
.ion-bluetooth:before,
.ion-bonfire:before,
.ion-bookmark:before,
.ion-bowtie:before,
.ion-briefcase:before,
.ion-bug:before,
.ion-calculator:before,
.ion-calendar:before,
.ion-camera:before,
.ion-card:before,
.ion-cash:before,
.ion-chatbox-working:before,
.ion-chatbox:before,
.ion-chatboxes:before,
.ion-chatbubble-working:before,
.ion-chatbubble:before,
.ion-chatbubbles:before,
.ion-checkmark-circled:before,
.ion-checkmark-round:before,
.ion-checkmark:before,
.ion-chevron-down:before,
.ion-chevron-left:before,
.ion-chevron-right:before,
.ion-chevron-up:before,
.ion-clipboard:before,
.ion-clock:before,
.ion-close-circled:before,
.ion-close-round:before,
.ion-close:before,
.ion-closed-captioning:before,
.ion-cloud:before,
.ion-code-download:before,
.ion-code-working:before,
.ion-code:before,
.ion-coffee:before,
.ion-compass:before,
.ion-compose:before,
.ion-connection-bars:before,
.ion-contrast:before,
.ion-crop:before,
.ion-cube:before,
.ion-disc:before,
.ion-document-text:before,
.ion-document:before,
.ion-drag:before,
.ion-earth:before,
.ion-easel:before,
.ion-edit:before,
.ion-egg:before,
.ion-eject:before,
.ion-email-unread:before,
.ion-email:before,
.ion-erlenmeyer-flask-bubbles:before,
.ion-erlenmeyer-flask:before,
.ion-eye-disabled:before,
.ion-eye:before,
.ion-female:before,
.ion-filing:before,
.ion-film-marker:before,
.ion-fireball:before,
.ion-flag:before,
.ion-flame:before,
.ion-flash-off:before,
.ion-flash:before,
.ion-folder:before,
.ion-fork-repo:before,
.ion-fork:before,
.ion-forward:before,
.ion-funnel:before,
.ion-gear-a:before,
.ion-gear-b:before,
.ion-grid:before,
.ion-hammer:before,
.ion-happy-outline:before,
.ion-happy:before,
.ion-headphone:before,
.ion-heart-broken:before,
.ion-heart:before,
.ion-help-buoy:before,
.ion-help-circled:before,
.ion-help:before,
.ion-home:before,
.ion-icecream:before,
.ion-image:before,
.ion-images:before,
.ion-information-circled:before,
.ion-information:before,
.ion-ionic:before,
.ion-ios-alarm-outline:before,
.ion-ios-alarm:before,
.ion-ios-albums-outline:before,
.ion-ios-albums:before,
.ion-ios-americanfootball-outline:before,
.ion-ios-americanfootball:before,
.ion-ios-analytics-outline:before,
.ion-ios-analytics:before,
.ion-ios-arrow-back:before,
.ion-ios-arrow-down:before,
.ion-ios-arrow-forward:before,
.ion-ios-arrow-left:before,
.ion-ios-arrow-right:before,
.ion-ios-arrow-thin-down:before,
.ion-ios-arrow-thin-left:before,
.ion-ios-arrow-thin-right:before,
.ion-ios-arrow-thin-up:before,
.ion-ios-arrow-up:before,
.ion-ios-at-outline:before,
.ion-ios-at:before,
.ion-ios-barcode-outline:before,
.ion-ios-barcode:before,
.ion-ios-baseball-outline:before,
.ion-ios-baseball:before,
.ion-ios-basketball-outline:before,
.ion-ios-basketball:before,
.ion-ios-bell-outline:before,
.ion-ios-bell:before,
.ion-ios-body-outline:before,
.ion-ios-body:before,
.ion-ios-bolt-outline:before,
.ion-ios-bolt:before,
.ion-ios-book-outline:before,
.ion-ios-book:before,
.ion-ios-bookmarks-outline:before,
.ion-ios-bookmarks:before,
.ion-ios-box-outline:before,
.ion-ios-box:before,
.ion-ios-briefcase-outline:before,
.ion-ios-briefcase:before,
.ion-ios-browsers-outline:before,
.ion-ios-browsers:before,
.ion-ios-calculator-outline:before,
.ion-ios-calculator:before,
.ion-ios-calendar-outline:before,
.ion-ios-calendar:before,
.ion-ios-camera-outline:before,
.ion-ios-camera:before,
.ion-ios-cart-outline:before,
.ion-ios-cart:before,
.ion-ios-chatboxes-outline:before,
.ion-ios-chatboxes:before,
.ion-ios-chatbubble-outline:before,
.ion-ios-chatbubble:before,
.ion-ios-checkmark-empty:before,
.ion-ios-checkmark-outline:before,
.ion-ios-checkmark:before,
.ion-ios-circle-filled:before,
.ion-ios-circle-outline:before,
.ion-ios-clock-outline:before,
.ion-ios-clock:before,
.ion-ios-close-empty:before,
.ion-ios-close-outline:before,
.ion-ios-close:before,
.ion-ios-cloud-download-outline:before,
.ion-ios-cloud-download:before,
.ion-ios-cloud-outline:before,
.ion-ios-cloud-upload-outline:before,
.ion-ios-cloud-upload:before,
.ion-ios-cloud:before,
.ion-ios-cloudy-night-outline:before,
.ion-ios-cloudy-night:before,
.ion-ios-cloudy-outline:before,
.ion-ios-cloudy:before,
.ion-ios-cog-outline:before,
.ion-ios-cog:before,
.ion-ios-color-filter-outline:before,
.ion-ios-color-filter:before,
.ion-ios-color-wand-outline:before,
.ion-ios-color-wand:before,
.ion-ios-compose-outline:before,
.ion-ios-compose:before,
.ion-ios-contact-outline:before,
.ion-ios-contact:before,
.ion-ios-copy-outline:before,
.ion-ios-copy:before,
.ion-ios-crop-strong:before,
.ion-ios-crop:before,
.ion-ios-download-outline:before,
.ion-ios-download:before,
.ion-ios-drag:before,
.ion-ios-email-outline:before,
.ion-ios-email:before,
.ion-ios-eye-outline:before,
.ion-ios-eye:before,
.ion-ios-fastforward-outline:before,
.ion-ios-fastforward:before,
.ion-ios-filing-outline:before,
.ion-ios-filing:before,
.ion-ios-film-outline:before,
.ion-ios-film:before,
.ion-ios-flag-outline:before,
.ion-ios-flag:before,
.ion-ios-flame-outline:before,
.ion-ios-flame:before,
.ion-ios-flask-outline:before,
.ion-ios-flask:before,
.ion-ios-flower-outline:before,
.ion-ios-flower:before,
.ion-ios-folder-outline:before,
.ion-ios-folder:before,
.ion-ios-football-outline:before,
.ion-ios-football:before,
.ion-ios-game-controller-a-outline:before,
.ion-ios-game-controller-a:before,
.ion-ios-game-controller-b-outline:before,
.ion-ios-game-controller-b:before,
.ion-ios-gear-outline:before,
.ion-ios-gear:before,
.ion-ios-glasses-outline:before,
.ion-ios-glasses:before,
.ion-ios-grid-view-outline:before,
.ion-ios-grid-view:before,
.ion-ios-heart-outline:before,
.ion-ios-heart:before,
.ion-ios-help-empty:before,
.ion-ios-help-outline:before,
.ion-ios-help:before,
.ion-ios-home-outline:before,
.ion-ios-home:before,
.ion-ios-infinite-outline:before,
.ion-ios-infinite:before,
.ion-ios-information-empty:before,
.ion-ios-information-outline:before,
.ion-ios-information:before,
.ion-ios-ionic-outline:before,
.ion-ios-keypad-outline:before,
.ion-ios-keypad:before,
.ion-ios-lightbulb-outline:before,
.ion-ios-lightbulb:before,
.ion-ios-list-outline:before,
.ion-ios-list:before,
.ion-ios-location-outline:before,
.ion-ios-location:before,
.ion-ios-locked-outline:before,
.ion-ios-locked:before,
.ion-ios-loop-strong:before,
.ion-ios-loop:before,
.ion-ios-medical-outline:before,
.ion-ios-medical:before,
.ion-ios-medkit-outline:before,
.ion-ios-medkit:before,
.ion-ios-mic-off:before,
.ion-ios-mic-outline:before,
.ion-ios-mic:before,
.ion-ios-minus-empty:before,
.ion-ios-minus-outline:before,
.ion-ios-minus:before,
.ion-ios-monitor-outline:before,
.ion-ios-monitor:before,
.ion-ios-moon-outline:before,
.ion-ios-moon:before,
.ion-ios-more-outline:before,
.ion-ios-more:before,
.ion-ios-musical-note:before,
.ion-ios-musical-notes:before,
.ion-ios-navigate-outline:before,
.ion-ios-navigate:before,
.ion-ios-nutrition-outline:before,
.ion-ios-nutrition:before,
.ion-ios-paper-outline:before,
.ion-ios-paper:before,
.ion-ios-paperplane-outline:before,
.ion-ios-paperplane:before,
.ion-ios-partlysunny-outline:before,
.ion-ios-partlysunny:before,
.ion-ios-pause-outline:before,
.ion-ios-pause:before,
.ion-ios-paw-outline:before,
.ion-ios-paw:before,
.ion-ios-people-outline:before,
.ion-ios-people:before,
.ion-ios-person-outline:before,
.ion-ios-person:before,
.ion-ios-personadd-outline:before,
.ion-ios-personadd:before,
.ion-ios-photos-outline:before,
.ion-ios-photos:before,
.ion-ios-pie-outline:before,
.ion-ios-pie:before,
.ion-ios-pint-outline:before,
.ion-ios-pint:before,
.ion-ios-play-outline:before,
.ion-ios-play:before,
.ion-ios-plus-empty:before,
.ion-ios-plus-outline:before,
.ion-ios-plus:before,
.ion-ios-pricetag-outline:before,
.ion-ios-pricetag:before,
.ion-ios-pricetags-outline:before,
.ion-ios-pricetags:before,
.ion-ios-printer-outline:before,
.ion-ios-printer:before,
.ion-ios-pulse-strong:before,
.ion-ios-pulse:before,
.ion-ios-rainy-outline:before,
.ion-ios-rainy:before,
.ion-ios-recording-outline:before,
.ion-ios-recording:before,
.ion-ios-redo-outline:before,
.ion-ios-redo:before,
.ion-ios-refresh-empty:before,
.ion-ios-refresh-outline:before,
.ion-ios-refresh:before,
.ion-ios-reload:before,
.ion-ios-reverse-camera-outline:before,
.ion-ios-reverse-camera:before,
.ion-ios-rewind-outline:before,
.ion-ios-rewind:before,
.ion-ios-rose-outline:before,
.ion-ios-rose:before,
.ion-ios-search-strong:before,
.ion-ios-search:before,
.ion-ios-settings-strong:before,
.ion-ios-settings:before,
.ion-ios-shuffle-strong:before,
.ion-ios-shuffle:before,
.ion-ios-skipbackward-outline:before,
.ion-ios-skipbackward:before,
.ion-ios-skipforward-outline:before,
.ion-ios-skipforward:before,
.ion-ios-snowy:before,
.ion-ios-speedometer-outline:before,
.ion-ios-speedometer:before,
.ion-ios-star-half:before,
.ion-ios-star-outline:before,
.ion-ios-star:before,
.ion-ios-stopwatch-outline:before,
.ion-ios-stopwatch:before,
.ion-ios-sunny-outline:before,
.ion-ios-sunny:before,
.ion-ios-telephone-outline:before,
.ion-ios-telephone:before,
.ion-ios-tennisball-outline:before,
.ion-ios-tennisball:before,
.ion-ios-thunderstorm-outline:before,
.ion-ios-thunderstorm:before,
.ion-ios-time-outline:before,
.ion-ios-time:before,
.ion-ios-timer-outline:before,
.ion-ios-timer:before,
.ion-ios-toggle-outline:before,
.ion-ios-toggle:before,
.ion-ios-trash-outline:before,
.ion-ios-trash:before,
.ion-ios-undo-outline:before,
.ion-ios-undo:before,
.ion-ios-unlocked-outline:before,
.ion-ios-unlocked:before,
.ion-ios-upload-outline:before,
.ion-ios-upload:before,
.ion-ios-videocam-outline:before,
.ion-ios-videocam:before,
.ion-ios-volume-high:before,
.ion-ios-volume-low:before,
.ion-ios-wineglass-outline:before,
.ion-ios-wineglass:before,
.ion-ios-world-outline:before,
.ion-ios-world:before,
.ion-ipad:before,
.ion-iphone:before,
.ion-ipod:before,
.ion-jet:before,
.ion-key:before,
.ion-knife:before,
.ion-laptop:before,
.ion-leaf:before,
.ion-levels:before,
.ion-lightbulb:before,
.ion-link:before,
.ion-load-a:before,
.ion-load-b:before,
.ion-load-c:before,
.ion-load-d:before,
.ion-location:before,
.ion-lock-combination:before,
.ion-locked:before,
.ion-log-in:before,
.ion-log-out:before,
.ion-loop:before,
.ion-magnet:before,
.ion-male:before,
.ion-man:before,
.ion-map:before,
.ion-medkit:before,
.ion-merge:before,
.ion-mic-a:before,
.ion-mic-b:before,
.ion-mic-c:before,
.ion-minus-circled:before,
.ion-minus-round:before,
.ion-minus:before,
.ion-model-s:before,
.ion-monitor:before,
.ion-more:before,
.ion-mouse:before,
.ion-music-note:before,
.ion-navicon-round:before,
.ion-navicon:before,
.ion-navigate:before,
.ion-network:before,
.ion-no-smoking:before,
.ion-nuclear:before,
.ion-outlet:before,
.ion-paintbrush:before,
.ion-paintbucket:before,
.ion-paper-airplane:before,
.ion-paperclip:before,
.ion-pause:before,
.ion-person-add:before,
.ion-person-stalker:before,
.ion-person:before,
.ion-pie-graph:before,
.ion-pin:before,
.ion-pinpoint:before,
.ion-pizza:before,
.ion-plane:before,
.ion-planet:before,
.ion-play:before,
.ion-playstation:before,
.ion-plus-circled:before,
.ion-plus-round:before,
.ion-plus:before,
.ion-podium:before,
.ion-pound:before,
.ion-power:before,
.ion-pricetag:before,
.ion-pricetags:before,
.ion-printer:before,
.ion-pull-request:before,
.ion-qr-scanner:before,
.ion-quote:before,
.ion-radio-waves:before,
.ion-record:before,
.ion-refresh:before,
.ion-reply-all:before,
.ion-reply:before,
.ion-ribbon-a:before,
.ion-ribbon-b:before,
.ion-sad-outline:before,
.ion-sad:before,
.ion-scissors:before,
.ion-search:before,
.ion-settings:before,
.ion-share:before,
.ion-shuffle:before,
.ion-skip-backward:before,
.ion-skip-forward:before,
.ion-social-android-outline:before,
.ion-social-android:before,
.ion-social-angular-outline:before,
.ion-social-angular:before,
.ion-social-apple-outline:before,
.ion-social-apple:before,
.ion-social-bitcoin-outline:before,
.ion-social-bitcoin:before,
.ion-social-buffer-outline:before,
.ion-social-buffer:before,
.ion-social-chrome-outline:before,
.ion-social-chrome:before,
.ion-social-codepen-outline:before,
.ion-social-codepen:before,
.ion-social-css3-outline:before,
.ion-social-css3:before,
.ion-social-designernews-outline:before,
.ion-social-designernews:before,
.ion-social-dribbble-outline:before,
.ion-social-dribbble:before,
.ion-social-dropbox-outline:before,
.ion-social-dropbox:before,
.ion-social-euro-outline:before,
.ion-social-euro:before,
.ion-social-facebook-outline:before,
.ion-social-facebook:before,
.ion-social-foursquare-outline:before,
.ion-social-foursquare:before,
.ion-social-freebsd-devil:before,
.ion-social-github-outline:before,
.ion-social-github:before,
.ion-social-google-outline:before,
.ion-social-google:before,
.ion-social-googleplus-outline:before,
.ion-social-googleplus:before,
.ion-social-hackernews-outline:before,
.ion-social-hackernews:before,
.ion-social-html5-outline:before,
.ion-social-html5:before,
.ion-social-instagram-outline:before,
.ion-social-instagram:before,
.ion-social-javascript-outline:before,
.ion-social-javascript:before,
.ion-social-linkedin-outline:before,
.ion-social-linkedin:before,
.ion-social-markdown:before,
.ion-social-nodejs:before,
.ion-social-octocat:before,
.ion-social-pinterest-outline:before,
.ion-social-pinterest:before,
.ion-social-python:before,
.ion-social-reddit-outline:before,
.ion-social-reddit:before,
.ion-social-rss-outline:before,
.ion-social-rss:before,
.ion-social-sass:before,
.ion-social-skype-outline:before,
.ion-social-skype:before,
.ion-social-snapchat-outline:before,
.ion-social-snapchat:before,
.ion-social-tumblr-outline:before,
.ion-social-tumblr:before,
.ion-social-tux:before,
.ion-social-twitch-outline:before,
.ion-social-twitch:before,
.ion-social-twitter-outline:before,
.ion-social-twitter:before,
.ion-social-usd-outline:before,
.ion-social-usd:before,
.ion-social-vimeo-outline:before,
.ion-social-vimeo:before,
.ion-social-whatsapp-outline:before,
.ion-social-whatsapp:before,
.ion-social-windows-outline:before,
.ion-social-windows:before,
.ion-social-wordpress-outline:before,
.ion-social-wordpress:before,
.ion-social-yahoo-outline:before,
.ion-social-yahoo:before,
.ion-social-yen-outline:before,
.ion-social-yen:before,
.ion-social-youtube-outline:before,
.ion-social-youtube:before,
.ion-soup-can-outline:before,
.ion-soup-can:before,
.ion-speakerphone:before,
.ion-speedometer:before,
.ion-spoon:before,
.ion-star:before,
.ion-stats-bars:before,
.ion-steam:before,
.ion-stop:before,
.ion-thermometer:before,
.ion-thumbsdown:before,
.ion-thumbsup:before,
.ion-toggle-filled:before,
.ion-toggle:before,
.ion-transgender:before,
.ion-trash-a:before,
.ion-trash-b:before,
.ion-trophy:before,
.ion-tshirt-outline:before,
.ion-tshirt:before,
.ion-umbrella:before,
.ion-university:before,
.ion-unlocked:before,
.ion-upload:before,
.ion-usb:before,
.ion-videocamera:before,
.ion-volume-high:before,
.ion-volume-low:before,
.ion-volume-medium:before,
.ion-volume-mute:before,
.ion-wand:before,
.ion-waterdrop:before,
.ion-wifi:before,
.ion-wineglass:before,
.ion-woman:before,
.ion-wrench:before,
.ion-xbox:before,
.ionicons {
  display: inline-block;
  font-family: Ionicons;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  text-rendering: auto;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 22px;
  text-align: center;
}
.ion-alert:before {
  content: "\f101";
}
.ion-alert-circled:before {
  content: "\f100";
}
.ion-android-add:before {
  content: "\f2c7";
}
.ion-android-add-circle:before {
  content: "\f359";
}
.ion-android-alarm-clock:before {
  content: "\f35a";
}
.ion-android-alert:before {
  content: "\f35b";
}
.ion-android-apps:before {
  content: "\f35c";
}
.ion-android-archive:before {
  content: "\f2c9";
}
.ion-android-arrow-back:before {
  content: "\f2ca";
}
.ion-android-arrow-down:before {
  content: "\f35d";
}
.ion-android-arrow-dropdown:before {
  content: "\f35f";
}
.ion-android-arrow-dropdown-circle:before {
  content: "\f35e";
}
.ion-android-arrow-dropleft:before {
  content: "\f361";
}
.ion-android-arrow-dropleft-circle:before {
  content: "\f360";
}
.ion-android-arrow-dropright:before {
  content: "\f363";
}
.ion-android-arrow-dropright-circle:before {
  content: "\f362";
}
.ion-android-arrow-dropup:before {
  content: "\f365";
}
.ion-android-arrow-dropup-circle:before {
  content: "\f364";
}
.ion-android-arrow-forward:before {
  content: "\f30f";
}
.ion-android-arrow-up:before {
  content: "\f366";
}
.ion-android-attach:before {
  content: "\f367";
}
.ion-android-bar:before {
  content: "\f368";
}
.ion-android-bicycle:before {
  content: "\f369";
}
.ion-android-boat:before {
  content: "\f36a";
}
.ion-android-bookmark:before {
  content: "\f36b";
}
.ion-android-bulb:before {
  content: "\f36c";
}
.ion-android-bus:before {
  content: "\f36d";
}
.ion-android-calendar:before {
  content: "\f2d1";
}
.ion-android-call:before {
  content: "\f2d2";
}
.ion-android-camera:before {
  content: "\f2d3";
}
.ion-android-cancel:before {
  content: "\f36e";
}
.ion-android-car:before {
  content: "\f36f";
}
.ion-android-cart:before {
  content: "\f370";
}
.ion-android-chat:before {
  content: "\f2d4";
}
.ion-android-checkbox:before {
  content: "\f374";
}
.ion-android-checkbox-blank:before {
  content: "\f371";
}
.ion-android-checkbox-outline:before {
  content: "\f373";
}
.ion-android-checkbox-outline-blank:before {
  content: "\f372";
}
.ion-android-checkmark-circle:before {
  content: "\f375";
}
.ion-android-clipboard:before {
  content: "\f376";
}
.ion-android-close:before {
  content: "\f2d7";
}
.ion-android-cloud:before {
  content: "\f37a";
}
.ion-android-cloud-circle:before {
  content: "\f377";
}
.ion-android-cloud-done:before {
  content: "\f378";
}
.ion-android-cloud-outline:before {
  content: "\f379";
}
.ion-android-color-palette:before {
  content: "\f37b";
}
.ion-android-compass:before {
  content: "\f37c";
}
.ion-android-contact:before {
  content: "\f2d8";
}
.ion-android-contacts:before {
  content: "\f2d9";
}
.ion-android-contract:before {
  content: "\f37d";
}
.ion-android-create:before {
  content: "\f37e";
}
.ion-android-delete:before {
  content: "\f37f";
}
.ion-android-desktop:before {
  content: "\f380";
}
.ion-android-document:before {
  content: "\f381";
}
.ion-android-done:before {
  content: "\f383";
}
.ion-android-done-all:before {
  content: "\f382";
}
.ion-android-download:before {
  content: "\f2dd";
}
.ion-android-drafts:before {
  content: "\f384";
}
.ion-android-exit:before {
  content: "\f385";
}
.ion-android-expand:before {
  content: "\f386";
}
.ion-android-favorite:before {
  content: "\f388";
}
.ion-android-favorite-outline:before {
  content: "\f387";
}
.ion-android-film:before {
  content: "\f389";
}
.ion-android-folder:before {
  content: "\f2e0";
}
.ion-android-folder-open:before {
  content: "\f38a";
}
.ion-android-funnel:before {
  content: "\f38b";
}
.ion-android-globe:before {
  content: "\f38c";
}
.ion-android-hand:before {
  content: "\f2e3";
}
.ion-android-hangout:before {
  content: "\f38d";
}
.ion-android-happy:before {
  content: "\f38e";
}
.ion-android-home:before {
  content: "\f38f";
}
.ion-android-image:before {
  content: "\f2e4";
}
.ion-android-laptop:before {
  content: "\f390";
}
.ion-android-list:before {
  content: "\f391";
}
.ion-android-locate:before {
  content: "\f2e9";
}
.ion-android-lock:before {
  content: "\f392";
}
.ion-android-mail:before {
  content: "\f2eb";
}
.ion-android-map:before {
  content: "\f393";
}
.ion-android-menu:before {
  content: "\f394";
}
.ion-android-microphone:before {
  content: "\f2ec";
}
.ion-android-microphone-off:before {
  content: "\f395";
}
.ion-android-more-horizontal:before {
  content: "\f396";
}
.ion-android-more-vertical:before {
  content: "\f397";
}
.ion-android-navigate:before {
  content: "\f398";
}
.ion-android-notifications:before {
  content: "\f39b";
}
.ion-android-notifications-none:before {
  content: "\f399";
}
.ion-android-notifications-off:before {
  content: "\f39a";
}
.ion-android-open:before {
  content: "\f39c";
}
.ion-android-options:before {
  content: "\f39d";
}
.ion-android-people:before {
  content: "\f39e";
}
.ion-android-person:before {
  content: "\f3a0";
}
.ion-android-person-add:before {
  content: "\f39f";
}
.ion-android-phone-landscape:before {
  content: "\f3a1";
}
.ion-android-phone-portrait:before {
  content: "\f3a2";
}
.ion-android-pin:before {
  content: "\f3a3";
}
.ion-android-plane:before {
  content: "\f3a4";
}
.ion-android-playstore:before {
  content: "\f2f0";
}
.ion-android-print:before {
  content: "\f3a5";
}
.ion-android-radio-button-off:before {
  content: "\f3a6";
}
.ion-android-radio-button-on:before {
  content: "\f3a7";
}
.ion-android-refresh:before {
  content: "\f3a8";
}
.ion-android-remove:before {
  content: "\f2f4";
}
.ion-android-remove-circle:before {
  content: "\f3a9";
}
.ion-android-restaurant:before {
  content: "\f3aa";
}
.ion-android-sad:before {
  content: "\f3ab";
}
.ion-android-search:before {
  content: "\f2f5";
}
.ion-android-send:before {
  content: "\f2f6";
}
.ion-android-settings:before {
  content: "\f2f7";
}
.ion-android-share:before {
  content: "\f2f8";
}
.ion-android-share-alt:before {
  content: "\f3ac";
}
.ion-android-star:before {
  content: "\f2fc";
}
.ion-android-star-half:before {
  content: "\f3ad";
}
.ion-android-star-outline:before {
  content: "\f3ae";
}
.ion-android-stopwatch:before {
  content: "\f2fd";
}
.ion-android-subway:before {
  content: "\f3af";
}
.ion-android-sunny:before {
  content: "\f3b0";
}
.ion-android-sync:before {
  content: "\f3b1";
}
.ion-android-textsms:before {
  content: "\f3b2";
}
.ion-android-time:before {
  content: "\f3b3";
}
.ion-android-train:before {
  content: "\f3b4";
}
.ion-android-unlock:before {
  content: "\f3b5";
}
.ion-android-upload:before {
  content: "\f3b6";
}
.ion-android-volume-down:before {
  content: "\f3b7";
}
.ion-android-volume-mute:before {
  content: "\f3b8";
}
.ion-android-volume-off:before {
  content: "\f3b9";
}
.ion-android-volume-up:before {
  content: "\f3ba";
}
.ion-android-walk:before {
  content: "\f3bb";
}
.ion-android-warning:before {
  content: "\f3bc";
}
.ion-android-watch:before {
  content: "\f3bd";
}
.ion-android-wifi:before {
  content: "\f305";
}
.ion-aperture:before {
  content: "\f313";
}
.ion-archive:before {
  content: "\f102";
}
.ion-arrow-down-a:before {
  content: "\f103";
}
.ion-arrow-down-b:before {
  content: "\f104";
}
.ion-arrow-down-c:before {
  content: "\f105";
}
.ion-arrow-expand:before {
  content: "\f25e";
}
.ion-arrow-graph-down-left:before {
  content: "\f25f";
}
.ion-arrow-graph-down-right:before {
  content: "\f260";
}
.ion-arrow-graph-up-left:before {
  content: "\f261";
}
.ion-arrow-graph-up-right:before {
  content: "\f262";
}
.ion-arrow-left-a:before {
  content: "\f106";
}
.ion-arrow-left-b:before {
  content: "\f107";
}
.ion-arrow-left-c:before {
  content: "\f108";
}
.ion-arrow-move:before {
  content: "\f263";
}
.ion-arrow-resize:before {
  content: "\f264";
}
.ion-arrow-return-left:before {
  content: "\f265";
}
.ion-arrow-return-right:before {
  content: "\f266";
}
.ion-arrow-right-a:before {
  content: "\f109";
}
.ion-arrow-right-b:before {
  content: "\f10a";
}
.ion-arrow-right-c:before {
  content: "\f10b";
}
.ion-arrow-shrink:before {
  content: "\f267";
}
.ion-arrow-swap:before {
  content: "\f268";
}
.ion-arrow-up-a:before {
  content: "\f10c";
}
.ion-arrow-up-b:before {
  content: "\f10d";
}
.ion-arrow-up-c:before {
  content: "\f10e";
}
.ion-asterisk:before {
  content: "\f314";
}
.ion-at:before {
  content: "\f10f";
}
.ion-backspace:before {
  content: "\f3bf";
}
.ion-backspace-outline:before {
  content: "\f3be";
}
.ion-bag:before {
  content: "\f110";
}
.ion-battery-charging:before {
  content: "\f111";
}
.ion-battery-empty:before {
  content: "\f112";
}
.ion-battery-full:before {
  content: "\f113";
}
.ion-battery-half:before {
  content: "\f114";
}
.ion-battery-low:before {
  content: "\f115";
}
.ion-beaker:before {
  content: "\f269";
}
.ion-beer:before {
  content: "\f26a";
}
.ion-bluetooth:before {
  content: "\f116";
}
.ion-bonfire:before {
  content: "\f315";
}
.ion-bookmark:before {
  content: "\f26b";
}
.ion-bowtie:before {
  content: "\f3c0";
}
.ion-briefcase:before {
  content: "\f26c";
}
.ion-bug:before {
  content: "\f2be";
}
.ion-calculator:before {
  content: "\f26d";
}
.ion-calendar:before {
  content: "\f117";
}
.ion-camera:before {
  content: "\f118";
}
.ion-card:before {
  content: "\f119";
}
.ion-cash:before {
  content: "\f316";
}
.ion-chatbox:before {
  content: "\f11b";
}
.ion-chatbox-working:before {
  content: "\f11a";
}
.ion-chatboxes:before {
  content: "\f11c";
}
.ion-chatbubble:before {
  content: "\f11e";
}
.ion-chatbubble-working:before {
  content: "\f11d";
}
.ion-chatbubbles:before {
  content: "\f11f";
}
.ion-checkmark:before {
  content: "\f122";
}
.ion-checkmark-circled:before {
  content: "\f120";
}
.ion-checkmark-round:before {
  content: "\f121";
}
.ion-chevron-down:before {
  content: "\f123";
}
.ion-chevron-left:before {
  content: "\f124";
}
.ion-chevron-right:before {
  content: "\f125";
}
.ion-chevron-up:before {
  content: "\f126";
}
.ion-clipboard:before {
  content: "\f127";
}
.ion-clock:before {
  content: "\f26e";
}
.ion-close:before {
  content: "\f12a";
}
.ion-close-circled:before {
  content: "\f128";
}
.ion-close-round:before {
  content: "\f129";
}
.ion-closed-captioning:before {
  content: "\f317";
}
.ion-cloud:before {
  content: "\f12b";
}
.ion-code:before {
  content: "\f271";
}
.ion-code-download:before {
  content: "\f26f";
}
.ion-code-working:before {
  content: "\f270";
}
.ion-coffee:before {
  content: "\f272";
}
.ion-compass:before {
  content: "\f273";
}
.ion-compose:before {
  content: "\f12c";
}
.ion-connection-bars:before {
  content: "\f274";
}
.ion-contrast:before {
  content: "\f275";
}
.ion-crop:before {
  content: "\f3c1";
}
.ion-cube:before {
  content: "\f318";
}
.ion-disc:before {
  content: "\f12d";
}
.ion-document:before {
  content: "\f12f";
}
.ion-document-text:before {
  content: "\f12e";
}
.ion-drag:before {
  content: "\f130";
}
.ion-earth:before {
  content: "\f276";
}
.ion-easel:before {
  content: "\f3c2";
}
.ion-edit:before {
  content: "\f2bf";
}
.ion-egg:before {
  content: "\f277";
}
.ion-eject:before {
  content: "\f131";
}
.ion-email:before {
  content: "\f132";
}
.ion-email-unread:before {
  content: "\f3c3";
}
.ion-erlenmeyer-flask:before {
  content: "\f3c5";
}
.ion-erlenmeyer-flask-bubbles:before {
  content: "\f3c4";
}
.ion-eye:before {
  content: "\f133";
}
.ion-eye-disabled:before {
  content: "\f306";
}
.ion-female:before {
  content: "\f278";
}
.ion-filing:before {
  content: "\f134";
}
.ion-film-marker:before {
  content: "\f135";
}
.ion-fireball:before {
  content: "\f319";
}
.ion-flag:before {
  content: "\f279";
}
.ion-flame:before {
  content: "\f31a";
}
.ion-flash:before {
  content: "\f137";
}
.ion-flash-off:before {
  content: "\f136";
}
.ion-folder:before {
  content: "\f139";
}
.ion-fork:before {
  content: "\f27a";
}
.ion-fork-repo:before {
  content: "\f2c0";
}
.ion-forward:before {
  content: "\f13a";
}
.ion-funnel:before {
  content: "\f31b";
}
.ion-gear-a:before {
  content: "\f13d";
}
.ion-gear-b:before {
  content: "\f13e";
}
.ion-grid:before {
  content: "\f13f";
}
.ion-hammer:before {
  content: "\f27b";
}
.ion-happy:before {
  content: "\f31c";
}
.ion-happy-outline:before {
  content: "\f3c6";
}
.ion-headphone:before {
  content: "\f140";
}
.ion-heart:before {
  content: "\f141";
}
.ion-heart-broken:before {
  content: "\f31d";
}
.ion-help:before {
  content: "\f143";
}
.ion-help-buoy:before {
  content: "\f27c";
}
.ion-help-circled:before {
  content: "\f142";
}
.ion-home:before {
  content: "\f144";
}
.ion-icecream:before {
  content: "\f27d";
}
.ion-image:before {
  content: "\f147";
}
.ion-images:before {
  content: "\f148";
}
.ion-information:before {
  content: "\f14a";
}
.ion-information-circled:before {
  content: "\f149";
}
.ion-ionic:before {
  content: "\f14b";
}
.ion-ios-alarm:before {
  content: "\f3c8";
}
.ion-ios-alarm-outline:before {
  content: "\f3c7";
}
.ion-ios-albums:before {
  content: "\f3ca";
}
.ion-ios-albums-outline:before {
  content: "\f3c9";
}
.ion-ios-americanfootball:before {
  content: "\f3cc";
}
.ion-ios-americanfootball-outline:before {
  content: "\f3cb";
}
.ion-ios-analytics:before {
  content: "\f3ce";
}
.ion-ios-analytics-outline:before {
  content: "\f3cd";
}
.ion-ios-arrow-back:before {
  content: "\f3cf";
}
.ion-ios-arrow-down:before {
  content: "\f3d0";
}
.ion-ios-arrow-forward:before {
  content: "\f3d1";
}
.ion-ios-arrow-left:before {
  content: "\f3d2";
}
.ion-ios-arrow-right:before {
  content: "\f3d3";
}
.ion-ios-arrow-thin-down:before {
  content: "\f3d4";
}
.ion-ios-arrow-thin-left:before {
  content: "\f3d5";
}
.ion-ios-arrow-thin-right:before {
  content: "\f3d6";
}
.ion-ios-arrow-thin-up:before {
  content: "\f3d7";
}
.ion-ios-arrow-up:before {
  content: "\f3d8";
}
.ion-ios-at:before {
  content: "\f3da";
}
.ion-ios-at-outline:before {
  content: "\f3d9";
}
.ion-ios-barcode:before {
  content: "\f3dc";
}
.ion-ios-barcode-outline:before {
  content: "\f3db";
}
.ion-ios-baseball:before {
  content: "\f3de";
}
.ion-ios-baseball-outline:before {
  content: "\f3dd";
}
.ion-ios-basketball:before {
  content: "\f3e0";
}
.ion-ios-basketball-outline:before {
  content: "\f3df";
}
.ion-ios-bell:before {
  content: "\f3e2";
}
.ion-ios-bell-outline:before {
  content: "\f3e1";
}
.ion-ios-body:before {
  content: "\f3e4";
}
.ion-ios-body-outline:before {
  content: "\f3e3";
}
.ion-ios-bolt:before {
  content: "\f3e6";
}
.ion-ios-bolt-outline:before {
  content: "\f3e5";
}
.ion-ios-book:before {
  content: "\f3e8";
}
.ion-ios-book-outline:before {
  content: "\f3e7";
}
.ion-ios-bookmarks:before {
  content: "\f3ea";
}
.ion-ios-bookmarks-outline:before {
  content: "\f3e9";
}
.ion-ios-box:before {
  content: "\f3ec";
}
.ion-ios-box-outline:before {
  content: "\f3eb";
}
.ion-ios-briefcase:before {
  content: "\f3ee";
}
.ion-ios-briefcase-outline:before {
  content: "\f3ed";
}
.ion-ios-browsers:before {
  content: "\f3f0";
}
.ion-ios-browsers-outline:before {
  content: "\f3ef";
}
.ion-ios-calculator:before {
  content: "\f3f2";
}
.ion-ios-calculator-outline:before {
  content: "\f3f1";
}
.ion-ios-calendar:before {
  content: "\f3f4";
}
.ion-ios-calendar-outline:before {
  content: "\f3f3";
}
.ion-ios-camera:before {
  content: "\f3f6";
}
.ion-ios-camera-outline:before {
  content: "\f3f5";
}
.ion-ios-cart:before {
  content: "\f3f8";
}
.ion-ios-cart-outline:before {
  content: "\f3f7";
}
.ion-ios-chatboxes:before {
  content: "\f3fa";
}
.ion-ios-chatboxes-outline:before {
  content: "\f3f9";
}
.ion-ios-chatbubble:before {
  content: "\f3fc";
}
.ion-ios-chatbubble-outline:before {
  content: "\f3fb";
}
.ion-ios-checkmark:before {
  content: "\f3ff";
}
.ion-ios-checkmark-empty:before {
  content: "\f3fd";
}
.ion-ios-checkmark-outline:before {
  content: "\f3fe";
}
.ion-ios-circle-filled:before {
  content: "\f400";
}
.ion-ios-circle-outline:before {
  content: "\f401";
}
.ion-ios-clock:before {
  content: "\f403";
}
.ion-ios-clock-outline:before {
  content: "\f402";
}
.ion-ios-close:before {
  content: "\f406";
}
.ion-ios-close-empty:before {
  content: "\f404";
}
.ion-ios-close-outline:before {
  content: "\f405";
}
.ion-ios-cloud:before {
  content: "\f40c";
}
.ion-ios-cloud-download:before {
  content: "\f408";
}
.ion-ios-cloud-download-outline:before {
  content: "\f407";
}
.ion-ios-cloud-outline:before {
  content: "\f409";
}
.ion-ios-cloud-upload:before {
  content: "\f40b";
}
.ion-ios-cloud-upload-outline:before {
  content: "\f40a";
}
.ion-ios-cloudy:before {
  content: "\f410";
}
.ion-ios-cloudy-night:before {
  content: "\f40e";
}
.ion-ios-cloudy-night-outline:before {
  content: "\f40d";
}
.ion-ios-cloudy-outline:before {
  content: "\f40f";
}
.ion-ios-cog:before {
  content: "\f412";
}
.ion-ios-cog-outline:before {
  content: "\f411";
}
.ion-ios-color-filter:before {
  content: "\f414";
}
.ion-ios-color-filter-outline:before {
  content: "\f413";
}
.ion-ios-color-wand:before {
  content: "\f416";
}
.ion-ios-color-wand-outline:before {
  content: "\f415";
}
.ion-ios-compose:before {
  content: "\f418";
}
.ion-ios-compose-outline:before {
  content: "\f417";
}
.ion-ios-contact:before {
  content: "\f41a";
}
.ion-ios-contact-outline:before {
  content: "\f419";
}
.ion-ios-copy:before {
  content: "\f41c";
}
.ion-ios-copy-outline:before {
  content: "\f41b";
}
.ion-ios-crop:before {
  content: "\f41e";
}
.ion-ios-crop-strong:before {
  content: "\f41d";
}
.ion-ios-download:before {
  content: "\f420";
}
.ion-ios-download-outline:before {
  content: "\f41f";
}
.ion-ios-drag:before {
  content: "\f421";
}
.ion-ios-email:before {
  content: "\f423";
}
.ion-ios-email-outline:before {
  content: "\f422";
}
.ion-ios-eye:before {
  content: "\f425";
}
.ion-ios-eye-outline:before {
  content: "\f424";
}
.ion-ios-fastforward:before {
  content: "\f427";
}
.ion-ios-fastforward-outline:before {
  content: "\f426";
}
.ion-ios-filing:before {
  content: "\f429";
}
.ion-ios-filing-outline:before {
  content: "\f428";
}
.ion-ios-film:before {
  content: "\f42b";
}
.ion-ios-film-outline:before {
  content: "\f42a";
}
.ion-ios-flag:before {
  content: "\f42d";
}
.ion-ios-flag-outline:before {
  content: "\f42c";
}
.ion-ios-flame:before {
  content: "\f42f";
}
.ion-ios-flame-outline:before {
  content: "\f42e";
}
.ion-ios-flask:before {
  content: "\f431";
}
.ion-ios-flask-outline:before {
  content: "\f430";
}
.ion-ios-flower:before {
  content: "\f433";
}
.ion-ios-flower-outline:before {
  content: "\f432";
}
.ion-ios-folder:before {
  content: "\f435";
}
.ion-ios-folder-outline:before {
  content: "\f434";
}
.ion-ios-football:before {
  content: "\f437";
}
.ion-ios-football-outline:before {
  content: "\f436";
}
.ion-ios-game-controller-a:before {
  content: "\f439";
}
.ion-ios-game-controller-a-outline:before {
  content: "\f438";
}
.ion-ios-game-controller-b:before {
  content: "\f43b";
}
.ion-ios-game-controller-b-outline:before {
  content: "\f43a";
}
.ion-ios-gear:before {
  content: "\f43d";
}
.ion-ios-gear-outline:before {
  content: "\f43c";
}
.ion-ios-glasses:before {
  content: "\f43f";
}
.ion-ios-glasses-outline:before {
  content: "\f43e";
}
.ion-ios-grid-view:before {
  content: "\f441";
}
.ion-ios-grid-view-outline:before {
  content: "\f440";
}
.ion-ios-heart:before {
  content: "\f443";
}
.ion-ios-heart-outline:before {
  content: "\f442";
}
.ion-ios-help:before {
  content: "\f446";
}
.ion-ios-help-empty:before {
  content: "\f444";
}
.ion-ios-help-outline:before {
  content: "\f445";
}
.ion-ios-home:before {
  content: "\f448";
}
.ion-ios-home-outline:before {
  content: "\f447";
}
.ion-ios-infinite:before {
  content: "\f44a";
}
.ion-ios-infinite-outline:before {
  content: "\f449";
}
.ion-ios-information:before {
  content: "\f44d";
}
.ion-ios-information-empty:before {
  content: "\f44b";
}
.ion-ios-information-outline:before {
  content: "\f44c";
}
.ion-ios-ionic-outline:before {
  content: "\f44e";
}
.ion-ios-keypad:before {
  content: "\f450";
}
.ion-ios-keypad-outline:before {
  content: "\f44f";
}
.ion-ios-lightbulb:before {
  content: "\f452";
}
.ion-ios-lightbulb-outline:before {
  content: "\f451";
}
.ion-ios-list:before {
  content: "\f454";
}
.ion-ios-list-outline:before {
  content: "\f453";
}
.ion-ios-location:before {
  content: "\f456";
}
.ion-ios-location-outline:before {
  content: "\f455";
}
.ion-ios-locked:before {
  content: "\f458";
}
.ion-ios-locked-outline:before {
  content: "\f457";
}
.ion-ios-loop:before {
  content: "\f45a";
}
.ion-ios-loop-strong:before {
  content: "\f459";
}
.ion-ios-medical:before {
  content: "\f45c";
}
.ion-ios-medical-outline:before {
  content: "\f45b";
}
.ion-ios-medkit:before {
  content: "\f45e";
}
.ion-ios-medkit-outline:before {
  content: "\f45d";
}
.ion-ios-mic:before {
  content: "\f461";
}
.ion-ios-mic-off:before {
  content: "\f45f";
}
.ion-ios-mic-outline:before {
  content: "\f460";
}
.ion-ios-minus:before {
  content: "\f464";
}
.ion-ios-minus-empty:before {
  content: "\f462";
}
.ion-ios-minus-outline:before {
  content: "\f463";
}
.ion-ios-monitor:before {
  content: "\f466";
}
.ion-ios-monitor-outline:before {
  content: "\f465";
}
.ion-ios-moon:before {
  content: "\f468";
}
.ion-ios-moon-outline:before {
  content: "\f467";
}
.ion-ios-more:before {
  content: "\f46a";
}
.ion-ios-more-outline:before {
  content: "\f469";
}
.ion-ios-musical-note:before {
  content: "\f46b";
}
.ion-ios-musical-notes:before {
  content: "\f46c";
}
.ion-ios-navigate:before {
  content: "\f46e";
}
.ion-ios-navigate-outline:before {
  content: "\f46d";
}
.ion-ios-nutrition:before {
  content: "\f470";
}
.ion-ios-nutrition-outline:before {
  content: "\f46f";
}
.ion-ios-paper:before {
  content: "\f472";
}
.ion-ios-paper-outline:before {
  content: "\f471";
}
.ion-ios-paperplane:before {
  content: "\f474";
}
.ion-ios-paperplane-outline:before {
  content: "\f473";
}
.ion-ios-partlysunny:before {
  content: "\f476";
}
.ion-ios-partlysunny-outline:before {
  content: "\f475";
}
.ion-ios-pause:before {
  content: "\f478";
}
.ion-ios-pause-outline:before {
  content: "\f477";
}
.ion-ios-paw:before {
  content: "\f47a";
}
.ion-ios-paw-outline:before {
  content: "\f479";
}
.ion-ios-people:before {
  content: "\f47c";
}
.ion-ios-people-outline:before {
  content: "\f47b";
}
.ion-ios-person:before {
  content: "\f47e";
}
.ion-ios-person-outline:before {
  content: "\f47d";
}
.ion-ios-personadd:before {
  content: "\f480";
}
.ion-ios-personadd-outline:before {
  content: "\f47f";
}
.ion-ios-photos:before {
  content: "\f482";
}
.ion-ios-photos-outline:before {
  content: "\f481";
}
.ion-ios-pie:before {
  content: "\f484";
}
.ion-ios-pie-outline:before {
  content: "\f483";
}
.ion-ios-pint:before {
  content: "\f486";
}
.ion-ios-pint-outline:before {
  content: "\f485";
}
.ion-ios-play:before {
  content: "\f488";
}
.ion-ios-play-outline:before {
  content: "\f487";
}
.ion-ios-plus:before {
  content: "\f48b";
}
.ion-ios-plus-empty:before {
  content: "\f489";
}
.ion-ios-plus-outline:before {
  content: "\f48a";
}
.ion-ios-pricetag:before {
  content: "\f48d";
}
.ion-ios-pricetag-outline:before {
  content: "\f48c";
}
.ion-ios-pricetags:before {
  content: "\f48f";
}
.ion-ios-pricetags-outline:before {
  content: "\f48e";
}
.ion-ios-printer:before {
  content: "\f491";
}
.ion-ios-printer-outline:before {
  content: "\f490";
}
.ion-ios-pulse:before {
  content: "\f493";
}
.ion-ios-pulse-strong:before {
  content: "\f492";
}
.ion-ios-rainy:before {
  content: "\f495";
}
.ion-ios-rainy-outline:before {
  content: "\f494";
}
.ion-ios-recording:before {
  content: "\f497";
}
.ion-ios-recording-outline:before {
  content: "\f496";
}
.ion-ios-redo:before {
  content: "\f499";
}
.ion-ios-redo-outline:before {
  content: "\f498";
}
.ion-ios-refresh:before {
  content: "\f49c";
}
.ion-ios-refresh-empty:before {
  content: "\f49a";
}
.ion-ios-refresh-outline:before {
  content: "\f49b";
}
.ion-ios-reload:before {
  content: "\f49d";
}
.ion-ios-reverse-camera:before {
  content: "\f49f";
}
.ion-ios-reverse-camera-outline:before {
  content: "\f49e";
}
.ion-ios-rewind:before {
  content: "\f4a1";
}
.ion-ios-rewind-outline:before {
  content: "\f4a0";
}
.ion-ios-rose:before {
  content: "\f4a3";
}
.ion-ios-rose-outline:before {
  content: "\f4a2";
}
.ion-ios-search:before {
  content: "\f4a5";
}
.ion-ios-search-strong:before {
  content: "\f4a4";
}
.ion-ios-settings:before {
  content: "\f4a7";
}
.ion-ios-settings-strong:before {
  content: "\f4a6";
}
.ion-ios-shuffle:before {
  content: "\f4a9";
}
.ion-ios-shuffle-strong:before {
  content: "\f4a8";
}
.ion-ios-skipbackward:before {
  content: "\f4ab";
}
.ion-ios-skipbackward-outline:before {
  content: "\f4aa";
}
.ion-ios-skipforward:before {
  content: "\f4ad";
}
.ion-ios-skipforward-outline:before {
  content: "\f4ac";
}
.ion-ios-snowy:before {
  content: "\f4ae";
}
.ion-ios-speedometer:before {
  content: "\f4b0";
}
.ion-ios-speedometer-outline:before {
  content: "\f4af";
}
.ion-ios-star:before {
  content: "\f4b3";
}
.ion-ios-star-half:before {
  content: "\f4b1";
}
.ion-ios-star-outline:before {
  content: "\f4b2";
}
.ion-ios-stopwatch:before {
  content: "\f4b5";
}
.ion-ios-stopwatch-outline:before {
  content: "\f4b4";
}
.ion-ios-sunny:before {
  content: "\f4b7";
}
.ion-ios-sunny-outline:before {
  content: "\f4b6";
}
.ion-ios-telephone:before {
  content: "\f4b9";
}
.ion-ios-telephone-outline:before {
  content: "\f4b8";
}
.ion-ios-tennisball:before {
  content: "\f4bb";
}
.ion-ios-tennisball-outline:before {
  content: "\f4ba";
}
.ion-ios-thunderstorm:before {
  content: "\f4bd";
}
.ion-ios-thunderstorm-outline:before {
  content: "\f4bc";
}
.ion-ios-time:before {
  content: "\f4bf";
}
.ion-ios-time-outline:before {
  content: "\f4be";
}
.ion-ios-timer:before {
  content: "\f4c1";
}
.ion-ios-timer-outline:before {
  content: "\f4c0";
}
.ion-ios-toggle:before {
  content: "\f4c3";
}
.ion-ios-toggle-outline:before {
  content: "\f4c2";
}
.ion-ios-trash:before {
  content: "\f4c5";
}
.ion-ios-trash-outline:before {
  content: "\f4c4";
}
.ion-ios-undo:before {
  content: "\f4c7";
}
.ion-ios-undo-outline:before {
  content: "\f4c6";
}
.ion-ios-unlocked:before {
  content: "\f4c9";
}
.ion-ios-unlocked-outline:before {
  content: "\f4c8";
}
.ion-ios-upload:before {
  content: "\f4cb";
}
.ion-ios-upload-outline:before {
  content: "\f4ca";
}
.ion-ios-videocam:before {
  content: "\f4cd";
}
.ion-ios-videocam-outline:before {
  content: "\f4cc";
}
.ion-ios-volume-high:before {
  content: "\f4ce";
}
.ion-ios-volume-low:before {
  content: "\f4cf";
}
.ion-ios-wineglass:before {
  content: "\f4d1";
}
.ion-ios-wineglass-outline:before {
  content: "\f4d0";
}
.ion-ios-world:before {
  content: "\f4d3";
}
.ion-ios-world-outline:before {
  content: "\f4d2";
}
.ion-ipad:before {
  content: "\f1f9";
}
.ion-iphone:before {
  content: "\f1fa";
}
.ion-ipod:before {
  content: "\f1fb";
}
.ion-jet:before {
  content: "\f295";
}
.ion-key:before {
  content: "\f296";
}
.ion-knife:before {
  content: "\f297";
}
.ion-laptop:before {
  content: "\f1fc";
}
.ion-leaf:before {
  content: "\f1fd";
}
.ion-levels:before {
  content: "\f298";
}
.ion-lightbulb:before {
  content: "\f299";
}
.ion-link:before {
  content: "\f1fe";
}
.ion-load-a:before {
  content: "\f29a";
}
.ion-load-b:before {
  content: "\f29b";
}
.ion-load-c:before {
  content: "\f29c";
}
.ion-load-d:before {
  content: "\f29d";
}
.ion-location:before {
  content: "\f1ff";
}
.ion-lock-combination:before {
  content: "\f4d4";
}
.ion-locked:before {
  content: "\f200";
}
.ion-log-in:before {
  content: "\f29e";
}
.ion-log-out:before {
  content: "\f29f";
}
.ion-loop:before {
  content: "\f201";
}
.ion-magnet:before {
  content: "\f2a0";
}
.ion-male:before {
  content: "\f2a1";
}
.ion-man:before {
  content: "\f202";
}
.ion-map:before {
  content: "\f203";
}
.ion-medkit:before {
  content: "\f2a2";
}
.ion-merge:before {
  content: "\f33f";
}
.ion-mic-a:before {
  content: "\f204";
}
.ion-mic-b:before {
  content: "\f205";
}
.ion-mic-c:before {
  content: "\f206";
}
.ion-minus:before {
  content: "\f209";
}
.ion-minus-circled:before {
  content: "\f207";
}
.ion-minus-round:before {
  content: "\f208";
}
.ion-model-s:before {
  content: "\f2c1";
}
.ion-monitor:before {
  content: "\f20a";
}
.ion-more:before {
  content: "\f20b";
}
.ion-mouse:before {
  content: "\f340";
}
.ion-music-note:before {
  content: "\f20c";
}
.ion-navicon:before {
  content: "\f20e";
}
.ion-navicon-round:before {
  content: "\f20d";
}
.ion-navigate:before {
  content: "\f2a3";
}
.ion-network:before {
  content: "\f341";
}
.ion-no-smoking:before {
  content: "\f2c2";
}
.ion-nuclear:before {
  content: "\f2a4";
}
.ion-outlet:before {
  content: "\f342";
}
.ion-paintbrush:before {
  content: "\f4d5";
}
.ion-paintbucket:before {
  content: "\f4d6";
}
.ion-paper-airplane:before {
  content: "\f2c3";
}
.ion-paperclip:before {
  content: "\f20f";
}
.ion-pause:before {
  content: "\f210";
}
.ion-person:before {
  content: "\f213";
}
.ion-person-add:before {
  content: "\f211";
}
.ion-person-stalker:before {
  content: "\f212";
}
.ion-pie-graph:before {
  content: "\f2a5";
}
.ion-pin:before {
  content: "\f2a6";
}
.ion-pinpoint:before {
  content: "\f2a7";
}
.ion-pizza:before {
  content: "\f2a8";
}
.ion-plane:before {
  content: "\f214";
}
.ion-planet:before {
  content: "\f343";
}
.ion-play:before {
  content: "\f215";
}
.ion-playstation:before {
  content: "\f30a";
}
.ion-plus:before {
  content: "\f218";
}
.ion-plus-circled:before {
  content: "\f216";
}
.ion-plus-round:before {
  content: "\f217";
}
.ion-podium:before {
  content: "\f344";
}
.ion-pound:before {
  content: "\f219";
}
.ion-power:before {
  content: "\f2a9";
}
.ion-pricetag:before {
  content: "\f2aa";
}
.ion-pricetags:before {
  content: "\f2ab";
}
.ion-printer:before {
  content: "\f21a";
}
.ion-pull-request:before {
  content: "\f345";
}
.ion-qr-scanner:before {
  content: "\f346";
}
.ion-quote:before {
  content: "\f347";
}
.ion-radio-waves:before {
  content: "\f2ac";
}
.ion-record:before {
  content: "\f21b";
}
.ion-refresh:before {
  content: "\f21c";
}
.ion-reply:before {
  content: "\f21e";
}
.ion-reply-all:before {
  content: "\f21d";
}
.ion-ribbon-a:before {
  content: "\f348";
}
.ion-ribbon-b:before {
  content: "\f349";
}
.ion-sad:before {
  content: "\f34a";
}
.ion-sad-outline:before {
  content: "\f4d7";
}
.ion-scissors:before {
  content: "\f34b";
}
.ion-search:before {
  content: "\f21f";
}
.ion-settings:before {
  content: "\f2ad";
}
.ion-share:before {
  content: "\f220";
}
.ion-shuffle:before {
  content: "\f221";
}
.ion-skip-backward:before {
  content: "\f222";
}
.ion-skip-forward:before {
  content: "\f223";
}
.ion-social-android:before {
  content: "\f225";
}
.ion-social-android-outline:before {
  content: "\f224";
}
.ion-social-angular:before {
  content: "\f4d9";
}
.ion-social-angular-outline:before {
  content: "\f4d8";
}
.ion-social-apple:before {
  content: "\f227";
}
.ion-social-apple-outline:before {
  content: "\f226";
}
.ion-social-bitcoin:before {
  content: "\f2af";
}
.ion-social-bitcoin-outline:before {
  content: "\f2ae";
}
.ion-social-buffer:before {
  content: "\f229";
}
.ion-social-buffer-outline:before {
  content: "\f228";
}
.ion-social-chrome:before {
  content: "\f4db";
}
.ion-social-chrome-outline:before {
  content: "\f4da";
}
.ion-social-codepen:before {
  content: "\f4dd";
}
.ion-social-codepen-outline:before {
  content: "\f4dc";
}
.ion-social-css3:before {
  content: "\f4df";
}
.ion-social-css3-outline:before {
  content: "\f4de";
}
.ion-social-designernews:before {
  content: "\f22b";
}
.ion-social-designernews-outline:before {
  content: "\f22a";
}
.ion-social-dribbble:before {
  content: "\f22d";
}
.ion-social-dribbble-outline:before {
  content: "\f22c";
}
.ion-social-dropbox:before {
  content: "\f22f";
}
.ion-social-dropbox-outline:before {
  content: "\f22e";
}
.ion-social-euro:before {
  content: "\f4e1";
}
.ion-social-euro-outline:before {
  content: "\f4e0";
}
.ion-social-facebook:before {
  content: "\f231";
}
.ion-social-facebook-outline:before {
  content: "\f230";
}
.ion-social-foursquare:before {
  content: "\f34d";
}
.ion-social-foursquare-outline:before {
  content: "\f34c";
}
.ion-social-freebsd-devil:before {
  content: "\f2c4";
}
.ion-social-github:before {
  content: "\f233";
}
.ion-social-github-outline:before {
  content: "\f232";
}
.ion-social-google:before {
  content: "\f34f";
}
.ion-social-google-outline:before {
  content: "\f34e";
}
.ion-social-googleplus:before {
  content: "\f235";
}
.ion-social-googleplus-outline:before {
  content: "\f234";
}
.ion-social-hackernews:before {
  content: "\f237";
}
.ion-social-hackernews-outline:before {
  content: "\f236";
}
.ion-social-html5:before {
  content: "\f4e3";
}
.ion-social-html5-outline:before {
  content: "\f4e2";
}
.ion-social-instagram:before {
  content: "\f351";
}
.ion-social-instagram-outline:before {
  content: "\f350";
}
.ion-social-javascript:before {
  content: "\f4e5";
}
.ion-social-javascript-outline:before {
  content: "\f4e4";
}
.ion-social-linkedin:before {
  content: "\f239";
}
.ion-social-linkedin-outline:before {
  content: "\f238";
}
.ion-social-markdown:before {
  content: "\f4e6";
}
.ion-social-nodejs:before {
  content: "\f4e7";
}
.ion-social-octocat:before {
  content: "\f4e8";
}
.ion-social-pinterest:before {
  content: "\f2b1";
}
.ion-social-pinterest-outline:before {
  content: "\f2b0";
}
.ion-social-python:before {
  content: "\f4e9";
}
.ion-social-reddit:before {
  content: "\f23b";
}
.ion-social-reddit-outline:before {
  content: "\f23a";
}
.ion-social-rss:before {
  content: "\f23d";
}
.ion-social-rss-outline:before {
  content: "\f23c";
}
.ion-social-sass:before {
  content: "\f4ea";
}
.ion-social-skype:before {
  content: "\f23f";
}
.ion-social-skype-outline:before {
  content: "\f23e";
}
.ion-social-snapchat:before {
  content: "\f4ec";
}
.ion-social-snapchat-outline:before {
  content: "\f4eb";
}
.ion-social-tumblr:before {
  content: "\f241";
}
.ion-social-tumblr-outline:before {
  content: "\f240";
}
.ion-social-tux:before {
  content: "\f2c5";
}
.ion-social-twitch:before {
  content: "\f4ee";
}
.ion-social-twitch-outline:before {
  content: "\f4ed";
}
.ion-social-twitter:before {
  content: "\f243";
}
.ion-social-twitter-outline:before {
  content: "\f242";
}
.ion-social-usd:before {
  content: "\f353";
}
.ion-social-usd-outline:before {
  content: "\f352";
}
.ion-social-vimeo:before {
  content: "\f245";
}
.ion-social-vimeo-outline:before {
  content: "\f244";
}
.ion-social-whatsapp:before {
  content: "\f4f0";
}
.ion-social-whatsapp-outline:before {
  content: "\f4ef";
}
.ion-social-windows:before {
  content: "\f247";
}
.ion-social-windows-outline:before {
  content: "\f246";
}
.ion-social-wordpress:before {
  content: "\f249";
}
.ion-social-wordpress-outline:before {
  content: "\f248";
}
.ion-social-yahoo:before {
  content: "\f24b";
}
.ion-social-yahoo-outline:before {
  content: "\f24a";
}
.ion-social-yen:before {
  content: "\f4f2";
}
.ion-social-yen-outline:before {
  content: "\f4f1";
}
.ion-social-youtube:before {
  content: "\f24d";
}
.ion-social-youtube-outline:before {
  content: "\f24c";
}
.ion-soup-can:before {
  content: "\f4f4";
}
.ion-soup-can-outline:before {
  content: "\f4f3";
}
.ion-speakerphone:before {
  content: "\f2b2";
}
.ion-speedometer:before {
  content: "\f2b3";
}
.ion-spoon:before {
  content: "\f2b4";
}
.ion-star:before {
  content: "\f24e";
}
.ion-stats-bars:before {
  content: "\f2b5";
}
.ion-steam:before {
  content: "\f30b";
}
.ion-stop:before {
  content: "\f24f";
}
.ion-thermometer:before {
  content: "\f2b6";
}
.ion-thumbsdown:before {
  content: "\f250";
}
.ion-thumbsup:before {
  content: "\f251";
}
.ion-toggle:before {
  content: "\f355";
}
.ion-toggle-filled:before {
  content: "\f354";
}
.ion-transgender:before {
  content: "\f4f5";
}
.ion-trash-a:before {
  content: "\f252";
}
.ion-trash-b:before {
  content: "\f253";
}
.ion-trophy:before {
  content: "\f356";
}
.ion-tshirt:before {
  content: "\f4f7";
}
.ion-tshirt-outline:before {
  content: "\f4f6";
}
.ion-umbrella:before {
  content: "\f2b7";
}
.ion-university:before {
  content: "\f357";
}
.ion-unlocked:before {
  content: "\f254";
}
.ion-upload:before {
  content: "\f255";
}
.ion-usb:before {
  content: "\f2b8";
}
.ion-videocamera:before {
  content: "\f256";
}
.ion-volume-high:before {
  content: "\f257";
}
.ion-volume-low:before {
  content: "\f258";
}
.ion-volume-medium:before {
  content: "\f259";
}
.ion-volume-mute:before {
  content: "\f25a";
}
.ion-wand:before {
  content: "\f358";
}
.ion-waterdrop:before {
  content: "\f25b";
}
.ion-wifi:before {
  content: "\f25c";
}
.ion-wineglass:before {
  content: "\f2b9";
}
.ion-woman:before {
  content: "\f25d";
}
.ion-wrench:before {
  content: "\f2ba";
}
.ion-xbox:before {
  content: "\f30c";
}
.component-header {
  display: block;
}
.footer,
.header {
  font-size: 18px;
  display: inline-block;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 52px;
  z-index: 9;
}
.footer {
  top: initial;
  bottom: 0;
}
.header.sub {
  top: 52px;
}
.footer.sub {
  top: initial;
  bottom: 52px;
}
.has-header {
  padding-top: 52px !important;
}
.has-header.has-sub-header {
  padding-top: 104px !important;
}
.has-footer {
  padding-bottom: 52px !important;
}
.has-footer.has-sub-footer {
  padding-bottom: 104px !important;
}
.footer .left,
.footer .right,
.footer .title,
.footer h1,
.header .left,
.header .right,
.header .title,
.header h1 {
  margin-top: 15px;
  margin-bottom: 5px;
}
.header .avatar {
  height: 40px;
  margin-top: -8px;
  margin-left: 4px;
}
.header .border-big {
  border-width: 2px;
  border-style: solid;
}
.footer h2,
.header h2 {
  padding-top: 8px;
  padding-left: 10px;
}
.footer p,
.header p {
  padding-left: 10px;
  margin: 0;
  margin-top: -4px;
}
.footer .left,
.header .left {
  padding-left: 5px;
}
.footer .right,
.header .right {
  padding-right: 5px;
}
.footer .title,
.footer h1,
.header .title,
.header h1 {
  margin-bottom: 15px;
  padding-left: 10px;
  padding-right: 15px;
  display: inline-block;
}
.footer button,
.header button {
  margin-top: -10px;
}
.footer button.icon,
.header button.icon {
  font-size: 30px;
  margin-top: -10px;
  z-index: 99;
  background: 0 0;
  border: none;
  padding: 0 6px;
}
.footer
  button.icon:not([class*="red"]):not([class*="pink"]):not([class*="white"]):not([class*="purple"]):not([class*="indigo"]):not([class*="blue"]):not([class*="cyan"]):not([class*="teal"]):not([class*="green"]):not([class*="lime"]):not([class*="yellow"]):not([class*="amber"]):not([class*="orange"]):not([class*="brown"]):not([class*="grey"]):not([class*="black"]):not([class*="white"]),
.header
  button.icon:not([class*="red"]):not([class*="pink"]):not([class*="white"]):not([class*="purple"]):not([class*="indigo"]):not([class*="blue"]):not([class*="cyan"]):not([class*="teal"]):not([class*="green"]):not([class*="lime"]):not([class*="yellow"]):not([class*="amber"]):not([class*="orange"]):not([class*="brown"]):not([class*="grey"]):not([class*="black"]):not([class*="white"]) {
  color: #fff;
}
.footer button.icon.left,
.footer button.icon.right,
.header button.icon.left,
.header button.icon.right {
  margin-top: 5px;
  margin-bottom: 5px;
}
.footer button.icon.left,
.header button.icon.left {
  margin-left: 5px;
}
.footer button.icon.right,
.header button.icon.right {
  margin-right: 5px;
}
.footer .title.align-center,
.footer h1.align-center,
.footer p.align-center,
.header .title.align-center,
.header h1.align-center,
.header p.align-center {
  width: 100%;
  position: inherit;
  padding-right: 15px;
  left: 0;
  z-index: 9;
}
.footer .icon-badge,
.header .icon-badge {
  position: absolute;
  margin-left: -6px;
  margin-top: -8px;
  border-radius: 8px;
  z-index: 999;
}
.footer .buttons-group,
.header .buttons-group {
  margin-top: 15px;
  margin-left: 5px;
  margin-right: 5px;
}
.footer .buttons-group.small,
.header .buttons-group.small {
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
}
.header input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.8);
}
.header input.placeholder-white::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.header input {
  padding: 15px;
  position: absolute;
}
.header .left + input {
  padding-right: 50px;
}
.component-button {
  display: block;
}
button {
  -webkit-transition: color 0.2s ease;
  -moz-transition: color 0.2s ease;
  -ms-transition: color 0.2s ease;
  -o-transition: color 0.2s ease;
  transition: color 0.2s ease;
  font-size: 14px;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  color: #444;
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 0 12px;
  min-height: 42px;
  vertical-align: top;
  text-align: center;
  text-overflow: ellipsis;
  font-size: 16px;
  line-height: 42px;
  cursor: pointer;
  overflow: hidden;
}
button[disabled] {
  opacity: 0.8;
}
button .icon {
  position: absolute;
  left: 10px;
}
button.circle {
  width: 42px;
  height: 42px;
  text-align: center;
  padding: 0;
  line-height: 0;
  font-size: 22px;
  z-index: 10;
}
button.icon-text {
  padding-left: 40px;
}
button.icon-text i {
  font-size: 22px;
}
button.icon-text.icon-right {
  padding-left: 12px;
  padding-right: 40px;
  position: relative;
}
button.icon-right i {
  position: absolute;
  right: 12px;
  text-align: right;
  margin-top: 2px;
}
button[class*="border-"] {
  min-height: 40px;
  line-height: 40px;
}
button.full {
  width: 100%;
}
.buttons-group.big button,
button.big {
  font-size: 22px;
  min-height: 50px;
  line-height: 50px;
}
button.circle.big {
  width: 50px;
  height: 50px;
  font-size: 28px;
}
button.big.icon-text {
  padding-left: 40px;
}
button.big.icon-text.icon-right {
  padding-left: 12px;
  padding-right: 40px;
}
button.big.icon-text i {
  font-size: 26px;
}
.buttons-group.small button,
button.small {
  font-size: 12px;
  min-height: 30px;
  line-height: 30px;
}
button.circle.small {
  width: 30px;
  height: 30px;
  font-size: 18px;
}
button.small.icon-text {
  padding-left: 30px;
}
button.small.icon-text.icon-right {
  padding-left: 12px;
  padding-right: 35px;
}
button.small.icon-text i {
  font-size: 18px;
}
.buttons-group.huge button,
button.huge {
  font-size: 32px;
  min-height: 70px;
  line-height: 70px;
}
button.circle.huge {
  width: 70px;
  height: 70px;
  font-size: 32px;
}
button.huge.icon-text {
  padding-left: 40px;
}
button.huge.icon-text.icon-right {
  padding-left: 12px;
  padding-right: 40px;
}
button.huge.icon-text i {
  font-size: 32px;
}
.buttons-group {
  display: inline-block;
}
.buttons-group button {
  float: left;
  margin-left: -1px;
}
.buttons-group button:first-child {
  margin-left: 0;
}
.buttons-group.full {
  display: flex;
}
.buttons-group.full button {
  flex: 1;
}
.ripple {
  position: absolute;
  background: rgba(255, 255, 255, 0.25);
  border-radius: 100%;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  pointer-events: none;
}
.ripple.show {
  -webkit-animation: ripple 0.5s ease-out;
  -moz-animation: ripple 0.5s ease-out;
  -o-animation: ripple 0.5s ease-out;
  animation: ripple 0.5s ease-out;
}
@-webkit-keyframes ripple {
  to {
    -webkit-transform: scale(1.5);
    opacity: 0;
  }
}
@-moz-keyframes ripple {
  to {
    -moz-transform: scale(1.5);
    opacity: 0;
  }
}
@-o-keyframes ripple {
  to {
    -o-transform: scale(1.5);
    opacity: 0;
  }
}
@keyframes ripple {
  to {
    transform: scale(1.5);
    opacity: 0;
  }
}
.component-list {
  display: block;
}
.list {
  width: 100%;
  position: relative;
  border: 1px solid #ddd;
}
.list .item {
  padding: 15px;
  display: inline-block;
  border-bottom: 1px solid #ddd;
  width: 100%;
  position: relative;
  font-size: 18px;
}
.list .item.hidden {
  display: none;
}
.list ol,
.list ul {
  list-style: none;
}
.list .item.space-small {
  padding-top: 5px;
  padding-bottom: 5px;
}
.list .item.mark {
  border-right: 0;
  border-top: 0;
}
.item .block {
  display: -webkit-box;
  display: flex;
}
.list .item.mark.margin-button {
  border-bottom: 0;
  margin-bottom: 10px;
}
.list .item.no-space-top {
  padding-top: 0 !important;
}
.list .item.no-space-bottom {
  padding-bottom: 0 !important;
}
.list .item.row {
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.list.no-border {
  border: 0;
}
.list .item.no-border.border-bottom,
.list.no-border .item.border-bottom {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.list .item.no-border,
.list.no-border .item {
  border: none;
}
.list .item.cover {
  padding: 0;
  margin-left: 20px;
  height: 200px;
  width: -webkit-calc(100% - 40px);
  width: -moz-calc(100% - 40px);
  width: calc(100% - 40px);
}
.list .item.full {
  padding: 0;
  display: flex;
}
.list .item h1,
.list .item h2 {
  font-size: 16px;
  margin: 0;
  padding: 0;
}
.list .item h1 {
  margin-top: 7px;
}
.list .item p {
  font-size: 14px;
  margin: 0;
  padding: 0;
  line-height: 20px;
}
.list .item:last-child {
  border-bottom: 0;
}
.list .left {
  margin-right: 10px;
  min-width: 25px;
}
.list .right {
  margin-right: 20px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
}
.list .right i {
  margin-left: 5px;
}
.list .align-top {
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: baseline;
  margin-top: 10px;
}
.list .avatar {
  height: 50px;
}
.avatar-badge {
  position: absolute;
  margin-left: -4px;
  margin-top: -3px;
  border-radius: 8px;
}
.list .item small,
.list .item small .icon {
  font-size: 14px;
}
.list .item .icon {
  font-size: 18px;
}
.list .item .icon.text-huge {
  font-size: 32px;
}
.list .left .icon,
.list .right .icon {
  vertical-align: -webkit-baseline-middle;
}
.list .border-big {
  border-width: 3px;
  border-style: solid;
}
.list .item[onclick]:active,
.list a.item:active {
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.list .item .right .icon,
.list .item .right.icon {
  font-size: 24px;
  padding: 0;
  margin: 0;
}
.component-cover {
  display: block;
}
.cover {
  padding: 15px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
}
.cover.cover-200 {
  height: 300px;
}
.cover.cover-200 {
  height: 300px;
}
.cover.cover-400 {
  height: 400px;
}
.cover.cover-500 {
  height: 500px;
}
.cover.no-padding {
  padding: 0;
}
.cover .avatar {
  height: 100px;
  border-width: 4px;
  margin-bottom: 5px;
}
.cover .item .avatar {
  height: 50px;
}
.cover .float-bottom-center,
.cover .float-bottom-left,
.cover .float-bottom-right {
  margin-bottom: -30px;
  z-index: 99;
  position: absolute;
}
.cover .big.float-bottom-center,
.cover .big.float-bottom-left,
.cover .big.float-bottom-right {
  margin-bottom: -35px;
}
.cover .small.float-bottom-center,
.cover .small.float-bottom-left,
.cover .small.float-bottom-right {
  margin-bottom: -25px;
}
.cover .float-bottom-center {
  left: 50%;
  margin-left: -21px;
}
.cover .small.float-bottom-center {
  left: 50%;
  margin-left: -15px;
}
.cover .big.float-bottom-center {
  left: 50%;
  margin-left: -25px;
}
.cover .gradient {
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0,
    rgba(0, 0, 0, 0.65) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0,
    rgba(0, 0, 0, 0.65) 100%
  );
  width: 100%;
  padding-bottom: 10px;
  padding-top: 20px;
}
.cover.blend-multiply {
  background-blend-mode: multiply;
}
.cover.blend-screen {
  background-blend-mode: screen;
}
.cover.blend-overlay {
  background-blend-mode: overlay;
}
.cover.blend-darken {
  background-blend-mode: darken;
}
.cover.blend-lighten {
  background-blend-mode: lighten;
}
.cover.blend-color-dodge {
  background-blend-mode: color-dodge;
}
.cover.blend-color-burn {
  background-blend-mode: color-burn;
}
.cover.blend-hard-light {
  background-blend-mode: hard-light;
}
.cover.blend-soft-light {
  background-blend-mode: soft-light;
}
.cover.blend-luminosity {
  background-blend-mode: luminosity;
}
.component-grid {
  display: block;
}
.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.row-between {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.row-around {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.row-stretch {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.vertical-align-top {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.vertical-align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.vertical-align-bottom {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.horizontal-align-left {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.horizontal-align-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.horizontal-align-right {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.row-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.col {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
}
.col-10 {
  position: relative;
  -ms-flex-preferred-size: 10%;
  flex-basis: 10%;
}
.col-20 {
  position: relative;
  -ms-flex-preferred-size: 20%;
  flex-basis: 20%;
}
.col-25 {
  position: relative;
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
}
.col-33,
.col-34 {
  position: relative;
  -ms-flex-preferred-size: 33.3333%;
  flex-basis: 33.3333%;
}
.col-50 {
  position: relative;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
}
.col-66,
.col-67 {
  position: relative;
  -ms-flex-preferred-size: 66.6666%;
  flex-basis: 66.6666%;
}
.col-75 {
  position: relative;
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%;
}
.col-80 {
  position: relative;
  -ms-flex-preferred-size: 80%;
  flex-basis: 80%;
}
.col-90 {
  position: relative;
  -ms-flex-preferred-size: 90%;
  flex-basis: 90%;
}
.full-width {
  width: 100%;
  margin: 0 auto;
}
.full-height {
  height: 100%;
  margin: 0 auto;
}
.component-input {
  display: block;
}
input,
select,
textarea {
  border: none;
  background: 0 0;
  font-size: 14px;
  width: 100%;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
input[disabled],
select[disabled],
textarea[disabled] {
  opacity: 0.8;
}
label {
  font-size: 14px;
}
.item.label-fixed,
.label-fixed {
  display: -moz-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
  width: 100%;
}
.label-fixed label {
  -webkit-flex: 0 0 100px;
  -ms-flex: 0 0 100px;
  flex: 0 0 100px;
  width: 100px;
  min-width: 100px;
  max-width: 200px;
}
.label-float label {
  margin-bottom: 0;
  -webkit-transform: translate3d(0, 27px, 0);
  transform: translate3d(0, 27px, 0);
  -webkit-transform-origin: left top;
  transform-origin: left top;
  transition: -webkit-transform 150ms ease-in-out;
  transition: transform 150ms ease-in-out;
  transition: transform 150ms ease-in-out, -webkit-transform 150ms ease-in-out;
  align-self: stretch;
  -webkit-flex: initial;
  -ms-flex: initial;
  flex: initial;
  display: block;
}
.label-float label.focus {
  -webkit-transform: translate3d(0, 0, 0) scale(0.8);
  transform: translate3d(0, 0, 0) scale(0.8);
}
.item.icon {
  display: flex;
}
.item.icon.radius {
  border-radius: 6px;
}
.item.icon:not([class*="text-"]) {
  color: #9f9f9f;
}
.item input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.6);
}
.item input.placeholder-white::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.item.icon:before {
  position: absolute;
}
.item.icon.icon-right:before {
  right: 20px;
}
.item.icon input,
.item.icon select {
  padding-left: 30px;
}
.item.icon.icon-right input,
.item.icon.icon-right select {
  padding-left: 0;
  padding-right: 30px;
}
input[type="checkbox"] {
  width: 17px;
  height: 17px;
  z-index: 1;
  border: 0;
  vertical-align: top;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 2px;
}
.platform-ios input[type="checkbox"] {
  height: 23px;
  width: 23px;
  border-radius: 50%;
}
input[type="checkbox"]:not(:checked) {
  background-color: #fff !important;
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.5);
}
.platform-ios input[type="checkbox"]:not(:checked) {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.4);
}
input[type="checkbox"]:disabled {
  opacity: 0.4;
}
input[type="checkbox"]::before {
  content: "";
  position: absolute;
  margin-top: 1px;
  margin-left: 5px;
  width: 5px;
  height: 10px;
  border-width: 2px;
  border-top-width: 0;
  border-left-width: 0;
  border-style: solid;
  border-color: #fff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.platform-ios input[type="checkbox"]::before {
  margin-top: 5px;
  margin-left: 9px;
  width: 4px;
  height: 9px;
  border-width: 1px;
  border-top-width: 0;
  border-left-width: 0;
}
input[type="checkbox"].switch {
  width: 36px;
  height: 14px;
  box-shadow: inset 0 0 0 30px rgba(255, 255, 255, 0.4);
  border-radius: 28px;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.35s;
  transition-duration: 0.35s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.platform-ios input[type="checkbox"].switch {
  height: 32px;
  width: 51px;
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.05);
}
input[type="checkbox"].switch:not(:checked) {
  background-color: #fff !important;
  box-shadow: inset 0 0 0 30px rgba(0, 0, 0, 0.35);
}
.platform-ios input[type="checkbox"].switch:not(:checked) {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.1);
}
input[type="checkbox"].switch::before {
  content: "";
  border-radius: 28px;
  height: 20px;
  width: 20px;
  margin-left: -1px;
  margin-top: -3px;
  -webkit-transition-duration: 0.35s;
  transition-duration: 0.35s;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.4);
  border: 0;
}
.platform-ios input[type="checkbox"].switch::before {
  height: 28px;
  width: 28px;
  box-shadow: 0 0 0 1px #e4e4e4, 0 3px 2px rgba(0, 0, 0, 0.25);
  margin-top: 2px;
  margin-left: 2px;
  background-color: #fff !important;
}
input[type="checkbox"].switch:checked::before {
  margin-left: 16px;
}
input[type="checkbox"].switch:not(:checked)::before {
  background-color: #fff !important;
}
.platform-ios input[type="checkbox"].switch:checked::before {
  margin-left: 21px;
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.25);
  background-color: #fff;
}
input[type="radio"] {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  z-index: 1;
  vertical-align: top;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: 0 0;
}
.platform-ios input[type="radio"] {
  background: 0 0 !important;
}
input[type="radio"]::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 50%;
}
.platform-ios input[type="radio"]:not(:checked)::after {
  box-shadow: none;
}
input[type="radio"]:not(:checked)::after {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.4);
  background-color: #fff !important;
}
input[type="radio"]:checked::before {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  border: 3px solid #fff;
  z-index: 1;
  border-radius: 50%;
  margin-top: 2px;
  margin-left: 2px;
}
input[type="radio"]:disabled {
  opacity: 0.4;
}
.platform-ios input[type="radio"]::after {
  background: 0 0 !important;
}
.platform-ios input[type="radio"]:checked::before {
  position: absolute;
  margin-top: 4px;
  margin-left: 7px;
  width: 5px;
  height: 12px;
  border-width: 2px;
  border-top-width: 0;
  border-left-width: 0;
  border-style: solid;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-radius: 0;
}
.item.range {
  position: relative;
  -ms-flex-item-align: center;
  -webkit-align-self: center;
  align-self: center;
}
.item.range input {
  position: absolute;
  height: 28px;
  width: calc(100% - 110px);
  margin: 4px 0 5px 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background: -webkit-gradient(
    linear,
    50% 0,
    50% 100%,
    color-stop(0, #ccc),
    color-stop(100%, #ccc)
  );
  background: linear-gradient(to right, #ccc 0, #ccc 100%);
  background-position: center;
  background-size: 100% 2px;
  background-repeat: no-repeat;
  outline: 0;
  border: none;
  box-sizing: content-box;
  -ms-background-position-y: 500px;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: #666;
  width: 13px;
  height: 13px;
  border-radius: 50%;
}
input[type="range"]:active::-webkit-slider-thumb {
  width: 20px;
  height: 20px;
}
.platform-ios input[type="range"]::-webkit-slider-thumb {
  background-color: #fff;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
  width: 25px;
  height: 25px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 50%;
}
input[type="checkbox"].red-50.switch::before,
input[type="radio"].red-50::after {
  background-color: #ffebee;
}
input[type="checkbox"].red-100.switch::before,
input[type="radio"].red-100::after {
  background-color: #ffcdd2;
}
input[type="checkbox"].red-200.switch::before,
input[type="radio"].red-200::after {
  background-color: #ef9a9a;
}
input[type="checkbox"].red-300.switch::before,
input[type="radio"].red-300::after {
  background-color: #e57373;
}
input[type="checkbox"].red-400.switch::before,
input[type="radio"].red-400::after {
  background-color: #ef5350;
}
input[type="checkbox"].red-500.switch::before,
input[type="radio"].red-500::after {
  background-color: #f44336;
}
input[type="checkbox"].red-600.switch::before,
input[type="radio"].red-600::after {
  background-color: #e53935;
}
input[type="checkbox"].red-700.switch::before,
input[type="radio"].red-700::after {
  background-color: #d32f2f;
}
input[type="checkbox"].red-800.switch::before,
input[type="radio"].red-800::after {
  background-color: #c62828;
}
input[type="checkbox"].red-900.switch::before,
input[type="radio"].red-900::after {
  background-color: #b71c1c;
}
input[type="checkbox"].pink.switch::before,
input[type="radio"].pink::after {
  background-color: #e91e63;
}
input[type="checkbox"].pink-50.switch::before,
input[type="radio"].pink-50::after {
  background-color: #fce4ec;
}
input[type="checkbox"].pink-100.switch::before,
input[type="radio"].pink-100::after {
  background-color: #f8bbd0;
}
input[type="checkbox"].pink-200.switch::before,
input[type="radio"].pink-200::after {
  background-color: #f48fb1;
}
input[type="checkbox"].Pink-300.switch::before,
input[type="radio"].Pink-300::after {
  background-color: #f06292;
}
input[type="checkbox"].pink-400.switch::before,
input[type="radio"].pink-400::after {
  background-color: #ec407a;
}
input[type="checkbox"].pink-500.switch::before,
input[type="radio"].pink-500::after {
  background-color: #e91e63;
}
input[type="checkbox"].pink-600.switch::before,
input[type="radio"].pink-600::after {
  background-color: #d81b60;
}
input[type="checkbox"].pink-700.switch::before,
input[type="radio"].pink-700::after {
  background-color: #c2185b;
}
input[type="checkbox"].pink-800.switch::before,
input[type="radio"].pink-800::after {
  background-color: #ad1457;
}
input[type="checkbox"].pink-900.switch::before,
input[type="radio"].pink-900::after {
  background-color: #880e4f;
}
input[type="checkbox"].purple.switch::before,
input[type="radio"].purple::after {
  background-color: #9c27b0;
}
input[type="checkbox"].purple-50.switch::before,
input[type="radio"].purple-50::after {
  background-color: #f3e5f5;
}
input[type="checkbox"].purple-100.switch::before,
input[type="radio"].purple-100::after {
  background-color: #e1bee7;
}
input[type="checkbox"].purple-200.switch::before,
input[type="radio"].purple-200::after {
  background-color: #ce93d8;
}
input[type="checkbox"].Purple-300.switch::before,
input[type="radio"].Purple-300::after {
  background-color: #ba68c8;
}
input[type="checkbox"].Purple-400.switch::before,
input[type="radio"].Purple-400::after {
  background-color: #ab47bc;
}
input[type="checkbox"].purple-500.switch::before,
input[type="radio"].purple-500::after {
  background-color: #9c27b0;
}
input[type="checkbox"].purple-600.switch::before,
input[type="radio"].purple-600::after {
  background-color: #8e24aa;
}
input[type="checkbox"].purple-700.switch::before,
input[type="radio"].purple-700::after {
  background-color: #7b1fa2;
}
input[type="checkbox"].purple-800.switch::before,
input[type="radio"].purple-800::after {
  background-color: #6a1b9a;
}
input[type="checkbox"].purple-900.switch::before,
input[type="radio"].purple-900::after {
  background-color: #4a148c;
}
input[type="checkbox"].deep-purple.switch::before,
input[type="radio"].deep-purple::after {
  background-color: #673ab7;
}
input[type="checkbox"].deep-purple-300.switch::before,
input[type="radio"].deep-purple-300::after {
  background-color: #9575cd;
}
input[type="checkbox"].deep-purple-400.switch::before,
input[type="radio"].deep-purple-400::after {
  background-color: #7e57c2;
}
input[type="checkbox"].deep-purple-500.switch::before,
input[type="radio"].deep-purple-500::after {
  background-color: #673ab7;
}
input[type="checkbox"].deep-purple-600.switch::before,
input[type="radio"].deep-purple-600::after {
  background-color: #5e35b1;
}
input[type="checkbox"].deep-purple-700.switch::before,
input[type="radio"].deep-purple-700::after {
  background-color: #512da8;
}
input[type="checkbox"].deep-purple-800.switch::before,
input[type="radio"].deep-purple-800::after {
  background-color: #4527a0;
}
input[type="checkbox"].deep-purple-900.switch::before,
input[type="radio"].deep-purple-900::after {
  background-color: #311b92;
}
input[type="checkbox"].indigo.switch::before,
input[type="radio"].indigo::after {
  background-color: #3f51b5;
}
input[type="checkbox"].indigo-50.switch::before,
input[type="radio"].indigo-50::after {
  background-color: #e8eaf6;
}
input[type="checkbox"].indigo-100.switch::before,
input[type="radio"].indigo-100::after {
  background-color: #c5cae9;
}
input[type="checkbox"].indigo-200.switch::before,
input[type="radio"].indigo-200::after {
  background-color: #9fa8da;
}
input[type="checkbox"].indigo-300.switch::before,
input[type="radio"].indigo-300::after {
  background-color: #7986cb;
}
input[type="checkbox"].indigo-400.switch::before,
input[type="radio"].indigo-400::after {
  background-color: #5c6bc0;
}
input[type="checkbox"].indigo-500.switch::before,
input[type="radio"].indigo-500::after {
  background-color: #3f51b5;
}
input[type="checkbox"].indigo-600.switch::before,
input[type="radio"].indigo-600::after {
  background-color: #3949ab;
}
input[type="checkbox"].indigo-700.switch::before,
input[type="radio"].indigo-700::after {
  background-color: #303f9f;
}
input[type="checkbox"].indigo-800.switch::before,
input[type="radio"].indigo-800::after {
  background-color: #283593;
}
input[type="checkbox"].indigo-900.switch::before,
input[type="radio"].indigo-900::after {
  background-color: #1a237e;
}
input[type="checkbox"].blue.switch::before,
input[type="radio"].blue::after {
  background-color: #2196f3;
}
input[type="checkbox"].blue-50.switch::before,
input[type="radio"].blue-50::after {
  background-color: #e3f2fd;
}
input[type="checkbox"].blue-100.switch::before,
input[type="radio"].blue-100::after {
  background-color: #bbdefb;
}
input[type="checkbox"].blue-200.switch::before,
input[type="radio"].blue-200::after {
  background-color: #90caf9;
}
input[type="checkbox"].blue-300.switch::before,
input[type="radio"].blue-300::after {
  background-color: #64b5f6;
}
input[type="checkbox"].blue-400.switch::before,
input[type="radio"].blue-400::after {
  background-color: #42a5f5;
}
input[type="checkbox"].blue-500.switch::before,
input[type="radio"].blue-500::after {
  background-color: #2196f3;
}
input[type="checkbox"].blue-600.switch::before,
input[type="radio"].blue-600::after {
  background-color: #1e88e5;
}
input[type="checkbox"].blue-700.switch::before,
input[type="radio"].blue-700::after {
  background-color: #1976d2;
}
input[type="checkbox"].blue-800.switch::before,
input[type="radio"].blue-800::after {
  background-color: #1565c0;
}
input[type="checkbox"].blue-900.switch::before,
input[type="radio"].blue-900::after {
  background-color: #0d47a1;
}
input[type="checkbox"].light-blue.switch::before,
input[type="radio"].light-blue::after {
  background-color: #03a9f4;
}
input[type="checkbox"].light-blue-50.switch::before,
input[type="radio"].light-blue-50::after {
  background-color: #e1f5fe;
}
input[type="checkbox"].light-blue-100.switch::before,
input[type="radio"].light-blue-100::after {
  background-color: #b3e5fc;
}
input[type="checkbox"].light-blue-200.switch::before,
input[type="radio"].light-blue-200::after {
  background-color: #81d4fa;
}
input[type="checkbox"].light-blue-300.switch::before,
input[type="radio"].light-blue-300::after {
  background-color: #4fc3f7;
}
input[type="checkbox"].light-blue-400.switch::before,
input[type="radio"].light-blue-400::after {
  background-color: #29b6f6;
}
input[type="checkbox"].light-blue-500.switch::before,
input[type="radio"].light-blue-500::after {
  background-color: #03a9f4;
}
input[type="checkbox"].light-blue-600.switch::before,
input[type="radio"].light-blue-600::after {
  background-color: #039be5;
}
input[type="checkbox"].light-blue-700.switch::before,
input[type="radio"].light-blue-700::after {
  background-color: #0288d1;
}
input[type="checkbox"].light-blue-800.switch::before,
input[type="radio"].light-blue-800::after {
  background-color: #0277bd;
}
input[type="checkbox"].light-blue-900.switch::before,
input[type="radio"].light-blue-900::after {
  background-color: #01579b;
}
input[type="checkbox"].cyan.switch::before,
input[type="radio"].cyan::after {
  background-color: #00bcd4;
}
input[type="checkbox"].cyan-50.switch::before,
input[type="radio"].cyan-50::after {
  background-color: #e0f7fa;
}
input[type="checkbox"].cyan-100.switch::before,
input[type="radio"].cyan-100::after {
  background-color: #b2ebf2;
}
input[type="checkbox"].cyan-200.switch::before,
input[type="radio"].cyan-200::after {
  background-color: #80deea;
}
input[type="checkbox"].cyan-300.switch::before,
input[type="radio"].cyan-300::after {
  background-color: #4dd0e1;
}
input[type="checkbox"].cyan-400.switch::before,
input[type="radio"].cyan-400::after {
  background-color: #26c6da;
}
input[type="checkbox"].cyan-500.switch::before,
input[type="radio"].cyan-500::after {
  background-color: #00bcd4;
}
input[type="checkbox"].cyan-600.switch::before,
input[type="radio"].cyan-600::after {
  background-color: #00acc1;
}
input[type="checkbox"].cyan-700.switch::before,
input[type="radio"].cyan-700::after {
  background-color: #0097a7;
}
input[type="checkbox"].cyan-800.switch::before,
input[type="radio"].cyan-800::after {
  background-color: #00838f;
}
input[type="checkbox"].cyan-900.switch::before,
input[type="radio"].cyan-900::after {
  background-color: #006064;
}
input[type="checkbox"].teal.switch::before,
input[type="radio"].teal::after {
  background-color: #009688;
}
input[type="checkbox"].teal-50.switch::before,
input[type="radio"].teal-50::after {
  background-color: #e0f2f1;
}
input[type="checkbox"].teal-100.switch::before,
input[type="radio"].teal-100::after {
  background-color: #b2dfdb;
}
input[type="checkbox"].teal-200.switch::before,
input[type="radio"].teal-200::after {
  background-color: #80cbc4;
}
input[type="checkbox"].teal-300.switch::before,
input[type="radio"].teal-300::after {
  background-color: #4db6ac;
}
input[type="checkbox"].teal-400.switch::before,
input[type="radio"].teal-400::after {
  background-color: #26a69a;
}
input[type="checkbox"].teal-500.switch::before,
input[type="radio"].teal-500::after {
  background-color: #009688;
}
input[type="checkbox"].teal-600.switch::before,
input[type="radio"].teal-600::after {
  background-color: #00897b;
}
input[type="checkbox"].teal-700.switch::before,
input[type="radio"].teal-700::after {
  background-color: #00796b;
}
input[type="checkbox"].teal-800.switch::before,
input[type="radio"].teal-800::after {
  background-color: #00695c;
}
input[type="checkbox"].teal-900.switch::before,
input[type="radio"].teal-900::after {
  background-color: #004d40;
}
input[type="checkbox"].green.switch::before,
input[type="radio"].green::after {
  background-color: #4caf50;
}
input[type="checkbox"].green-50.switch::before,
input[type="radio"].green-50::after {
  background-color: #e8f5e9;
}
input[type="checkbox"].green-100.switch::before,
input[type="radio"].green-100::after {
  background-color: #c8e6c9;
}
input[type="checkbox"].green-200.switch::before,
input[type="radio"].green-200::after {
  background-color: #a5d6a7;
}
input[type="checkbox"].green-300.switch::before,
input[type="radio"].green-300::after {
  background-color: #81c784;
}
input[type="checkbox"].green-400.switch::before,
input[type="radio"].green-400::after {
  background-color: #66bb6a;
}
input[type="checkbox"].green-500.switch::before,
input[type="radio"].green-500::after {
  background-color: #4caf50;
}
input[type="checkbox"].green-600.switch::before,
input[type="radio"].green-600::after {
  background-color: #43a047;
}
input[type="checkbox"].green-700.switch::before,
input[type="radio"].green-700::after {
  background-color: #388e3c;
}
input[type="checkbox"].green-800.switch::before,
input[type="radio"].green-800::after {
  background-color: #2e7d32;
}
input[type="checkbox"].green-900.switch::before,
input[type="radio"].green-900::after {
  background-color: #1b5e20;
}
input[type="checkbox"].light-green.switch::before,
input[type="radio"].light-green::after {
  background-color: #8bc34a;
}
input[type="checkbox"].light-green-50.switch::before,
input[type="radio"].light-green-50::after {
  background-color: #f1f8e9;
}
input[type="checkbox"].light-green-100.switch::before,
input[type="radio"].light-green-100::after {
  background-color: #dcedc8;
}
input[type="checkbox"].light-green-200.switch::before,
input[type="radio"].light-green-200::after {
  background-color: #c5e1a5;
}
input[type="checkbox"].light-green-300.switch::before,
input[type="radio"].light-green-300::after {
  background-color: #aed581;
}
input[type="checkbox"].light-green-400.switch::before,
input[type="radio"].light-green-400::after {
  background-color: #9ccc65;
}
input[type="checkbox"].light-green-500.switch::before,
input[type="radio"].light-green-500::after {
  background-color: #8bc34a;
}
input[type="checkbox"].light-green-600.switch::before,
input[type="radio"].light-green-600::after {
  background-color: #7cb342;
}
input[type="checkbox"].light-green-700.switch::before,
input[type="radio"].light-green-700::after {
  background-color: #689f38;
}
input[type="checkbox"].light-green-800.switch::before,
input[type="radio"].light-green-800::after {
  background-color: #558b2f;
}
input[type="checkbox"].light-green-900.switch::before,
input[type="radio"].light-green-900::after {
  background-color: #33691e;
}
input[type="checkbox"].lime.switch::before,
input[type="radio"].lime::after {
  background-color: #cddc39;
}
input[type="checkbox"].lime-50.switch::before,
input[type="radio"].lime-50::after {
  background-color: #f9fbe7;
}
input[type="checkbox"].lime-100.switch::before,
input[type="radio"].lime-100::after {
  background-color: #f0f4c3;
}
input[type="checkbox"].lime-200.switch::before,
input[type="radio"].lime-200::after {
  background-color: #e6ee9c;
}
input[type="checkbox"].lime-300.switch::before,
input[type="radio"].lime-300::after {
  background-color: #dce775;
}
input[type="checkbox"].lime-400.switch::before,
input[type="radio"].lime-400::after {
  background-color: #d4e157;
}
input[type="checkbox"].lime-500.switch::before,
input[type="radio"].lime-500::after {
  background-color: #cddc39;
}
input[type="checkbox"].lime-600.switch::before,
input[type="radio"].lime-600::after {
  background-color: #c0ca33;
}
input[type="checkbox"].lime-700.switch::before,
input[type="radio"].lime-700::after {
  background-color: #afb42b;
}
input[type="checkbox"].lime-800.switch::before,
input[type="radio"].lime-800::after {
  background-color: #9e9d24;
}
input[type="checkbox"].lime-900.switch::before,
input[type="radio"].lime-900::after {
  background-color: #827717;
}
input[type="checkbox"].yellow.switch::before,
input[type="radio"].yellow::after {
  background-color: #ffeb3b;
}
input[type="checkbox"].yellow-50.switch::before,
input[type="radio"].yellow-50::after {
  background-color: #fffde7;
}
input[type="checkbox"].yellow-100.switch::before,
input[type="radio"].yellow-100::after {
  background-color: #fff9c4;
}
input[type="checkbox"].yellow-200.switch::before,
input[type="radio"].yellow-200::after {
  background-color: #fff59d;
}
input[type="checkbox"].yellow-300.switch::before,
input[type="radio"].yellow-300::after {
  background-color: #fff176;
}
input[type="checkbox"].yellow-500.switch::before,
input[type="radio"].yellow-500::after {
  background-color: #ffeb3b;
}
input[type="checkbox"].yellow-600.switch::before,
input[type="radio"].yellow-600::after {
  background-color: #fdd835;
}
input[type="checkbox"].yellow-700.switch::before,
input[type="radio"].yellow-700::after {
  background-color: #fbc02d;
}
input[type="checkbox"].yellow-800.switch::before,
input[type="radio"].yellow-800::after {
  background-color: #f9a825;
}
input[type="checkbox"].yellow-900.switch::before,
input[type="radio"].yellow-900::after {
  background-color: #f57f17;
}
input[type="checkbox"].amber-50.switch::before,
input[type="radio"].amber-50::after {
  background-color: #fff8e1;
}
input[type="checkbox"].amber-100.switch::before,
input[type="radio"].amber-100::after {
  background-color: #ffecb3;
}
input[type="checkbox"].amber-200.switch::before,
input[type="radio"].amber-200::after {
  background-color: #ffe082;
}
input[type="checkbox"].amber-300.switch::before,
input[type="radio"].amber-300::after {
  background-color: #ffd54f;
}
input[type="checkbox"].amber-500.switch::before,
input[type="radio"].amber-500::after {
  background-color: #ffc107;
}
input[type="checkbox"].amber-600.switch::before,
input[type="radio"].amber-600::after {
  background-color: #ffb300;
}
input[type="checkbox"].amber-700.switch::before,
input[type="radio"].amber-700::after {
  background-color: #ffa000;
}
input[type="checkbox"].amber-800.switch::before,
input[type="radio"].amber-800::after {
  background-color: #ff8f00;
}
input[type="checkbox"].amber-900.switch::before,
input[type="radio"].amber-900::after {
  background-color: #ff6f00;
}
input[type="checkbox"].orange-50.switch::before,
input[type="radio"].orange-50::after {
  background-color: #fff3e0;
}
input[type="checkbox"].orange-100.switch::before,
input[type="radio"].orange-100::after {
  background-color: #ffe0b2;
}
input[type="checkbox"].orange-200.switch::before,
input[type="radio"].orange-200::after {
  background-color: #ffcc80;
}
input[type="checkbox"].orange-300.switch::before,
input[type="radio"].orange-300::after {
  background-color: #ffb74d;
}
input[type="checkbox"].orange-400.switch::before,
input[type="radio"].orange-400::after {
  background-color: #ffa726;
}
input[type="checkbox"].orange-500.switch::before,
input[type="radio"].orange-500::after {
  background-color: #ff9800;
}
input[type="checkbox"].orange-600.switch::before,
input[type="radio"].orange-600::after {
  background-color: #fb8c00;
}
input[type="checkbox"].orange-700.switch::before,
input[type="radio"].orange-700::after {
  background-color: #f57c00;
}
input[type="checkbox"].orange-800.switch::before,
input[type="radio"].orange-800::after {
  background-color: #ef6c00;
}
input[type="checkbox"].orange-900.switch::before,
input[type="radio"].orange-900::after {
  background-color: #e65100;
}
input[type="checkbox"].deep-orange.switch::before,
input[type="radio"].deep-orange::after {
  background-color: #ff5722;
}
input[type="checkbox"].deep-orange-50.switch::before,
input[type="radio"].deep-orange-50::after {
  background-color: #fbe9e7;
}
input[type="checkbox"].deep-orange-100.switch::before,
input[type="radio"].deep-orange-100::after {
  background-color: #ffccbc;
}
input[type="checkbox"].deep-orange-200.switch::before,
input[type="radio"].deep-orange-200::after {
  background-color: #ffab91;
}
input[type="checkbox"].deep-orange-300.switch::before,
input[type="radio"].deep-orange-300::after {
  background-color: #ff8a65;
}
input[type="checkbox"].deep-orange-400.switch::before,
input[type="radio"].deep-orange-400::after {
  background-color: #ff7043;
}
input[type="checkbox"].deep-orange-500.switch::before,
input[type="radio"].deep-orange-500::after {
  background-color: #ff5722;
}
input[type="checkbox"].deep-orange-600.switch::before,
input[type="radio"].deep-orange-600::after {
  background-color: #f4511e;
}
input[type="checkbox"].deep-orange-700.switch::before,
input[type="radio"].deep-orange-700::after {
  background-color: #e64a19;
}
input[type="checkbox"].deep-orange-800.switch::before,
input[type="radio"].deep-orange-800::after {
  background-color: #d84315;
}
input[type="checkbox"].deep-orange-900.switch::before,
input[type="radio"].deep-orange-900::after {
  background-color: #bf360c;
}
input[type="checkbox"].brown.switch::before,
input[type="radio"].brown::after {
  background-color: #795548;
}
input[type="checkbox"].brown-50.switch::before,
input[type="radio"].brown-50::after {
  background-color: #efebe9;
}
input[type="checkbox"].brown-100.switch::before,
input[type="radio"].brown-100::after {
  background-color: #d7ccc8;
}
input[type="checkbox"].brown-200.switch::before,
input[type="radio"].brown-200::after {
  background-color: #bcaaa4;
}
input[type="checkbox"].brown-300.switch::before,
input[type="radio"].brown-300::after {
  background-color: #a1887f;
}
input[type="checkbox"].brown-400.switch::before,
input[type="radio"].brown-400::after {
  background-color: #8d6e63;
}
input[type="checkbox"].brown-500.switch::before,
input[type="radio"].brown-500::after {
  background-color: #795548;
}
input[type="checkbox"].brown-600.switch::before,
input[type="radio"].brown-600::after {
  background-color: #6d4c41;
}
input[type="checkbox"].brown-700.switch::before,
input[type="radio"].brown-700::after {
  background-color: #5d4037;
}
input[type="checkbox"].brown-800.switch::before,
input[type="radio"].brown-800::after {
  background-color: #4e342e;
}
input[type="checkbox"].brown-900.switch::before,
input[type="radio"].brown-900::after {
  background-color: #3e2723;
}
input[type="checkbox"].grey.switch::before,
input[type="radio"].grey::after {
  background-color: #9e9e9e;
}
input[type="checkbox"].grey-50.switch::before,
input[type="radio"].grey-50::after {
  background-color: #fafafa;
}
input[type="checkbox"].grey-100.switch::before,
input[type="radio"].grey-100::after {
  background-color: #f5f5f5;
}
input[type="checkbox"].grey-200.switch::before,
input[type="radio"].grey-200::after {
  background-color: #eee;
}
input[type="checkbox"].grey-300.switch::before,
input[type="radio"].grey-300::after {
  background-color: #e0e0e0;
}
input[type="checkbox"].grey-400.switch::before,
input[type="radio"].grey-400::after {
  background-color: #bdbdbd;
}
input[type="checkbox"].grey-500.switch::before,
input[type="radio"].grey-500::after {
  background-color: #9e9e9e;
}
input[type="checkbox"].grey-600.switch::before,
input[type="radio"].grey-600::after {
  background-color: #757575;
}
input[type="checkbox"].grey-700.switch::before,
input[type="radio"].grey-700::after {
  background-color: #616161;
}
input[type="checkbox"].grey-800.switch::before,
input[type="radio"].grey-800::after {
  background-color: #424242;
}
input[type="checkbox"].grey-900.switch::before,
input[type="radio"].grey-900::after {
  background-color: #212121;
}
input[type="checkbox"].blue-grey.switch::before,
input[type="radio"].blue-grey::after {
  background-color: #607d8b;
}
input[type="checkbox"].blue-grey-50.switch::before,
input[type="radio"].blue-grey-50::after {
  background-color: #eceff1;
}
input[type="checkbox"].blue-grey-100.switch::before,
input[type="radio"].blue-grey-100::after {
  background-color: #cfd8dc;
}
input[type="checkbox"].blue-grey-200.switch::before,
input[type="radio"].blue-grey-200::after {
  background-color: #b0bec5;
}
input[type="checkbox"].blue-grey-300.switch::before,
input[type="radio"].blue-grey-300::after {
  background-color: #90a4ae;
}
input[type="checkbox"].blue-grey-400.switch::before,
input[type="radio"].blue-grey-400::after {
  background-color: #78909c;
}
input[type="checkbox"].blue-grey-500.switch::before,
input[type="radio"].blue-grey-500::after {
  background-color: #607d8b;
}
input[type="checkbox"].blue-grey-600.switch::before,
input[type="radio"].blue-grey-600::after {
  background-color: #546e7a;
}
input[type="checkbox"].blue-grey-700.switch::before,
input[type="radio"].blue-grey-700::after {
  background-color: #455a64;
}
input[type="checkbox"].blue-grey-800.switch::before,
input[type="radio"].blue-grey-800::after {
  background-color: #37474f;
}
input[type="checkbox"].blue-grey-900.switch::before,
input[type="radio"].blue-grey-900::after {
  background-color: #263238;
}
input[type="checkbox"].black.switch::before,
input[type="radio"].black::after {
  background-color: #000;
}
.platform-ios input[type="radio"].red-50::before {
  border-color: #ffebee;
}
.platform-ios input[type="radio"].red-100::before {
  border-color: #ffcdd2;
}
.platform-ios input[type="radio"].red-200::before {
  border-color: #ef9a9a;
}
.platform-ios input[type="radio"].red-300::before {
  border-color: #e57373;
}
.platform-ios input[type="radio"].red-400::before {
  border-color: #ef5350;
}
.platform-ios input[type="radio"].red-500::before {
  border-color: #f44336;
}
.platform-ios input[type="radio"].red-600::before {
  border-color: #e53935;
}
.platform-ios input[type="radio"].red-700::before {
  border-color: #d32f2f;
}
.platform-ios input[type="radio"].red-800::before {
  border-color: #c62828;
}
.platform-ios input[type="radio"].red-900::before {
  border-color: #b71c1c;
}
.platform-ios input[type="radio"].pink::before {
  border-color: #e91e63;
}
.platform-ios input[type="radio"].pink-50::before {
  border-color: #fce4ec;
}
.platform-ios input[type="radio"].pink-100::before {
  border-color: #f8bbd0;
}
.platform-ios input[type="radio"].pink-200::before {
  border-color: #f48fb1;
}
.platform-ios input[type="radio"].Pink-300::before {
  border-color: #f06292;
}
.platform-ios input[type="radio"].pink-400::before {
  border-color: #ec407a;
}
.platform-ios input[type="radio"].pink-500::before {
  border-color: #e91e63;
}
.platform-ios input[type="radio"].pink-600::before {
  border-color: #d81b60;
}
.platform-ios input[type="radio"].pink-700::before {
  border-color: #c2185b;
}
.platform-ios input[type="radio"].pink-800::before {
  border-color: #ad1457;
}
.platform-ios input[type="radio"].pink-900::before {
  border-color: #880e4f;
}
.platform-ios input[type="radio"].purple::before {
  border-color: #9c27b0;
}
.platform-ios input[type="radio"].purple-50::before {
  border-color: #f3e5f5;
}
.platform-ios input[type="radio"].purple-100::before {
  border-color: #e1bee7;
}
.platform-ios input[type="radio"].purple-200::before {
  border-color: #ce93d8;
}
.platform-ios input[type="radio"].Purple-300::before {
  border-color: #ba68c8;
}
.platform-ios input[type="radio"].Purple-400::before {
  border-color: #ab47bc;
}
.platform-ios input[type="radio"].purple-500::before {
  border-color: #9c27b0;
}
.platform-ios input[type="radio"].purple-600::before {
  border-color: #8e24aa;
}
.platform-ios input[type="radio"].purple-700::before {
  border-color: #7b1fa2;
}
.platform-ios input[type="radio"].purple-800::before {
  border-color: #6a1b9a;
}
.platform-ios input[type="radio"].purple-900::before {
  border-color: #4a148c;
}
.platform-ios input[type="radio"].deep-purple::before {
  border-color: #673ab7;
}
.platform-ios input[type="radio"].deep-purple-300::before {
  border-color: #9575cd;
}
.platform-ios input[type="radio"].deep-purple-400::before {
  border-color: #7e57c2;
}
.platform-ios input[type="radio"].deep-purple-500::before {
  border-color: #673ab7;
}
.platform-ios input[type="radio"].deep-purple-600::before {
  border-color: #5e35b1;
}
.platform-ios input[type="radio"].deep-purple-700::before {
  border-color: #512da8;
}
.platform-ios input[type="radio"].deep-purple-800::before {
  border-color: #4527a0;
}
.platform-ios input[type="radio"].deep-purple-900::before {
  border-color: #311b92;
}
.platform-ios input[type="radio"].indigo::before {
  border-color: #3f51b5;
}
.platform-ios input[type="radio"].indigo-50::before {
  border-color: #e8eaf6;
}
.platform-ios input[type="radio"].indigo-100::before {
  border-color: #c5cae9;
}
.platform-ios input[type="radio"].indigo-200::before {
  border-color: #9fa8da;
}
.platform-ios input[type="radio"].indigo-300::before {
  border-color: #7986cb;
}
.platform-ios input[type="radio"].indigo-400::before {
  border-color: #5c6bc0;
}
.platform-ios input[type="radio"].indigo-500::before {
  border-color: #3f51b5;
}
.platform-ios input[type="radio"].indigo-600::before {
  border-color: #3949ab;
}
.platform-ios input[type="radio"].indigo-700::before {
  border-color: #303f9f;
}
.platform-ios input[type="radio"].indigo-800::before {
  border-color: #283593;
}
.platform-ios input[type="radio"].indigo-900::before {
  border-color: #1a237e;
}
.platform-ios input[type="radio"].blue::before {
  border-color: #2196f3;
}
.platform-ios input[type="radio"].blue-50::before {
  border-color: #e3f2fd;
}
.platform-ios input[type="radio"].blue-100::before {
  border-color: #bbdefb;
}
.platform-ios input[type="radio"].blue-200::before {
  border-color: #90caf9;
}
.platform-ios input[type="radio"].blue-300::before {
  border-color: #64b5f6;
}
.platform-ios input[type="radio"].blue-400::before {
  border-color: #42a5f5;
}
.platform-ios input[type="radio"].blue-500::before {
  border-color: #2196f3;
}
.platform-ios input[type="radio"].blue-600::before {
  border-color: #1e88e5;
}
.platform-ios input[type="radio"].blue-700::before {
  border-color: #1976d2;
}
.platform-ios input[type="radio"].blue-800::before {
  border-color: #1565c0;
}
.platform-ios input[type="radio"].blue-900::before {
  border-color: #0d47a1;
}
.platform-ios input[type="radio"].light-blue::before {
  border-color: #03a9f4;
}
.platform-ios input[type="radio"].light-blue-50::before {
  border-color: #e1f5fe;
}
.platform-ios input[type="radio"].light-blue-100::before {
  border-color: #b3e5fc;
}
.platform-ios input[type="radio"].light-blue-200::before {
  border-color: #81d4fa;
}
.platform-ios input[type="radio"].light-blue-300::before {
  border-color: #4fc3f7;
}
.platform-ios input[type="radio"].light-blue-400::before {
  border-color: #29b6f6;
}
.platform-ios input[type="radio"].light-blue-500::before {
  border-color: #03a9f4;
}
.platform-ios input[type="radio"].light-blue-600::before {
  border-color: #039be5;
}
.platform-ios input[type="radio"].light-blue-700::before {
  border-color: #0288d1;
}
.platform-ios input[type="radio"].light-blue-800::before {
  border-color: #0277bd;
}
.platform-ios input[type="radio"].light-blue-900::before {
  border-color: #01579b;
}
.platform-ios input[type="radio"].cyan::before {
  border-color: #00bcd4;
}
.platform-ios input[type="radio"].cyan-50::before {
  border-color: #e0f7fa;
}
.platform-ios input[type="radio"].cyan-100::before {
  border-color: #b2ebf2;
}
.platform-ios input[type="radio"].cyan-200::before {
  border-color: #80deea;
}
.platform-ios input[type="radio"].cyan-300::before {
  border-color: #4dd0e1;
}
.platform-ios input[type="radio"].cyan-400::before {
  border-color: #26c6da;
}
.platform-ios input[type="radio"].cyan-500::before {
  border-color: #00bcd4;
}
.platform-ios input[type="radio"].cyan-600::before {
  border-color: #00acc1;
}
.platform-ios input[type="radio"].cyan-700::before {
  border-color: #0097a7;
}
.platform-ios input[type="radio"].cyan-800::before {
  border-color: #00838f;
}
.platform-ios input[type="radio"].cyan-900::before {
  border-color: #006064;
}
.platform-ios input[type="radio"].teal::before {
  border-color: #009688;
}
.platform-ios input[type="radio"].teal-50::before {
  border-color: #e0f2f1;
}
.platform-ios input[type="radio"].teal-100::before {
  border-color: #b2dfdb;
}
.platform-ios input[type="radio"].teal-200::before {
  border-color: #80cbc4;
}
.platform-ios input[type="radio"].teal-300::before {
  border-color: #4db6ac;
}
.platform-ios input[type="radio"].teal-400::before {
  border-color: #26a69a;
}
.platform-ios input[type="radio"].teal-500::before {
  border-color: #009688;
}
.platform-ios input[type="radio"].teal-600::before {
  border-color: #00897b;
}
.platform-ios input[type="radio"].teal-700::before {
  border-color: #00796b;
}
.platform-ios input[type="radio"].teal-800::before {
  border-color: #00695c;
}
.platform-ios input[type="radio"].teal-900::before {
  border-color: #004d40;
}
.platform-ios input[type="radio"].green::before {
  border-color: #4caf50;
}
.platform-ios input[type="radio"].green-50::before {
  border-color: #e8f5e9;
}
.platform-ios input[type="radio"].green-100::before {
  border-color: #c8e6c9;
}
.platform-ios input[type="radio"].green-200::before {
  border-color: #a5d6a7;
}
.platform-ios input[type="radio"].green-300::before {
  border-color: #81c784;
}
.platform-ios input[type="radio"].green-400::before {
  border-color: #66bb6a;
}
.platform-ios input[type="radio"].green-500::before {
  border-color: #4caf50;
}
.platform-ios input[type="radio"].green-600::before {
  border-color: #43a047;
}
.platform-ios input[type="radio"].green-700::before {
  border-color: #388e3c;
}
.platform-ios input[type="radio"].green-800::before {
  border-color: #2e7d32;
}
.platform-ios input[type="radio"].green-900::before {
  border-color: #1b5e20;
}
.platform-ios input[type="radio"].light-green::before {
  border-color: #8bc34a;
}
.platform-ios input[type="radio"].light-green-50::before {
  border-color: #f1f8e9;
}
.platform-ios input[type="radio"].light-green-100::before {
  border-color: #dcedc8;
}
.platform-ios input[type="radio"].light-green-200::before {
  border-color: #c5e1a5;
}
.platform-ios input[type="radio"].light-green-300::before {
  border-color: #aed581;
}
.platform-ios input[type="radio"].light-green-400::before {
  border-color: #9ccc65;
}
.platform-ios input[type="radio"].light-green-500::before {
  border-color: #8bc34a;
}
.platform-ios input[type="radio"].light-green-600::before {
  border-color: #7cb342;
}
.platform-ios input[type="radio"].light-green-700::before {
  border-color: #689f38;
}
.platform-ios input[type="radio"].light-green-800::before {
  border-color: #558b2f;
}
.platform-ios input[type="radio"].light-green-900::before {
  border-color: #33691e;
}
.platform-ios input[type="radio"].lime::before {
  border-color: #cddc39;
}
.platform-ios input[type="radio"].lime-50::before {
  border-color: #f9fbe7;
}
.platform-ios input[type="radio"].lime-100::before {
  border-color: #f0f4c3;
}
.platform-ios input[type="radio"].lime-200::before {
  border-color: #e6ee9c;
}
.platform-ios input[type="radio"].lime-300::before {
  border-color: #dce775;
}
.platform-ios input[type="radio"].lime-400::before {
  border-color: #d4e157;
}
.platform-ios input[type="radio"].lime-500::before {
  border-color: #cddc39;
}
.platform-ios input[type="radio"].lime-600::before {
  border-color: #c0ca33;
}
.platform-ios input[type="radio"].lime-700::before {
  border-color: #afb42b;
}
.platform-ios input[type="radio"].lime-800::before {
  border-color: #9e9d24;
}
.platform-ios input[type="radio"].lime-900::before {
  border-color: #827717;
}
.platform-ios input[type="radio"].yellow::before {
  border-color: #ffeb3b;
}
.platform-ios input[type="radio"].yellow-50::before {
  border-color: #fffde7;
}
.platform-ios input[type="radio"].yellow-100::before {
  border-color: #fff9c4;
}
.platform-ios input[type="radio"].yellow-200::before {
  border-color: #fff59d;
}
.platform-ios input[type="radio"].yellow-300::before {
  border-color: #fff176;
}
.platform-ios input[type="radio"].yellow-500::before {
  border-color: #ffeb3b;
}
.platform-ios input[type="radio"].yellow-600::before {
  border-color: #fdd835;
}
.platform-ios input[type="radio"].yellow-700::before {
  border-color: #fbc02d;
}
.platform-ios input[type="radio"].yellow-800::before {
  border-color: #f9a825;
}
.platform-ios input[type="radio"].yellow-900::before {
  border-color: #f57f17;
}
.platform-ios input[type="radio"].amber-50::before {
  border-color: #fff8e1;
}
.platform-ios input[type="radio"].amber-100::before {
  border-color: #ffecb3;
}
.platform-ios input[type="radio"].amber-200::before {
  border-color: #ffe082;
}
.platform-ios input[type="radio"].amber-300::before {
  border-color: #ffd54f;
}
.platform-ios input[type="radio"].amber-500::before {
  border-color: #ffc107;
}
.platform-ios input[type="radio"].amber-600::before {
  border-color: #ffb300;
}
.platform-ios input[type="radio"].amber-700::before {
  border-color: #ffa000;
}
.platform-ios input[type="radio"].amber-800::before {
  border-color: #ff8f00;
}
.platform-ios input[type="radio"].amber-900::before {
  border-color: #ff6f00;
}
.platform-ios input[type="radio"].orange-50::before {
  border-color: #fff3e0;
}
.platform-ios input[type="radio"].orange-100::before {
  border-color: #ffe0b2;
}
.platform-ios input[type="radio"].orange-200::before {
  border-color: #ffcc80;
}
.platform-ios input[type="radio"].orange-300::before {
  border-color: #ffb74d;
}
.platform-ios input[type="radio"].orange-400::before {
  border-color: #ffa726;
}
.platform-ios input[type="radio"].orange-500::before {
  border-color: #ff9800;
}
.platform-ios input[type="radio"].orange-600::before {
  border-color: #fb8c00;
}
.platform-ios input[type="radio"].orange-700::before {
  border-color: #f57c00;
}
.platform-ios input[type="radio"].orange-800::before {
  border-color: #ef6c00;
}
.platform-ios input[type="radio"].orange-900::before {
  border-color: #e65100;
}
.platform-ios input[type="radio"].deep-orange::before {
  border-color: #ff5722;
}
.platform-ios input[type="radio"].deep-orange-50::before {
  border-color: #fbe9e7;
}
.platform-ios input[type="radio"].deep-orange-100::before {
  border-color: #ffccbc;
}
.platform-ios input[type="radio"].deep-orange-200::before {
  border-color: #ffab91;
}
.platform-ios input[type="radio"].deep-orange-300::before {
  border-color: #ff8a65;
}
.platform-ios input[type="radio"].deep-orange-400::before {
  border-color: #ff7043;
}
.platform-ios input[type="radio"].deep-orange-500::before {
  border-color: #ff5722;
}
.platform-ios input[type="radio"].deep-orange-600::before {
  border-color: #f4511e;
}
.platform-ios input[type="radio"].deep-orange-700::before {
  border-color: #e64a19;
}
.platform-ios input[type="radio"].deep-orange-800::before {
  border-color: #d84315;
}
.platform-ios input[type="radio"].deep-orange-900::before {
  border-color: #bf360c;
}
.platform-ios input[type="radio"].brown::before {
  border-color: #795548;
}
.platform-ios input[type="radio"].brown-50::before {
  border-color: #efebe9;
}
.platform-ios input[type="radio"].brown-100::before {
  border-color: #d7ccc8;
}
.platform-ios input[type="radio"].brown-200::before {
  border-color: #bcaaa4;
}
.platform-ios input[type="radio"].brown-300::before {
  border-color: #a1887f;
}
.platform-ios input[type="radio"].brown-400::before {
  border-color: #8d6e63;
}
.platform-ios input[type="radio"].brown-500::before {
  border-color: #795548;
}
.platform-ios input[type="radio"].brown-600::before {
  border-color: #6d4c41;
}
.platform-ios input[type="radio"].brown-700::before {
  border-color: #5d4037;
}
.platform-ios input[type="radio"].brown-800::before {
  border-color: #4e342e;
}
.platform-ios input[type="radio"].brown-900::before {
  border-color: #3e2723;
}
.platform-ios input[type="radio"].grey::before {
  border-color: #9e9e9e;
}
.platform-ios input[type="radio"].grey-50::before {
  border-color: #fafafa;
}
.platform-ios input[type="radio"].grey-100::before {
  border-color: #f5f5f5;
}
.platform-ios input[type="radio"].grey-200::before {
  border-color: #eee;
}
.platform-ios input[type="radio"].grey-300::before {
  border-color: #e0e0e0;
}
.platform-ios input[type="radio"].grey-400::before {
  border-color: #bdbdbd;
}
.platform-ios input[type="radio"].grey-500::before {
  border-color: #9e9e9e;
}
.platform-ios input[type="radio"].grey-600::before {
  border-color: #757575;
}
.platform-ios input[type="radio"].grey-700::before {
  border-color: #616161;
}
.platform-ios input[type="radio"].grey-800::before {
  border-color: #424242;
}
.platform-ios input[type="radio"].grey-900::before {
  border-color: #212121;
}
.platform-ios input[type="radio"].blue-grey::before {
  border-color: #607d8b;
}
.platform-ios input[type="radio"].blue-grey-50::before {
  border-color: #eceff1;
}
.platform-ios input[type="radio"].blue-grey-100::before {
  border-color: #cfd8dc;
}
.platform-ios input[type="radio"].blue-grey-200::before {
  border-color: #b0bec5;
}
.platform-ios input[type="radio"].blue-grey-300::before {
  border-color: #90a4ae;
}
.platform-ios input[type="radio"].blue-grey-400::before {
  border-color: #78909c;
}
.platform-ios input[type="radio"].blue-grey-500::before {
  border-color: #607d8b;
}
.platform-ios input[type="radio"].blue-grey-600::before {
  border-color: #546e7a;
}
.platform-ios input[type="radio"].blue-grey-700::before {
  border-color: #455a64;
}
.platform-ios input[type="radio"].blue-grey-800::before {
  border-color: #37474f;
}
.platform-ios input[type="radio"].blue-grey-900::before {
  border-color: #263238;
}
.platform-ios input[type="radio"].black::before {
  border-color: #000;
}

.component-tab {
  display: block;
}
.tab {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
.tab-content {
  display: none;
}
.tab-content.active {
  display: block;
}
.tab button {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  font-size: 13px !important;
  text-transform: uppercase;
  opacity: 0.6;
  margin: 0;
}
.footer.tab button.icon {
  margin-top: 0;
}
.header.tab.shadow {
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
}
.platform-ios .tab button {
  text-transform: none;
}
.tab button.active {
  opacity: 1;
}
.tab button.icon {
  font-size: 14px;
  line-height: 16px;
}
.tab button::before {
  display: block;
  font-size: 25px;
}
.tab button.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #fff;
}
.tab button .badge {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  border-radius: 50%;
  margin-top: 10px;
  position: absolute;
  line-height: 18px;
  padding-top: 1px;
}
.tab.footer button.active::after {
  top: 0;
}
.header.tab.footer.shadow {
  box-shadow: -2px -2px 3px rgba(0, 0, 0, 0.2);
}
.platform-ios .tab button.active::after {
  display: none;
}
.component-swiper {
  display: block;
}
.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.swiper-container-fullscreen {
  height: 100%;
}
.swipper-gallery {
  height: 300px;
}
.swiper-container-no-flexbox .swiper-slide {
  float: left;
}
.swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.swiper-wrapper {
  position: relative;
  width: 100%;
  z-index: 1;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.swiper-container-android .swiper-slide,
.swiper-wrapper {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate(0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.swiper-container-multirow > .swiper-wrapper {
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto;
}
.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex: 0 0 auto;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
}
.swiper-slide.vertical-align-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  text-align: center;
}
.swiper-slide i {
  font-size: 120px;
}
.swiper-slide h1 {
  font-size: 40px;
  font-weight: 600;
  width: 100%;
}
.swiper-slide h2 {
  font-size: 30px;
  font-weight: 600;
  width: 100%;
}
.swiper-slide .margin-bottom {
  margin-bottom: 25px;
}
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto;
}
.swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-transition-property: -webkit-transform, height;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform, height;
}
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}
.swiper-wp8-horizontal {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}
.swiper-wp8-vertical {
  -ms-touch-action: pan-x;
  touch-action: pan-x;
}
.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  -moz-background-size: 27px 44px;
  -webkit-background-size: 27px 44px;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat;
}
.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  left: 10px;
  right: auto;
}
.swiper-button-prev.swiper-button-black,
.swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  right: 10px;
  left: auto;
}
.swiper-button-next.swiper-button-black,
.swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}
.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10;
}
.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}
.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 10px;
  left: 0;
  width: 100%;
}
.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: 0.2;
}
button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}
.swiper-pagination-white .swiper-pagination-bullet {
  background: #fff;
}
.swiper-pagination-bullet-active {
  opacity: 1;
  background: #007aff;
}
.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #fff;
}
.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000;
}
.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0, -50%, 0);
  -moz-transform: translate3d(0, -50%, 0);
  -o-transform: translate(0, -50%);
  -ms-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}
.swiper-container-vertical
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 5px 0;
  display: block;
}
.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 5px;
}
.swiper-pagination-progress {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
}
.swiper-pagination-progress .swiper-pagination-progressbar {
  background: #007aff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  transform-origin: left top;
}
.swiper-container-rtl
  .swiper-pagination-progress
  .swiper-pagination-progressbar {
  -webkit-transform-origin: right top;
  -moz-transform-origin: right top;
  -ms-transform-origin: right top;
  -o-transform-origin: right top;
  transform-origin: right top;
}
.swiper-container-horizontal > .swiper-pagination-progress {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0;
}
.swiper-container-vertical > .swiper-pagination-progress {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
}
.swiper-pagination-progress.swiper-pagination-white {
  background: rgba(255, 255, 255, 0.5);
}
.swiper-pagination-progress.swiper-pagination-white
  .swiper-pagination-progressbar {
  background: #fff;
}
.swiper-pagination-progress.swiper-pagination-black
  .swiper-pagination-progressbar {
  background: #000;
}
.swiper-container-3d {
  -webkit-perspective: 1200px;
  -moz-perspective: 1200px;
  -o-perspective: 1200px;
  perspective: 1200px;
}
.swiper-container-3d .swiper-cube-shadow,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-wrapper {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
}
.swiper-container-3d .swiper-slide-shadow-left {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(0, 0, 0, 0.5)),
    to(rgba(0, 0, 0, 0))
  );
  background-image: -webkit-linear-gradient(
    right,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  background-image: -moz-linear-gradient(
    right,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  background-image: -o-linear-gradient(
    right,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  background-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
}
.swiper-container-3d .swiper-slide-shadow-right {
  background-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(rgba(0, 0, 0, 0.5)),
    to(rgba(0, 0, 0, 0))
  );
  background-image: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  background-image: -moz-linear-gradient(
    left,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  background-image: -o-linear-gradient(
    left,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
}
.swiper-container-3d .swiper-slide-shadow-top {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0.5)),
    to(rgba(0, 0, 0, 0))
  );
  background-image: -webkit-linear-gradient(
    bottom,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  background-image: -moz-linear-gradient(
    bottom,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  background-image: -o-linear-gradient(
    bottom,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
}
.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(0, 0, 0, 0.5)),
    to(rgba(0, 0, 0, 0))
  );
  background-image: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  background-image: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  background-image: -o-linear-gradient(
    top,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
}
.swiper-container-coverflow .swiper-wrapper,
.swiper-container-flip .swiper-wrapper {
  -ms-perspective: 1200px;
}
.swiper-container-cube,
.swiper-container-flip {
  overflow: visible;
}
.swiper-container-cube .swiper-slide,
.swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
}
.swiper-container-cube .swiper-slide .swiper-slide,
.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none;
}
.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active,
.swiper-container-flip .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}
.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right,
.swiper-container-cube .swiper-slide-shadow-top,
.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-right,
.swiper-container-flip .swiper-slide-shadow-top {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}
.swiper-container-cube .swiper-slide {
  visibility: hidden;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
}
.swiper-container-cube.swiper-container-rtl .swiper-slide {
  -webkit-transform-origin: 100% 0;
  -moz-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0;
}
.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-next + .swiper-slide,
.swiper-container-cube .swiper-slide-prev {
  pointer-events: auto;
  visibility: visible;
}
.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0;
}
.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.swiper-container-fade .swiper-slide {
  pointer-events: none;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
}
.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}
.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}
.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  text-align: center;
}
.swiper-zoom-container > canvas,
.swiper-zoom-container > img,
.swiper-zoom-container > svg {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1);
}
.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%;
}
.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%;
}
.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0;
}
.swiper-scrollbar-cursor-drag {
  cursor: move;
}
.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  -moz-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  -moz-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  animation: swiper-preloader-spin 1s steps(12, end) infinite;
}
.swiper-lazy-preloader:after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  -webkit-background-size: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
}
.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}
@-webkit-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg);
  }
}
.component-mobileuijs {
  display: block;
}
[data] {
  display: none !important;
}
.component-timeline {
  display: block;
}
.timeline {
  font-size: 14px;
  padding: 2px;
}
.timeline .marker {
  position: relative;
}
.timeline .marker::before {
  background: rgba(0, 0, 0, 0.3);
  content: "";
  height: 100%;
  width: 2px;
  margin: 0 auto;
  position: absolute;
  margin-top: 10px;
  z-index: 0;
  left: 18px;
}
.timeline .row:last-child .marker::before {
  display: none;
}
.timeline .icon-circle,
.timeline .icon-circle-big,
.timeline .icon-circle-small {
  margin: 0 auto;
  margin-top: 10px;
  position: absolute;
  margin-left: 7px;
}
.timeline .icon-circle i::before,
.timeline .icon-circle-big i::before,
.timeline .icon-circle-small i::before {
  margin-top: 4px;
}
.timeline .avatar-group {
  -webkit-transform: translateX(10px);
  transform: translateX(10px);
}
.timeline .avatar-photo {
  background: #eaeaea;
  width: 30px;
  height: 30px;
  overflow: hidden;
  text-align: center;
  border: 1px solid #d7d2d2;
}
.timeline .avatar-photo small {
  margin-top: 100px;
}
.timeline .circle-more {
  background: #eaeaea;
  width: 30px;
  height: 30px;
  overflow: hidden;
  text-align: center;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #bdbbbb;
  border: 1px solid #d7d2d2;
  font-size: 12px;
}
.component-alert {
  display: block;
}
.alert-mobileui {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.alert-mobileui .alert {
  font-family: Roboto, Noto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  border-radius: 2px;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.4);
  max-width: 270px;
}
.platform-ios .alert-mobileui .alert {
  font-family: -apple-system, "Helvetica Neue", Helvetica, Arial,
    "Lucida Grande", sans-serif;
  border-radius: 8px;
  background-color: #fff !important;
  box-shadow: none;
}
.alert-mobileui .alert h1 {
  text-align: left;
  font-size: 20px;
  font-weight: 500;
  padding: 22px 22px 0 24px;
}
.platform-ios .alert-mobileui .alert h1 {
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  color: #000 !important;
  padding: 0;
  padding-top: 22px;
}
.alert-mobileui .alert p {
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  padding: 0 24px;
  margin-top: 24px;
  margin-bottom: 24px;
  min-height: 0;
  opacity: 0.8;
}
.platform-ios .alert-mobileui .alert p {
  font-size: 14px;
  text-align: center;
  color: #000 !important;
}
.alert-mobileui .alert .buttons {
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
}
.alert-mobileui .alert .buttons button {
  text-transform: uppercase;
  display: inline-block;
  width: auto;
  min-width: 70px;
  float: right;
  background: 0 0;
  border-top: none;
  font-size: 14px;
  font-weight: 600;
  outline: 0;
  margin-right: 5px;
  text-transform: uppercase;
}
.platform-ios .alert-mobileui .alert .buttons button {
  width: 100%;
  text-align: center;
  text-transform: none;
  font-weight: 400;
  font-size: 16px;
  border-top: 1px solid #ddd;
  padding: 0 8px;
  margin-right: 0;
  color: rgba(24, 103, 194, 0.81) !important;
  background: 0 0 !important;
}
.component-toast {
  display: block;
}
.toast {
  z-index: 999999;
  position: absolute;
  left: 0;
  padding: 20px;
  text-align: center;
  width: 100%;
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
  -webkit-transition: opacity 0.25s ease-in-out;
}
.toast.show {
  opacity: 1;
}
.toast.toast-bottom {
  bottom: 10px;
}
.toast.toast-top {
  top: 10px;
}
.toast.toast-center {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 100%;
  top: 0;
}
.toast div {
  padding: 10px;
  display: inline-block;
}
.toast .full {
  display: block;
  width: 100%;
}
