@charset "UTF-8";
/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
.mobileui .component-base {
  display: block;
}
.mobileui * {
  -webkit-tap-highlight-color: transparent;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
}
.mobileui body {
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: none;
  -webkit-user-select: none;
  background-color: #fff;
  font-size: 14px;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}
.mobileui h1,
.mobileui h2,
.mobileui h3,
.mobileui h4,
.mobileui h5,
.mobileui h6 {
  font-size: 100%;
  font-weight: 400;
}
.mobileui * {
  box-sizing: border-box;
  outline: 0;
}
.mobileui body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
  position: absolute;
}
.mobileui body.platform-ios {
  font-family: -apple-system, "Helvetica Neue", Roboto, sans-serif;
}
.mobileui a {
  cursor: pointer;
  color: #000;
  text-decoration: none;
}
.mobileui .content {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;
}
.mobileui .left {
  float: left;
}
.mobileui .right {
  float: right;
}
.mobileui .top {
  position: absolute;
  top: 0;
  left: 0;
}
.mobileui .bottom {
  position: absolute;
  bottom: 0;
  left: 0;
}
.mobileui .align-left {
  text-align: left;
}
.mobileui .align-center {
  text-align: center;
}
.mobileui .align-right {
  text-align: right;
}
.mobileui .border {
  border-width: 1px;
  border-style: solid;
}
.mobileui .hidden {
  display: none;
}
.mobileui .opacity-90 {
  opacity: 0.9;
}
.mobileui .opacity-80 {
  opacity: 0.8;
}
.mobileui .opacity-70 {
  opacity: 0.7;
}
.mobileui .opacity-60 {
  opacity: 0.6;
}
.mobileui .opacity-50 {
  opacity: 0.5;
}
.mobileui .opacity-40 {
  opacity: 0.4;
}
.mobileui .opacity-30 {
  opacity: 0.3;
}
.mobileui .opacity-20 {
  opacity: 0.2;
}
.mobileui .opacity-10 {
  opacity: 0.1;
}
.mobileui .line {
  width: 100%;
  height: 1px;
  display: block;
  clear: both;
}
.mobileui .icon {
  font-size: 28px;
}
.mobileui img,
.mobileui video {
  max-width: 100%;
}
.mobileui p {
  margin-top: 5px;
  margin-bottom: 5px;
}
.mobileui .padding {
  padding: 10px;
}
.mobileui .padding-top {
  padding-top: 10px;
}
.mobileui .margin {
  margin: 10px;
}
.mobileui span.padding {
  padding: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
}
.mobileui .radius {
  border-radius: 4px;
}
.mobileui .radius-big {
  border-radius: 22px;
}
.mobileui .circle {
  border-radius: 50%;
}
.mobileui .radius-left {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.mobileui .radius-right {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.mobileui .radius-top {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.mobileui .ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mobileui .shadow {
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
}
.mobileui .space {
  width: 100%;
  height: 20px;
}
.mobileui .space-big {
  width: 100%;
  height: 50px;
}
.mobileui .space-huge {
  width: 100%;
  height: 100px;
}
.mobileui .margin-bottom {
  margin-bottom: 10px;
}
.mobileui .margin-top {
  margin-top: 10px;
}
.mobileui .text-shadow {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
}
.mobileui .text-small {
  font-size: 14px;
}
.mobileui .text-small .icon,
.mobileui .text-small.icon {
  font-size: 14px !important;
}
.mobileui .icon-small {
  font-size: 14px !important;
}
.mobileui .icon-big {
  font-size: 50px !important;
}
.mobileui .icon-huge {
  font-size: 65px !important;
}
.mobileui .icon-extra-huge {
  font-size: 120px !important;
}
.mobileui .text-big {
  font-size: 22px;
}
.mobileui .text-big .icon,
.mobileui .text-big.icon {
  font-size: 22px !important;
}
.mobileui .text-extra-huge {
  font-size: 65px;
}
.mobileui .text-extra-huge .icon,
.mobileui .text-extra-huge.icon {
  font-size: 65px !important;
}
.mobileui .text-huge {
  font-size: 32px;
}
.mobileui .text-huge .icon,
.mobileui .text-huge.icon {
  font-size: 32px !important;
}
.mobileui .text-light {
  font-weight: 300;
}
.mobileui .text-bold {
  font-weight: 700;
}
.mobileui .text-strong {
  font-weight: 800;
}
.mobileui .float-bottom-right {
  position: fixed;
  right: 15px;
  bottom: 15px;
}
.mobileui .float-bottom-left {
  position: fixed;
  left: 15px;
  bottom: 15px;
}
.mobileui .float-bottom-center {
  position: fixed;
  margin: auto;
  left: 0;
  bottom: 15px;
}
.mobileui .icon-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobileui .icon-circle-small {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobileui .icon-circle-small i {
  font-size: 15px;
}
.mobileui .icon-circle-big {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobileui .icon-circle-big i {
  font-size: 70px;
}
.mobileui .backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.01;
  z-index: 9999;
  transition-duration: 280ms;
}
.mobileui .backdrop.show {
  opacity: 0.4;
}
.mobileui .mark {
  border-left-width: 8px !important;
}
.mobileui .red {
  color: rgba(255, 255, 255, 0.9);
  background-color: #f44336 !important;
}
.mobileui .red-100,
.mobileui .red-200,
.mobileui .red-300,
.mobileui .red-50 {
  color: rgba(0, 0, 0, 0.8);
}
.mobileui .red-50,
.mobileui input[type=checkbox].red-50.switch::after {
  background-color: #ffebee !important;
}
.mobileui .red-100,
.mobileui input[type=checkbox].red-100.switch::after {
  background-color: #ffcdd2 !important;
}
.mobileui .red-200,
.mobileui input[type=checkbox].red-200.switch::after {
  background-color: #ef9a9a !important;
}
.mobileui .red-300,
.mobileui input[type=checkbox].red-300.switch::after {
  background-color: #e57373 !important;
}
.mobileui .pink,
.mobileui .red-400,
.mobileui .red-500,
.mobileui .red-600,
.mobileui .red-700,
.mobileui .red-800,
.mobileui .red-900 {
  color: rgba(255, 255, 255, 0.9);
}
.mobileui .red-400,
.mobileui input[type=checkbox].red-400.switch::after {
  background-color: #ef5350 !important;
}
.mobileui .red-500,
.mobileui input[type=checkbox].red-500.switch::after {
  background-color: #f44336 !important;
}
.mobileui .red-600,
.mobileui input[type=checkbox].red-600.switch::after {
  background-color: #e53935 !important;
}
.mobileui .red-700,
.mobileui input[type=checkbox].red-700.switch::after {
  background-color: #d32f2f !important;
}
.mobileui .red-800,
.mobileui input[type=checkbox].red-800.switch::after {
  background-color: #c62828 !important;
}
.mobileui .red-900,
.mobileui input[type=checkbox].red-900.switch::after {
  background-color: #b71c1c !important;
}
.mobileui .pink,
.mobileui input[type=checkbox].pink.switch::after {
  background-color: #e91e63 !important;
}
.mobileui .pink-100,
.mobileui .pink-200,
.mobileui .pink-50 {
  color: rgba(0, 0, 0, 0.8);
}
.mobileui .pink-50,
.mobileui input[type=checkbox].pink-50.switch::after {
  background-color: #fce4ec !important;
}
.mobileui .pink-100,
.mobileui input[type=checkbox].pink-100.switch::after {
  background-color: #f8bbd0 !important;
}
.mobileui .pink-200,
.mobileui input[type=checkbox].pink-200.switch::after {
  background-color: #f48fb1 !important;
}
.mobileui .Pink-300,
.mobileui .pink-400,
.mobileui .pink-500,
.mobileui .pink-600,
.mobileui .pink-700,
.mobileui .pink-800,
.mobileui .pink-900,
.mobileui .purple {
  color: rgba(255, 255, 255, 0.9);
}
.mobileui .Pink-300,
.mobileui input[type=checkbox].Pink-300.switch::after {
  background-color: #f06292 !important;
}
.mobileui .pink-400,
.mobileui input[type=checkbox].pink-400.switch::after {
  background-color: #ec407a !important;
}
.mobileui .pink-500,
.mobileui input[type=checkbox].pink-500.switch::after {
  background-color: #e91e63 !important;
}
.mobileui .pink-600,
.mobileui input[type=checkbox].pink-600.switch::after {
  background-color: #d81b60 !important;
}
.mobileui .pink-700,
.mobileui input[type=checkbox].pink-700.switch::after {
  background-color: #c2185b !important;
}
.mobileui .pink-800,
.mobileui input[type=checkbox].pink-800.switch::after {
  background-color: #ad1457 !important;
}
.mobileui .pink-900,
.mobileui input[type=checkbox].pink-900.switch::after {
  background-color: #880e4f !important;
}
.mobileui .purple,
.mobileui input[type=checkbox].purple.switch::after {
  background-color: #9c27b0 !important;
}
.mobileui .purple-100,
.mobileui .purple-200,
.mobileui .purple-50 {
  color: rgba(0, 0, 0, 0.8);
}
.mobileui .purple-50,
.mobileui input[type=checkbox].purple-50.switch::after {
  background-color: #f3e5f5 !important;
}
.mobileui .purple-100,
.mobileui input[type=checkbox].purple-100.switch::after {
  background-color: #e1bee7 !important;
}
.mobileui .purple-200,
.mobileui input[type=checkbox].purple-200.switch::after {
  background-color: #ce93d8 !important;
}
.mobileui .deep-purple,
.mobileui .purple-300,
.mobileui .purple-400,
.mobileui .purple-500,
.mobileui .purple-600,
.mobileui .purple-700,
.mobileui .purple-800,
.mobileui .purple-900 {
  color: rgba(255, 255, 255, 0.9);
}
.mobileui .purple-300,
.mobileui input[type=checkbox].purple-300.switch::after {
  background-color: #ba68c8 !important;
}
.mobileui .purple-400,
.mobileui input[type=checkbox].purple-400.switch::after {
  background-color: #ab47bc !important;
}
.mobileui .purple-500,
.mobileui input[type=checkbox].purple-500.switch::after {
  background-color: #9c27b0 !important;
}
.mobileui .purple-600,
.mobileui input[type=checkbox].purple-600.switch::after {
  background-color: #8e24aa !important;
}
.mobileui .purple-700,
.mobileui input[type=checkbox].purple-700.switch::after {
  background-color: #7b1fa2 !important;
}
.mobileui .purple-800,
.mobileui input[type=checkbox].purple-800.switch::after {
  background-color: #6a1b9a !important;
}
.mobileui .purple-900,
.mobileui input[type=checkbox].purple-900.switch::after {
  background-color: #4a148c !important;
}
.mobileui .deep-purple,
.mobileui input[type=checkbox].deep-purple.switch::after {
  background-color: #673ab7 !important;
}
.mobileui .deep-purple-50 {
  color: rgba(0, 0, 0, 0.8);
  background-color: #ede7f6 !important;
}
.mobileui .deep-purple-100 {
  color: rgba(0, 0, 0, 0.8);
  background-color: #d1c4e9 !important;
}
.mobileui .deep-purple-200 {
  color: rgba(0, 0, 0, 0.8);
  background-color: #b39ddb !important;
}
.mobileui .deep-purple-300,
.mobileui .deep-purple-400,
.mobileui .deep-purple-500,
.mobileui .deep-purple-600,
.mobileui .deep-purple-700,
.mobileui .deep-purple-800,
.mobileui .deep-purple-900,
.mobileui .indigo {
  color: rgba(255, 255, 255, 0.9);
}
.mobileui .deep-purple-300,
.mobileui input[type=checkbox].deep-purple-300.switch::after {
  background-color: #9575cd !important;
}
.mobileui .deep-purple-400,
.mobileui input[type=checkbox].deep-purple-400.switch::after {
  background-color: #7e57c2 !important;
}
.mobileui .deep-purple-500,
.mobileui input[type=checkbox].deep-purple-500.switch::after {
  background-color: #673ab7 !important;
}
.mobileui .deep-purple-600,
.mobileui input[type=checkbox].deep-purple-600.switch::after {
  background-color: #5e35b1 !important;
}
.mobileui .deep-purple-700,
.mobileui input[type=checkbox].deep-purple-700.switch::after {
  background-color: #512da8 !important;
}
.mobileui .deep-purple-800,
.mobileui input[type=checkbox].deep-purple-800.switch::after {
  background-color: #4527a0 !important;
}
.mobileui .deep-purple-900,
.mobileui input[type=checkbox].deep-purple-900.switch::after {
  background-color: #311b92 !important;
}
.mobileui .indigo,
.mobileui input[type=checkbox].indigo.switch::after {
  background-color: #3f51b5 !important;
}
.mobileui .indigo-100,
.mobileui .indigo-200,
.mobileui .indigo-50 {
  color: rgba(0, 0, 0, 0.8);
}
.mobileui .indigo-50,
.mobileui input[type=checkbox].indigo-50.switch::after {
  background-color: #e8eaf6 !important;
}
.mobileui .indigo-100,
.mobileui input[type=checkbox].indigo-100.switch::after {
  background-color: #c5cae9 !important;
}
.mobileui .indigo-200,
.mobileui input[type=checkbox].indigo-200.switch::after {
  background-color: #9fa8da !important;
}
.mobileui .blue,
.mobileui .indigo-300,
.mobileui .indigo-400,
.mobileui .indigo-500,
.mobileui .indigo-600,
.mobileui .indigo-700,
.mobileui .indigo-800,
.mobileui .indigo-900 {
  color: rgba(255, 255, 255, 0.9);
}
.mobileui .indigo-300,
.mobileui input[type=checkbox].indigo-300.switch::after {
  background-color: #7986cb !important;
}
.mobileui .indigo-400,
.mobileui input[type=checkbox].indigo-400.switch::after {
  background-color: #5c6bc0 !important;
}
.mobileui .indigo-500,
.mobileui input[type=checkbox].indigo-500.switch::after {
  background-color: #3f51b5 !important;
}
.mobileui .indigo-600,
.mobileui input[type=checkbox].indigo-600.switch::after {
  background-color: #3949ab !important;
}
.mobileui .indigo-700,
.mobileui input[type=checkbox].indigo-700.switch::after {
  background-color: #303f9f !important;
}
.mobileui .indigo-800,
.mobileui input[type=checkbox].indigo-800.switch::after {
  background-color: #283593 !important;
}
.mobileui .indigo-900,
.mobileui input[type=checkbox].indigo-900.switch::after {
  background-color: #1a237e !important;
}
.mobileui .blue,
.mobileui input[type=checkbox].blue.switch::after {
  background-color: #2196f3 !important;
}
.mobileui .blue-100,
.mobileui .blue-200,
.mobileui .blue-300,
.mobileui .blue-400,
.mobileui .blue-50 {
  color: rgba(0, 0, 0, 0.8);
}
.mobileui .blue-50,
.mobileui input[type=checkbox].blue-50.switch::after {
  background-color: #e3f2fd !important;
}
.mobileui .blue-100,
.mobileui input[type=checkbox].blue-100.switch::after {
  background-color: #bbdefb !important;
}
.mobileui .blue-200,
.mobileui input[type=checkbox].blue-200.switch::after {
  background-color: #90caf9 !important;
}
.mobileui .blue-300,
.mobileui input[type=checkbox].blue-300.switch::after {
  background-color: #64b5f6 !important;
}
.mobileui .blue-400,
.mobileui input[type=checkbox].blue-400.switch::after {
  background-color: #42a5f5 !important;
}
.mobileui .blue-500,
.mobileui .blue-600,
.mobileui .blue-700,
.mobileui .blue-800,
.mobileui .blue-900,
.mobileui .light-blue {
  color: rgba(255, 255, 255, 0.9);
}
.mobileui .blue-500,
.mobileui input[type=checkbox].blue-500.switch::after {
  background-color: #2196f3 !important;
}
.mobileui .blue-600,
.mobileui input[type=checkbox].blue-600.switch::after {
  background-color: #1e88e5 !important;
}
.mobileui .blue-700,
.mobileui input[type=checkbox].blue-700.switch::after {
  background-color: #1976d2 !important;
}
.mobileui .blue-800,
.mobileui input[type=checkbox].blue-800.switch::after {
  background-color: #1565c0 !important;
}
.mobileui .blue-900,
.mobileui input[type=checkbox].blue-900.switch::after {
  background-color: #0d47a1 !important;
}
.mobileui .light-blue,
.mobileui input[type=checkbox].light-blue.switch::after {
  background-color: #03a9f4 !important;
}
.mobileui .light-blue-100,
.mobileui .light-blue-200,
.mobileui .light-blue-300,
.mobileui .light-blue-400,
.mobileui .light-blue-50,
.mobileui .light-blue-500 {
  color: rgba(0, 0, 0, 0.8);
}
.mobileui .light-blue-50,
.mobileui input[type=checkbox].light-blue-50.switch::after {
  background-color: #e1f5fe !important;
}
.mobileui .light-blue-100,
.mobileui input[type=checkbox].light-blue-100.switch::after {
  background-color: #b3e5fc !important;
}
.mobileui .light-blue-200,
.mobileui input[type=checkbox].light-blue-200.switch::after {
  background-color: #81d4fa !important;
}
.mobileui .light-blue-300,
.mobileui input[type=checkbox].light-blue-300.switch::after {
  background-color: #4fc3f7 !important;
}
.mobileui .light-blue-400,
.mobileui input[type=checkbox].light-blue-400.switch::after {
  background-color: #29b6f6 !important;
}
.mobileui .light-blue-500,
.mobileui input[type=checkbox].light-blue-500.switch::after {
  background-color: #03a9f4 !important;
}
.mobileui .cyan,
.mobileui .light-blue-600,
.mobileui .light-blue-700,
.mobileui .light-blue-800,
.mobileui .light-blue-900 {
  color: rgba(255, 255, 255, 0.9);
}
.mobileui .light-blue-600,
.mobileui input[type=checkbox].light-blue-600.switch::after {
  background-color: #039be5 !important;
}
.mobileui .light-blue-700,
.mobileui input[type=checkbox].light-blue-700.switch::after {
  background-color: #0288d1 !important;
}
.mobileui .light-blue-800,
.mobileui input[type=checkbox].light-blue-800.switch::after {
  background-color: #0277bd !important;
}
.mobileui .light-blue-900,
.mobileui input[type=checkbox].light-blue-900.switch::after {
  background-color: #01579b !important;
}
.mobileui .cyan,
.mobileui input[type=checkbox].cyan.switch::after {
  background-color: #00bcd4 !important;
}
.mobileui .cyan-100,
.mobileui .cyan-200,
.mobileui .cyan-300,
.mobileui .cyan-400,
.mobileui .cyan-50,
.mobileui .cyan-500,
.mobileui .cyan-600 {
  color: rgba(0, 0, 0, 0.8);
}
.mobileui .cyan-50,
.mobileui input[type=checkbox].cyan-50.switch::after {
  background-color: #e0f7fa !important;
}
.mobileui .cyan-100,
.mobileui input[type=checkbox].cyan-100.switch::after {
  background-color: #b2ebf2 !important;
}
.mobileui .cyan-200,
.mobileui input[type=checkbox].cyan-200.switch::after {
  background-color: #80deea !important;
}
.mobileui .cyan-300,
.mobileui input[type=checkbox].cyan-300.switch::after {
  background-color: #4dd0e1 !important;
}
.mobileui .cyan-400,
.mobileui input[type=checkbox].cyan-400.switch::after {
  background-color: #26c6da !important;
}
.mobileui .cyan-500,
.mobileui input[type=checkbox].cyan-500.switch::after {
  background-color: #00bcd4 !important;
}
.mobileui .cyan-600,
.mobileui input[type=checkbox].cyan-600.switch::after {
  background-color: #00acc1 !important;
}
.mobileui .cyan-700,
.mobileui .cyan-800,
.mobileui .cyan-900,
.mobileui .teal {
  color: rgba(255, 255, 255, 0.9);
}
.mobileui .cyan-700,
.mobileui input[type=checkbox].cyan-700.switch::after {
  background-color: #0097a7 !important;
}
.mobileui .cyan-800,
.mobileui input[type=checkbox].cyan-800.switch::after {
  background-color: #00838f !important;
}
.mobileui .cyan-900,
.mobileui input[type=checkbox].cyan-900.switch::after {
  background-color: #006064 !important;
}
.mobileui .teal,
.mobileui input[type=checkbox].teal.switch::after {
  background-color: #009688 !important;
}
.mobileui .teal-100,
.mobileui .teal-200,
.mobileui .teal-300,
.mobileui .teal-400,
.mobileui .teal-50 {
  color: rgba(0, 0, 0, 0.8);
}
.mobileui .teal-50,
.mobileui input[type=checkbox].teal-50.switch::after {
  background-color: #e0f2f1 !important;
}
.mobileui .teal-100,
.mobileui input[type=checkbox].teal-100.switch::after {
  background-color: #b2dfdb !important;
}
.mobileui .teal-200,
.mobileui input[type=checkbox].teal-200.switch::after {
  background-color: #80cbc4 !important;
}
.mobileui .teal-300,
.mobileui input[type=checkbox].teal-300.switch::after {
  background-color: #4db6ac !important;
}
.mobileui .teal-400,
.mobileui input[type=checkbox].teal-400.switch::after {
  background-color: #26a69a !important;
}
.mobileui .green,
.mobileui .teal-500,
.mobileui .teal-600,
.mobileui .teal-700,
.mobileui .teal-800,
.mobileui .teal-900 {
  color: rgba(255, 255, 255, 0.9);
}
.mobileui .teal-500,
.mobileui input[type=checkbox].teal-500.switch::after {
  background-color: #009688 !important;
}
.mobileui .teal-600,
.mobileui input[type=checkbox].teal-600.switch::after {
  background-color: #00897b !important;
}
.mobileui .teal-700,
.mobileui input[type=checkbox].teal-700.switch::after {
  background-color: #00796b !important;
}
.mobileui .teal-800,
.mobileui input[type=checkbox].teal-800.switch::after {
  background-color: #00695c !important;
}
.mobileui .teal-900,
.mobileui input[type=checkbox].teal-900.switch::after {
  background-color: #004d40 !important;
}
.mobileui .green,
.mobileui input[type=checkbox].green.switch::after {
  background-color: #4caf50 !important;
}
.mobileui .green-100,
.mobileui .green-200,
.mobileui .green-300,
.mobileui .green-400,
.mobileui .green-50,
.mobileui .green-500 {
  color: rgba(0, 0, 0, 0.8);
}
.mobileui .green-50,
.mobileui input[type=checkbox].green-50.switch::after {
  background-color: #e8f5e9 !important;
}
.mobileui .green-100,
.mobileui input[type=checkbox].green-100.switch::after {
  background-color: #c8e6c9 !important;
}
.mobileui .green-200,
.mobileui input[type=checkbox].green-200.switch::after {
  background-color: #a5d6a7 !important;
}
.mobileui .green-300,
.mobileui input[type=checkbox].green-300.switch::after {
  background-color: #81c784 !important;
}
.mobileui .green-400,
.mobileui input[type=checkbox].green-400.switch::after {
  background-color: #66bb6a !important;
}
.mobileui .green-500,
.mobileui input[type=checkbox].green-500.switch::after {
  background-color: #4caf50 !important;
}
.mobileui .green-600,
.mobileui .green-700,
.mobileui .green-800,
.mobileui .green-900,
.mobileui .light-green {
  color: rgba(255, 255, 255, 0.9);
}
.mobileui .green-600,
.mobileui input[type=checkbox].green-600.switch::after {
  background-color: #43a047 !important;
}
.mobileui .green-700,
.mobileui input[type=checkbox].green-700.switch::after {
  background-color: #388e3c !important;
}
.mobileui .green-800,
.mobileui input[type=checkbox].green-800.switch::after {
  background-color: #2e7d32 !important;
}
.mobileui .green-900,
.mobileui input[type=checkbox].green-900.switch::after {
  background-color: #1b5e20 !important;
}
.mobileui .light-green,
.mobileui input[type=checkbox].light-green.switch::after {
  background-color: #8bc34a !important;
}
.mobileui .light-green-100,
.mobileui .light-green-200,
.mobileui .light-green-300,
.mobileui .light-green-400,
.mobileui .light-green-50,
.mobileui .light-green-500,
.mobileui .light-green-600 {
  color: rgba(0, 0, 0, 0.8);
}
.mobileui .light-green-50,
.mobileui input[type=checkbox].light-green-50.switch::after {
  background-color: #f1f8e9 !important;
}
.mobileui .light-green-100,
.mobileui input[type=checkbox].light-green-100.switch::after {
  background-color: #dcedc8 !important;
}
.mobileui .light-green-200,
.mobileui input[type=checkbox].light-green-200.switch::after {
  background-color: #c5e1a5 !important;
}
.mobileui .light-green-300,
.mobileui input[type=checkbox].light-green-300.switch::after {
  background-color: #aed581 !important;
}
.mobileui .light-green-400,
.mobileui input[type=checkbox].light-green-400.switch::after {
  background-color: #9ccc65 !important;
}
.mobileui .light-green-500,
.mobileui input[type=checkbox].light-green-500.switch::after {
  background-color: #8bc34a !important;
}
.mobileui .light-green-600,
.mobileui input[type=checkbox].light-green-600.switch::after {
  background-color: #7cb342 !important;
}
.mobileui .light-green-700,
.mobileui .light-green-800,
.mobileui .light-green-900,
.mobileui .lime {
  color: rgba(255, 255, 255, 0.9);
}
.mobileui .light-green-700,
.mobileui input[type=checkbox].light-green-700.switch::after {
  background-color: #689f38 !important;
}
.mobileui .light-green-800,
.mobileui input[type=checkbox].light-green-800.switch::after {
  background-color: #558b2f !important;
}
.mobileui .light-green-900,
.mobileui input[type=checkbox].light-green-900.switch::after {
  background-color: #33691e !important;
}
.mobileui .lime,
.mobileui input[type=checkbox].lime.switch::after {
  background-color: #cddc39 !important;
}
.mobileui .lime-100,
.mobileui .lime-200,
.mobileui .lime-300,
.mobileui .lime-400,
.mobileui .lime-50,
.mobileui .lime-500,
.mobileui .lime-600,
.mobileui .lime-700,
.mobileui .lime-800 {
  color: rgba(0, 0, 0, 0.8);
}
.mobileui .lime-50,
.mobileui input[type=checkbox].lime-50.switch::after {
  background-color: #f9fbe7 !important;
}
.mobileui .lime-100,
.mobileui input[type=checkbox].lime-100.switch::after {
  background-color: #f0f4c3 !important;
}
.mobileui .lime-200,
.mobileui input[type=checkbox].lime-200.switch::after {
  background-color: #e6ee9c !important;
}
.mobileui .lime-300,
.mobileui input[type=checkbox].lime-300.switch::after {
  background-color: #dce775 !important;
}
.mobileui .lime-400,
.mobileui input[type=checkbox].lime-400.switch::after {
  background-color: #d4e157 !important;
}
.mobileui .lime-500,
.mobileui input[type=checkbox].lime-500.switch::after {
  background-color: #cddc39 !important;
}
.mobileui .lime-600,
.mobileui input[type=checkbox].lime-600.switch::after {
  background-color: #c0ca33 !important;
}
.mobileui .lime-700,
.mobileui input[type=checkbox].lime-700.switch::after {
  background-color: #afb42b !important;
}
.mobileui .lime-800,
.mobileui input[type=checkbox].lime-800.switch::after {
  background-color: #9e9d24 !important;
}
.mobileui .lime-900,
.mobileui .yellow {
  color: rgba(255, 255, 255, 0.9);
}
.mobileui .lime-900,
.mobileui input[type=checkbox].lime-900.switch::after {
  background-color: #827717 !important;
}
.mobileui .yellow,
.mobileui input[type=checkbox].yellow.switch::after {
  background-color: #ffeb3b !important;
}
.mobileui .yellow-100,
.mobileui .yellow-200,
.mobileui .yellow-300,
.mobileui .yellow-50,
.mobileui .yellow-500,
.mobileui .yellow-600,
.mobileui .yellow-700,
.mobileui .yellow-800,
.mobileui .yellow-900 {
  color: rgba(0, 0, 0, 0.8);
}
.mobileui .yellow-50,
.mobileui input[type=checkbox].yellow-50.switch::after {
  background-color: #fffde7 !important;
}
.mobileui .yellow-100,
.mobileui input[type=checkbox].yellow-100.switch::after {
  background-color: #fff9c4 !important;
}
.mobileui .yellow-200,
.mobileui input[type=checkbox].yellow-200.switch::after {
  background-color: #fff59d !important;
}
.mobileui .yellow-300,
.mobileui input[type=checkbox].yellow-300.switch::after {
  background-color: #fff176 !important;
}
.mobileui .yellow-400 {
  color: rgba(0, 0, 0, 0.8);
  background-color: #ffee58 !important;
}
.mobileui .yellow-500,
.mobileui input[type=checkbox].yellow-500.switch::after {
  background-color: #ffeb3b !important;
}
.mobileui .yellow-600,
.mobileui input[type=checkbox].yellow-600.switch::after {
  background-color: #fdd835 !important;
}
.mobileui .yellow-700,
.mobileui input[type=checkbox].yellow-700.switch::after {
  background-color: #fbc02d !important;
}
.mobileui .yellow-800,
.mobileui input[type=checkbox].yellow-800.switch::after {
  background-color: #f9a825 !important;
}
.mobileui .yellow-900,
.mobileui input[type=checkbox].yellow-900.switch::after {
  background-color: #f57f17 !important;
}
.mobileui .amber {
  color: rgba(255, 255, 255, 0.9);
  background-color: #ffc107 !important;
}
.mobileui .amber-100,
.mobileui .amber-200,
.mobileui .amber-300,
.mobileui .amber-50,
.mobileui .amber-500,
.mobileui .amber-600,
.mobileui .amber-700,
.mobileui .amber-800,
.mobileui .amber-900 {
  color: rgba(0, 0, 0, 0.8);
}
.mobileui .amber-50,
.mobileui input[type=checkbox].amber-50.switch::after {
  background-color: #fff8e1 !important;
}
.mobileui .amber-100,
.mobileui input[type=checkbox].amber-100.switch::after {
  background-color: #ffecb3 !important;
}
.mobileui .amber-200,
.mobileui input[type=checkbox].amber-200.switch::after {
  background-color: #ffe082 !important;
}
.mobileui .amber-300,
.mobileui input[type=checkbox].amber-300.switch::after {
  background-color: #ffd54f !important;
}
.mobileui .amber-400 {
  color: rgba(0, 0, 0, 0.8);
  background-color: #ffca28 !important;
}
.mobileui .amber-500,
.mobileui input[type=checkbox].amber-500.switch::after {
  background-color: #ffc107 !important;
}
.mobileui .amber-600,
.mobileui input[type=checkbox].amber-600.switch::after {
  background-color: #ffb300 !important;
}
.mobileui .amber-700,
.mobileui input[type=checkbox].amber-700.switch::after {
  background-color: #ffa000 !important;
}
.mobileui .amber-800,
.mobileui input[type=checkbox].amber-800.switch::after {
  background-color: #ff8f00 !important;
}
.mobileui .amber-900,
.mobileui input[type=checkbox].amber-900.switch::after {
  background-color: #ff6f00 !important;
}
.mobileui .orange {
  color: rgba(255, 255, 255, 0.9);
  background-color: #ff9800 !important;
}
.mobileui .orange-100,
.mobileui .orange-200,
.mobileui .orange-300,
.mobileui .orange-400,
.mobileui .orange-50,
.mobileui .orange-500,
.mobileui .orange-600,
.mobileui .orange-700 {
  color: rgba(0, 0, 0, 0.8);
}
.mobileui .orange-50,
.mobileui input[type=checkbox].orange-50.switch::after {
  background-color: #fff3e0 !important;
}
.mobileui .orange-100,
.mobileui input[type=checkbox].orange-100.switch::after {
  background-color: #ffe0b2 !important;
}
.mobileui .orange-200,
.mobileui input[type=checkbox].orange-200.switch::after {
  background-color: #ffcc80 !important;
}
.mobileui .orange-300,
.mobileui input[type=checkbox].orange-300.switch::after {
  background-color: #ffb74d !important;
}
.mobileui .orange-400,
.mobileui input[type=checkbox].orange-400.switch::after {
  background-color: #ffa726 !important;
}
.mobileui .orange-500,
.mobileui input[type=checkbox].orange-500.switch::after {
  background-color: #ff9800 !important;
}
.mobileui .orange-600,
.mobileui input[type=checkbox].orange-600.switch::after {
  background-color: #fb8c00 !important;
}
.mobileui .orange-700,
.mobileui input[type=checkbox].orange-700.switch::after {
  background-color: #f57c00 !important;
}
.mobileui .deep-orange,
.mobileui .orange-800,
.mobileui .orange-900 {
  color: rgba(255, 255, 255, 0.9);
}
.mobileui .orange-800,
.mobileui input[type=checkbox].orange-800.switch::after {
  background-color: #ef6c00 !important;
}
.mobileui .orange-900,
.mobileui input[type=checkbox].orange-900.switch::after {
  background-color: #e65100 !important;
}
.mobileui .deep-orange,
.mobileui input[type=checkbox].deep-orange.switch::after {
  background-color: #ff5722 !important;
}
.mobileui .deep-orange-100,
.mobileui .deep-orange-200,
.mobileui .deep-orange-300,
.mobileui .deep-orange-400,
.mobileui .deep-orange-50 {
  color: rgba(0, 0, 0, 0.8);
}
.mobileui .deep-orange-50,
.mobileui input[type=checkbox].deep-orange-50.switch::after {
  background-color: #fbe9e7 !important;
}
.mobileui .deep-orange-100,
.mobileui input[type=checkbox].deep-orange-100.switch::after {
  background-color: #ffccbc !important;
}
.mobileui .deep-orange-200,
.mobileui input[type=checkbox].deep-orange-200.switch::after {
  background-color: #ffab91 !important;
}
.mobileui .deep-orange-300,
.mobileui input[type=checkbox].deep-orange-300.switch::after {
  background-color: #ff8a65 !important;
}
.mobileui .deep-orange-400,
.mobileui input[type=checkbox].deep-orange-400.switch::after {
  background-color: #ff7043 !important;
}
.mobileui .brown,
.mobileui .deep-orange-500,
.mobileui .deep-orange-600,
.mobileui .deep-orange-700,
.mobileui .deep-orange-800,
.mobileui .deep-orange-900 {
  color: rgba(255, 255, 255, 0.9);
}
.mobileui .deep-orange-500,
.mobileui input[type=checkbox].deep-orange-500.switch::after {
  background-color: #ff5722 !important;
}
.mobileui .deep-orange-600,
.mobileui input[type=checkbox].deep-orange-600.switch::after {
  background-color: #f4511e !important;
}
.mobileui .deep-orange-700,
.mobileui input[type=checkbox].deep-orange-700.switch::after {
  background-color: #e64a19 !important;
}
.mobileui .deep-orange-800,
.mobileui input[type=checkbox].deep-orange-800.switch::after {
  background-color: #d84315 !important;
}
.mobileui .deep-orange-900,
.mobileui input[type=checkbox].deep-orange-900.switch::after {
  background-color: #bf360c !important;
}
.mobileui .brown,
.mobileui input[type=checkbox].brown.switch::after {
  background-color: #795548 !important;
}
.mobileui .brown-100,
.mobileui .brown-200,
.mobileui .brown-50 {
  color: rgba(0, 0, 0, 0.8);
}
.mobileui .brown-50,
.mobileui input[type=checkbox].brown-50.switch::after {
  background-color: #efebe9 !important;
}
.mobileui .brown-100,
.mobileui input[type=checkbox].brown-100.switch::after {
  background-color: #d7ccc8 !important;
}
.mobileui .brown-200,
.mobileui input[type=checkbox].brown-200.switch::after {
  background-color: #bcaaa4 !important;
}
.mobileui .brown-300,
.mobileui .brown-400,
.mobileui .brown-500,
.mobileui .brown-600,
.mobileui .brown-700,
.mobileui .brown-800,
.mobileui .brown-900,
.mobileui .grey {
  color: rgba(255, 255, 255, 0.9);
}
.mobileui .brown-300,
.mobileui input[type=checkbox].brown-300.switch::after {
  background-color: #a1887f !important;
}
.mobileui .brown-400,
.mobileui input[type=checkbox].brown-400.switch::after {
  background-color: #8d6e63 !important;
}
.mobileui .brown-500,
.mobileui input[type=checkbox].brown-500.switch::after {
  background-color: #795548 !important;
}
.mobileui .brown-600,
.mobileui input[type=checkbox].brown-600.switch::after {
  background-color: #6d4c41 !important;
}
.mobileui .brown-700,
.mobileui input[type=checkbox].brown-700.switch::after {
  background-color: #5d4037 !important;
}
.mobileui .brown-800,
.mobileui input[type=checkbox].brown-800.switch::after {
  background-color: #4e342e !important;
}
.mobileui .brown-900,
.mobileui input[type=checkbox].brown-900.switch::after {
  background-color: #3e2723 !important;
}
.mobileui .grey,
.mobileui input[type=checkbox].grey.switch::after {
  background-color: #9e9e9e !important;
}
.mobileui .grey-100,
.mobileui .grey-200,
.mobileui .grey-300,
.mobileui .grey-400,
.mobileui .grey-50,
.mobileui .grey-500 {
  color: rgba(0, 0, 0, 0.8);
}
.mobileui .grey-50,
.mobileui input[type=checkbox].grey-50.switch::after {
  background-color: #fafafa !important;
}
.mobileui .grey-100,
.mobileui input[type=checkbox].grey-100.switch::after {
  background-color: #f5f5f5 !important;
}
.mobileui .grey-200,
.mobileui input[type=checkbox].grey-200.switch::after {
  background-color: #eee !important;
}
.mobileui .grey-300,
.mobileui input[type=checkbox].grey-300.switch::after {
  background-color: #e0e0e0 !important;
}
.mobileui .grey-400,
.mobileui input[type=checkbox].grey-400.switch::after {
  background-color: #bdbdbd !important;
}
.mobileui .grey-500,
.mobileui input[type=checkbox].grey-500.switch::after {
  background-color: #9e9e9e !important;
}
.mobileui .blue-grey,
.mobileui .grey-600,
.mobileui .grey-700,
.mobileui .grey-800,
.mobileui .grey-900 {
  color: rgba(255, 255, 255, 0.9);
}
.mobileui .grey-600,
.mobileui input[type=checkbox].grey-600.switch::after {
  background-color: #757575 !important;
}
.mobileui .grey-700,
.mobileui input[type=checkbox].grey-700.switch::after {
  background-color: #616161 !important;
}
.mobileui .grey-800,
.mobileui input[type=checkbox].grey-800.switch::after {
  background-color: #424242 !important;
}
.mobileui .grey-900,
.mobileui input[type=checkbox].grey-900.switch::after {
  background-color: #212121 !important;
}
.mobileui .blue-grey,
.mobileui input[type=checkbox].blue-grey.switch::after {
  background-color: #607d8b !important;
}
.mobileui .blue-grey-100,
.mobileui .blue-grey-200,
.mobileui .blue-grey-300,
.mobileui .blue-grey-50 {
  color: rgba(0, 0, 0, 0.8);
}
.mobileui .blue-grey-50,
.mobileui input[type=checkbox].blue-grey-50.switch::after {
  background-color: #eceff1 !important;
}
.mobileui .blue-grey-100,
.mobileui input[type=checkbox].blue-grey-100.switch::after {
  background-color: #cfd8dc !important;
}
.mobileui .blue-grey-200,
.mobileui input[type=checkbox].blue-grey-200.switch::after {
  background-color: #b0bec5 !important;
}
.mobileui .blue-grey-300,
.mobileui input[type=checkbox].blue-grey-300.switch::after {
  background-color: #90a4ae !important;
}
.mobileui .black,
.mobileui .blue-grey-400,
.mobileui .blue-grey-500,
.mobileui .blue-grey-600,
.mobileui .blue-grey-700,
.mobileui .blue-grey-800,
.mobileui .blue-grey-900 {
  color: rgba(255, 255, 255, 0.9);
}
.mobileui .blue-grey-400,
.mobileui input[type=checkbox].blue-grey-400.switch::after {
  background-color: #78909c !important;
}
.mobileui .transparent {
  background-color: rgba(0, 0, 0, 0) !important;
}
.mobileui .blue-grey-500,
.mobileui input[type=checkbox].blue-grey-500.switch::after {
  background-color: #607d8b !important;
}
.mobileui .blue-grey-600,
.mobileui input[type=checkbox].blue-grey-600.switch::after {
  background-color: #546e7a !important;
}
.mobileui .blue-grey-700,
.mobileui input[type=checkbox].blue-grey-700.switch::after {
  background-color: #455a64 !important;
}
.mobileui .blue-grey-800,
.mobileui input[type=checkbox].blue-grey-800.switch::after {
  background-color: #37474f !important;
}
.mobileui .blue-grey-900,
.mobileui input[type=checkbox].blue-grey-900.switch::after {
  background-color: #263238 !important;
}
.mobileui .black,
.mobileui input[type=checkbox].black.switch::after {
  background-color: #000 !important;
}
.mobileui .black-opacity-90 {
  background-color: rgba(0, 0, 0, 0.9);
}
.mobileui .black-opacity-70 {
  background-color: rgba(0, 0, 0, 0.7);
}
.mobileui .black-opacity-50 {
  background-color: rgba(0, 0, 0, 0.5);
}
.mobileui .black-opacity-30 {
  background-color: rgba(0, 0, 0, 0.3);
}
.mobileui .black-opacity-10 {
  background-color: rgba(0, 0, 0, 0.1);
}
.mobileui .white {
  color: rgba(0, 0, 0, 0.8);
  background-color: #fff !important;
}
.mobileui .white-opacity-90 {
  background-color: rgba(255, 255, 255, 0.9);
}
.mobileui .white-opacity-70 {
  background-color: rgba(255, 255, 255, 0.7);
}
.mobileui .white-opacity-50 {
  background-color: rgba(255, 255, 255, 0.5);
}
.mobileui .white-opacity-30 {
  background-color: rgba(255, 255, 255, 0.3);
}
.mobileui .white-opacity-10 {
  background-color: rgba(255, 255, 255, 0.1);
}
.mobileui .text-red,
.mobileui i.red {
  color: #f44336;
}
.mobileui .text-red-50,
.mobileui i.red-50 {
  color: #ffebee;
}
.mobileui .text-red-100,
.mobileui i.red-100 {
  color: #ffcdd2;
}
.mobileui .text-red-200,
.mobileui i.red-200 {
  color: #ef9a9a;
}
.mobileui .text-red-300,
.mobileui i.red-300 {
  color: #e57373;
}
.mobileui .text-red-400,
.mobileui i.red-400 {
  color: #ef5350;
}
.mobileui .text-red-500,
.mobileui i.red-500 {
  color: #f44336;
}
.mobileui .text-red-600,
.mobileui i.red-600 {
  color: #e53935;
}
.mobileui .text-red-700,
.mobileui i.red-700 {
  color: #d32f2f;
}
.mobileui .text-red-800,
.mobileui i.red-800 {
  color: #c62828;
}
.mobileui .text-red-900,
.mobileui i.red-900 {
  color: #b71c1c;
}
.mobileui .text-pink,
.mobileui i.pink {
  color: #e91e63;
}
.mobileui .text-pink-50,
.mobileui i.pink-50 {
  color: #fce4ec;
}
.mobileui .text-pink-100,
.mobileui i.pink-100 {
  color: #f8bbd0;
}
.mobileui .text-pink-200,
.mobileui i.pink-200 {
  color: #f48fb1;
}
.mobileui .text-Pink-300,
.mobileui i.Pink-300 {
  color: #f06292;
}
.mobileui .text-pink-400,
.mobileui i.pink-400 {
  color: #ec407a;
}
.mobileui .text-pink-500,
.mobileui i.pink-500 {
  color: #e91e63;
}
.mobileui .text-pink-600,
.mobileui i.pink-600 {
  color: #d81b60;
}
.mobileui .text-pink-700,
.mobileui i.pink-700 {
  color: #c2185b;
}
.mobileui .text-pink-800,
.mobileui i.pink-800 {
  color: #ad1457;
}
.mobileui .text-pink-900,
.mobileui i.pink-900 {
  color: #880e4f;
}
.mobileui .text-purple,
.mobileui i.purple {
  color: #9c27b0;
}
.mobileui .text-purple-50,
.mobileui i.purple-50 {
  color: #f3e5f5;
}
.mobileui .text-purple-100,
.mobileui i.purple-100 {
  color: #e1bee7;
}
.mobileui .text-purple-200,
.mobileui i.purple-200 {
  color: #ce93d8;
}
.mobileui .text-purple-300,
.mobileui i.purple-300 {
  color: #ba68c8;
}
.mobileui .text-purple-400,
.mobileui i.purple-400 {
  color: #ab47bc;
}
.mobileui .text-purple-500,
.mobileui i.purple-500 {
  color: #9c27b0;
}
.mobileui .text-purple-600,
.mobileui i.purple-600 {
  color: #8e24aa;
}
.mobileui .text-purple-700,
.mobileui i.purple-700 {
  color: #7b1fa2;
}
.mobileui .text-purple-800,
.mobileui i.purple-800 {
  color: #6a1b9a;
}
.mobileui .text-purple-900,
.mobileui i.purple-900 {
  color: #4a148c;
}
.mobileui .text-deep-purple,
.mobileui i.deep-purple {
  color: #673ab7;
}
.mobileui .text-deep-purple-50,
.mobileui i.deep-purple-50 {
  color: #ede7f6;
}
.mobileui .text-deep-purple-100,
.mobileui i.deep-purple-100 {
  color: #d1c4e9;
}
.mobileui .text-deep-purple-200,
.mobileui i.deep-purple-200 {
  color: #b39ddb;
}
.mobileui .text-deep-purple-300,
.mobileui i.deep-purple-300 {
  color: #9575cd;
}
.mobileui .text-deep-purple-400,
.mobileui i.deep-purple-400 {
  color: #7e57c2;
}
.mobileui .text-deep-purple-500,
.mobileui i.deep-purple-500 {
  color: #673ab7;
}
.mobileui .text-deep-purple-600,
.mobileui i.deep-purple-600 {
  color: #5e35b1;
}
.mobileui .text-deep-purple-700,
.mobileui i.deep-purple-700 {
  color: #512da8;
}
.mobileui .text-deep-purple-800,
.mobileui i.deep-purple-800 {
  color: #4527a0;
}
.mobileui .text-deep-purple-900,
.mobileui i.deep-purple-900 {
  color: #311b92;
}
.mobileui .text-indigo,
.mobileui i.indigo {
  color: #3f51b5;
}
.mobileui .text-indigo-50,
.mobileui i.indigo-50 {
  color: #e8eaf6;
}
.mobileui .text-indigo-100,
.mobileui i.indigo-100 {
  color: #c5cae9;
}
.mobileui .text-indigo-200,
.mobileui i.indigo-200 {
  color: #9fa8da;
}
.mobileui .text-indigo-300,
.mobileui i.indigo-300 {
  color: #7986cb;
}
.mobileui .text-indigo-400,
.mobileui i.indigo-400 {
  color: #5c6bc0;
}
.mobileui .text-indigo-500,
.mobileui i.indigo-500 {
  color: #3f51b5;
}
.mobileui .text-indigo-600,
.mobileui i.indigo-600 {
  color: #3949ab;
}
.mobileui .text-indigo-700,
.mobileui i.indigo-700 {
  color: #303f9f;
}
.mobileui .text-indigo-800,
.mobileui i.indigo-800 {
  color: #283593;
}
.mobileui .text-indigo-900,
.mobileui i.indigo-900 {
  color: #1a237e;
}
.mobileui .text-blue,
.mobileui i.blue {
  color: #2196f3;
}
.mobileui .text-blue-50,
.mobileui i.blue-50 {
  color: #e3f2fd;
}
.mobileui .text-blue-100,
.mobileui i.blue-100 {
  color: #bbdefb;
}
.mobileui .text-blue-200,
.mobileui i.blue-200 {
  color: #90caf9;
}
.mobileui .text-blue-300,
.mobileui i.blue-300 {
  color: #64b5f6;
}
.mobileui .text-blue-400,
.mobileui i.blue-400 {
  color: #42a5f5;
}
.mobileui .text-blue-500,
.mobileui i.blue-500 {
  color: #2196f3;
}
.mobileui .text-blue-600,
.mobileui i.blue-600 {
  color: #1e88e5;
}
.mobileui .text-blue-700,
.mobileui i.blue-700 {
  color: #1976d2;
}
.mobileui .text-blue-800,
.mobileui i.blue-800 {
  color: #1565c0;
}
.mobileui .text-blue-900,
.mobileui i.blue-900 {
  color: #0d47a1;
}
.mobileui .text-light-blue,
.mobileui i.light-blue {
  color: #03a9f4;
}
.mobileui .text-light-blue-50,
.mobileui i.light-blue-50 {
  color: #e1f5fe;
}
.mobileui .text-light-blue-100,
.mobileui i.light-blue-100 {
  color: #b3e5fc;
}
.mobileui .text-light-blue-200,
.mobileui i.light-blue-200 {
  color: #81d4fa;
}
.mobileui .text-light-blue-300,
.mobileui i.light-blue-300 {
  color: #4fc3f7;
}
.mobileui .text-light-blue-400,
.mobileui i.light-blue-400 {
  color: #29b6f6;
}
.mobileui .text-light-blue-500,
.mobileui i.light-blue-500 {
  color: #03a9f4;
}
.mobileui .text-light-blue-600,
.mobileui i.light-blue-600 {
  color: #039be5;
}
.mobileui .text-light-blue-700,
.mobileui i.light-blue-700 {
  color: #0288d1;
}
.mobileui .text-light-blue-800,
.mobileui i.light-blue-800 {
  color: #0277bd;
}
.mobileui .text-light-blue-900,
.mobileui i.light-blue-900 {
  color: #01579b;
}
.mobileui .text-cyan,
.mobileui i.cyan {
  color: #00bcd4;
}
.mobileui .text-cyan-50,
.mobileui i.cyan-50 {
  color: #e0f7fa;
}
.mobileui .text-cyan-100,
.mobileui i.cyan-100 {
  color: #b2ebf2;
}
.mobileui .text-cyan-200,
.mobileui i.cyan-200 {
  color: #80deea;
}
.mobileui .text-cyan-300,
.mobileui i.cyan-300 {
  color: #4dd0e1;
}
.mobileui .text-cyan-400,
.mobileui i.cyan-400 {
  color: #26c6da;
}
.mobileui .text-cyan-500,
.mobileui i.cyan-500 {
  color: #00bcd4;
}
.mobileui .text-cyan-600,
.mobileui i.cyan-600 {
  color: #00acc1;
}
.mobileui .text-cyan-700,
.mobileui i.cyan-700 {
  color: #0097a7;
}
.mobileui .text-cyan-800,
.mobileui i.cyan-800 {
  color: #00838f;
}
.mobileui .text-cyan-900,
.mobileui i.cyan-900 {
  color: #006064;
}
.mobileui .text-teal,
.mobileui i.teal {
  color: #009688;
}
.mobileui .text-teal-50,
.mobileui i.teal-50 {
  color: #e0f2f1;
}
.mobileui .text-teal-100,
.mobileui i.teal-100 {
  color: #b2dfdb;
}
.mobileui .text-teal-200,
.mobileui i.teal-200 {
  color: #80cbc4;
}
.mobileui .text-teal-300,
.mobileui i.teal-300 {
  color: #4db6ac;
}
.mobileui .text-teal-400,
.mobileui i.teal-400 {
  color: #26a69a;
}
.mobileui .text-teal-500,
.mobileui i.teal-500 {
  color: #009688;
}
.mobileui .text-teal-600,
.mobileui i.teal-600 {
  color: #00897b;
}
.mobileui .text-teal-700,
.mobileui i.teal-700 {
  color: #00796b;
}
.mobileui .text-teal-800,
.mobileui i.teal-800 {
  color: #00695c;
}
.mobileui .text-teal-900,
.mobileui i.teal-900 {
  color: #004d40;
}
.mobileui .text-green,
.mobileui i.green {
  color: #4caf50;
}
.mobileui .text-green-50,
.mobileui i.green-50 {
  color: #e8f5e9;
}
.mobileui .text-green-100,
.mobileui i.green-100 {
  color: #c8e6c9;
}
.mobileui .text-green-200,
.mobileui i.green-200 {
  color: #a5d6a7;
}
.mobileui .text-green-300,
.mobileui i.green-300 {
  color: #81c784;
}
.mobileui .text-green-400,
.mobileui i.green-400 {
  color: #66bb6a;
}
.mobileui .text-green-500,
.mobileui i.green-500 {
  color: #4caf50;
}
.mobileui .text-green-600,
.mobileui i.green-600 {
  color: #43a047;
}
.mobileui .text-green-700,
.mobileui i.green-700 {
  color: #388e3c;
}
.mobileui .text-green-800,
.mobileui i.green-800 {
  color: #2e7d32;
}
.mobileui .text-green-900,
.mobileui i.green-900 {
  color: #1b5e20;
}
.mobileui .text-light-green,
.mobileui i.light-green {
  color: #8bc34a;
}
.mobileui .text-light-green-50,
.mobileui i.light-green-50 {
  color: #f1f8e9;
}
.mobileui .text-light-green-100,
.mobileui i.light-green-100 {
  color: #dcedc8;
}
.mobileui .text-light-green-200,
.mobileui i.light-green-200 {
  color: #c5e1a5;
}
.mobileui .text-light-green-300,
.mobileui i.light-green-300 {
  color: #aed581;
}
.mobileui .text-light-green-400,
.mobileui i.light-green-400 {
  color: #9ccc65;
}
.mobileui .text-light-green-500,
.mobileui i.light-green-500 {
  color: #8bc34a;
}
.mobileui .text-light-green-600,
.mobileui i.light-green-600 {
  color: #7cb342;
}
.mobileui .text-light-green-700,
.mobileui i.light-green-700 {
  color: #689f38;
}
.mobileui .text-light-green-800,
.mobileui i.light-green-800 {
  color: #558b2f;
}
.mobileui .text-light-green-900,
.mobileui i.light-green-900 {
  color: #33691e;
}
.mobileui .text-lime,
.mobileui i.lime {
  color: #cddc39;
}
.mobileui .text-lime-50,
.mobileui i.lime-50 {
  color: #f9fbe7;
}
.mobileui .text-lime-100,
.mobileui i.lime-100 {
  color: #f0f4c3;
}
.mobileui .text-lime-200,
.mobileui i.lime-200 {
  color: #e6ee9c;
}
.mobileui .text-lime-300,
.mobileui i.lime-300 {
  color: #dce775;
}
.mobileui .text-lime-400,
.mobileui i.lime-400 {
  color: #d4e157;
}
.mobileui .text-lime-500,
.mobileui i.lime-500 {
  color: #cddc39;
}
.mobileui .text-lime-600,
.mobileui i.lime-600 {
  color: #c0ca33;
}
.mobileui .text-lime-700,
.mobileui i.lime-700 {
  color: #afb42b;
}
.mobileui .text-lime-800,
.mobileui i.lime-800 {
  color: #9e9d24;
}
.mobileui .text-lime-900,
.mobileui i.lime-900 {
  color: #827717;
}
.mobileui .text-yellow,
.mobileui i.yellow {
  color: #ffeb3b;
}
.mobileui .text-yellow-50,
.mobileui i.yellow-50 {
  color: #fffde7;
}
.mobileui .text-yellow-100,
.mobileui i.yellow-100 {
  color: #fff9c4;
}
.mobileui .text-yellow-200,
.mobileui i.yellow-200 {
  color: #fff59d;
}
.mobileui .text-yellow-300,
.mobileui i.yellow-300 {
  color: #fff176;
}
.mobileui .text-yellow-400,
.mobileui i.yellow-400 {
  color: #ffee58;
}
.mobileui .text-yellow-500,
.mobileui i.yellow-500 {
  color: #ffeb3b;
}
.mobileui .text-yellow-600,
.mobileui i.yellow-600 {
  color: #fdd835;
}
.mobileui .text-yellow-700,
.mobileui i.yellow-700 {
  color: #fbc02d;
}
.mobileui .text-yellow-800,
.mobileui i.yellow-800 {
  color: #f9a825;
}
.mobileui .text-yellow-900,
.mobileui i.yellow-900 {
  color: #f57f17;
}
.mobileui .text-amber,
.mobileui i.amber {
  color: #ffc107;
}
.mobileui .text-amber-50,
.mobileui i.amber-50 {
  color: #fff8e1;
}
.mobileui .text-amber-100,
.mobileui i.amber-100 {
  color: #ffecb3;
}
.mobileui .text-amber-200,
.mobileui i.amber-200 {
  color: #ffe082;
}
.mobileui .text-amber-300,
.mobileui i.amber-300 {
  color: #ffd54f;
}
.mobileui .text-amber-400,
.mobileui i.amber-400 {
  color: #ffca28;
}
.mobileui .text-amber-500,
.mobileui i.amber-500 {
  color: #ffc107;
}
.mobileui .text-amber-600,
.mobileui i.amber-600 {
  color: #ffb300;
}
.mobileui .text-amber-700,
.mobileui i.amber-700 {
  color: #ffa000;
}
.mobileui .text-amber-800,
.mobileui i.amber-800 {
  color: #ff8f00;
}
.mobileui .text-amber-900,
.mobileui i.amber-900 {
  color: #ff6f00;
}
.mobileui .text-orange,
.mobileui i.orange {
  color: #ff9800;
}
.mobileui .text-orange-50,
.mobileui i.orange-50 {
  color: #fff3e0;
}
.mobileui .text-orange-100,
.mobileui i.orange-100 {
  color: #ffe0b2;
}
.mobileui .text-orange-200,
.mobileui i.orange-200 {
  color: #ffcc80;
}
.mobileui .text-orange-300,
.mobileui i.orange-300 {
  color: #ffb74d;
}
.mobileui .text-orange-400,
.mobileui i.orange-400 {
  color: #ffa726;
}
.mobileui .text-orange-500,
.mobileui i.orange-500 {
  color: #ff9800;
}
.mobileui .text-orange-600,
.mobileui i.orange-600 {
  color: #fb8c00;
}
.mobileui .text-orange-700,
.mobileui i.orange-700 {
  color: #f57c00;
}
.mobileui .text-orange-800,
.mobileui i.orange-800 {
  color: #ef6c00;
}
.mobileui .text-orange-900,
.mobileui i.orange-900 {
  color: #e65100;
}
.mobileui .text-deep-orange,
.mobileui i.deep-orange {
  color: #ff5722;
}
.mobileui .text-deep-orange-50,
.mobileui i.deep-orange-50 {
  color: #fbe9e7;
}
.mobileui .text-deep-orange-100,
.mobileui i.deep-orange-100 {
  color: #ffccbc;
}
.mobileui .text-deep-orange-200,
.mobileui i.deep-orange-200 {
  color: #ffab91;
}
.mobileui .text-deep-orange-300,
.mobileui i.deep-orange-300 {
  color: #ff8a65;
}
.mobileui .text-deep-orange-400,
.mobileui i.deep-orange-400 {
  color: #ff7043;
}
.mobileui .text-deep-orange-500,
.mobileui i.deep-orange-500 {
  color: #ff5722;
}
.mobileui .text-deep-orange-600,
.mobileui i.deep-orange-600 {
  color: #f4511e;
}
.mobileui .text-deep-orange-700,
.mobileui i.deep-orange-700 {
  color: #e64a19;
}
.mobileui .text-deep-orange-800,
.mobileui i.deep-orange-800 {
  color: #d84315;
}
.mobileui .text-deep-orange-900,
.mobileui i.deep-orange-900 {
  color: #bf360c;
}
.mobileui .text-brown,
.mobileui i.brown {
  color: #795548;
}
.mobileui .text-brown-50,
.mobileui i.brown-50 {
  color: #efebe9;
}
.mobileui .text-brown-100,
.mobileui i.brown-100 {
  color: #d7ccc8;
}
.mobileui .text-brown-200,
.mobileui i.brown-200 {
  color: #bcaaa4;
}
.mobileui .text-brown-300,
.mobileui i.brown-300 {
  color: #a1887f;
}
.mobileui .text-brown-400,
.mobileui i.brown-400 {
  color: #8d6e63;
}
.mobileui .text-brown-500,
.mobileui i.brown-500 {
  color: #795548;
}
.mobileui .text-brown-600,
.mobileui i.brown-600 {
  color: #6d4c41;
}
.mobileui .text-brown-700,
.mobileui i.brown-700 {
  color: #5d4037;
}
.mobileui .text-brown-800,
.mobileui i.brown-800 {
  color: #4e342e;
}
.mobileui .text-brown-900,
.mobileui i.brown-900 {
  color: #3e2723;
}
.mobileui .text-grey,
.mobileui i.grey {
  color: #9e9e9e;
}
.mobileui .text-grey-50,
.mobileui i.grey-50 {
  color: #fafafa;
}
.mobileui .text-grey-100,
.mobileui i.grey-100 {
  color: #f5f5f5;
}
.mobileui .text-grey-200,
.mobileui i.grey-200 {
  color: #eee;
}
.mobileui .text-grey-300,
.mobileui i.grey-300 {
  color: #e0e0e0;
}
.mobileui .text-grey-400,
.mobileui i.grey-400 {
  color: #bdbdbd;
}
.mobileui .text-grey-500,
.mobileui i.grey-500 {
  color: #9e9e9e;
}
.mobileui .text-grey-600,
.mobileui i.grey-600 {
  color: #757575;
}
.mobileui .text-grey-700,
.mobileui i.grey-700 {
  color: #616161;
}
.mobileui .text-grey-800,
.mobileui i.grey-800 {
  color: #424242;
}
.mobileui .text-grey-900,
.mobileui i.grey-900 {
  color: #212121;
}
.mobileui .text-blue-grey,
.mobileui i.blue-grey {
  color: #607d8b;
}
.mobileui .text-blue-grey-50,
.mobileui i.blue-grey-50 {
  color: #eceff1;
}
.mobileui .text-blue-grey-100,
.mobileui i.blue-grey-100 {
  color: #cfd8dc;
}
.mobileui .text-blue-grey-200,
.mobileui i.blue-grey-200 {
  color: #b0bec5;
}
.mobileui .text-blue-grey-300,
.mobileui i.blue-grey-300 {
  color: #90a4ae;
}
.mobileui .text-blue-grey-400,
.mobileui i.blue-grey-400 {
  color: #78909c;
}
.mobileui .text-blue-grey-500,
.mobileui i.blue-grey-500 {
  color: #607d8b;
}
.mobileui .text-blue-grey-600,
.mobileui i.blue-grey-600 {
  color: #546e7a;
}
.mobileui .text-blue-grey-700,
.mobileui i.blue-grey-700 {
  color: #455a64;
}
.mobileui .text-blue-grey-800,
.mobileui i.blue-grey-800 {
  color: #37474f;
}
.mobileui .text-blue-grey-900,
.mobileui i.blue-grey-900 {
  color: #263238;
}
.mobileui .text-black,
.mobileui i.black {
  color: #000;
}
.mobileui .text-white,
.mobileui i.white {
  color: #fff;
}
.mobileui .border-red {
  border: 1px solid #f44336;
}
.mobileui .border-red-50 {
  border: 1px solid #ffebee;
}
.mobileui .border-red-100 {
  border: 1px solid #ffcdd2;
}
.mobileui .border-red-200 {
  border: 1px solid #ef9a9a;
}
.mobileui .border-red-300 {
  border: 1px solid #e57373;
}
.mobileui .border-red-400 {
  border: 1px solid #ef5350;
}
.mobileui .border-red-500 {
  border: 1px solid #f44336;
}
.mobileui .border-red-600 {
  border: 1px solid #e53935;
}
.mobileui .border-red-700 {
  border: 1px solid #d32f2f;
}
.mobileui .border-red-800 {
  border: 1px solid #c62828;
}
.mobileui .border-red-900 {
  border: 1px solid #b71c1c;
}
.mobileui .border-pink {
  border: 1px solid #e91e63;
}
.mobileui .border-pink-50 {
  border: 1px solid #fce4ec;
}
.mobileui .border-pink-100 {
  border: 1px solid #f8bbd0;
}
.mobileui .border-pink-200 {
  border: 1px solid #f48fb1;
}
.mobileui .border-pink-300 {
  border: 1px solid #f06292;
}
.mobileui .border-pink-400 {
  border: 1px solid #ec407a;
}
.mobileui .border-pink-500 {
  border: 1px solid #e91e63;
}
.mobileui .border-pink-600 {
  border: 1px solid #d81b60;
}
.mobileui .border-pink-700 {
  border: 1px solid #c2185b;
}
.mobileui .border-pink-800 {
  border: 1px solid #ad1457;
}
.mobileui .border-pink-900 {
  border: 1px solid #880e4f;
}
.mobileui .border-purple {
  border: 1px solid #9c27b0;
}
.mobileui .border-purple-50 {
  border: 1px solid #f3e5f5;
}
.mobileui .border-purple-100 {
  border: 1px solid #e1bee7;
}
.mobileui .border-purple-200 {
  border: 1px solid #ce93d8;
}
.mobileui .border-purple-300 {
  border: 1px solid #ba68c8;
}
.mobileui .border-purple-400 {
  border: 1px solid #ab47bc;
}
.mobileui .border-purple-500 {
  border: 1px solid #9c27b0;
}
.mobileui .border-purple-600 {
  border: 1px solid #8e24aa;
}
.mobileui .border-purple-700 {
  border: 1px solid #7b1fa2;
}
.mobileui .border-purple-800 {
  border: 1px solid #6a1b9a;
}
.mobileui .border-purple-900 {
  border: 1px solid #4a148c;
}
.mobileui .border-deep-purple {
  border: 1px solid #673ab7;
}
.mobileui .border-deep-purple-50 {
  border: 1px solid #ede7f6;
}
.mobileui .border-deep-purple-100 {
  border: 1px solid #d1c4e9;
}
.mobileui .border-deep-purple-200 {
  border: 1px solid #b39ddb;
}
.mobileui .border-deep-purple-300 {
  border: 1px solid #9575cd;
}
.mobileui .border-deep-purple-400 {
  border: 1px solid #7e57c2;
}
.mobileui .border-deep-purple-500 {
  border: 1px solid #673ab7;
}
.mobileui .border-deep-purple-600 {
  border: 1px solid #5e35b1;
}
.mobileui .border-deep-purple-700 {
  border: 1px solid #512da8;
}
.mobileui .border-deep-purple-800 {
  border: 1px solid #4527a0;
}
.mobileui .border-deep-purple-900 {
  border: 1px solid #311b92;
}
.mobileui .border-indigo {
  border: 1px solid #3f51b5;
}
.mobileui .border-indigo-50 {
  border: 1px solid #e8eaf6;
}
.mobileui .border-indigo-100 {
  border: 1px solid #c5cae9;
}
.mobileui .border-indigo-200 {
  border: 1px solid #9fa8da;
}
.mobileui .border-indigo-300 {
  border: 1px solid #7986cb;
}
.mobileui .border-indigo-400 {
  border: 1px solid #5c6bc0;
}
.mobileui .border-indigo-500 {
  border: 1px solid #3f51b5;
}
.mobileui .border-indigo-600 {
  border: 1px solid #3949ab;
}
.mobileui .border-indigo-700 {
  border: 1px solid #303f9f;
}
.mobileui .border-indigo-800 {
  border: 1px solid #283593;
}
.mobileui .border-indigo-900 {
  border: 1px solid #1a237e;
}
.mobileui .border-blue {
  border: 1px solid #2196f3;
}
.mobileui .border-blue-50 {
  border: 1px solid #e3f2fd;
}
.mobileui .border-blue-100 {
  border: 1px solid #bbdefb;
}
.mobileui .border-blue-200 {
  border: 1px solid #90caf9;
}
.mobileui .border-blue-300 {
  border: 1px solid #64b5f6;
}
.mobileui .border-blue-400 {
  border: 1px solid #42a5f5;
}
.mobileui .border-blue-500 {
  border: 1px solid #2196f3;
}
.mobileui .border-blue-600 {
  border: 1px solid #1e88e5;
}
.mobileui .border-blue-700 {
  border: 1px solid #1976d2;
}
.mobileui .border-blue-800 {
  border: 1px solid #1565c0;
}
.mobileui .border-blue-900 {
  border: 1px solid #0d47a1;
}
.mobileui .border-light-blue {
  border: 1px solid #03a9f4;
}
.mobileui .border-light-blue-50 {
  border: 1px solid #e1f5fe;
}
.mobileui .border-light-blue-100 {
  border: 1px solid #b3e5fc;
}
.mobileui .border-light-blue-200 {
  border: 1px solid #81d4fa;
}
.mobileui .border-light-blue-300 {
  border: 1px solid #4fc3f7;
}
.mobileui .border-light-blue-400 {
  border: 1px solid #29b6f6;
}
.mobileui .border-light-blue-500 {
  border: 1px solid #03a9f4;
}
.mobileui .border-light-blue-600 {
  border: 1px solid #039be5;
}
.mobileui .border-light-blue-700 {
  border: 1px solid #0288d1;
}
.mobileui .border-light-blue-800 {
  border: 1px solid #0277bd;
}
.mobileui .border-light-blue-900 {
  border: 1px solid #01579b;
}
.mobileui .border-cyan {
  border: 1px solid #00bcd4;
}
.mobileui .border-cyan-50 {
  border: 1px solid #e0f7fa;
}
.mobileui .border-cyan-100 {
  border: 1px solid #b2ebf2;
}
.mobileui .border-cyan-200 {
  border: 1px solid #80deea;
}
.mobileui .border-cyan-300 {
  border: 1px solid #4dd0e1;
}
.mobileui .border-cyan-400 {
  border: 1px solid #26c6da;
}
.mobileui .border-cyan-500 {
  border: 1px solid #00bcd4;
}
.mobileui .border-cyan-600 {
  border: 1px solid #00acc1;
}
.mobileui .border-cyan-700 {
  border: 1px solid #0097a7;
}
.mobileui .border-cyan-800 {
  border: 1px solid #00838f;
}
.mobileui .border-cyan-900 {
  border: 1px solid #006064;
}
.mobileui .border-teal {
  border: 1px solid #009688;
}
.mobileui .border-teal-50 {
  border: 1px solid #e0f2f1;
}
.mobileui .border-teal-100 {
  border: 1px solid #b2dfdb;
}
.mobileui .border-teal-200 {
  border: 1px solid #80cbc4;
}
.mobileui .border-teal-300 {
  border: 1px solid #4db6ac;
}
.mobileui .border-teal-400 {
  border: 1px solid #26a69a;
}
.mobileui .border-teal-500 {
  border: 1px solid #009688;
}
.mobileui .border-teal-600 {
  border: 1px solid #00897b;
}
.mobileui .border-teal-700 {
  border: 1px solid #00796b;
}
.mobileui .border-teal-800 {
  border: 1px solid #00695c;
}
.mobileui .border-teal-900 {
  border: 1px solid #004d40;
}
.mobileui .border-green {
  border: 1px solid #4caf50;
}
.mobileui .border-green-50 {
  border: 1px solid #e8f5e9;
}
.mobileui .border-green-100 {
  border: 1px solid #c8e6c9;
}
.mobileui .border-green-200 {
  border: 1px solid #a5d6a7;
}
.mobileui .border-green-300 {
  border: 1px solid #81c784;
}
.mobileui .border-green-400 {
  border: 1px solid #66bb6a;
}
.mobileui .border-green-500 {
  border: 1px solid #4caf50;
}
.mobileui .border-green-600 {
  border: 1px solid #43a047;
}
.mobileui .border-green-700 {
  border: 1px solid #388e3c;
}
.mobileui .border-green-800 {
  border: 1px solid #2e7d32;
}
.mobileui .border-green-900 {
  border: 1px solid #1b5e20;
}
.mobileui .border-light-green {
  border: 1px solid #8bc34a;
}
.mobileui .border-light-green-50 {
  border: 1px solid #f1f8e9;
}
.mobileui .border-light-green-100 {
  border: 1px solid #dcedc8;
}
.mobileui .border-light-green-200 {
  border: 1px solid #c5e1a5;
}
.mobileui .border-light-green-300 {
  border: 1px solid #aed581;
}
.mobileui .border-light-green-400 {
  border: 1px solid #9ccc65;
}
.mobileui .border-light-green-500 {
  border: 1px solid #8bc34a;
}
.mobileui .border-light-green-600 {
  border: 1px solid #7cb342;
}
.mobileui .border-light-green-700 {
  border: 1px solid #689f38;
}
.mobileui .border-light-green-800 {
  border: 1px solid #558b2f;
}
.mobileui .border-light-green-900 {
  border: 1px solid #33691e;
}
.mobileui .border-lime {
  border: 1px solid #cddc39;
}
.mobileui .border-lime-50 {
  border: 1px solid #f9fbe7;
}
.mobileui .border-lime-100 {
  border: 1px solid #f0f4c3;
}
.mobileui .border-lime-200 {
  border: 1px solid #e6ee9c;
}
.mobileui .border-lime-300 {
  border: 1px solid #dce775;
}
.mobileui .border-lime-400 {
  border: 1px solid #d4e157;
}
.mobileui .border-lime-500 {
  border: 1px solid #cddc39;
}
.mobileui .border-lime-600 {
  border: 1px solid #c0ca33;
}
.mobileui .border-lime-700 {
  border: 1px solid #afb42b;
}
.mobileui .border-lime-800 {
  border: 1px solid #9e9d24;
}
.mobileui .border-lime-900 {
  border: 1px solid #827717;
}
.mobileui .border-yellow {
  border: 1px solid #ffeb3b;
}
.mobileui .border-yellow-50 {
  border: 1px solid #fffde7;
}
.mobileui .border-yellow-100 {
  border: 1px solid #fff9c4;
}
.mobileui .border-yellow-200 {
  border: 1px solid #fff59d;
}
.mobileui .border-yellow-300 {
  border: 1px solid #fff176;
}
.mobileui .border-yellow-400 {
  border: 1px solid #ffee58;
}
.mobileui .border-yellow-500 {
  border: 1px solid #ffeb3b;
}
.mobileui .border-yellow-600 {
  border: 1px solid #fdd835;
}
.mobileui .border-yellow-700 {
  border: 1px solid #fbc02d;
}
.mobileui .border-yellow-800 {
  border: 1px solid #f9a825;
}
.mobileui .border-yellow-900 {
  border: 1px solid #f57f17;
}
.mobileui .border-amber {
  border: 1px solid #ffc107;
}
.mobileui .border-amber-50 {
  border: 1px solid #fff8e1;
}
.mobileui .border-amber-100 {
  border: 1px solid #ffecb3;
}
.mobileui .border-amber-200 {
  border: 1px solid #ffe082;
}
.mobileui .border-amber-300 {
  border: 1px solid #ffd54f;
}
.mobileui .border-amber-400 {
  border: 1px solid #ffca28;
}
.mobileui .border-amber-500 {
  border: 1px solid #ffc107;
}
.mobileui .border-amber-600 {
  border: 1px solid #ffb300;
}
.mobileui .border-amber-700 {
  border: 1px solid #ffa000;
}
.mobileui .border-amber-800 {
  border: 1px solid #ff8f00;
}
.mobileui .border-amber-900 {
  border: 1px solid #ff6f00;
}
.mobileui .border-orange {
  border: 1px solid #ff9800;
}
.mobileui .border-orange-50 {
  border: 1px solid #fff3e0;
}
.mobileui .border-orange-100 {
  border: 1px solid #ffe0b2;
}
.mobileui .border-orange-200 {
  border: 1px solid #ffcc80;
}
.mobileui .border-orange-300 {
  border: 1px solid #ffb74d;
}
.mobileui .border-orange-400 {
  border: 1px solid #ffa726;
}
.mobileui .border-orange-500 {
  border: 1px solid #ff9800;
}
.mobileui .border-orange-600 {
  border: 1px solid #fb8c00;
}
.mobileui .border-orange-700 {
  border: 1px solid #f57c00;
}
.mobileui .border-orange-800 {
  border: 1px solid #ef6c00;
}
.mobileui .border-orange-900 {
  border: 1px solid #e65100;
}
.mobileui .border-deep-orange {
  border: 1px solid #ff5722;
}
.mobileui .border-deep-orange-50 {
  border: 1px solid #fbe9e7;
}
.mobileui .border-deep-orange-100 {
  border: 1px solid #ffccbc;
}
.mobileui .border-deep-orange-200 {
  border: 1px solid #ffab91;
}
.mobileui .border-deep-orange-300 {
  border: 1px solid #ff8a65;
}
.mobileui .border-deep-orange-400 {
  border: 1px solid #ff7043;
}
.mobileui .border-deep-orange-500 {
  border: 1px solid #ff5722;
}
.mobileui .border-deep-orange-600 {
  border: 1px solid #f4511e;
}
.mobileui .border-deep-orange-700 {
  border: 1px solid #e64a19;
}
.mobileui .border-deep-orange-800 {
  border: 1px solid #d84315;
}
.mobileui .border-deep-orange-900 {
  border: 1px solid #bf360c;
}
.mobileui .border-brown {
  border: 1px solid #795548;
}
.mobileui .border-brown-50 {
  border: 1px solid #efebe9;
}
.mobileui .border-brown-100 {
  border: 1px solid #d7ccc8;
}
.mobileui .border-brown-200 {
  border: 1px solid #bcaaa4;
}
.mobileui .border-brown-300 {
  border: 1px solid #a1887f;
}
.mobileui .border-brown-400 {
  border: 1px solid #8d6e63;
}
.mobileui .border-brown-500 {
  border: 1px solid #795548;
}
.mobileui .border-brown-600 {
  border: 1px solid #6d4c41;
}
.mobileui .border-brown-700 {
  border: 1px solid #5d4037;
}
.mobileui .border-brown-800 {
  border: 1px solid #4e342e;
}
.mobileui .border-brown-900 {
  border: 1px solid #3e2723;
}
.mobileui .border-grey {
  border: 1px solid #9e9e9e;
}
.mobileui .border-grey-50 {
  border: 1px solid #fafafa;
}
.mobileui .border-grey-100 {
  border: 1px solid #f5f5f5;
}
.mobileui .border-grey-200 {
  border: 1px solid #eee;
}
.mobileui .border-grey-300 {
  border: 1px solid #e0e0e0;
}
.mobileui .border-grey-400 {
  border: 1px solid #bdbdbd;
}
.mobileui .border-grey-500 {
  border: 1px solid #9e9e9e;
}
.mobileui .border-grey-600 {
  border: 1px solid #757575;
}
.mobileui .border-grey-700 {
  border: 1px solid #616161;
}
.mobileui .border-grey-800 {
  border: 1px solid #424242;
}
.mobileui .border-grey-900 {
  border: 1px solid #212121;
}
.mobileui .border-blue-grey {
  border: 1px solid #607d8b;
}
.mobileui .border-blue-grey-50 {
  border: 1px solid #eceff1;
}
.mobileui .border-blue-grey-100 {
  border: 1px solid #cfd8dc;
}
.mobileui .border-blue-grey-200 {
  border: 1px solid #b0bec5;
}
.mobileui .border-blue-grey-300 {
  border: 1px solid #90a4ae;
}
.mobileui .border-blue-grey-400 {
  border: 1px solid #78909c;
}
.mobileui .border-blue-grey-500 {
  border: 1px solid #607d8b;
}
.mobileui .border-blue-grey-600 {
  border: 1px solid #546e7a;
}
.mobileui .border-blue-grey-700 {
  border: 1px solid #455a64;
}
.mobileui .border-blue-grey-800 {
  border: 1px solid #37474f;
}
.mobileui .border-blue-grey-900 {
  border: 1px solid #263238;
}
.mobileui .border-black {
  border: 1px solid #000;
}
.mobileui .border-white {
  border: 1px solid #fff;
}
.mobileui i.icon {
  background: 0 0 !important;
}
.mobileui .ion,
.mobileui .ion-alert-circled:before,
.mobileui .ion-alert:before,
.mobileui .ion-android-add-circle:before,
.mobileui .ion-android-add:before,
.mobileui .ion-android-alarm-clock:before,
.mobileui .ion-android-alert:before,
.mobileui .ion-android-apps:before,
.mobileui .ion-android-archive:before,
.mobileui .ion-android-arrow-back:before,
.mobileui .ion-android-arrow-down:before,
.mobileui .ion-android-arrow-dropdown-circle:before,
.mobileui .ion-android-arrow-dropdown:before,
.mobileui .ion-android-arrow-dropleft-circle:before,
.mobileui .ion-android-arrow-dropleft:before,
.mobileui .ion-android-arrow-dropright-circle:before,
.mobileui .ion-android-arrow-dropright:before,
.mobileui .ion-android-arrow-dropup-circle:before,
.mobileui .ion-android-arrow-dropup:before,
.mobileui .ion-android-arrow-forward:before,
.mobileui .ion-android-arrow-up:before,
.mobileui .ion-android-attach:before,
.mobileui .ion-android-bar:before,
.mobileui .ion-android-bicycle:before,
.mobileui .ion-android-boat:before,
.mobileui .ion-android-bookmark:before,
.mobileui .ion-android-bulb:before,
.mobileui .ion-android-bus:before,
.mobileui .ion-android-calendar:before,
.mobileui .ion-android-call:before,
.mobileui .ion-android-camera:before,
.mobileui .ion-android-cancel:before,
.mobileui .ion-android-car:before,
.mobileui .ion-android-cart:before,
.mobileui .ion-android-chat:before,
.mobileui .ion-android-checkbox-blank:before,
.mobileui .ion-android-checkbox-outline-blank:before,
.mobileui .ion-android-checkbox-outline:before,
.mobileui .ion-android-checkbox:before,
.mobileui .ion-android-checkmark-circle:before,
.mobileui .ion-android-clipboard:before,
.mobileui .ion-android-close:before,
.mobileui .ion-android-cloud-circle:before,
.mobileui .ion-android-cloud-done:before,
.mobileui .ion-android-cloud-outline:before,
.mobileui .ion-android-cloud:before,
.mobileui .ion-android-color-palette:before,
.mobileui .ion-android-compass:before,
.mobileui .ion-android-contact:before,
.mobileui .ion-android-contacts:before,
.mobileui .ion-android-contract:before,
.mobileui .ion-android-create:before,
.mobileui .ion-android-delete:before,
.mobileui .ion-android-desktop:before,
.mobileui .ion-android-document:before,
.mobileui .ion-android-done-all:before,
.mobileui .ion-android-done:before,
.mobileui .ion-android-download:before,
.mobileui .ion-android-drafts:before,
.mobileui .ion-android-exit:before,
.mobileui .ion-android-expand:before,
.mobileui .ion-android-favorite-outline:before,
.mobileui .ion-android-favorite:before,
.mobileui .ion-android-film:before,
.mobileui .ion-android-folder-open:before,
.mobileui .ion-android-folder:before,
.mobileui .ion-android-funnel:before,
.mobileui .ion-android-globe:before,
.mobileui .ion-android-hand:before,
.mobileui .ion-android-hangout:before,
.mobileui .ion-android-happy:before,
.mobileui .ion-android-home:before,
.mobileui .ion-android-image:before,
.mobileui .ion-android-laptop:before,
.mobileui .ion-android-list:before,
.mobileui .ion-android-locate:before,
.mobileui .ion-android-lock:before,
.mobileui .ion-android-mail:before,
.mobileui .ion-android-map:before,
.mobileui .ion-android-menu:before,
.mobileui .ion-android-microphone-off:before,
.mobileui .ion-android-microphone:before,
.mobileui .ion-android-more-horizontal:before,
.mobileui .ion-android-more-vertical:before,
.mobileui .ion-android-navigate:before,
.mobileui .ion-android-notifications-none:before,
.mobileui .ion-android-notifications-off:before,
.mobileui .ion-android-notifications:before,
.mobileui .ion-android-open:before,
.mobileui .ion-android-options:before,
.mobileui .ion-android-people:before,
.mobileui .ion-android-person-add:before,
.mobileui .ion-android-person:before,
.mobileui .ion-android-phone-landscape:before,
.mobileui .ion-android-phone-portrait:before,
.mobileui .ion-android-pin:before,
.mobileui .ion-android-plane:before,
.mobileui .ion-android-playstore:before,
.mobileui .ion-android-print:before,
.mobileui .ion-android-radio-button-off:before,
.mobileui .ion-android-radio-button-on:before,
.mobileui .ion-android-refresh:before,
.mobileui .ion-android-remove-circle:before,
.mobileui .ion-android-remove:before,
.mobileui .ion-android-restaurant:before,
.mobileui .ion-android-sad:before,
.mobileui .ion-android-search:before,
.mobileui .ion-android-send:before,
.mobileui .ion-android-settings:before,
.mobileui .ion-android-share-alt:before,
.mobileui .ion-android-share:before,
.mobileui .ion-android-star-half:before,
.mobileui .ion-android-star-outline:before,
.mobileui .ion-android-star:before,
.mobileui .ion-android-stopwatch:before,
.mobileui .ion-android-subway:before,
.mobileui .ion-android-sunny:before,
.mobileui .ion-android-sync:before,
.mobileui .ion-android-textsms:before,
.mobileui .ion-android-time:before,
.mobileui .ion-android-train:before,
.mobileui .ion-android-unlock:before,
.mobileui .ion-android-upload:before,
.mobileui .ion-android-volume-down:before,
.mobileui .ion-android-volume-mute:before,
.mobileui .ion-android-volume-off:before,
.mobileui .ion-android-volume-up:before,
.mobileui .ion-android-walk:before,
.mobileui .ion-android-warning:before,
.mobileui .ion-android-watch:before,
.mobileui .ion-android-wifi:before,
.mobileui .ion-aperture:before,
.mobileui .ion-archive:before,
.mobileui .ion-arrow-down-a:before,
.mobileui .ion-arrow-down-b:before,
.mobileui .ion-arrow-down-c:before,
.mobileui .ion-arrow-expand:before,
.mobileui .ion-arrow-graph-down-left:before,
.mobileui .ion-arrow-graph-down-right:before,
.mobileui .ion-arrow-graph-up-left:before,
.mobileui .ion-arrow-graph-up-right:before,
.mobileui .ion-arrow-left-a:before,
.mobileui .ion-arrow-left-b:before,
.mobileui .ion-arrow-left-c:before,
.mobileui .ion-arrow-move:before,
.mobileui .ion-arrow-resize:before,
.mobileui .ion-arrow-return-left:before,
.mobileui .ion-arrow-return-right:before,
.mobileui .ion-arrow-right-a:before,
.mobileui .ion-arrow-right-b:before,
.mobileui .ion-arrow-right-c:before,
.mobileui .ion-arrow-shrink:before,
.mobileui .ion-arrow-swap:before,
.mobileui .ion-arrow-up-a:before,
.mobileui .ion-arrow-up-b:before,
.mobileui .ion-arrow-up-c:before,
.mobileui .ion-asterisk:before,
.mobileui .ion-at:before,
.mobileui .ion-backspace-outline:before,
.mobileui .ion-backspace:before,
.mobileui .ion-bag:before,
.mobileui .ion-battery-charging:before,
.mobileui .ion-battery-empty:before,
.mobileui .ion-battery-full:before,
.mobileui .ion-battery-half:before,
.mobileui .ion-battery-low:before,
.mobileui .ion-beaker:before,
.mobileui .ion-beer:before,
.mobileui .ion-bluetooth:before,
.mobileui .ion-bonfire:before,
.mobileui .ion-bookmark:before,
.mobileui .ion-bowtie:before,
.mobileui .ion-briefcase:before,
.mobileui .ion-bug:before,
.mobileui .ion-calculator:before,
.mobileui .ion-calendar:before,
.mobileui .ion-camera:before,
.mobileui .ion-card:before,
.mobileui .ion-cash:before,
.mobileui .ion-chatbox-working:before,
.mobileui .ion-chatbox:before,
.mobileui .ion-chatboxes:before,
.mobileui .ion-chatbubble-working:before,
.mobileui .ion-chatbubble:before,
.mobileui .ion-chatbubbles:before,
.mobileui .ion-checkmark-circled:before,
.mobileui .ion-checkmark-round:before,
.mobileui .ion-checkmark:before,
.mobileui .ion-chevron-down:before,
.mobileui .ion-chevron-left:before,
.mobileui .ion-chevron-right:before,
.mobileui .ion-chevron-up:before,
.mobileui .ion-clipboard:before,
.mobileui .ion-clock:before,
.mobileui .ion-close-circled:before,
.mobileui .ion-close-round:before,
.mobileui .ion-close:before,
.mobileui .ion-closed-captioning:before,
.mobileui .ion-cloud:before,
.mobileui .ion-code-download:before,
.mobileui .ion-code-working:before,
.mobileui .ion-code:before,
.mobileui .ion-coffee:before,
.mobileui .ion-compass:before,
.mobileui .ion-compose:before,
.mobileui .ion-connection-bars:before,
.mobileui .ion-contrast:before,
.mobileui .ion-crop:before,
.mobileui .ion-cube:before,
.mobileui .ion-disc:before,
.mobileui .ion-document-text:before,
.mobileui .ion-document:before,
.mobileui .ion-drag:before,
.mobileui .ion-earth:before,
.mobileui .ion-easel:before,
.mobileui .ion-edit:before,
.mobileui .ion-egg:before,
.mobileui .ion-eject:before,
.mobileui .ion-email-unread:before,
.mobileui .ion-email:before,
.mobileui .ion-erlenmeyer-flask-bubbles:before,
.mobileui .ion-erlenmeyer-flask:before,
.mobileui .ion-eye-disabled:before,
.mobileui .ion-eye:before,
.mobileui .ion-female:before,
.mobileui .ion-filing:before,
.mobileui .ion-film-marker:before,
.mobileui .ion-fireball:before,
.mobileui .ion-flag:before,
.mobileui .ion-flame:before,
.mobileui .ion-flash-off:before,
.mobileui .ion-flash:before,
.mobileui .ion-folder:before,
.mobileui .ion-fork-repo:before,
.mobileui .ion-fork:before,
.mobileui .ion-forward:before,
.mobileui .ion-funnel:before,
.mobileui .ion-gear-a:before,
.mobileui .ion-gear-b:before,
.mobileui .ion-grid:before,
.mobileui .ion-hammer:before,
.mobileui .ion-happy-outline:before,
.mobileui .ion-happy:before,
.mobileui .ion-headphone:before,
.mobileui .ion-heart-broken:before,
.mobileui .ion-heart:before,
.mobileui .ion-help-buoy:before,
.mobileui .ion-help-circled:before,
.mobileui .ion-help:before,
.mobileui .ion-home:before,
.mobileui .ion-icecream:before,
.mobileui .ion-image:before,
.mobileui .ion-images:before,
.mobileui .ion-information-circled:before,
.mobileui .ion-information:before,
.mobileui .ion-ionic:before,
.mobileui .ion-ios-alarm-outline:before,
.mobileui .ion-ios-alarm:before,
.mobileui .ion-ios-albums-outline:before,
.mobileui .ion-ios-albums:before,
.mobileui .ion-ios-americanfootball-outline:before,
.mobileui .ion-ios-americanfootball:before,
.mobileui .ion-ios-analytics-outline:before,
.mobileui .ion-ios-analytics:before,
.mobileui .ion-ios-arrow-back:before,
.mobileui .ion-ios-arrow-down:before,
.mobileui .ion-ios-arrow-forward:before,
.mobileui .ion-ios-arrow-left:before,
.mobileui .ion-ios-arrow-right:before,
.mobileui .ion-ios-arrow-thin-down:before,
.mobileui .ion-ios-arrow-thin-left:before,
.mobileui .ion-ios-arrow-thin-right:before,
.mobileui .ion-ios-arrow-thin-up:before,
.mobileui .ion-ios-arrow-up:before,
.mobileui .ion-ios-at-outline:before,
.mobileui .ion-ios-at:before,
.mobileui .ion-ios-barcode-outline:before,
.mobileui .ion-ios-barcode:before,
.mobileui .ion-ios-baseball-outline:before,
.mobileui .ion-ios-baseball:before,
.mobileui .ion-ios-basketball-outline:before,
.mobileui .ion-ios-basketball:before,
.mobileui .ion-ios-bell-outline:before,
.mobileui .ion-ios-bell:before,
.mobileui .ion-ios-body-outline:before,
.mobileui .ion-ios-body:before,
.mobileui .ion-ios-bolt-outline:before,
.mobileui .ion-ios-bolt:before,
.mobileui .ion-ios-book-outline:before,
.mobileui .ion-ios-book:before,
.mobileui .ion-ios-bookmarks-outline:before,
.mobileui .ion-ios-bookmarks:before,
.mobileui .ion-ios-box-outline:before,
.mobileui .ion-ios-box:before,
.mobileui .ion-ios-briefcase-outline:before,
.mobileui .ion-ios-briefcase:before,
.mobileui .ion-ios-browsers-outline:before,
.mobileui .ion-ios-browsers:before,
.mobileui .ion-ios-calculator-outline:before,
.mobileui .ion-ios-calculator:before,
.mobileui .ion-ios-calendar-outline:before,
.mobileui .ion-ios-calendar:before,
.mobileui .ion-ios-camera-outline:before,
.mobileui .ion-ios-camera:before,
.mobileui .ion-ios-cart-outline:before,
.mobileui .ion-ios-cart:before,
.mobileui .ion-ios-chatboxes-outline:before,
.mobileui .ion-ios-chatboxes:before,
.mobileui .ion-ios-chatbubble-outline:before,
.mobileui .ion-ios-chatbubble:before,
.mobileui .ion-ios-checkmark-empty:before,
.mobileui .ion-ios-checkmark-outline:before,
.mobileui .ion-ios-checkmark:before,
.mobileui .ion-ios-circle-filled:before,
.mobileui .ion-ios-circle-outline:before,
.mobileui .ion-ios-clock-outline:before,
.mobileui .ion-ios-clock:before,
.mobileui .ion-ios-close-empty:before,
.mobileui .ion-ios-close-outline:before,
.mobileui .ion-ios-close:before,
.mobileui .ion-ios-cloud-download-outline:before,
.mobileui .ion-ios-cloud-download:before,
.mobileui .ion-ios-cloud-outline:before,
.mobileui .ion-ios-cloud-upload-outline:before,
.mobileui .ion-ios-cloud-upload:before,
.mobileui .ion-ios-cloud:before,
.mobileui .ion-ios-cloudy-night-outline:before,
.mobileui .ion-ios-cloudy-night:before,
.mobileui .ion-ios-cloudy-outline:before,
.mobileui .ion-ios-cloudy:before,
.mobileui .ion-ios-cog-outline:before,
.mobileui .ion-ios-cog:before,
.mobileui .ion-ios-color-filter-outline:before,
.mobileui .ion-ios-color-filter:before,
.mobileui .ion-ios-color-wand-outline:before,
.mobileui .ion-ios-color-wand:before,
.mobileui .ion-ios-compose-outline:before,
.mobileui .ion-ios-compose:before,
.mobileui .ion-ios-contact-outline:before,
.mobileui .ion-ios-contact:before,
.mobileui .ion-ios-copy-outline:before,
.mobileui .ion-ios-copy:before,
.mobileui .ion-ios-crop-strong:before,
.mobileui .ion-ios-crop:before,
.mobileui .ion-ios-download-outline:before,
.mobileui .ion-ios-download:before,
.mobileui .ion-ios-drag:before,
.mobileui .ion-ios-email-outline:before,
.mobileui .ion-ios-email:before,
.mobileui .ion-ios-eye-outline:before,
.mobileui .ion-ios-eye:before,
.mobileui .ion-ios-fastforward-outline:before,
.mobileui .ion-ios-fastforward:before,
.mobileui .ion-ios-filing-outline:before,
.mobileui .ion-ios-filing:before,
.mobileui .ion-ios-film-outline:before,
.mobileui .ion-ios-film:before,
.mobileui .ion-ios-flag-outline:before,
.mobileui .ion-ios-flag:before,
.mobileui .ion-ios-flame-outline:before,
.mobileui .ion-ios-flame:before,
.mobileui .ion-ios-flask-outline:before,
.mobileui .ion-ios-flask:before,
.mobileui .ion-ios-flower-outline:before,
.mobileui .ion-ios-flower:before,
.mobileui .ion-ios-folder-outline:before,
.mobileui .ion-ios-folder:before,
.mobileui .ion-ios-football-outline:before,
.mobileui .ion-ios-football:before,
.mobileui .ion-ios-game-controller-a-outline:before,
.mobileui .ion-ios-game-controller-a:before,
.mobileui .ion-ios-game-controller-b-outline:before,
.mobileui .ion-ios-game-controller-b:before,
.mobileui .ion-ios-gear-outline:before,
.mobileui .ion-ios-gear:before,
.mobileui .ion-ios-glasses-outline:before,
.mobileui .ion-ios-glasses:before,
.mobileui .ion-ios-grid-view-outline:before,
.mobileui .ion-ios-grid-view:before,
.mobileui .ion-ios-heart-outline:before,
.mobileui .ion-ios-heart:before,
.mobileui .ion-ios-help-empty:before,
.mobileui .ion-ios-help-outline:before,
.mobileui .ion-ios-help:before,
.mobileui .ion-ios-home-outline:before,
.mobileui .ion-ios-home:before,
.mobileui .ion-ios-infinite-outline:before,
.mobileui .ion-ios-infinite:before,
.mobileui .ion-ios-information-empty:before,
.mobileui .ion-ios-information-outline:before,
.mobileui .ion-ios-information:before,
.mobileui .ion-ios-ionic-outline:before,
.mobileui .ion-ios-keypad-outline:before,
.mobileui .ion-ios-keypad:before,
.mobileui .ion-ios-lightbulb-outline:before,
.mobileui .ion-ios-lightbulb:before,
.mobileui .ion-ios-list-outline:before,
.mobileui .ion-ios-list:before,
.mobileui .ion-ios-location-outline:before,
.mobileui .ion-ios-location:before,
.mobileui .ion-ios-locked-outline:before,
.mobileui .ion-ios-locked:before,
.mobileui .ion-ios-loop-strong:before,
.mobileui .ion-ios-loop:before,
.mobileui .ion-ios-medical-outline:before,
.mobileui .ion-ios-medical:before,
.mobileui .ion-ios-medkit-outline:before,
.mobileui .ion-ios-medkit:before,
.mobileui .ion-ios-mic-off:before,
.mobileui .ion-ios-mic-outline:before,
.mobileui .ion-ios-mic:before,
.mobileui .ion-ios-minus-empty:before,
.mobileui .ion-ios-minus-outline:before,
.mobileui .ion-ios-minus:before,
.mobileui .ion-ios-monitor-outline:before,
.mobileui .ion-ios-monitor:before,
.mobileui .ion-ios-moon-outline:before,
.mobileui .ion-ios-moon:before,
.mobileui .ion-ios-more-outline:before,
.mobileui .ion-ios-more:before,
.mobileui .ion-ios-musical-note:before,
.mobileui .ion-ios-musical-notes:before,
.mobileui .ion-ios-navigate-outline:before,
.mobileui .ion-ios-navigate:before,
.mobileui .ion-ios-nutrition-outline:before,
.mobileui .ion-ios-nutrition:before,
.mobileui .ion-ios-paper-outline:before,
.mobileui .ion-ios-paper:before,
.mobileui .ion-ios-paperplane-outline:before,
.mobileui .ion-ios-paperplane:before,
.mobileui .ion-ios-partlysunny-outline:before,
.mobileui .ion-ios-partlysunny:before,
.mobileui .ion-ios-pause-outline:before,
.mobileui .ion-ios-pause:before,
.mobileui .ion-ios-paw-outline:before,
.mobileui .ion-ios-paw:before,
.mobileui .ion-ios-people-outline:before,
.mobileui .ion-ios-people:before,
.mobileui .ion-ios-person-outline:before,
.mobileui .ion-ios-person:before,
.mobileui .ion-ios-personadd-outline:before,
.mobileui .ion-ios-personadd:before,
.mobileui .ion-ios-photos-outline:before,
.mobileui .ion-ios-photos:before,
.mobileui .ion-ios-pie-outline:before,
.mobileui .ion-ios-pie:before,
.mobileui .ion-ios-pint-outline:before,
.mobileui .ion-ios-pint:before,
.mobileui .ion-ios-play-outline:before,
.mobileui .ion-ios-play:before,
.mobileui .ion-ios-plus-empty:before,
.mobileui .ion-ios-plus-outline:before,
.mobileui .ion-ios-plus:before,
.mobileui .ion-ios-pricetag-outline:before,
.mobileui .ion-ios-pricetag:before,
.mobileui .ion-ios-pricetags-outline:before,
.mobileui .ion-ios-pricetags:before,
.mobileui .ion-ios-printer-outline:before,
.mobileui .ion-ios-printer:before,
.mobileui .ion-ios-pulse-strong:before,
.mobileui .ion-ios-pulse:before,
.mobileui .ion-ios-rainy-outline:before,
.mobileui .ion-ios-rainy:before,
.mobileui .ion-ios-recording-outline:before,
.mobileui .ion-ios-recording:before,
.mobileui .ion-ios-redo-outline:before,
.mobileui .ion-ios-redo:before,
.mobileui .ion-ios-refresh-empty:before,
.mobileui .ion-ios-refresh-outline:before,
.mobileui .ion-ios-refresh:before,
.mobileui .ion-ios-reload:before,
.mobileui .ion-ios-reverse-camera-outline:before,
.mobileui .ion-ios-reverse-camera:before,
.mobileui .ion-ios-rewind-outline:before,
.mobileui .ion-ios-rewind:before,
.mobileui .ion-ios-rose-outline:before,
.mobileui .ion-ios-rose:before,
.mobileui .ion-ios-search-strong:before,
.mobileui .ion-ios-search:before,
.mobileui .ion-ios-settings-strong:before,
.mobileui .ion-ios-settings:before,
.mobileui .ion-ios-shuffle-strong:before,
.mobileui .ion-ios-shuffle:before,
.mobileui .ion-ios-skipbackward-outline:before,
.mobileui .ion-ios-skipbackward:before,
.mobileui .ion-ios-skipforward-outline:before,
.mobileui .ion-ios-skipforward:before,
.mobileui .ion-ios-snowy:before,
.mobileui .ion-ios-speedometer-outline:before,
.mobileui .ion-ios-speedometer:before,
.mobileui .ion-ios-star-half:before,
.mobileui .ion-ios-star-outline:before,
.mobileui .ion-ios-star:before,
.mobileui .ion-ios-stopwatch-outline:before,
.mobileui .ion-ios-stopwatch:before,
.mobileui .ion-ios-sunny-outline:before,
.mobileui .ion-ios-sunny:before,
.mobileui .ion-ios-telephone-outline:before,
.mobileui .ion-ios-telephone:before,
.mobileui .ion-ios-tennisball-outline:before,
.mobileui .ion-ios-tennisball:before,
.mobileui .ion-ios-thunderstorm-outline:before,
.mobileui .ion-ios-thunderstorm:before,
.mobileui .ion-ios-time-outline:before,
.mobileui .ion-ios-time:before,
.mobileui .ion-ios-timer-outline:before,
.mobileui .ion-ios-timer:before,
.mobileui .ion-ios-toggle-outline:before,
.mobileui .ion-ios-toggle:before,
.mobileui .ion-ios-trash-outline:before,
.mobileui .ion-ios-trash:before,
.mobileui .ion-ios-undo-outline:before,
.mobileui .ion-ios-undo:before,
.mobileui .ion-ios-unlocked-outline:before,
.mobileui .ion-ios-unlocked:before,
.mobileui .ion-ios-upload-outline:before,
.mobileui .ion-ios-upload:before,
.mobileui .ion-ios-videocam-outline:before,
.mobileui .ion-ios-videocam:before,
.mobileui .ion-ios-volume-high:before,
.mobileui .ion-ios-volume-low:before,
.mobileui .ion-ios-wineglass-outline:before,
.mobileui .ion-ios-wineglass:before,
.mobileui .ion-ios-world-outline:before,
.mobileui .ion-ios-world:before,
.mobileui .ion-ipad:before,
.mobileui .ion-iphone:before,
.mobileui .ion-ipod:before,
.mobileui .ion-jet:before,
.mobileui .ion-key:before,
.mobileui .ion-knife:before,
.mobileui .ion-laptop:before,
.mobileui .ion-leaf:before,
.mobileui .ion-levels:before,
.mobileui .ion-lightbulb:before,
.mobileui .ion-link:before,
.mobileui .ion-load-a:before,
.mobileui .ion-load-b:before,
.mobileui .ion-load-c:before,
.mobileui .ion-load-d:before,
.mobileui .ion-location:before,
.mobileui .ion-lock-combination:before,
.mobileui .ion-locked:before,
.mobileui .ion-log-in:before,
.mobileui .ion-log-out:before,
.mobileui .ion-loop:before,
.mobileui .ion-magnet:before,
.mobileui .ion-male:before,
.mobileui .ion-man:before,
.mobileui .ion-map:before,
.mobileui .ion-medkit:before,
.mobileui .ion-merge:before,
.mobileui .ion-mic-a:before,
.mobileui .ion-mic-b:before,
.mobileui .ion-mic-c:before,
.mobileui .ion-minus-circled:before,
.mobileui .ion-minus-round:before,
.mobileui .ion-minus:before,
.mobileui .ion-model-s:before,
.mobileui .ion-monitor:before,
.mobileui .ion-more:before,
.mobileui .ion-mouse:before,
.mobileui .ion-music-note:before,
.mobileui .ion-navicon-round:before,
.mobileui .ion-navicon:before,
.mobileui .ion-navigate:before,
.mobileui .ion-network:before,
.mobileui .ion-no-smoking:before,
.mobileui .ion-nuclear:before,
.mobileui .ion-outlet:before,
.mobileui .ion-paintbrush:before,
.mobileui .ion-paintbucket:before,
.mobileui .ion-paper-airplane:before,
.mobileui .ion-paperclip:before,
.mobileui .ion-pause:before,
.mobileui .ion-person-add:before,
.mobileui .ion-person-stalker:before,
.mobileui .ion-person:before,
.mobileui .ion-pie-graph:before,
.mobileui .ion-pin:before,
.mobileui .ion-pinpoint:before,
.mobileui .ion-pizza:before,
.mobileui .ion-plane:before,
.mobileui .ion-planet:before,
.mobileui .ion-play:before,
.mobileui .ion-playstation:before,
.mobileui .ion-plus-circled:before,
.mobileui .ion-plus-round:before,
.mobileui .ion-plus:before,
.mobileui .ion-podium:before,
.mobileui .ion-pound:before,
.mobileui .ion-power:before,
.mobileui .ion-pricetag:before,
.mobileui .ion-pricetags:before,
.mobileui .ion-printer:before,
.mobileui .ion-pull-request:before,
.mobileui .ion-qr-scanner:before,
.mobileui .ion-quote:before,
.mobileui .ion-radio-waves:before,
.mobileui .ion-record:before,
.mobileui .ion-refresh:before,
.mobileui .ion-reply-all:before,
.mobileui .ion-reply:before,
.mobileui .ion-ribbon-a:before,
.mobileui .ion-ribbon-b:before,
.mobileui .ion-sad-outline:before,
.mobileui .ion-sad:before,
.mobileui .ion-scissors:before,
.mobileui .ion-search:before,
.mobileui .ion-settings:before,
.mobileui .ion-share:before,
.mobileui .ion-shuffle:before,
.mobileui .ion-skip-backward:before,
.mobileui .ion-skip-forward:before,
.mobileui .ion-social-android-outline:before,
.mobileui .ion-social-android:before,
.mobileui .ion-social-angular-outline:before,
.mobileui .ion-social-angular:before,
.mobileui .ion-social-apple-outline:before,
.mobileui .ion-social-apple:before,
.mobileui .ion-social-bitcoin-outline:before,
.mobileui .ion-social-bitcoin:before,
.mobileui .ion-social-buffer-outline:before,
.mobileui .ion-social-buffer:before,
.mobileui .ion-social-chrome-outline:before,
.mobileui .ion-social-chrome:before,
.mobileui .ion-social-codepen-outline:before,
.mobileui .ion-social-codepen:before,
.mobileui .ion-social-css3-outline:before,
.mobileui .ion-social-css3:before,
.mobileui .ion-social-designernews-outline:before,
.mobileui .ion-social-designernews:before,
.mobileui .ion-social-dribbble-outline:before,
.mobileui .ion-social-dribbble:before,
.mobileui .ion-social-dropbox-outline:before,
.mobileui .ion-social-dropbox:before,
.mobileui .ion-social-euro-outline:before,
.mobileui .ion-social-euro:before,
.mobileui .ion-social-facebook-outline:before,
.mobileui .ion-social-facebook:before,
.mobileui .ion-social-foursquare-outline:before,
.mobileui .ion-social-foursquare:before,
.mobileui .ion-social-freebsd-devil:before,
.mobileui .ion-social-github-outline:before,
.mobileui .ion-social-github:before,
.mobileui .ion-social-google-outline:before,
.mobileui .ion-social-google:before,
.mobileui .ion-social-googleplus-outline:before,
.mobileui .ion-social-googleplus:before,
.mobileui .ion-social-hackernews-outline:before,
.mobileui .ion-social-hackernews:before,
.mobileui .ion-social-html5-outline:before,
.mobileui .ion-social-html5:before,
.mobileui .ion-social-instagram-outline:before,
.mobileui .ion-social-instagram:before,
.mobileui .ion-social-javascript-outline:before,
.mobileui .ion-social-javascript:before,
.mobileui .ion-social-linkedin-outline:before,
.mobileui .ion-social-linkedin:before,
.mobileui .ion-social-markdown:before,
.mobileui .ion-social-nodejs:before,
.mobileui .ion-social-octocat:before,
.mobileui .ion-social-pinterest-outline:before,
.mobileui .ion-social-pinterest:before,
.mobileui .ion-social-python:before,
.mobileui .ion-social-reddit-outline:before,
.mobileui .ion-social-reddit:before,
.mobileui .ion-social-rss-outline:before,
.mobileui .ion-social-rss:before,
.mobileui .ion-social-sass:before,
.mobileui .ion-social-skype-outline:before,
.mobileui .ion-social-skype:before,
.mobileui .ion-social-snapchat-outline:before,
.mobileui .ion-social-snapchat:before,
.mobileui .ion-social-tumblr-outline:before,
.mobileui .ion-social-tumblr:before,
.mobileui .ion-social-tux:before,
.mobileui .ion-social-twitch-outline:before,
.mobileui .ion-social-twitch:before,
.mobileui .ion-social-twitter-outline:before,
.mobileui .ion-social-twitter:before,
.mobileui .ion-social-usd-outline:before,
.mobileui .ion-social-usd:before,
.mobileui .ion-social-vimeo-outline:before,
.mobileui .ion-social-vimeo:before,
.mobileui .ion-social-whatsapp-outline:before,
.mobileui .ion-social-whatsapp:before,
.mobileui .ion-social-windows-outline:before,
.mobileui .ion-social-windows:before,
.mobileui .ion-social-wordpress-outline:before,
.mobileui .ion-social-wordpress:before,
.mobileui .ion-social-yahoo-outline:before,
.mobileui .ion-social-yahoo:before,
.mobileui .ion-social-yen-outline:before,
.mobileui .ion-social-yen:before,
.mobileui .ion-social-youtube-outline:before,
.mobileui .ion-social-youtube:before,
.mobileui .ion-soup-can-outline:before,
.mobileui .ion-soup-can:before,
.mobileui .ion-speakerphone:before,
.mobileui .ion-speedometer:before,
.mobileui .ion-spoon:before,
.mobileui .ion-star:before,
.mobileui .ion-stats-bars:before,
.mobileui .ion-steam:before,
.mobileui .ion-stop:before,
.mobileui .ion-thermometer:before,
.mobileui .ion-thumbsdown:before,
.mobileui .ion-thumbsup:before,
.mobileui .ion-toggle-filled:before,
.mobileui .ion-toggle:before,
.mobileui .ion-transgender:before,
.mobileui .ion-trash-a:before,
.mobileui .ion-trash-b:before,
.mobileui .ion-trophy:before,
.mobileui .ion-tshirt-outline:before,
.mobileui .ion-tshirt:before,
.mobileui .ion-umbrella:before,
.mobileui .ion-university:before,
.mobileui .ion-unlocked:before,
.mobileui .ion-upload:before,
.mobileui .ion-usb:before,
.mobileui .ion-videocamera:before,
.mobileui .ion-volume-high:before,
.mobileui .ion-volume-low:before,
.mobileui .ion-volume-medium:before,
.mobileui .ion-volume-mute:before,
.mobileui .ion-wand:before,
.mobileui .ion-waterdrop:before,
.mobileui .ion-wifi:before,
.mobileui .ion-wineglass:before,
.mobileui .ion-woman:before,
.mobileui .ion-wrench:before,
.mobileui .ion-xbox:before,
.mobileui .ionicons {
  display: inline-block;
  font-family: Ionicons;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  text-rendering: auto;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 22px;
  text-align: center;
}
.mobileui .ion-alert:before {
  content: "";
}
.mobileui .ion-alert-circled:before {
  content: "";
}
.mobileui .ion-android-add:before {
  content: "";
}
.mobileui .ion-android-add-circle:before {
  content: "";
}
.mobileui .ion-android-alarm-clock:before {
  content: "";
}
.mobileui .ion-android-alert:before {
  content: "";
}
.mobileui .ion-android-apps:before {
  content: "";
}
.mobileui .ion-android-archive:before {
  content: "";
}
.mobileui .ion-android-arrow-back:before {
  content: "";
}
.mobileui .ion-android-arrow-down:before {
  content: "";
}
.mobileui .ion-android-arrow-dropdown:before {
  content: "";
}
.mobileui .ion-android-arrow-dropdown-circle:before {
  content: "";
}
.mobileui .ion-android-arrow-dropleft:before {
  content: "";
}
.mobileui .ion-android-arrow-dropleft-circle:before {
  content: "";
}
.mobileui .ion-android-arrow-dropright:before {
  content: "";
}
.mobileui .ion-android-arrow-dropright-circle:before {
  content: "";
}
.mobileui .ion-android-arrow-dropup:before {
  content: "";
}
.mobileui .ion-android-arrow-dropup-circle:before {
  content: "";
}
.mobileui .ion-android-arrow-forward:before {
  content: "";
}
.mobileui .ion-android-arrow-up:before {
  content: "";
}
.mobileui .ion-android-attach:before {
  content: "";
}
.mobileui .ion-android-bar:before {
  content: "";
}
.mobileui .ion-android-bicycle:before {
  content: "";
}
.mobileui .ion-android-boat:before {
  content: "";
}
.mobileui .ion-android-bookmark:before {
  content: "";
}
.mobileui .ion-android-bulb:before {
  content: "";
}
.mobileui .ion-android-bus:before {
  content: "";
}
.mobileui .ion-android-calendar:before {
  content: "";
}
.mobileui .ion-android-call:before {
  content: "";
}
.mobileui .ion-android-camera:before {
  content: "";
}
.mobileui .ion-android-cancel:before {
  content: "";
}
.mobileui .ion-android-car:before {
  content: "";
}
.mobileui .ion-android-cart:before {
  content: "";
}
.mobileui .ion-android-chat:before {
  content: "";
}
.mobileui .ion-android-checkbox:before {
  content: "";
}
.mobileui .ion-android-checkbox-blank:before {
  content: "";
}
.mobileui .ion-android-checkbox-outline:before {
  content: "";
}
.mobileui .ion-android-checkbox-outline-blank:before {
  content: "";
}
.mobileui .ion-android-checkmark-circle:before {
  content: "";
}
.mobileui .ion-android-clipboard:before {
  content: "";
}
.mobileui .ion-android-close:before {
  content: "";
}
.mobileui .ion-android-cloud:before {
  content: "";
}
.mobileui .ion-android-cloud-circle:before {
  content: "";
}
.mobileui .ion-android-cloud-done:before {
  content: "";
}
.mobileui .ion-android-cloud-outline:before {
  content: "";
}
.mobileui .ion-android-color-palette:before {
  content: "";
}
.mobileui .ion-android-compass:before {
  content: "";
}
.mobileui .ion-android-contact:before {
  content: "";
}
.mobileui .ion-android-contacts:before {
  content: "";
}
.mobileui .ion-android-contract:before {
  content: "";
}
.mobileui .ion-android-create:before {
  content: "";
}
.mobileui .ion-android-delete:before {
  content: "";
}
.mobileui .ion-android-desktop:before {
  content: "";
}
.mobileui .ion-android-document:before {
  content: "";
}
.mobileui .ion-android-done:before {
  content: "";
}
.mobileui .ion-android-done-all:before {
  content: "";
}
.mobileui .ion-android-download:before {
  content: "";
}
.mobileui .ion-android-drafts:before {
  content: "";
}
.mobileui .ion-android-exit:before {
  content: "";
}
.mobileui .ion-android-expand:before {
  content: "";
}
.mobileui .ion-android-favorite:before {
  content: "";
}
.mobileui .ion-android-favorite-outline:before {
  content: "";
}
.mobileui .ion-android-film:before {
  content: "";
}
.mobileui .ion-android-folder:before {
  content: "";
}
.mobileui .ion-android-folder-open:before {
  content: "";
}
.mobileui .ion-android-funnel:before {
  content: "";
}
.mobileui .ion-android-globe:before {
  content: "";
}
.mobileui .ion-android-hand:before {
  content: "";
}
.mobileui .ion-android-hangout:before {
  content: "";
}
.mobileui .ion-android-happy:before {
  content: "";
}
.mobileui .ion-android-home:before {
  content: "";
}
.mobileui .ion-android-image:before {
  content: "";
}
.mobileui .ion-android-laptop:before {
  content: "";
}
.mobileui .ion-android-list:before {
  content: "";
}
.mobileui .ion-android-locate:before {
  content: "";
}
.mobileui .ion-android-lock:before {
  content: "";
}
.mobileui .ion-android-mail:before {
  content: "";
}
.mobileui .ion-android-map:before {
  content: "";
}
.mobileui .ion-android-menu:before {
  content: "";
}
.mobileui .ion-android-microphone:before {
  content: "";
}
.mobileui .ion-android-microphone-off:before {
  content: "";
}
.mobileui .ion-android-more-horizontal:before {
  content: "";
}
.mobileui .ion-android-more-vertical:before {
  content: "";
}
.mobileui .ion-android-navigate:before {
  content: "";
}
.mobileui .ion-android-notifications:before {
  content: "";
}
.mobileui .ion-android-notifications-none:before {
  content: "";
}
.mobileui .ion-android-notifications-off:before {
  content: "";
}
.mobileui .ion-android-open:before {
  content: "";
}
.mobileui .ion-android-options:before {
  content: "";
}
.mobileui .ion-android-people:before {
  content: "";
}
.mobileui .ion-android-person:before {
  content: "";
}
.mobileui .ion-android-person-add:before {
  content: "";
}
.mobileui .ion-android-phone-landscape:before {
  content: "";
}
.mobileui .ion-android-phone-portrait:before {
  content: "";
}
.mobileui .ion-android-pin:before {
  content: "";
}
.mobileui .ion-android-plane:before {
  content: "";
}
.mobileui .ion-android-playstore:before {
  content: "";
}
.mobileui .ion-android-print:before {
  content: "";
}
.mobileui .ion-android-radio-button-off:before {
  content: "";
}
.mobileui .ion-android-radio-button-on:before {
  content: "";
}
.mobileui .ion-android-refresh:before {
  content: "";
}
.mobileui .ion-android-remove:before {
  content: "";
}
.mobileui .ion-android-remove-circle:before {
  content: "";
}
.mobileui .ion-android-restaurant:before {
  content: "";
}
.mobileui .ion-android-sad:before {
  content: "";
}
.mobileui .ion-android-search:before {
  content: "";
}
.mobileui .ion-android-send:before {
  content: "";
}
.mobileui .ion-android-settings:before {
  content: "";
}
.mobileui .ion-android-share:before {
  content: "";
}
.mobileui .ion-android-share-alt:before {
  content: "";
}
.mobileui .ion-android-star:before {
  content: "";
}
.mobileui .ion-android-star-half:before {
  content: "";
}
.mobileui .ion-android-star-outline:before {
  content: "";
}
.mobileui .ion-android-stopwatch:before {
  content: "";
}
.mobileui .ion-android-subway:before {
  content: "";
}
.mobileui .ion-android-sunny:before {
  content: "";
}
.mobileui .ion-android-sync:before {
  content: "";
}
.mobileui .ion-android-textsms:before {
  content: "";
}
.mobileui .ion-android-time:before {
  content: "";
}
.mobileui .ion-android-train:before {
  content: "";
}
.mobileui .ion-android-unlock:before {
  content: "";
}
.mobileui .ion-android-upload:before {
  content: "";
}
.mobileui .ion-android-volume-down:before {
  content: "";
}
.mobileui .ion-android-volume-mute:before {
  content: "";
}
.mobileui .ion-android-volume-off:before {
  content: "";
}
.mobileui .ion-android-volume-up:before {
  content: "";
}
.mobileui .ion-android-walk:before {
  content: "";
}
.mobileui .ion-android-warning:before {
  content: "";
}
.mobileui .ion-android-watch:before {
  content: "";
}
.mobileui .ion-android-wifi:before {
  content: "";
}
.mobileui .ion-aperture:before {
  content: "";
}
.mobileui .ion-archive:before {
  content: "";
}
.mobileui .ion-arrow-down-a:before {
  content: "";
}
.mobileui .ion-arrow-down-b:before {
  content: "";
}
.mobileui .ion-arrow-down-c:before {
  content: "";
}
.mobileui .ion-arrow-expand:before {
  content: "";
}
.mobileui .ion-arrow-graph-down-left:before {
  content: "";
}
.mobileui .ion-arrow-graph-down-right:before {
  content: "";
}
.mobileui .ion-arrow-graph-up-left:before {
  content: "";
}
.mobileui .ion-arrow-graph-up-right:before {
  content: "";
}
.mobileui .ion-arrow-left-a:before {
  content: "";
}
.mobileui .ion-arrow-left-b:before {
  content: "";
}
.mobileui .ion-arrow-left-c:before {
  content: "";
}
.mobileui .ion-arrow-move:before {
  content: "";
}
.mobileui .ion-arrow-resize:before {
  content: "";
}
.mobileui .ion-arrow-return-left:before {
  content: "";
}
.mobileui .ion-arrow-return-right:before {
  content: "";
}
.mobileui .ion-arrow-right-a:before {
  content: "";
}
.mobileui .ion-arrow-right-b:before {
  content: "";
}
.mobileui .ion-arrow-right-c:before {
  content: "";
}
.mobileui .ion-arrow-shrink:before {
  content: "";
}
.mobileui .ion-arrow-swap:before {
  content: "";
}
.mobileui .ion-arrow-up-a:before {
  content: "";
}
.mobileui .ion-arrow-up-b:before {
  content: "";
}
.mobileui .ion-arrow-up-c:before {
  content: "";
}
.mobileui .ion-asterisk:before {
  content: "";
}
.mobileui .ion-at:before {
  content: "";
}
.mobileui .ion-backspace:before {
  content: "";
}
.mobileui .ion-backspace-outline:before {
  content: "";
}
.mobileui .ion-bag:before {
  content: "";
}
.mobileui .ion-battery-charging:before {
  content: "";
}
.mobileui .ion-battery-empty:before {
  content: "";
}
.mobileui .ion-battery-full:before {
  content: "";
}
.mobileui .ion-battery-half:before {
  content: "";
}
.mobileui .ion-battery-low:before {
  content: "";
}
.mobileui .ion-beaker:before {
  content: "";
}
.mobileui .ion-beer:before {
  content: "";
}
.mobileui .ion-bluetooth:before {
  content: "";
}
.mobileui .ion-bonfire:before {
  content: "";
}
.mobileui .ion-bookmark:before {
  content: "";
}
.mobileui .ion-bowtie:before {
  content: "";
}
.mobileui .ion-briefcase:before {
  content: "";
}
.mobileui .ion-bug:before {
  content: "";
}
.mobileui .ion-calculator:before {
  content: "";
}
.mobileui .ion-calendar:before {
  content: "";
}
.mobileui .ion-camera:before {
  content: "";
}
.mobileui .ion-card:before {
  content: "";
}
.mobileui .ion-cash:before {
  content: "";
}
.mobileui .ion-chatbox:before {
  content: "";
}
.mobileui .ion-chatbox-working:before {
  content: "";
}
.mobileui .ion-chatboxes:before {
  content: "";
}
.mobileui .ion-chatbubble:before {
  content: "";
}
.mobileui .ion-chatbubble-working:before {
  content: "";
}
.mobileui .ion-chatbubbles:before {
  content: "";
}
.mobileui .ion-checkmark:before {
  content: "";
}
.mobileui .ion-checkmark-circled:before {
  content: "";
}
.mobileui .ion-checkmark-round:before {
  content: "";
}
.mobileui .ion-chevron-down:before {
  content: "";
}
.mobileui .ion-chevron-left:before {
  content: "";
}
.mobileui .ion-chevron-right:before {
  content: "";
}
.mobileui .ion-chevron-up:before {
  content: "";
}
.mobileui .ion-clipboard:before {
  content: "";
}
.mobileui .ion-clock:before {
  content: "";
}
.mobileui .ion-close:before {
  content: "";
}
.mobileui .ion-close-circled:before {
  content: "";
}
.mobileui .ion-close-round:before {
  content: "";
}
.mobileui .ion-closed-captioning:before {
  content: "";
}
.mobileui .ion-cloud:before {
  content: "";
}
.mobileui .ion-code:before {
  content: "";
}
.mobileui .ion-code-download:before {
  content: "";
}
.mobileui .ion-code-working:before {
  content: "";
}
.mobileui .ion-coffee:before {
  content: "";
}
.mobileui .ion-compass:before {
  content: "";
}
.mobileui .ion-compose:before {
  content: "";
}
.mobileui .ion-connection-bars:before {
  content: "";
}
.mobileui .ion-contrast:before {
  content: "";
}
.mobileui .ion-crop:before {
  content: "";
}
.mobileui .ion-cube:before {
  content: "";
}
.mobileui .ion-disc:before {
  content: "";
}
.mobileui .ion-document:before {
  content: "";
}
.mobileui .ion-document-text:before {
  content: "";
}
.mobileui .ion-drag:before {
  content: "";
}
.mobileui .ion-earth:before {
  content: "";
}
.mobileui .ion-easel:before {
  content: "";
}
.mobileui .ion-edit:before {
  content: "";
}
.mobileui .ion-egg:before {
  content: "";
}
.mobileui .ion-eject:before {
  content: "";
}
.mobileui .ion-email:before {
  content: "";
}
.mobileui .ion-email-unread:before {
  content: "";
}
.mobileui .ion-erlenmeyer-flask:before {
  content: "";
}
.mobileui .ion-erlenmeyer-flask-bubbles:before {
  content: "";
}
.mobileui .ion-eye:before {
  content: "";
}
.mobileui .ion-eye-disabled:before {
  content: "";
}
.mobileui .ion-female:before {
  content: "";
}
.mobileui .ion-filing:before {
  content: "";
}
.mobileui .ion-film-marker:before {
  content: "";
}
.mobileui .ion-fireball:before {
  content: "";
}
.mobileui .ion-flag:before {
  content: "";
}
.mobileui .ion-flame:before {
  content: "";
}
.mobileui .ion-flash:before {
  content: "";
}
.mobileui .ion-flash-off:before {
  content: "";
}
.mobileui .ion-folder:before {
  content: "";
}
.mobileui .ion-fork:before {
  content: "";
}
.mobileui .ion-fork-repo:before {
  content: "";
}
.mobileui .ion-forward:before {
  content: "";
}
.mobileui .ion-funnel:before {
  content: "";
}
.mobileui .ion-gear-a:before {
  content: "";
}
.mobileui .ion-gear-b:before {
  content: "";
}
.mobileui .ion-grid:before {
  content: "";
}
.mobileui .ion-hammer:before {
  content: "";
}
.mobileui .ion-happy:before {
  content: "";
}
.mobileui .ion-happy-outline:before {
  content: "";
}
.mobileui .ion-headphone:before {
  content: "";
}
.mobileui .ion-heart:before {
  content: "";
}
.mobileui .ion-heart-broken:before {
  content: "";
}
.mobileui .ion-help:before {
  content: "";
}
.mobileui .ion-help-buoy:before {
  content: "";
}
.mobileui .ion-help-circled:before {
  content: "";
}
.mobileui .ion-home:before {
  content: "";
}
.mobileui .ion-icecream:before {
  content: "";
}
.mobileui .ion-image:before {
  content: "";
}
.mobileui .ion-images:before {
  content: "";
}
.mobileui .ion-information:before {
  content: "";
}
.mobileui .ion-information-circled:before {
  content: "";
}
.mobileui .ion-ionic:before {
  content: "";
}
.mobileui .ion-ios-alarm:before {
  content: "";
}
.mobileui .ion-ios-alarm-outline:before {
  content: "";
}
.mobileui .ion-ios-albums:before {
  content: "";
}
.mobileui .ion-ios-albums-outline:before {
  content: "";
}
.mobileui .ion-ios-americanfootball:before {
  content: "";
}
.mobileui .ion-ios-americanfootball-outline:before {
  content: "";
}
.mobileui .ion-ios-analytics:before {
  content: "";
}
.mobileui .ion-ios-analytics-outline:before {
  content: "";
}
.mobileui .ion-ios-arrow-back:before {
  content: "";
}
.mobileui .ion-ios-arrow-down:before {
  content: "";
}
.mobileui .ion-ios-arrow-forward:before {
  content: "";
}
.mobileui .ion-ios-arrow-left:before {
  content: "";
}
.mobileui .ion-ios-arrow-right:before {
  content: "";
}
.mobileui .ion-ios-arrow-thin-down:before {
  content: "";
}
.mobileui .ion-ios-arrow-thin-left:before {
  content: "";
}
.mobileui .ion-ios-arrow-thin-right:before {
  content: "";
}
.mobileui .ion-ios-arrow-thin-up:before {
  content: "";
}
.mobileui .ion-ios-arrow-up:before {
  content: "";
}
.mobileui .ion-ios-at:before {
  content: "";
}
.mobileui .ion-ios-at-outline:before {
  content: "";
}
.mobileui .ion-ios-barcode:before {
  content: "";
}
.mobileui .ion-ios-barcode-outline:before {
  content: "";
}
.mobileui .ion-ios-baseball:before {
  content: "";
}
.mobileui .ion-ios-baseball-outline:before {
  content: "";
}
.mobileui .ion-ios-basketball:before {
  content: "";
}
.mobileui .ion-ios-basketball-outline:before {
  content: "";
}
.mobileui .ion-ios-bell:before {
  content: "";
}
.mobileui .ion-ios-bell-outline:before {
  content: "";
}
.mobileui .ion-ios-body:before {
  content: "";
}
.mobileui .ion-ios-body-outline:before {
  content: "";
}
.mobileui .ion-ios-bolt:before {
  content: "";
}
.mobileui .ion-ios-bolt-outline:before {
  content: "";
}
.mobileui .ion-ios-book:before {
  content: "";
}
.mobileui .ion-ios-book-outline:before {
  content: "";
}
.mobileui .ion-ios-bookmarks:before {
  content: "";
}
.mobileui .ion-ios-bookmarks-outline:before {
  content: "";
}
.mobileui .ion-ios-box:before {
  content: "";
}
.mobileui .ion-ios-box-outline:before {
  content: "";
}
.mobileui .ion-ios-briefcase:before {
  content: "";
}
.mobileui .ion-ios-briefcase-outline:before {
  content: "";
}
.mobileui .ion-ios-browsers:before {
  content: "";
}
.mobileui .ion-ios-browsers-outline:before {
  content: "";
}
.mobileui .ion-ios-calculator:before {
  content: "";
}
.mobileui .ion-ios-calculator-outline:before {
  content: "";
}
.mobileui .ion-ios-calendar:before {
  content: "";
}
.mobileui .ion-ios-calendar-outline:before {
  content: "";
}
.mobileui .ion-ios-camera:before {
  content: "";
}
.mobileui .ion-ios-camera-outline:before {
  content: "";
}
.mobileui .ion-ios-cart:before {
  content: "";
}
.mobileui .ion-ios-cart-outline:before {
  content: "";
}
.mobileui .ion-ios-chatboxes:before {
  content: "";
}
.mobileui .ion-ios-chatboxes-outline:before {
  content: "";
}
.mobileui .ion-ios-chatbubble:before {
  content: "";
}
.mobileui .ion-ios-chatbubble-outline:before {
  content: "";
}
.mobileui .ion-ios-checkmark:before {
  content: "";
}
.mobileui .ion-ios-checkmark-empty:before {
  content: "";
}
.mobileui .ion-ios-checkmark-outline:before {
  content: "";
}
.mobileui .ion-ios-circle-filled:before {
  content: "";
}
.mobileui .ion-ios-circle-outline:before {
  content: "";
}
.mobileui .ion-ios-clock:before {
  content: "";
}
.mobileui .ion-ios-clock-outline:before {
  content: "";
}
.mobileui .ion-ios-close:before {
  content: "";
}
.mobileui .ion-ios-close-empty:before {
  content: "";
}
.mobileui .ion-ios-close-outline:before {
  content: "";
}
.mobileui .ion-ios-cloud:before {
  content: "";
}
.mobileui .ion-ios-cloud-download:before {
  content: "";
}
.mobileui .ion-ios-cloud-download-outline:before {
  content: "";
}
.mobileui .ion-ios-cloud-outline:before {
  content: "";
}
.mobileui .ion-ios-cloud-upload:before {
  content: "";
}
.mobileui .ion-ios-cloud-upload-outline:before {
  content: "";
}
.mobileui .ion-ios-cloudy:before {
  content: "";
}
.mobileui .ion-ios-cloudy-night:before {
  content: "";
}
.mobileui .ion-ios-cloudy-night-outline:before {
  content: "";
}
.mobileui .ion-ios-cloudy-outline:before {
  content: "";
}
.mobileui .ion-ios-cog:before {
  content: "";
}
.mobileui .ion-ios-cog-outline:before {
  content: "";
}
.mobileui .ion-ios-color-filter:before {
  content: "";
}
.mobileui .ion-ios-color-filter-outline:before {
  content: "";
}
.mobileui .ion-ios-color-wand:before {
  content: "";
}
.mobileui .ion-ios-color-wand-outline:before {
  content: "";
}
.mobileui .ion-ios-compose:before {
  content: "";
}
.mobileui .ion-ios-compose-outline:before {
  content: "";
}
.mobileui .ion-ios-contact:before {
  content: "";
}
.mobileui .ion-ios-contact-outline:before {
  content: "";
}
.mobileui .ion-ios-copy:before {
  content: "";
}
.mobileui .ion-ios-copy-outline:before {
  content: "";
}
.mobileui .ion-ios-crop:before {
  content: "";
}
.mobileui .ion-ios-crop-strong:before {
  content: "";
}
.mobileui .ion-ios-download:before {
  content: "";
}
.mobileui .ion-ios-download-outline:before {
  content: "";
}
.mobileui .ion-ios-drag:before {
  content: "";
}
.mobileui .ion-ios-email:before {
  content: "";
}
.mobileui .ion-ios-email-outline:before {
  content: "";
}
.mobileui .ion-ios-eye:before {
  content: "";
}
.mobileui .ion-ios-eye-outline:before {
  content: "";
}
.mobileui .ion-ios-fastforward:before {
  content: "";
}
.mobileui .ion-ios-fastforward-outline:before {
  content: "";
}
.mobileui .ion-ios-filing:before {
  content: "";
}
.mobileui .ion-ios-filing-outline:before {
  content: "";
}
.mobileui .ion-ios-film:before {
  content: "";
}
.mobileui .ion-ios-film-outline:before {
  content: "";
}
.mobileui .ion-ios-flag:before {
  content: "";
}
.mobileui .ion-ios-flag-outline:before {
  content: "";
}
.mobileui .ion-ios-flame:before {
  content: "";
}
.mobileui .ion-ios-flame-outline:before {
  content: "";
}
.mobileui .ion-ios-flask:before {
  content: "";
}
.mobileui .ion-ios-flask-outline:before {
  content: "";
}
.mobileui .ion-ios-flower:before {
  content: "";
}
.mobileui .ion-ios-flower-outline:before {
  content: "";
}
.mobileui .ion-ios-folder:before {
  content: "";
}
.mobileui .ion-ios-folder-outline:before {
  content: "";
}
.mobileui .ion-ios-football:before {
  content: "";
}
.mobileui .ion-ios-football-outline:before {
  content: "";
}
.mobileui .ion-ios-game-controller-a:before {
  content: "";
}
.mobileui .ion-ios-game-controller-a-outline:before {
  content: "";
}
.mobileui .ion-ios-game-controller-b:before {
  content: "";
}
.mobileui .ion-ios-game-controller-b-outline:before {
  content: "";
}
.mobileui .ion-ios-gear:before {
  content: "";
}
.mobileui .ion-ios-gear-outline:before {
  content: "";
}
.mobileui .ion-ios-glasses:before {
  content: "";
}
.mobileui .ion-ios-glasses-outline:before {
  content: "";
}
.mobileui .ion-ios-grid-view:before {
  content: "";
}
.mobileui .ion-ios-grid-view-outline:before {
  content: "";
}
.mobileui .ion-ios-heart:before {
  content: "";
}
.mobileui .ion-ios-heart-outline:before {
  content: "";
}
.mobileui .ion-ios-help:before {
  content: "";
}
.mobileui .ion-ios-help-empty:before {
  content: "";
}
.mobileui .ion-ios-help-outline:before {
  content: "";
}
.mobileui .ion-ios-home:before {
  content: "";
}
.mobileui .ion-ios-home-outline:before {
  content: "";
}
.mobileui .ion-ios-infinite:before {
  content: "";
}
.mobileui .ion-ios-infinite-outline:before {
  content: "";
}
.mobileui .ion-ios-information:before {
  content: "";
}
.mobileui .ion-ios-information-empty:before {
  content: "";
}
.mobileui .ion-ios-information-outline:before {
  content: "";
}
.mobileui .ion-ios-ionic-outline:before {
  content: "";
}
.mobileui .ion-ios-keypad:before {
  content: "";
}
.mobileui .ion-ios-keypad-outline:before {
  content: "";
}
.mobileui .ion-ios-lightbulb:before {
  content: "";
}
.mobileui .ion-ios-lightbulb-outline:before {
  content: "";
}
.mobileui .ion-ios-list:before {
  content: "";
}
.mobileui .ion-ios-list-outline:before {
  content: "";
}
.mobileui .ion-ios-location:before {
  content: "";
}
.mobileui .ion-ios-location-outline:before {
  content: "";
}
.mobileui .ion-ios-locked:before {
  content: "";
}
.mobileui .ion-ios-locked-outline:before {
  content: "";
}
.mobileui .ion-ios-loop:before {
  content: "";
}
.mobileui .ion-ios-loop-strong:before {
  content: "";
}
.mobileui .ion-ios-medical:before {
  content: "";
}
.mobileui .ion-ios-medical-outline:before {
  content: "";
}
.mobileui .ion-ios-medkit:before {
  content: "";
}
.mobileui .ion-ios-medkit-outline:before {
  content: "";
}
.mobileui .ion-ios-mic:before {
  content: "";
}
.mobileui .ion-ios-mic-off:before {
  content: "";
}
.mobileui .ion-ios-mic-outline:before {
  content: "";
}
.mobileui .ion-ios-minus:before {
  content: "";
}
.mobileui .ion-ios-minus-empty:before {
  content: "";
}
.mobileui .ion-ios-minus-outline:before {
  content: "";
}
.mobileui .ion-ios-monitor:before {
  content: "";
}
.mobileui .ion-ios-monitor-outline:before {
  content: "";
}
.mobileui .ion-ios-moon:before {
  content: "";
}
.mobileui .ion-ios-moon-outline:before {
  content: "";
}
.mobileui .ion-ios-more:before {
  content: "";
}
.mobileui .ion-ios-more-outline:before {
  content: "";
}
.mobileui .ion-ios-musical-note:before {
  content: "";
}
.mobileui .ion-ios-musical-notes:before {
  content: "";
}
.mobileui .ion-ios-navigate:before {
  content: "";
}
.mobileui .ion-ios-navigate-outline:before {
  content: "";
}
.mobileui .ion-ios-nutrition:before {
  content: "";
}
.mobileui .ion-ios-nutrition-outline:before {
  content: "";
}
.mobileui .ion-ios-paper:before {
  content: "";
}
.mobileui .ion-ios-paper-outline:before {
  content: "";
}
.mobileui .ion-ios-paperplane:before {
  content: "";
}
.mobileui .ion-ios-paperplane-outline:before {
  content: "";
}
.mobileui .ion-ios-partlysunny:before {
  content: "";
}
.mobileui .ion-ios-partlysunny-outline:before {
  content: "";
}
.mobileui .ion-ios-pause:before {
  content: "";
}
.mobileui .ion-ios-pause-outline:before {
  content: "";
}
.mobileui .ion-ios-paw:before {
  content: "";
}
.mobileui .ion-ios-paw-outline:before {
  content: "";
}
.mobileui .ion-ios-people:before {
  content: "";
}
.mobileui .ion-ios-people-outline:before {
  content: "";
}
.mobileui .ion-ios-person:before {
  content: "";
}
.mobileui .ion-ios-person-outline:before {
  content: "";
}
.mobileui .ion-ios-personadd:before {
  content: "";
}
.mobileui .ion-ios-personadd-outline:before {
  content: "";
}
.mobileui .ion-ios-photos:before {
  content: "";
}
.mobileui .ion-ios-photos-outline:before {
  content: "";
}
.mobileui .ion-ios-pie:before {
  content: "";
}
.mobileui .ion-ios-pie-outline:before {
  content: "";
}
.mobileui .ion-ios-pint:before {
  content: "";
}
.mobileui .ion-ios-pint-outline:before {
  content: "";
}
.mobileui .ion-ios-play:before {
  content: "";
}
.mobileui .ion-ios-play-outline:before {
  content: "";
}
.mobileui .ion-ios-plus:before {
  content: "";
}
.mobileui .ion-ios-plus-empty:before {
  content: "";
}
.mobileui .ion-ios-plus-outline:before {
  content: "";
}
.mobileui .ion-ios-pricetag:before {
  content: "";
}
.mobileui .ion-ios-pricetag-outline:before {
  content: "";
}
.mobileui .ion-ios-pricetags:before {
  content: "";
}
.mobileui .ion-ios-pricetags-outline:before {
  content: "";
}
.mobileui .ion-ios-printer:before {
  content: "";
}
.mobileui .ion-ios-printer-outline:before {
  content: "";
}
.mobileui .ion-ios-pulse:before {
  content: "";
}
.mobileui .ion-ios-pulse-strong:before {
  content: "";
}
.mobileui .ion-ios-rainy:before {
  content: "";
}
.mobileui .ion-ios-rainy-outline:before {
  content: "";
}
.mobileui .ion-ios-recording:before {
  content: "";
}
.mobileui .ion-ios-recording-outline:before {
  content: "";
}
.mobileui .ion-ios-redo:before {
  content: "";
}
.mobileui .ion-ios-redo-outline:before {
  content: "";
}
.mobileui .ion-ios-refresh:before {
  content: "";
}
.mobileui .ion-ios-refresh-empty:before {
  content: "";
}
.mobileui .ion-ios-refresh-outline:before {
  content: "";
}
.mobileui .ion-ios-reload:before {
  content: "";
}
.mobileui .ion-ios-reverse-camera:before {
  content: "";
}
.mobileui .ion-ios-reverse-camera-outline:before {
  content: "";
}
.mobileui .ion-ios-rewind:before {
  content: "";
}
.mobileui .ion-ios-rewind-outline:before {
  content: "";
}
.mobileui .ion-ios-rose:before {
  content: "";
}
.mobileui .ion-ios-rose-outline:before {
  content: "";
}
.mobileui .ion-ios-search:before {
  content: "";
}
.mobileui .ion-ios-search-strong:before {
  content: "";
}
.mobileui .ion-ios-settings:before {
  content: "";
}
.mobileui .ion-ios-settings-strong:before {
  content: "";
}
.mobileui .ion-ios-shuffle:before {
  content: "";
}
.mobileui .ion-ios-shuffle-strong:before {
  content: "";
}
.mobileui .ion-ios-skipbackward:before {
  content: "";
}
.mobileui .ion-ios-skipbackward-outline:before {
  content: "";
}
.mobileui .ion-ios-skipforward:before {
  content: "";
}
.mobileui .ion-ios-skipforward-outline:before {
  content: "";
}
.mobileui .ion-ios-snowy:before {
  content: "";
}
.mobileui .ion-ios-speedometer:before {
  content: "";
}
.mobileui .ion-ios-speedometer-outline:before {
  content: "";
}
.mobileui .ion-ios-star:before {
  content: "";
}
.mobileui .ion-ios-star-half:before {
  content: "";
}
.mobileui .ion-ios-star-outline:before {
  content: "";
}
.mobileui .ion-ios-stopwatch:before {
  content: "";
}
.mobileui .ion-ios-stopwatch-outline:before {
  content: "";
}
.mobileui .ion-ios-sunny:before {
  content: "";
}
.mobileui .ion-ios-sunny-outline:before {
  content: "";
}
.mobileui .ion-ios-telephone:before {
  content: "";
}
.mobileui .ion-ios-telephone-outline:before {
  content: "";
}
.mobileui .ion-ios-tennisball:before {
  content: "";
}
.mobileui .ion-ios-tennisball-outline:before {
  content: "";
}
.mobileui .ion-ios-thunderstorm:before {
  content: "";
}
.mobileui .ion-ios-thunderstorm-outline:before {
  content: "";
}
.mobileui .ion-ios-time:before {
  content: "";
}
.mobileui .ion-ios-time-outline:before {
  content: "";
}
.mobileui .ion-ios-timer:before {
  content: "";
}
.mobileui .ion-ios-timer-outline:before {
  content: "";
}
.mobileui .ion-ios-toggle:before {
  content: "";
}
.mobileui .ion-ios-toggle-outline:before {
  content: "";
}
.mobileui .ion-ios-trash:before {
  content: "";
}
.mobileui .ion-ios-trash-outline:before {
  content: "";
}
.mobileui .ion-ios-undo:before {
  content: "";
}
.mobileui .ion-ios-undo-outline:before {
  content: "";
}
.mobileui .ion-ios-unlocked:before {
  content: "";
}
.mobileui .ion-ios-unlocked-outline:before {
  content: "";
}
.mobileui .ion-ios-upload:before {
  content: "";
}
.mobileui .ion-ios-upload-outline:before {
  content: "";
}
.mobileui .ion-ios-videocam:before {
  content: "";
}
.mobileui .ion-ios-videocam-outline:before {
  content: "";
}
.mobileui .ion-ios-volume-high:before {
  content: "";
}
.mobileui .ion-ios-volume-low:before {
  content: "";
}
.mobileui .ion-ios-wineglass:before {
  content: "";
}
.mobileui .ion-ios-wineglass-outline:before {
  content: "";
}
.mobileui .ion-ios-world:before {
  content: "";
}
.mobileui .ion-ios-world-outline:before {
  content: "";
}
.mobileui .ion-ipad:before {
  content: "";
}
.mobileui .ion-iphone:before {
  content: "";
}
.mobileui .ion-ipod:before {
  content: "";
}
.mobileui .ion-jet:before {
  content: "";
}
.mobileui .ion-key:before {
  content: "";
}
.mobileui .ion-knife:before {
  content: "";
}
.mobileui .ion-laptop:before {
  content: "";
}
.mobileui .ion-leaf:before {
  content: "";
}
.mobileui .ion-levels:before {
  content: "";
}
.mobileui .ion-lightbulb:before {
  content: "";
}
.mobileui .ion-link:before {
  content: "";
}
.mobileui .ion-load-a:before {
  content: "";
}
.mobileui .ion-load-b:before {
  content: "";
}
.mobileui .ion-load-c:before {
  content: "";
}
.mobileui .ion-load-d:before {
  content: "";
}
.mobileui .ion-location:before {
  content: "";
}
.mobileui .ion-lock-combination:before {
  content: "";
}
.mobileui .ion-locked:before {
  content: "";
}
.mobileui .ion-log-in:before {
  content: "";
}
.mobileui .ion-log-out:before {
  content: "";
}
.mobileui .ion-loop:before {
  content: "";
}
.mobileui .ion-magnet:before {
  content: "";
}
.mobileui .ion-male:before {
  content: "";
}
.mobileui .ion-man:before {
  content: "";
}
.mobileui .ion-map:before {
  content: "";
}
.mobileui .ion-medkit:before {
  content: "";
}
.mobileui .ion-merge:before {
  content: "";
}
.mobileui .ion-mic-a:before {
  content: "";
}
.mobileui .ion-mic-b:before {
  content: "";
}
.mobileui .ion-mic-c:before {
  content: "";
}
.mobileui .ion-minus:before {
  content: "";
}
.mobileui .ion-minus-circled:before {
  content: "";
}
.mobileui .ion-minus-round:before {
  content: "";
}
.mobileui .ion-model-s:before {
  content: "";
}
.mobileui .ion-monitor:before {
  content: "";
}
.mobileui .ion-more:before {
  content: "";
}
.mobileui .ion-mouse:before {
  content: "";
}
.mobileui .ion-music-note:before {
  content: "";
}
.mobileui .ion-navicon:before {
  content: "";
}
.mobileui .ion-navicon-round:before {
  content: "";
}
.mobileui .ion-navigate:before {
  content: "";
}
.mobileui .ion-network:before {
  content: "";
}
.mobileui .ion-no-smoking:before {
  content: "";
}
.mobileui .ion-nuclear:before {
  content: "";
}
.mobileui .ion-outlet:before {
  content: "";
}
.mobileui .ion-paintbrush:before {
  content: "";
}
.mobileui .ion-paintbucket:before {
  content: "";
}
.mobileui .ion-paper-airplane:before {
  content: "";
}
.mobileui .ion-paperclip:before {
  content: "";
}
.mobileui .ion-pause:before {
  content: "";
}
.mobileui .ion-person:before {
  content: "";
}
.mobileui .ion-person-add:before {
  content: "";
}
.mobileui .ion-person-stalker:before {
  content: "";
}
.mobileui .ion-pie-graph:before {
  content: "";
}
.mobileui .ion-pin:before {
  content: "";
}
.mobileui .ion-pinpoint:before {
  content: "";
}
.mobileui .ion-pizza:before {
  content: "";
}
.mobileui .ion-plane:before {
  content: "";
}
.mobileui .ion-planet:before {
  content: "";
}
.mobileui .ion-play:before {
  content: "";
}
.mobileui .ion-playstation:before {
  content: "";
}
.mobileui .ion-plus:before {
  content: "";
}
.mobileui .ion-plus-circled:before {
  content: "";
}
.mobileui .ion-plus-round:before {
  content: "";
}
.mobileui .ion-podium:before {
  content: "";
}
.mobileui .ion-pound:before {
  content: "";
}
.mobileui .ion-power:before {
  content: "";
}
.mobileui .ion-pricetag:before {
  content: "";
}
.mobileui .ion-pricetags:before {
  content: "";
}
.mobileui .ion-printer:before {
  content: "";
}
.mobileui .ion-pull-request:before {
  content: "";
}
.mobileui .ion-qr-scanner:before {
  content: "";
}
.mobileui .ion-quote:before {
  content: "";
}
.mobileui .ion-radio-waves:before {
  content: "";
}
.mobileui .ion-record:before {
  content: "";
}
.mobileui .ion-refresh:before {
  content: "";
}
.mobileui .ion-reply:before {
  content: "";
}
.mobileui .ion-reply-all:before {
  content: "";
}
.mobileui .ion-ribbon-a:before {
  content: "";
}
.mobileui .ion-ribbon-b:before {
  content: "";
}
.mobileui .ion-sad:before {
  content: "";
}
.mobileui .ion-sad-outline:before {
  content: "";
}
.mobileui .ion-scissors:before {
  content: "";
}
.mobileui .ion-search:before {
  content: "";
}
.mobileui .ion-settings:before {
  content: "";
}
.mobileui .ion-share:before {
  content: "";
}
.mobileui .ion-shuffle:before {
  content: "";
}
.mobileui .ion-skip-backward:before {
  content: "";
}
.mobileui .ion-skip-forward:before {
  content: "";
}
.mobileui .ion-social-android:before {
  content: "";
}
.mobileui .ion-social-android-outline:before {
  content: "";
}
.mobileui .ion-social-angular:before {
  content: "";
}
.mobileui .ion-social-angular-outline:before {
  content: "";
}
.mobileui .ion-social-apple:before {
  content: "";
}
.mobileui .ion-social-apple-outline:before {
  content: "";
}
.mobileui .ion-social-bitcoin:before {
  content: "";
}
.mobileui .ion-social-bitcoin-outline:before {
  content: "";
}
.mobileui .ion-social-buffer:before {
  content: "";
}
.mobileui .ion-social-buffer-outline:before {
  content: "";
}
.mobileui .ion-social-chrome:before {
  content: "";
}
.mobileui .ion-social-chrome-outline:before {
  content: "";
}
.mobileui .ion-social-codepen:before {
  content: "";
}
.mobileui .ion-social-codepen-outline:before {
  content: "";
}
.mobileui .ion-social-css3:before {
  content: "";
}
.mobileui .ion-social-css3-outline:before {
  content: "";
}
.mobileui .ion-social-designernews:before {
  content: "";
}
.mobileui .ion-social-designernews-outline:before {
  content: "";
}
.mobileui .ion-social-dribbble:before {
  content: "";
}
.mobileui .ion-social-dribbble-outline:before {
  content: "";
}
.mobileui .ion-social-dropbox:before {
  content: "";
}
.mobileui .ion-social-dropbox-outline:before {
  content: "";
}
.mobileui .ion-social-euro:before {
  content: "";
}
.mobileui .ion-social-euro-outline:before {
  content: "";
}
.mobileui .ion-social-facebook:before {
  content: "";
}
.mobileui .ion-social-facebook-outline:before {
  content: "";
}
.mobileui .ion-social-foursquare:before {
  content: "";
}
.mobileui .ion-social-foursquare-outline:before {
  content: "";
}
.mobileui .ion-social-freebsd-devil:before {
  content: "";
}
.mobileui .ion-social-github:before {
  content: "";
}
.mobileui .ion-social-github-outline:before {
  content: "";
}
.mobileui .ion-social-google:before {
  content: "";
}
.mobileui .ion-social-google-outline:before {
  content: "";
}
.mobileui .ion-social-googleplus:before {
  content: "";
}
.mobileui .ion-social-googleplus-outline:before {
  content: "";
}
.mobileui .ion-social-hackernews:before {
  content: "";
}
.mobileui .ion-social-hackernews-outline:before {
  content: "";
}
.mobileui .ion-social-html5:before {
  content: "";
}
.mobileui .ion-social-html5-outline:before {
  content: "";
}
.mobileui .ion-social-instagram:before {
  content: "";
}
.mobileui .ion-social-instagram-outline:before {
  content: "";
}
.mobileui .ion-social-javascript:before {
  content: "";
}
.mobileui .ion-social-javascript-outline:before {
  content: "";
}
.mobileui .ion-social-linkedin:before {
  content: "";
}
.mobileui .ion-social-linkedin-outline:before {
  content: "";
}
.mobileui .ion-social-markdown:before {
  content: "";
}
.mobileui .ion-social-nodejs:before {
  content: "";
}
.mobileui .ion-social-octocat:before {
  content: "";
}
.mobileui .ion-social-pinterest:before {
  content: "";
}
.mobileui .ion-social-pinterest-outline:before {
  content: "";
}
.mobileui .ion-social-python:before {
  content: "";
}
.mobileui .ion-social-reddit:before {
  content: "";
}
.mobileui .ion-social-reddit-outline:before {
  content: "";
}
.mobileui .ion-social-rss:before {
  content: "";
}
.mobileui .ion-social-rss-outline:before {
  content: "";
}
.mobileui .ion-social-sass:before {
  content: "";
}
.mobileui .ion-social-skype:before {
  content: "";
}
.mobileui .ion-social-skype-outline:before {
  content: "";
}
.mobileui .ion-social-snapchat:before {
  content: "";
}
.mobileui .ion-social-snapchat-outline:before {
  content: "";
}
.mobileui .ion-social-tumblr:before {
  content: "";
}
.mobileui .ion-social-tumblr-outline:before {
  content: "";
}
.mobileui .ion-social-tux:before {
  content: "";
}
.mobileui .ion-social-twitch:before {
  content: "";
}
.mobileui .ion-social-twitch-outline:before {
  content: "";
}
.mobileui .ion-social-twitter:before {
  content: "";
}
.mobileui .ion-social-twitter-outline:before {
  content: "";
}
.mobileui .ion-social-usd:before {
  content: "";
}
.mobileui .ion-social-usd-outline:before {
  content: "";
}
.mobileui .ion-social-vimeo:before {
  content: "";
}
.mobileui .ion-social-vimeo-outline:before {
  content: "";
}
.mobileui .ion-social-whatsapp:before {
  content: "";
}
.mobileui .ion-social-whatsapp-outline:before {
  content: "";
}
.mobileui .ion-social-windows:before {
  content: "";
}
.mobileui .ion-social-windows-outline:before {
  content: "";
}
.mobileui .ion-social-wordpress:before {
  content: "";
}
.mobileui .ion-social-wordpress-outline:before {
  content: "";
}
.mobileui .ion-social-yahoo:before {
  content: "";
}
.mobileui .ion-social-yahoo-outline:before {
  content: "";
}
.mobileui .ion-social-yen:before {
  content: "";
}
.mobileui .ion-social-yen-outline:before {
  content: "";
}
.mobileui .ion-social-youtube:before {
  content: "";
}
.mobileui .ion-social-youtube-outline:before {
  content: "";
}
.mobileui .ion-soup-can:before {
  content: "";
}
.mobileui .ion-soup-can-outline:before {
  content: "";
}
.mobileui .ion-speakerphone:before {
  content: "";
}
.mobileui .ion-speedometer:before {
  content: "";
}
.mobileui .ion-spoon:before {
  content: "";
}
.mobileui .ion-star:before {
  content: "";
}
.mobileui .ion-stats-bars:before {
  content: "";
}
.mobileui .ion-steam:before {
  content: "";
}
.mobileui .ion-stop:before {
  content: "";
}
.mobileui .ion-thermometer:before {
  content: "";
}
.mobileui .ion-thumbsdown:before {
  content: "";
}
.mobileui .ion-thumbsup:before {
  content: "";
}
.mobileui .ion-toggle:before {
  content: "";
}
.mobileui .ion-toggle-filled:before {
  content: "";
}
.mobileui .ion-transgender:before {
  content: "";
}
.mobileui .ion-trash-a:before {
  content: "";
}
.mobileui .ion-trash-b:before {
  content: "";
}
.mobileui .ion-trophy:before {
  content: "";
}
.mobileui .ion-tshirt:before {
  content: "";
}
.mobileui .ion-tshirt-outline:before {
  content: "";
}
.mobileui .ion-umbrella:before {
  content: "";
}
.mobileui .ion-university:before {
  content: "";
}
.mobileui .ion-unlocked:before {
  content: "";
}
.mobileui .ion-upload:before {
  content: "";
}
.mobileui .ion-usb:before {
  content: "";
}
.mobileui .ion-videocamera:before {
  content: "";
}
.mobileui .ion-volume-high:before {
  content: "";
}
.mobileui .ion-volume-low:before {
  content: "";
}
.mobileui .ion-volume-medium:before {
  content: "";
}
.mobileui .ion-volume-mute:before {
  content: "";
}
.mobileui .ion-wand:before {
  content: "";
}
.mobileui .ion-waterdrop:before {
  content: "";
}
.mobileui .ion-wifi:before {
  content: "";
}
.mobileui .ion-wineglass:before {
  content: "";
}
.mobileui .ion-woman:before {
  content: "";
}
.mobileui .ion-wrench:before {
  content: "";
}
.mobileui .ion-xbox:before {
  content: "";
}
.mobileui .component-header {
  display: block;
}
.mobileui .footer,
.mobileui .header {
  font-size: 18px;
  display: inline-block;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 52px;
  z-index: 9;
}
.mobileui .footer {
  top: initial;
  bottom: 0;
}
.mobileui .header.sub {
  top: 52px;
}
.mobileui .footer.sub {
  top: initial;
  bottom: 52px;
}
.mobileui .has-header {
  padding-top: 52px !important;
}
.mobileui .has-header.has-sub-header {
  padding-top: 104px !important;
}
.mobileui .has-footer {
  padding-bottom: 52px !important;
}
.mobileui .has-footer.has-sub-footer {
  padding-bottom: 104px !important;
}
.mobileui .footer .left,
.mobileui .footer .right,
.mobileui .footer .title,
.mobileui .footer h1,
.mobileui .header .left,
.mobileui .header .right,
.mobileui .header .title,
.mobileui .header h1 {
  margin-top: 15px;
  margin-bottom: 5px;
}
.mobileui .header .avatar {
  height: 40px;
  margin-top: -8px;
  margin-left: 4px;
}
.mobileui .header .border-big {
  border-width: 2px;
  border-style: solid;
}
.mobileui .footer h2,
.mobileui .header h2 {
  padding-top: 8px;
  padding-left: 10px;
}
.mobileui .footer p,
.mobileui .header p {
  padding-left: 10px;
  margin: 0;
  margin-top: -4px;
}
.mobileui .footer .left,
.mobileui .header .left {
  padding-left: 5px;
}
.mobileui .footer .right,
.mobileui .header .right {
  padding-right: 5px;
}
.mobileui .footer .title,
.mobileui .footer h1,
.mobileui .header .title,
.mobileui .header h1 {
  margin-bottom: 15px;
  padding-left: 10px;
  padding-right: 15px;
  display: inline-block;
}
.mobileui .footer button,
.mobileui .header button {
  margin-top: -10px;
}
.mobileui .footer button.icon,
.mobileui .header button.icon {
  font-size: 30px;
  margin-top: -10px;
  z-index: 99;
  background: 0 0;
  border: none;
  padding: 0 6px;
}
.mobileui .footer button.icon:not([class*=red]):not([class*=pink]):not([class*=white]):not([class*=purple]):not([class*=indigo]):not([class*=blue]):not([class*=cyan]):not([class*=teal]):not([class*=green]):not([class*=lime]):not([class*=yellow]):not([class*=amber]):not([class*=orange]):not([class*=brown]):not([class*=grey]):not([class*=black]):not([class*=white]),
.mobileui .header button.icon:not([class*=red]):not([class*=pink]):not([class*=white]):not([class*=purple]):not([class*=indigo]):not([class*=blue]):not([class*=cyan]):not([class*=teal]):not([class*=green]):not([class*=lime]):not([class*=yellow]):not([class*=amber]):not([class*=orange]):not([class*=brown]):not([class*=grey]):not([class*=black]):not([class*=white]) {
  color: #fff;
}
.mobileui .footer button.icon.left,
.mobileui .footer button.icon.right,
.mobileui .header button.icon.left,
.mobileui .header button.icon.right {
  margin-top: 5px;
  margin-bottom: 5px;
}
.mobileui .footer button.icon.left,
.mobileui .header button.icon.left {
  margin-left: 5px;
}
.mobileui .footer button.icon.right,
.mobileui .header button.icon.right {
  margin-right: 5px;
}
.mobileui .footer .title.align-center,
.mobileui .footer h1.align-center,
.mobileui .footer p.align-center,
.mobileui .header .title.align-center,
.mobileui .header h1.align-center,
.mobileui .header p.align-center {
  width: 100%;
  position: inherit;
  padding-right: 15px;
  left: 0;
  z-index: 9;
}
.mobileui .footer .icon-badge,
.mobileui .header .icon-badge {
  position: absolute;
  margin-left: -6px;
  margin-top: -8px;
  border-radius: 8px;
  z-index: 999;
}
.mobileui .footer .buttons-group,
.mobileui .header .buttons-group {
  margin-top: 15px;
  margin-left: 5px;
  margin-right: 5px;
}
.mobileui .footer .buttons-group.small,
.mobileui .header .buttons-group.small {
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
}
.mobileui .header input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.8);
}
.mobileui .header input.placeholder-white::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.mobileui .header input {
  padding: 15px;
  position: absolute;
}
.mobileui .header .left + input {
  padding-right: 50px;
}
.mobileui .component-button {
  display: block;
}
.mobileui button {
  -webkit-transition: color 0.2s ease;
  -moz-transition: color 0.2s ease;
  -ms-transition: color 0.2s ease;
  -o-transition: color 0.2s ease;
  transition: color 0.2s ease;
  font-size: 14px;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  color: #444;
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 0 12px;
  min-height: 42px;
  vertical-align: top;
  text-align: center;
  text-overflow: ellipsis;
  font-size: 16px;
  line-height: 42px;
  cursor: pointer;
  overflow: hidden;
}
.mobileui button[disabled] {
  opacity: 0.8;
}
.mobileui button .icon {
  position: absolute;
  left: 10px;
}
.mobileui button.circle {
  width: 42px;
  height: 42px;
  text-align: center;
  padding: 0;
  line-height: 0;
  font-size: 22px;
  z-index: 10;
}
.mobileui button.icon-text {
  padding-left: 40px;
}
.mobileui button.icon-text i {
  font-size: 22px;
}
.mobileui button.icon-text.icon-right {
  padding-left: 12px;
  padding-right: 40px;
  position: relative;
}
.mobileui button.icon-right i {
  position: absolute;
  right: 12px;
  text-align: right;
  margin-top: 2px;
}
.mobileui button[class*=border-] {
  min-height: 40px;
  line-height: 40px;
}
.mobileui button.full {
  width: 100%;
}
.mobileui .buttons-group.big button,
.mobileui button.big {
  font-size: 22px;
  min-height: 50px;
  line-height: 50px;
}
.mobileui button.circle.big {
  width: 50px;
  height: 50px;
  font-size: 28px;
}
.mobileui button.big.icon-text {
  padding-left: 40px;
}
.mobileui button.big.icon-text.icon-right {
  padding-left: 12px;
  padding-right: 40px;
}
.mobileui button.big.icon-text i {
  font-size: 26px;
}
.mobileui .buttons-group.small button,
.mobileui button.small {
  font-size: 12px;
  min-height: 30px;
  line-height: 30px;
}
.mobileui button.circle.small {
  width: 30px;
  height: 30px;
  font-size: 18px;
}
.mobileui button.small.icon-text {
  padding-left: 30px;
}
.mobileui button.small.icon-text.icon-right {
  padding-left: 12px;
  padding-right: 35px;
}
.mobileui button.small.icon-text i {
  font-size: 18px;
}
.mobileui .buttons-group.huge button,
.mobileui button.huge {
  font-size: 32px;
  min-height: 70px;
  line-height: 70px;
}
.mobileui button.circle.huge {
  width: 70px;
  height: 70px;
  font-size: 32px;
}
.mobileui button.huge.icon-text {
  padding-left: 40px;
}
.mobileui button.huge.icon-text.icon-right {
  padding-left: 12px;
  padding-right: 40px;
}
.mobileui button.huge.icon-text i {
  font-size: 32px;
}
.mobileui .buttons-group {
  display: inline-block;
}
.mobileui .buttons-group button {
  float: left;
  margin-left: -1px;
}
.mobileui .buttons-group button:first-child {
  margin-left: 0;
}
.mobileui .buttons-group.full {
  display: flex;
}
.mobileui .buttons-group.full button {
  flex: 1;
}
.mobileui .ripple {
  position: absolute;
  background: rgba(255, 255, 255, 0.25);
  border-radius: 100%;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  pointer-events: none;
}
.mobileui .ripple.show {
  -webkit-animation: ripple 0.5s ease-out;
  -moz-animation: ripple 0.5s ease-out;
  -o-animation: ripple 0.5s ease-out;
  animation: ripple 0.5s ease-out;
}
@-webkit-keyframes ripple {
  to {
    -webkit-transform: scale(1.5);
    opacity: 0;
  }
}
@-moz-keyframes ripple {
  to {
    -moz-transform: scale(1.5);
    opacity: 0;
  }
}
@-o-keyframes ripple {
  to {
    -o-transform: scale(1.5);
    opacity: 0;
  }
}
@keyframes ripple {
  to {
    transform: scale(1.5);
    opacity: 0;
  }
}
.mobileui .component-list {
  display: block;
}
.mobileui .list {
  width: 100%;
  position: relative;
  border: 1px solid #ddd;
}
.mobileui .list .item {
  padding: 15px;
  display: inline-block;
  border-bottom: 1px solid #ddd;
  width: 100%;
  position: relative;
  font-size: 18px;
}
.mobileui .list .item.hidden {
  display: none;
}
.mobileui .list ol,
.mobileui .list ul {
  list-style: none;
}
.mobileui .list .item.space-small {
  padding-top: 5px;
  padding-bottom: 5px;
}
.mobileui .list .item.mark {
  border-right: 0;
  border-top: 0;
}
.mobileui .item .block {
  display: -webkit-box;
  display: flex;
}
.mobileui .list .item.mark.margin-button {
  border-bottom: 0;
  margin-bottom: 10px;
}
.mobileui .list .item.no-space-top {
  padding-top: 0 !important;
}
.mobileui .list .item.no-space-bottom {
  padding-bottom: 0 !important;
}
.mobileui .list .item.row {
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.mobileui .list.no-border {
  border: 0;
}
.mobileui .list .item.no-border.border-bottom,
.mobileui .list.no-border .item.border-bottom {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.mobileui .list .item.no-border,
.mobileui .list.no-border .item {
  border: none;
}
.mobileui .list .item.cover {
  padding: 0;
  margin-left: 20px;
  height: 200px;
  width: -webkit-calc(100% - 40px);
  width: -moz-calc(100% - 40px);
  width: calc(100% - 40px);
}
.mobileui .list .item.full {
  padding: 0;
  display: flex;
}
.mobileui .list .item h1,
.mobileui .list .item h2 {
  font-size: 16px;
  margin: 0;
  padding: 0;
}
.mobileui .list .item h1 {
  margin-top: 7px;
}
.mobileui .list .item p {
  font-size: 14px;
  margin: 0;
  padding: 0;
  line-height: 20px;
}
.mobileui .list .item:last-child {
  border-bottom: 0;
}
.mobileui .list .left {
  margin-right: 10px;
  min-width: 25px;
}
.mobileui .list .right {
  margin-right: 20px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
}
.mobileui .list .right i {
  margin-left: 5px;
}
.mobileui .list .align-top {
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: baseline;
  margin-top: 10px;
}
.mobileui .list .avatar {
  height: 50px;
}
.mobileui .avatar-badge {
  position: absolute;
  margin-left: -4px;
  margin-top: -3px;
  border-radius: 8px;
}
.mobileui .list .item small,
.mobileui .list .item small .icon {
  font-size: 14px;
}
.mobileui .list .item .icon {
  font-size: 18px;
}
.mobileui .list .item .icon.text-huge {
  font-size: 32px;
}
.mobileui .list .left .icon,
.mobileui .list .right .icon {
  vertical-align: -webkit-baseline-middle;
}
.mobileui .list .border-big {
  border-width: 3px;
  border-style: solid;
}
.mobileui .list .item[onclick]:active,
.mobileui .list a.item:active {
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.mobileui .list .item .right .icon,
.mobileui .list .item .right.icon {
  font-size: 24px;
  padding: 0;
  margin: 0;
}
.mobileui .component-cover {
  display: block;
}
.mobileui .cover {
  padding: 15px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
}
.mobileui .cover.cover-200 {
  height: 300px;
}
.mobileui .cover.cover-200 {
  height: 300px;
}
.mobileui .cover.cover-400 {
  height: 400px;
}
.mobileui .cover.cover-500 {
  height: 500px;
}
.mobileui .cover.no-padding {
  padding: 0;
}
.mobileui .cover .avatar {
  height: 100px;
  border-width: 4px;
  margin-bottom: 5px;
}
.mobileui .cover .item .avatar {
  height: 50px;
}
.mobileui .cover .float-bottom-center,
.mobileui .cover .float-bottom-left,
.mobileui .cover .float-bottom-right {
  margin-bottom: -30px;
  z-index: 99;
  position: absolute;
}
.mobileui .cover .big.float-bottom-center,
.mobileui .cover .big.float-bottom-left,
.mobileui .cover .big.float-bottom-right {
  margin-bottom: -35px;
}
.mobileui .cover .small.float-bottom-center,
.mobileui .cover .small.float-bottom-left,
.mobileui .cover .small.float-bottom-right {
  margin-bottom: -25px;
}
.mobileui .cover .float-bottom-center {
  left: 50%;
  margin-left: -21px;
}
.mobileui .cover .small.float-bottom-center {
  left: 50%;
  margin-left: -15px;
}
.mobileui .cover .big.float-bottom-center {
  left: 50%;
  margin-left: -25px;
}
.mobileui .cover .gradient {
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.65) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.65) 100%);
  width: 100%;
  padding-bottom: 10px;
  padding-top: 20px;
}
.mobileui .cover.blend-multiply {
  background-blend-mode: multiply;
}
.mobileui .cover.blend-screen {
  background-blend-mode: screen;
}
.mobileui .cover.blend-overlay {
  background-blend-mode: overlay;
}
.mobileui .cover.blend-darken {
  background-blend-mode: darken;
}
.mobileui .cover.blend-lighten {
  background-blend-mode: lighten;
}
.mobileui .cover.blend-color-dodge {
  background-blend-mode: color-dodge;
}
.mobileui .cover.blend-color-burn {
  background-blend-mode: color-burn;
}
.mobileui .cover.blend-hard-light {
  background-blend-mode: hard-light;
}
.mobileui .cover.blend-soft-light {
  background-blend-mode: soft-light;
}
.mobileui .cover.blend-luminosity {
  background-blend-mode: luminosity;
}
.mobileui .component-grid {
  display: block;
}
.mobileui .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.mobileui .row-between {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.mobileui .row-around {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.mobileui .row-stretch {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.mobileui .column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.mobileui .wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.mobileui .vertical-align-top {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.mobileui .vertical-align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.mobileui .vertical-align-bottom {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.mobileui .horizontal-align-left {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.mobileui .horizontal-align-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.mobileui .horizontal-align-right {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.mobileui .row-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.mobileui .col {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
}
.mobileui .col-10 {
  position: relative;
  -ms-flex-preferred-size: 10%;
  flex-basis: 10%;
}
.mobileui .col-20 {
  position: relative;
  -ms-flex-preferred-size: 20%;
  flex-basis: 20%;
}
.mobileui .col-25 {
  position: relative;
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
}
.mobileui .col-33,
.mobileui .col-34 {
  position: relative;
  -ms-flex-preferred-size: 33.3333%;
  flex-basis: 33.3333%;
}
.mobileui .col-50 {
  position: relative;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
}
.mobileui .col-66,
.mobileui .col-67 {
  position: relative;
  -ms-flex-preferred-size: 66.6666%;
  flex-basis: 66.6666%;
}
.mobileui .col-75 {
  position: relative;
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%;
}
.mobileui .col-80 {
  position: relative;
  -ms-flex-preferred-size: 80%;
  flex-basis: 80%;
}
.mobileui .col-90 {
  position: relative;
  -ms-flex-preferred-size: 90%;
  flex-basis: 90%;
}
.mobileui .full-width {
  width: 100%;
  margin: 0 auto;
}
.mobileui .full-height {
  height: 100%;
  margin: 0 auto;
}
.mobileui .component-input {
  display: block;
}
.mobileui input,
.mobileui select,
.mobileui textarea {
  border: none;
  background: 0 0;
  font-size: 14px;
  width: 100%;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.mobileui input[disabled],
.mobileui select[disabled],
.mobileui textarea[disabled] {
  opacity: 0.8;
}
.mobileui label {
  font-size: 14px;
}
.mobileui .item.label-fixed,
.mobileui .label-fixed {
  display: -moz-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
  width: 100%;
}
.mobileui .label-fixed label {
  -webkit-flex: 0 0 100px;
  -ms-flex: 0 0 100px;
  flex: 0 0 100px;
  width: 100px;
  min-width: 100px;
  max-width: 200px;
}
.mobileui .label-float label {
  margin-bottom: 0;
  -webkit-transform: translate3d(0, 27px, 0);
  transform: translate3d(0, 27px, 0);
  -webkit-transform-origin: left top;
  transform-origin: left top;
  transition: -webkit-transform 150ms ease-in-out;
  transition: transform 150ms ease-in-out;
  transition: transform 150ms ease-in-out, -webkit-transform 150ms ease-in-out;
  align-self: stretch;
  -webkit-flex: initial;
  -ms-flex: initial;
  flex: initial;
  display: block;
}
.mobileui .label-float label.focus {
  -webkit-transform: translate3d(0, 0, 0) scale(0.8);
  transform: translate3d(0, 0, 0) scale(0.8);
}
.mobileui .item.icon {
  display: flex;
}
.mobileui .item.icon.radius {
  border-radius: 6px;
}
.mobileui .item.icon:not([class*=text-]) {
  color: #9f9f9f;
}
.mobileui .item input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.6);
}
.mobileui .item input.placeholder-white::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.mobileui .item.icon:before {
  position: absolute;
}
.mobileui .item.icon.icon-right:before {
  right: 20px;
}
.mobileui .item.icon input,
.mobileui .item.icon select {
  padding-left: 30px;
}
.mobileui .item.icon.icon-right input,
.mobileui .item.icon.icon-right select {
  padding-left: 0;
  padding-right: 30px;
}
.mobileui input[type=checkbox] {
  width: 17px;
  height: 17px;
  z-index: 1;
  border: 0;
  vertical-align: top;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 2px;
}
.mobileui .platform-ios input[type=checkbox] {
  height: 23px;
  width: 23px;
  border-radius: 50%;
}
.mobileui input[type=checkbox]:not(:checked) {
  background-color: #fff !important;
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.5);
}
.mobileui .platform-ios input[type=checkbox]:not(:checked) {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.4);
}
.mobileui input[type=checkbox]:disabled {
  opacity: 0.4;
}
.mobileui input[type=checkbox]::before {
  content: "";
  position: absolute;
  margin-top: 1px;
  margin-left: 5px;
  width: 5px;
  height: 10px;
  border-width: 2px;
  border-top-width: 0;
  border-left-width: 0;
  border-style: solid;
  border-color: #fff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.mobileui .platform-ios input[type=checkbox]::before {
  margin-top: 5px;
  margin-left: 9px;
  width: 4px;
  height: 9px;
  border-width: 1px;
  border-top-width: 0;
  border-left-width: 0;
}
.mobileui input[type=checkbox].switch {
  width: 36px;
  height: 14px;
  box-shadow: inset 0 0 0 30px rgba(255, 255, 255, 0.4);
  border-radius: 28px;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.35s;
  transition-duration: 0.35s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.mobileui .platform-ios input[type=checkbox].switch {
  height: 32px;
  width: 51px;
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.05);
}
.mobileui input[type=checkbox].switch:not(:checked) {
  background-color: #fff !important;
  box-shadow: inset 0 0 0 30px rgba(0, 0, 0, 0.35);
}
.mobileui .platform-ios input[type=checkbox].switch:not(:checked) {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.1);
}
.mobileui input[type=checkbox].switch::before {
  content: "";
  border-radius: 28px;
  height: 20px;
  width: 20px;
  margin-left: -1px;
  margin-top: -3px;
  -webkit-transition-duration: 0.35s;
  transition-duration: 0.35s;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.4);
  border: 0;
}
.mobileui .platform-ios input[type=checkbox].switch::before {
  height: 28px;
  width: 28px;
  box-shadow: 0 0 0 1px #e4e4e4, 0 3px 2px rgba(0, 0, 0, 0.25);
  margin-top: 2px;
  margin-left: 2px;
  background-color: #fff !important;
}
.mobileui input[type=checkbox].switch:checked::before {
  margin-left: 16px;
}
.mobileui input[type=checkbox].switch:not(:checked)::before {
  background-color: #fff !important;
}
.mobileui .platform-ios input[type=checkbox].switch:checked::before {
  margin-left: 21px;
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.25);
  background-color: #fff;
}
.mobileui input[type=radio] {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  z-index: 1;
  vertical-align: top;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: 0 0;
}
.mobileui .platform-ios input[type=radio] {
  background: 0 0 !important;
}
.mobileui input[type=radio]::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 50%;
}
.mobileui .platform-ios input[type=radio]:not(:checked)::after {
  box-shadow: none;
}
.mobileui input[type=radio]:not(:checked)::after {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.4);
  background-color: #fff !important;
}
.mobileui input[type=radio]:checked::before {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  border: 3px solid #fff;
  z-index: 1;
  border-radius: 50%;
  margin-top: 2px;
  margin-left: 2px;
}
.mobileui input[type=radio]:disabled {
  opacity: 0.4;
}
.mobileui .platform-ios input[type=radio]::after {
  background: 0 0 !important;
}
.mobileui .platform-ios input[type=radio]:checked::before {
  position: absolute;
  margin-top: 4px;
  margin-left: 7px;
  width: 5px;
  height: 12px;
  border-width: 2px;
  border-top-width: 0;
  border-left-width: 0;
  border-style: solid;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-radius: 0;
}
.mobileui .item.range {
  position: relative;
  -ms-flex-item-align: center;
  -webkit-align-self: center;
  align-self: center;
}
.mobileui .item.range input {
  position: absolute;
  height: 28px;
  width: calc(100% - 110px);
  margin: 4px 0 5px 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0, #ccc), color-stop(100%, #ccc));
  background: linear-gradient(to right, #ccc 0, #ccc 100%);
  background-position: center;
  background-size: 100% 2px;
  background-repeat: no-repeat;
  outline: 0;
  border: none;
  box-sizing: content-box;
  -ms-background-position-y: 500px;
}
.mobileui input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: #666;
  width: 13px;
  height: 13px;
  border-radius: 50%;
}
.mobileui input[type=range]:active::-webkit-slider-thumb {
  width: 20px;
  height: 20px;
}
.mobileui .platform-ios input[type=range]::-webkit-slider-thumb {
  background-color: #fff;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
  width: 25px;
  height: 25px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 50%;
}
.mobileui input[type=checkbox].red-50.switch::before,
.mobileui input[type=radio].red-50::after {
  background-color: #ffebee;
}
.mobileui input[type=checkbox].red-100.switch::before,
.mobileui input[type=radio].red-100::after {
  background-color: #ffcdd2;
}
.mobileui input[type=checkbox].red-200.switch::before,
.mobileui input[type=radio].red-200::after {
  background-color: #ef9a9a;
}
.mobileui input[type=checkbox].red-300.switch::before,
.mobileui input[type=radio].red-300::after {
  background-color: #e57373;
}
.mobileui input[type=checkbox].red-400.switch::before,
.mobileui input[type=radio].red-400::after {
  background-color: #ef5350;
}
.mobileui input[type=checkbox].red-500.switch::before,
.mobileui input[type=radio].red-500::after {
  background-color: #f44336;
}
.mobileui input[type=checkbox].red-600.switch::before,
.mobileui input[type=radio].red-600::after {
  background-color: #e53935;
}
.mobileui input[type=checkbox].red-700.switch::before,
.mobileui input[type=radio].red-700::after {
  background-color: #d32f2f;
}
.mobileui input[type=checkbox].red-800.switch::before,
.mobileui input[type=radio].red-800::after {
  background-color: #c62828;
}
.mobileui input[type=checkbox].red-900.switch::before,
.mobileui input[type=radio].red-900::after {
  background-color: #b71c1c;
}
.mobileui input[type=checkbox].pink.switch::before,
.mobileui input[type=radio].pink::after {
  background-color: #e91e63;
}
.mobileui input[type=checkbox].pink-50.switch::before,
.mobileui input[type=radio].pink-50::after {
  background-color: #fce4ec;
}
.mobileui input[type=checkbox].pink-100.switch::before,
.mobileui input[type=radio].pink-100::after {
  background-color: #f8bbd0;
}
.mobileui input[type=checkbox].pink-200.switch::before,
.mobileui input[type=radio].pink-200::after {
  background-color: #f48fb1;
}
.mobileui input[type=checkbox].Pink-300.switch::before,
.mobileui input[type=radio].Pink-300::after {
  background-color: #f06292;
}
.mobileui input[type=checkbox].pink-400.switch::before,
.mobileui input[type=radio].pink-400::after {
  background-color: #ec407a;
}
.mobileui input[type=checkbox].pink-500.switch::before,
.mobileui input[type=radio].pink-500::after {
  background-color: #e91e63;
}
.mobileui input[type=checkbox].pink-600.switch::before,
.mobileui input[type=radio].pink-600::after {
  background-color: #d81b60;
}
.mobileui input[type=checkbox].pink-700.switch::before,
.mobileui input[type=radio].pink-700::after {
  background-color: #c2185b;
}
.mobileui input[type=checkbox].pink-800.switch::before,
.mobileui input[type=radio].pink-800::after {
  background-color: #ad1457;
}
.mobileui input[type=checkbox].pink-900.switch::before,
.mobileui input[type=radio].pink-900::after {
  background-color: #880e4f;
}
.mobileui input[type=checkbox].purple.switch::before,
.mobileui input[type=radio].purple::after {
  background-color: #9c27b0;
}
.mobileui input[type=checkbox].purple-50.switch::before,
.mobileui input[type=radio].purple-50::after {
  background-color: #f3e5f5;
}
.mobileui input[type=checkbox].purple-100.switch::before,
.mobileui input[type=radio].purple-100::after {
  background-color: #e1bee7;
}
.mobileui input[type=checkbox].purple-200.switch::before,
.mobileui input[type=radio].purple-200::after {
  background-color: #ce93d8;
}
.mobileui input[type=checkbox].Purple-300.switch::before,
.mobileui input[type=radio].Purple-300::after {
  background-color: #ba68c8;
}
.mobileui input[type=checkbox].Purple-400.switch::before,
.mobileui input[type=radio].Purple-400::after {
  background-color: #ab47bc;
}
.mobileui input[type=checkbox].purple-500.switch::before,
.mobileui input[type=radio].purple-500::after {
  background-color: #9c27b0;
}
.mobileui input[type=checkbox].purple-600.switch::before,
.mobileui input[type=radio].purple-600::after {
  background-color: #8e24aa;
}
.mobileui input[type=checkbox].purple-700.switch::before,
.mobileui input[type=radio].purple-700::after {
  background-color: #7b1fa2;
}
.mobileui input[type=checkbox].purple-800.switch::before,
.mobileui input[type=radio].purple-800::after {
  background-color: #6a1b9a;
}
.mobileui input[type=checkbox].purple-900.switch::before,
.mobileui input[type=radio].purple-900::after {
  background-color: #4a148c;
}
.mobileui input[type=checkbox].deep-purple.switch::before,
.mobileui input[type=radio].deep-purple::after {
  background-color: #673ab7;
}
.mobileui input[type=checkbox].deep-purple-300.switch::before,
.mobileui input[type=radio].deep-purple-300::after {
  background-color: #9575cd;
}
.mobileui input[type=checkbox].deep-purple-400.switch::before,
.mobileui input[type=radio].deep-purple-400::after {
  background-color: #7e57c2;
}
.mobileui input[type=checkbox].deep-purple-500.switch::before,
.mobileui input[type=radio].deep-purple-500::after {
  background-color: #673ab7;
}
.mobileui input[type=checkbox].deep-purple-600.switch::before,
.mobileui input[type=radio].deep-purple-600::after {
  background-color: #5e35b1;
}
.mobileui input[type=checkbox].deep-purple-700.switch::before,
.mobileui input[type=radio].deep-purple-700::after {
  background-color: #512da8;
}
.mobileui input[type=checkbox].deep-purple-800.switch::before,
.mobileui input[type=radio].deep-purple-800::after {
  background-color: #4527a0;
}
.mobileui input[type=checkbox].deep-purple-900.switch::before,
.mobileui input[type=radio].deep-purple-900::after {
  background-color: #311b92;
}
.mobileui input[type=checkbox].indigo.switch::before,
.mobileui input[type=radio].indigo::after {
  background-color: #3f51b5;
}
.mobileui input[type=checkbox].indigo-50.switch::before,
.mobileui input[type=radio].indigo-50::after {
  background-color: #e8eaf6;
}
.mobileui input[type=checkbox].indigo-100.switch::before,
.mobileui input[type=radio].indigo-100::after {
  background-color: #c5cae9;
}
.mobileui input[type=checkbox].indigo-200.switch::before,
.mobileui input[type=radio].indigo-200::after {
  background-color: #9fa8da;
}
.mobileui input[type=checkbox].indigo-300.switch::before,
.mobileui input[type=radio].indigo-300::after {
  background-color: #7986cb;
}
.mobileui input[type=checkbox].indigo-400.switch::before,
.mobileui input[type=radio].indigo-400::after {
  background-color: #5c6bc0;
}
.mobileui input[type=checkbox].indigo-500.switch::before,
.mobileui input[type=radio].indigo-500::after {
  background-color: #3f51b5;
}
.mobileui input[type=checkbox].indigo-600.switch::before,
.mobileui input[type=radio].indigo-600::after {
  background-color: #3949ab;
}
.mobileui input[type=checkbox].indigo-700.switch::before,
.mobileui input[type=radio].indigo-700::after {
  background-color: #303f9f;
}
.mobileui input[type=checkbox].indigo-800.switch::before,
.mobileui input[type=radio].indigo-800::after {
  background-color: #283593;
}
.mobileui input[type=checkbox].indigo-900.switch::before,
.mobileui input[type=radio].indigo-900::after {
  background-color: #1a237e;
}
.mobileui input[type=checkbox].blue.switch::before,
.mobileui input[type=radio].blue::after {
  background-color: #2196f3;
}
.mobileui input[type=checkbox].blue-50.switch::before,
.mobileui input[type=radio].blue-50::after {
  background-color: #e3f2fd;
}
.mobileui input[type=checkbox].blue-100.switch::before,
.mobileui input[type=radio].blue-100::after {
  background-color: #bbdefb;
}
.mobileui input[type=checkbox].blue-200.switch::before,
.mobileui input[type=radio].blue-200::after {
  background-color: #90caf9;
}
.mobileui input[type=checkbox].blue-300.switch::before,
.mobileui input[type=radio].blue-300::after {
  background-color: #64b5f6;
}
.mobileui input[type=checkbox].blue-400.switch::before,
.mobileui input[type=radio].blue-400::after {
  background-color: #42a5f5;
}
.mobileui input[type=checkbox].blue-500.switch::before,
.mobileui input[type=radio].blue-500::after {
  background-color: #2196f3;
}
.mobileui input[type=checkbox].blue-600.switch::before,
.mobileui input[type=radio].blue-600::after {
  background-color: #1e88e5;
}
.mobileui input[type=checkbox].blue-700.switch::before,
.mobileui input[type=radio].blue-700::after {
  background-color: #1976d2;
}
.mobileui input[type=checkbox].blue-800.switch::before,
.mobileui input[type=radio].blue-800::after {
  background-color: #1565c0;
}
.mobileui input[type=checkbox].blue-900.switch::before,
.mobileui input[type=radio].blue-900::after {
  background-color: #0d47a1;
}
.mobileui input[type=checkbox].light-blue.switch::before,
.mobileui input[type=radio].light-blue::after {
  background-color: #03a9f4;
}
.mobileui input[type=checkbox].light-blue-50.switch::before,
.mobileui input[type=radio].light-blue-50::after {
  background-color: #e1f5fe;
}
.mobileui input[type=checkbox].light-blue-100.switch::before,
.mobileui input[type=radio].light-blue-100::after {
  background-color: #b3e5fc;
}
.mobileui input[type=checkbox].light-blue-200.switch::before,
.mobileui input[type=radio].light-blue-200::after {
  background-color: #81d4fa;
}
.mobileui input[type=checkbox].light-blue-300.switch::before,
.mobileui input[type=radio].light-blue-300::after {
  background-color: #4fc3f7;
}
.mobileui input[type=checkbox].light-blue-400.switch::before,
.mobileui input[type=radio].light-blue-400::after {
  background-color: #29b6f6;
}
.mobileui input[type=checkbox].light-blue-500.switch::before,
.mobileui input[type=radio].light-blue-500::after {
  background-color: #03a9f4;
}
.mobileui input[type=checkbox].light-blue-600.switch::before,
.mobileui input[type=radio].light-blue-600::after {
  background-color: #039be5;
}
.mobileui input[type=checkbox].light-blue-700.switch::before,
.mobileui input[type=radio].light-blue-700::after {
  background-color: #0288d1;
}
.mobileui input[type=checkbox].light-blue-800.switch::before,
.mobileui input[type=radio].light-blue-800::after {
  background-color: #0277bd;
}
.mobileui input[type=checkbox].light-blue-900.switch::before,
.mobileui input[type=radio].light-blue-900::after {
  background-color: #01579b;
}
.mobileui input[type=checkbox].cyan.switch::before,
.mobileui input[type=radio].cyan::after {
  background-color: #00bcd4;
}
.mobileui input[type=checkbox].cyan-50.switch::before,
.mobileui input[type=radio].cyan-50::after {
  background-color: #e0f7fa;
}
.mobileui input[type=checkbox].cyan-100.switch::before,
.mobileui input[type=radio].cyan-100::after {
  background-color: #b2ebf2;
}
.mobileui input[type=checkbox].cyan-200.switch::before,
.mobileui input[type=radio].cyan-200::after {
  background-color: #80deea;
}
.mobileui input[type=checkbox].cyan-300.switch::before,
.mobileui input[type=radio].cyan-300::after {
  background-color: #4dd0e1;
}
.mobileui input[type=checkbox].cyan-400.switch::before,
.mobileui input[type=radio].cyan-400::after {
  background-color: #26c6da;
}
.mobileui input[type=checkbox].cyan-500.switch::before,
.mobileui input[type=radio].cyan-500::after {
  background-color: #00bcd4;
}
.mobileui input[type=checkbox].cyan-600.switch::before,
.mobileui input[type=radio].cyan-600::after {
  background-color: #00acc1;
}
.mobileui input[type=checkbox].cyan-700.switch::before,
.mobileui input[type=radio].cyan-700::after {
  background-color: #0097a7;
}
.mobileui input[type=checkbox].cyan-800.switch::before,
.mobileui input[type=radio].cyan-800::after {
  background-color: #00838f;
}
.mobileui input[type=checkbox].cyan-900.switch::before,
.mobileui input[type=radio].cyan-900::after {
  background-color: #006064;
}
.mobileui input[type=checkbox].teal.switch::before,
.mobileui input[type=radio].teal::after {
  background-color: #009688;
}
.mobileui input[type=checkbox].teal-50.switch::before,
.mobileui input[type=radio].teal-50::after {
  background-color: #e0f2f1;
}
.mobileui input[type=checkbox].teal-100.switch::before,
.mobileui input[type=radio].teal-100::after {
  background-color: #b2dfdb;
}
.mobileui input[type=checkbox].teal-200.switch::before,
.mobileui input[type=radio].teal-200::after {
  background-color: #80cbc4;
}
.mobileui input[type=checkbox].teal-300.switch::before,
.mobileui input[type=radio].teal-300::after {
  background-color: #4db6ac;
}
.mobileui input[type=checkbox].teal-400.switch::before,
.mobileui input[type=radio].teal-400::after {
  background-color: #26a69a;
}
.mobileui input[type=checkbox].teal-500.switch::before,
.mobileui input[type=radio].teal-500::after {
  background-color: #009688;
}
.mobileui input[type=checkbox].teal-600.switch::before,
.mobileui input[type=radio].teal-600::after {
  background-color: #00897b;
}
.mobileui input[type=checkbox].teal-700.switch::before,
.mobileui input[type=radio].teal-700::after {
  background-color: #00796b;
}
.mobileui input[type=checkbox].teal-800.switch::before,
.mobileui input[type=radio].teal-800::after {
  background-color: #00695c;
}
.mobileui input[type=checkbox].teal-900.switch::before,
.mobileui input[type=radio].teal-900::after {
  background-color: #004d40;
}
.mobileui input[type=checkbox].green.switch::before,
.mobileui input[type=radio].green::after {
  background-color: #4caf50;
}
.mobileui input[type=checkbox].green-50.switch::before,
.mobileui input[type=radio].green-50::after {
  background-color: #e8f5e9;
}
.mobileui input[type=checkbox].green-100.switch::before,
.mobileui input[type=radio].green-100::after {
  background-color: #c8e6c9;
}
.mobileui input[type=checkbox].green-200.switch::before,
.mobileui input[type=radio].green-200::after {
  background-color: #a5d6a7;
}
.mobileui input[type=checkbox].green-300.switch::before,
.mobileui input[type=radio].green-300::after {
  background-color: #81c784;
}
.mobileui input[type=checkbox].green-400.switch::before,
.mobileui input[type=radio].green-400::after {
  background-color: #66bb6a;
}
.mobileui input[type=checkbox].green-500.switch::before,
.mobileui input[type=radio].green-500::after {
  background-color: #4caf50;
}
.mobileui input[type=checkbox].green-600.switch::before,
.mobileui input[type=radio].green-600::after {
  background-color: #43a047;
}
.mobileui input[type=checkbox].green-700.switch::before,
.mobileui input[type=radio].green-700::after {
  background-color: #388e3c;
}
.mobileui input[type=checkbox].green-800.switch::before,
.mobileui input[type=radio].green-800::after {
  background-color: #2e7d32;
}
.mobileui input[type=checkbox].green-900.switch::before,
.mobileui input[type=radio].green-900::after {
  background-color: #1b5e20;
}
.mobileui input[type=checkbox].light-green.switch::before,
.mobileui input[type=radio].light-green::after {
  background-color: #8bc34a;
}
.mobileui input[type=checkbox].light-green-50.switch::before,
.mobileui input[type=radio].light-green-50::after {
  background-color: #f1f8e9;
}
.mobileui input[type=checkbox].light-green-100.switch::before,
.mobileui input[type=radio].light-green-100::after {
  background-color: #dcedc8;
}
.mobileui input[type=checkbox].light-green-200.switch::before,
.mobileui input[type=radio].light-green-200::after {
  background-color: #c5e1a5;
}
.mobileui input[type=checkbox].light-green-300.switch::before,
.mobileui input[type=radio].light-green-300::after {
  background-color: #aed581;
}
.mobileui input[type=checkbox].light-green-400.switch::before,
.mobileui input[type=radio].light-green-400::after {
  background-color: #9ccc65;
}
.mobileui input[type=checkbox].light-green-500.switch::before,
.mobileui input[type=radio].light-green-500::after {
  background-color: #8bc34a;
}
.mobileui input[type=checkbox].light-green-600.switch::before,
.mobileui input[type=radio].light-green-600::after {
  background-color: #7cb342;
}
.mobileui input[type=checkbox].light-green-700.switch::before,
.mobileui input[type=radio].light-green-700::after {
  background-color: #689f38;
}
.mobileui input[type=checkbox].light-green-800.switch::before,
.mobileui input[type=radio].light-green-800::after {
  background-color: #558b2f;
}
.mobileui input[type=checkbox].light-green-900.switch::before,
.mobileui input[type=radio].light-green-900::after {
  background-color: #33691e;
}
.mobileui input[type=checkbox].lime.switch::before,
.mobileui input[type=radio].lime::after {
  background-color: #cddc39;
}
.mobileui input[type=checkbox].lime-50.switch::before,
.mobileui input[type=radio].lime-50::after {
  background-color: #f9fbe7;
}
.mobileui input[type=checkbox].lime-100.switch::before,
.mobileui input[type=radio].lime-100::after {
  background-color: #f0f4c3;
}
.mobileui input[type=checkbox].lime-200.switch::before,
.mobileui input[type=radio].lime-200::after {
  background-color: #e6ee9c;
}
.mobileui input[type=checkbox].lime-300.switch::before,
.mobileui input[type=radio].lime-300::after {
  background-color: #dce775;
}
.mobileui input[type=checkbox].lime-400.switch::before,
.mobileui input[type=radio].lime-400::after {
  background-color: #d4e157;
}
.mobileui input[type=checkbox].lime-500.switch::before,
.mobileui input[type=radio].lime-500::after {
  background-color: #cddc39;
}
.mobileui input[type=checkbox].lime-600.switch::before,
.mobileui input[type=radio].lime-600::after {
  background-color: #c0ca33;
}
.mobileui input[type=checkbox].lime-700.switch::before,
.mobileui input[type=radio].lime-700::after {
  background-color: #afb42b;
}
.mobileui input[type=checkbox].lime-800.switch::before,
.mobileui input[type=radio].lime-800::after {
  background-color: #9e9d24;
}
.mobileui input[type=checkbox].lime-900.switch::before,
.mobileui input[type=radio].lime-900::after {
  background-color: #827717;
}
.mobileui input[type=checkbox].yellow.switch::before,
.mobileui input[type=radio].yellow::after {
  background-color: #ffeb3b;
}
.mobileui input[type=checkbox].yellow-50.switch::before,
.mobileui input[type=radio].yellow-50::after {
  background-color: #fffde7;
}
.mobileui input[type=checkbox].yellow-100.switch::before,
.mobileui input[type=radio].yellow-100::after {
  background-color: #fff9c4;
}
.mobileui input[type=checkbox].yellow-200.switch::before,
.mobileui input[type=radio].yellow-200::after {
  background-color: #fff59d;
}
.mobileui input[type=checkbox].yellow-300.switch::before,
.mobileui input[type=radio].yellow-300::after {
  background-color: #fff176;
}
.mobileui input[type=checkbox].yellow-500.switch::before,
.mobileui input[type=radio].yellow-500::after {
  background-color: #ffeb3b;
}
.mobileui input[type=checkbox].yellow-600.switch::before,
.mobileui input[type=radio].yellow-600::after {
  background-color: #fdd835;
}
.mobileui input[type=checkbox].yellow-700.switch::before,
.mobileui input[type=radio].yellow-700::after {
  background-color: #fbc02d;
}
.mobileui input[type=checkbox].yellow-800.switch::before,
.mobileui input[type=radio].yellow-800::after {
  background-color: #f9a825;
}
.mobileui input[type=checkbox].yellow-900.switch::before,
.mobileui input[type=radio].yellow-900::after {
  background-color: #f57f17;
}
.mobileui input[type=checkbox].amber-50.switch::before,
.mobileui input[type=radio].amber-50::after {
  background-color: #fff8e1;
}
.mobileui input[type=checkbox].amber-100.switch::before,
.mobileui input[type=radio].amber-100::after {
  background-color: #ffecb3;
}
.mobileui input[type=checkbox].amber-200.switch::before,
.mobileui input[type=radio].amber-200::after {
  background-color: #ffe082;
}
.mobileui input[type=checkbox].amber-300.switch::before,
.mobileui input[type=radio].amber-300::after {
  background-color: #ffd54f;
}
.mobileui input[type=checkbox].amber-500.switch::before,
.mobileui input[type=radio].amber-500::after {
  background-color: #ffc107;
}
.mobileui input[type=checkbox].amber-600.switch::before,
.mobileui input[type=radio].amber-600::after {
  background-color: #ffb300;
}
.mobileui input[type=checkbox].amber-700.switch::before,
.mobileui input[type=radio].amber-700::after {
  background-color: #ffa000;
}
.mobileui input[type=checkbox].amber-800.switch::before,
.mobileui input[type=radio].amber-800::after {
  background-color: #ff8f00;
}
.mobileui input[type=checkbox].amber-900.switch::before,
.mobileui input[type=radio].amber-900::after {
  background-color: #ff6f00;
}
.mobileui input[type=checkbox].orange-50.switch::before,
.mobileui input[type=radio].orange-50::after {
  background-color: #fff3e0;
}
.mobileui input[type=checkbox].orange-100.switch::before,
.mobileui input[type=radio].orange-100::after {
  background-color: #ffe0b2;
}
.mobileui input[type=checkbox].orange-200.switch::before,
.mobileui input[type=radio].orange-200::after {
  background-color: #ffcc80;
}
.mobileui input[type=checkbox].orange-300.switch::before,
.mobileui input[type=radio].orange-300::after {
  background-color: #ffb74d;
}
.mobileui input[type=checkbox].orange-400.switch::before,
.mobileui input[type=radio].orange-400::after {
  background-color: #ffa726;
}
.mobileui input[type=checkbox].orange-500.switch::before,
.mobileui input[type=radio].orange-500::after {
  background-color: #ff9800;
}
.mobileui input[type=checkbox].orange-600.switch::before,
.mobileui input[type=radio].orange-600::after {
  background-color: #fb8c00;
}
.mobileui input[type=checkbox].orange-700.switch::before,
.mobileui input[type=radio].orange-700::after {
  background-color: #f57c00;
}
.mobileui input[type=checkbox].orange-800.switch::before,
.mobileui input[type=radio].orange-800::after {
  background-color: #ef6c00;
}
.mobileui input[type=checkbox].orange-900.switch::before,
.mobileui input[type=radio].orange-900::after {
  background-color: #e65100;
}
.mobileui input[type=checkbox].deep-orange.switch::before,
.mobileui input[type=radio].deep-orange::after {
  background-color: #ff5722;
}
.mobileui input[type=checkbox].deep-orange-50.switch::before,
.mobileui input[type=radio].deep-orange-50::after {
  background-color: #fbe9e7;
}
.mobileui input[type=checkbox].deep-orange-100.switch::before,
.mobileui input[type=radio].deep-orange-100::after {
  background-color: #ffccbc;
}
.mobileui input[type=checkbox].deep-orange-200.switch::before,
.mobileui input[type=radio].deep-orange-200::after {
  background-color: #ffab91;
}
.mobileui input[type=checkbox].deep-orange-300.switch::before,
.mobileui input[type=radio].deep-orange-300::after {
  background-color: #ff8a65;
}
.mobileui input[type=checkbox].deep-orange-400.switch::before,
.mobileui input[type=radio].deep-orange-400::after {
  background-color: #ff7043;
}
.mobileui input[type=checkbox].deep-orange-500.switch::before,
.mobileui input[type=radio].deep-orange-500::after {
  background-color: #ff5722;
}
.mobileui input[type=checkbox].deep-orange-600.switch::before,
.mobileui input[type=radio].deep-orange-600::after {
  background-color: #f4511e;
}
.mobileui input[type=checkbox].deep-orange-700.switch::before,
.mobileui input[type=radio].deep-orange-700::after {
  background-color: #e64a19;
}
.mobileui input[type=checkbox].deep-orange-800.switch::before,
.mobileui input[type=radio].deep-orange-800::after {
  background-color: #d84315;
}
.mobileui input[type=checkbox].deep-orange-900.switch::before,
.mobileui input[type=radio].deep-orange-900::after {
  background-color: #bf360c;
}
.mobileui input[type=checkbox].brown.switch::before,
.mobileui input[type=radio].brown::after {
  background-color: #795548;
}
.mobileui input[type=checkbox].brown-50.switch::before,
.mobileui input[type=radio].brown-50::after {
  background-color: #efebe9;
}
.mobileui input[type=checkbox].brown-100.switch::before,
.mobileui input[type=radio].brown-100::after {
  background-color: #d7ccc8;
}
.mobileui input[type=checkbox].brown-200.switch::before,
.mobileui input[type=radio].brown-200::after {
  background-color: #bcaaa4;
}
.mobileui input[type=checkbox].brown-300.switch::before,
.mobileui input[type=radio].brown-300::after {
  background-color: #a1887f;
}
.mobileui input[type=checkbox].brown-400.switch::before,
.mobileui input[type=radio].brown-400::after {
  background-color: #8d6e63;
}
.mobileui input[type=checkbox].brown-500.switch::before,
.mobileui input[type=radio].brown-500::after {
  background-color: #795548;
}
.mobileui input[type=checkbox].brown-600.switch::before,
.mobileui input[type=radio].brown-600::after {
  background-color: #6d4c41;
}
.mobileui input[type=checkbox].brown-700.switch::before,
.mobileui input[type=radio].brown-700::after {
  background-color: #5d4037;
}
.mobileui input[type=checkbox].brown-800.switch::before,
.mobileui input[type=radio].brown-800::after {
  background-color: #4e342e;
}
.mobileui input[type=checkbox].brown-900.switch::before,
.mobileui input[type=radio].brown-900::after {
  background-color: #3e2723;
}
.mobileui input[type=checkbox].grey.switch::before,
.mobileui input[type=radio].grey::after {
  background-color: #9e9e9e;
}
.mobileui input[type=checkbox].grey-50.switch::before,
.mobileui input[type=radio].grey-50::after {
  background-color: #fafafa;
}
.mobileui input[type=checkbox].grey-100.switch::before,
.mobileui input[type=radio].grey-100::after {
  background-color: #f5f5f5;
}
.mobileui input[type=checkbox].grey-200.switch::before,
.mobileui input[type=radio].grey-200::after {
  background-color: #eee;
}
.mobileui input[type=checkbox].grey-300.switch::before,
.mobileui input[type=radio].grey-300::after {
  background-color: #e0e0e0;
}
.mobileui input[type=checkbox].grey-400.switch::before,
.mobileui input[type=radio].grey-400::after {
  background-color: #bdbdbd;
}
.mobileui input[type=checkbox].grey-500.switch::before,
.mobileui input[type=radio].grey-500::after {
  background-color: #9e9e9e;
}
.mobileui input[type=checkbox].grey-600.switch::before,
.mobileui input[type=radio].grey-600::after {
  background-color: #757575;
}
.mobileui input[type=checkbox].grey-700.switch::before,
.mobileui input[type=radio].grey-700::after {
  background-color: #616161;
}
.mobileui input[type=checkbox].grey-800.switch::before,
.mobileui input[type=radio].grey-800::after {
  background-color: #424242;
}
.mobileui input[type=checkbox].grey-900.switch::before,
.mobileui input[type=radio].grey-900::after {
  background-color: #212121;
}
.mobileui input[type=checkbox].blue-grey.switch::before,
.mobileui input[type=radio].blue-grey::after {
  background-color: #607d8b;
}
.mobileui input[type=checkbox].blue-grey-50.switch::before,
.mobileui input[type=radio].blue-grey-50::after {
  background-color: #eceff1;
}
.mobileui input[type=checkbox].blue-grey-100.switch::before,
.mobileui input[type=radio].blue-grey-100::after {
  background-color: #cfd8dc;
}
.mobileui input[type=checkbox].blue-grey-200.switch::before,
.mobileui input[type=radio].blue-grey-200::after {
  background-color: #b0bec5;
}
.mobileui input[type=checkbox].blue-grey-300.switch::before,
.mobileui input[type=radio].blue-grey-300::after {
  background-color: #90a4ae;
}
.mobileui input[type=checkbox].blue-grey-400.switch::before,
.mobileui input[type=radio].blue-grey-400::after {
  background-color: #78909c;
}
.mobileui input[type=checkbox].blue-grey-500.switch::before,
.mobileui input[type=radio].blue-grey-500::after {
  background-color: #607d8b;
}
.mobileui input[type=checkbox].blue-grey-600.switch::before,
.mobileui input[type=radio].blue-grey-600::after {
  background-color: #546e7a;
}
.mobileui input[type=checkbox].blue-grey-700.switch::before,
.mobileui input[type=radio].blue-grey-700::after {
  background-color: #455a64;
}
.mobileui input[type=checkbox].blue-grey-800.switch::before,
.mobileui input[type=radio].blue-grey-800::after {
  background-color: #37474f;
}
.mobileui input[type=checkbox].blue-grey-900.switch::before,
.mobileui input[type=radio].blue-grey-900::after {
  background-color: #263238;
}
.mobileui input[type=checkbox].black.switch::before,
.mobileui input[type=radio].black::after {
  background-color: #000;
}
.mobileui .platform-ios input[type=radio].red-50::before {
  border-color: #ffebee;
}
.mobileui .platform-ios input[type=radio].red-100::before {
  border-color: #ffcdd2;
}
.mobileui .platform-ios input[type=radio].red-200::before {
  border-color: #ef9a9a;
}
.mobileui .platform-ios input[type=radio].red-300::before {
  border-color: #e57373;
}
.mobileui .platform-ios input[type=radio].red-400::before {
  border-color: #ef5350;
}
.mobileui .platform-ios input[type=radio].red-500::before {
  border-color: #f44336;
}
.mobileui .platform-ios input[type=radio].red-600::before {
  border-color: #e53935;
}
.mobileui .platform-ios input[type=radio].red-700::before {
  border-color: #d32f2f;
}
.mobileui .platform-ios input[type=radio].red-800::before {
  border-color: #c62828;
}
.mobileui .platform-ios input[type=radio].red-900::before {
  border-color: #b71c1c;
}
.mobileui .platform-ios input[type=radio].pink::before {
  border-color: #e91e63;
}
.mobileui .platform-ios input[type=radio].pink-50::before {
  border-color: #fce4ec;
}
.mobileui .platform-ios input[type=radio].pink-100::before {
  border-color: #f8bbd0;
}
.mobileui .platform-ios input[type=radio].pink-200::before {
  border-color: #f48fb1;
}
.mobileui .platform-ios input[type=radio].Pink-300::before {
  border-color: #f06292;
}
.mobileui .platform-ios input[type=radio].pink-400::before {
  border-color: #ec407a;
}
.mobileui .platform-ios input[type=radio].pink-500::before {
  border-color: #e91e63;
}
.mobileui .platform-ios input[type=radio].pink-600::before {
  border-color: #d81b60;
}
.mobileui .platform-ios input[type=radio].pink-700::before {
  border-color: #c2185b;
}
.mobileui .platform-ios input[type=radio].pink-800::before {
  border-color: #ad1457;
}
.mobileui .platform-ios input[type=radio].pink-900::before {
  border-color: #880e4f;
}
.mobileui .platform-ios input[type=radio].purple::before {
  border-color: #9c27b0;
}
.mobileui .platform-ios input[type=radio].purple-50::before {
  border-color: #f3e5f5;
}
.mobileui .platform-ios input[type=radio].purple-100::before {
  border-color: #e1bee7;
}
.mobileui .platform-ios input[type=radio].purple-200::before {
  border-color: #ce93d8;
}
.mobileui .platform-ios input[type=radio].Purple-300::before {
  border-color: #ba68c8;
}
.mobileui .platform-ios input[type=radio].Purple-400::before {
  border-color: #ab47bc;
}
.mobileui .platform-ios input[type=radio].purple-500::before {
  border-color: #9c27b0;
}
.mobileui .platform-ios input[type=radio].purple-600::before {
  border-color: #8e24aa;
}
.mobileui .platform-ios input[type=radio].purple-700::before {
  border-color: #7b1fa2;
}
.mobileui .platform-ios input[type=radio].purple-800::before {
  border-color: #6a1b9a;
}
.mobileui .platform-ios input[type=radio].purple-900::before {
  border-color: #4a148c;
}
.mobileui .platform-ios input[type=radio].deep-purple::before {
  border-color: #673ab7;
}
.mobileui .platform-ios input[type=radio].deep-purple-300::before {
  border-color: #9575cd;
}
.mobileui .platform-ios input[type=radio].deep-purple-400::before {
  border-color: #7e57c2;
}
.mobileui .platform-ios input[type=radio].deep-purple-500::before {
  border-color: #673ab7;
}
.mobileui .platform-ios input[type=radio].deep-purple-600::before {
  border-color: #5e35b1;
}
.mobileui .platform-ios input[type=radio].deep-purple-700::before {
  border-color: #512da8;
}
.mobileui .platform-ios input[type=radio].deep-purple-800::before {
  border-color: #4527a0;
}
.mobileui .platform-ios input[type=radio].deep-purple-900::before {
  border-color: #311b92;
}
.mobileui .platform-ios input[type=radio].indigo::before {
  border-color: #3f51b5;
}
.mobileui .platform-ios input[type=radio].indigo-50::before {
  border-color: #e8eaf6;
}
.mobileui .platform-ios input[type=radio].indigo-100::before {
  border-color: #c5cae9;
}
.mobileui .platform-ios input[type=radio].indigo-200::before {
  border-color: #9fa8da;
}
.mobileui .platform-ios input[type=radio].indigo-300::before {
  border-color: #7986cb;
}
.mobileui .platform-ios input[type=radio].indigo-400::before {
  border-color: #5c6bc0;
}
.mobileui .platform-ios input[type=radio].indigo-500::before {
  border-color: #3f51b5;
}
.mobileui .platform-ios input[type=radio].indigo-600::before {
  border-color: #3949ab;
}
.mobileui .platform-ios input[type=radio].indigo-700::before {
  border-color: #303f9f;
}
.mobileui .platform-ios input[type=radio].indigo-800::before {
  border-color: #283593;
}
.mobileui .platform-ios input[type=radio].indigo-900::before {
  border-color: #1a237e;
}
.mobileui .platform-ios input[type=radio].blue::before {
  border-color: #2196f3;
}
.mobileui .platform-ios input[type=radio].blue-50::before {
  border-color: #e3f2fd;
}
.mobileui .platform-ios input[type=radio].blue-100::before {
  border-color: #bbdefb;
}
.mobileui .platform-ios input[type=radio].blue-200::before {
  border-color: #90caf9;
}
.mobileui .platform-ios input[type=radio].blue-300::before {
  border-color: #64b5f6;
}
.mobileui .platform-ios input[type=radio].blue-400::before {
  border-color: #42a5f5;
}
.mobileui .platform-ios input[type=radio].blue-500::before {
  border-color: #2196f3;
}
.mobileui .platform-ios input[type=radio].blue-600::before {
  border-color: #1e88e5;
}
.mobileui .platform-ios input[type=radio].blue-700::before {
  border-color: #1976d2;
}
.mobileui .platform-ios input[type=radio].blue-800::before {
  border-color: #1565c0;
}
.mobileui .platform-ios input[type=radio].blue-900::before {
  border-color: #0d47a1;
}
.mobileui .platform-ios input[type=radio].light-blue::before {
  border-color: #03a9f4;
}
.mobileui .platform-ios input[type=radio].light-blue-50::before {
  border-color: #e1f5fe;
}
.mobileui .platform-ios input[type=radio].light-blue-100::before {
  border-color: #b3e5fc;
}
.mobileui .platform-ios input[type=radio].light-blue-200::before {
  border-color: #81d4fa;
}
.mobileui .platform-ios input[type=radio].light-blue-300::before {
  border-color: #4fc3f7;
}
.mobileui .platform-ios input[type=radio].light-blue-400::before {
  border-color: #29b6f6;
}
.mobileui .platform-ios input[type=radio].light-blue-500::before {
  border-color: #03a9f4;
}
.mobileui .platform-ios input[type=radio].light-blue-600::before {
  border-color: #039be5;
}
.mobileui .platform-ios input[type=radio].light-blue-700::before {
  border-color: #0288d1;
}
.mobileui .platform-ios input[type=radio].light-blue-800::before {
  border-color: #0277bd;
}
.mobileui .platform-ios input[type=radio].light-blue-900::before {
  border-color: #01579b;
}
.mobileui .platform-ios input[type=radio].cyan::before {
  border-color: #00bcd4;
}
.mobileui .platform-ios input[type=radio].cyan-50::before {
  border-color: #e0f7fa;
}
.mobileui .platform-ios input[type=radio].cyan-100::before {
  border-color: #b2ebf2;
}
.mobileui .platform-ios input[type=radio].cyan-200::before {
  border-color: #80deea;
}
.mobileui .platform-ios input[type=radio].cyan-300::before {
  border-color: #4dd0e1;
}
.mobileui .platform-ios input[type=radio].cyan-400::before {
  border-color: #26c6da;
}
.mobileui .platform-ios input[type=radio].cyan-500::before {
  border-color: #00bcd4;
}
.mobileui .platform-ios input[type=radio].cyan-600::before {
  border-color: #00acc1;
}
.mobileui .platform-ios input[type=radio].cyan-700::before {
  border-color: #0097a7;
}
.mobileui .platform-ios input[type=radio].cyan-800::before {
  border-color: #00838f;
}
.mobileui .platform-ios input[type=radio].cyan-900::before {
  border-color: #006064;
}
.mobileui .platform-ios input[type=radio].teal::before {
  border-color: #009688;
}
.mobileui .platform-ios input[type=radio].teal-50::before {
  border-color: #e0f2f1;
}
.mobileui .platform-ios input[type=radio].teal-100::before {
  border-color: #b2dfdb;
}
.mobileui .platform-ios input[type=radio].teal-200::before {
  border-color: #80cbc4;
}
.mobileui .platform-ios input[type=radio].teal-300::before {
  border-color: #4db6ac;
}
.mobileui .platform-ios input[type=radio].teal-400::before {
  border-color: #26a69a;
}
.mobileui .platform-ios input[type=radio].teal-500::before {
  border-color: #009688;
}
.mobileui .platform-ios input[type=radio].teal-600::before {
  border-color: #00897b;
}
.mobileui .platform-ios input[type=radio].teal-700::before {
  border-color: #00796b;
}
.mobileui .platform-ios input[type=radio].teal-800::before {
  border-color: #00695c;
}
.mobileui .platform-ios input[type=radio].teal-900::before {
  border-color: #004d40;
}
.mobileui .platform-ios input[type=radio].green::before {
  border-color: #4caf50;
}
.mobileui .platform-ios input[type=radio].green-50::before {
  border-color: #e8f5e9;
}
.mobileui .platform-ios input[type=radio].green-100::before {
  border-color: #c8e6c9;
}
.mobileui .platform-ios input[type=radio].green-200::before {
  border-color: #a5d6a7;
}
.mobileui .platform-ios input[type=radio].green-300::before {
  border-color: #81c784;
}
.mobileui .platform-ios input[type=radio].green-400::before {
  border-color: #66bb6a;
}
.mobileui .platform-ios input[type=radio].green-500::before {
  border-color: #4caf50;
}
.mobileui .platform-ios input[type=radio].green-600::before {
  border-color: #43a047;
}
.mobileui .platform-ios input[type=radio].green-700::before {
  border-color: #388e3c;
}
.mobileui .platform-ios input[type=radio].green-800::before {
  border-color: #2e7d32;
}
.mobileui .platform-ios input[type=radio].green-900::before {
  border-color: #1b5e20;
}
.mobileui .platform-ios input[type=radio].light-green::before {
  border-color: #8bc34a;
}
.mobileui .platform-ios input[type=radio].light-green-50::before {
  border-color: #f1f8e9;
}
.mobileui .platform-ios input[type=radio].light-green-100::before {
  border-color: #dcedc8;
}
.mobileui .platform-ios input[type=radio].light-green-200::before {
  border-color: #c5e1a5;
}
.mobileui .platform-ios input[type=radio].light-green-300::before {
  border-color: #aed581;
}
.mobileui .platform-ios input[type=radio].light-green-400::before {
  border-color: #9ccc65;
}
.mobileui .platform-ios input[type=radio].light-green-500::before {
  border-color: #8bc34a;
}
.mobileui .platform-ios input[type=radio].light-green-600::before {
  border-color: #7cb342;
}
.mobileui .platform-ios input[type=radio].light-green-700::before {
  border-color: #689f38;
}
.mobileui .platform-ios input[type=radio].light-green-800::before {
  border-color: #558b2f;
}
.mobileui .platform-ios input[type=radio].light-green-900::before {
  border-color: #33691e;
}
.mobileui .platform-ios input[type=radio].lime::before {
  border-color: #cddc39;
}
.mobileui .platform-ios input[type=radio].lime-50::before {
  border-color: #f9fbe7;
}
.mobileui .platform-ios input[type=radio].lime-100::before {
  border-color: #f0f4c3;
}
.mobileui .platform-ios input[type=radio].lime-200::before {
  border-color: #e6ee9c;
}
.mobileui .platform-ios input[type=radio].lime-300::before {
  border-color: #dce775;
}
.mobileui .platform-ios input[type=radio].lime-400::before {
  border-color: #d4e157;
}
.mobileui .platform-ios input[type=radio].lime-500::before {
  border-color: #cddc39;
}
.mobileui .platform-ios input[type=radio].lime-600::before {
  border-color: #c0ca33;
}
.mobileui .platform-ios input[type=radio].lime-700::before {
  border-color: #afb42b;
}
.mobileui .platform-ios input[type=radio].lime-800::before {
  border-color: #9e9d24;
}
.mobileui .platform-ios input[type=radio].lime-900::before {
  border-color: #827717;
}
.mobileui .platform-ios input[type=radio].yellow::before {
  border-color: #ffeb3b;
}
.mobileui .platform-ios input[type=radio].yellow-50::before {
  border-color: #fffde7;
}
.mobileui .platform-ios input[type=radio].yellow-100::before {
  border-color: #fff9c4;
}
.mobileui .platform-ios input[type=radio].yellow-200::before {
  border-color: #fff59d;
}
.mobileui .platform-ios input[type=radio].yellow-300::before {
  border-color: #fff176;
}
.mobileui .platform-ios input[type=radio].yellow-500::before {
  border-color: #ffeb3b;
}
.mobileui .platform-ios input[type=radio].yellow-600::before {
  border-color: #fdd835;
}
.mobileui .platform-ios input[type=radio].yellow-700::before {
  border-color: #fbc02d;
}
.mobileui .platform-ios input[type=radio].yellow-800::before {
  border-color: #f9a825;
}
.mobileui .platform-ios input[type=radio].yellow-900::before {
  border-color: #f57f17;
}
.mobileui .platform-ios input[type=radio].amber-50::before {
  border-color: #fff8e1;
}
.mobileui .platform-ios input[type=radio].amber-100::before {
  border-color: #ffecb3;
}
.mobileui .platform-ios input[type=radio].amber-200::before {
  border-color: #ffe082;
}
.mobileui .platform-ios input[type=radio].amber-300::before {
  border-color: #ffd54f;
}
.mobileui .platform-ios input[type=radio].amber-500::before {
  border-color: #ffc107;
}
.mobileui .platform-ios input[type=radio].amber-600::before {
  border-color: #ffb300;
}
.mobileui .platform-ios input[type=radio].amber-700::before {
  border-color: #ffa000;
}
.mobileui .platform-ios input[type=radio].amber-800::before {
  border-color: #ff8f00;
}
.mobileui .platform-ios input[type=radio].amber-900::before {
  border-color: #ff6f00;
}
.mobileui .platform-ios input[type=radio].orange-50::before {
  border-color: #fff3e0;
}
.mobileui .platform-ios input[type=radio].orange-100::before {
  border-color: #ffe0b2;
}
.mobileui .platform-ios input[type=radio].orange-200::before {
  border-color: #ffcc80;
}
.mobileui .platform-ios input[type=radio].orange-300::before {
  border-color: #ffb74d;
}
.mobileui .platform-ios input[type=radio].orange-400::before {
  border-color: #ffa726;
}
.mobileui .platform-ios input[type=radio].orange-500::before {
  border-color: #ff9800;
}
.mobileui .platform-ios input[type=radio].orange-600::before {
  border-color: #fb8c00;
}
.mobileui .platform-ios input[type=radio].orange-700::before {
  border-color: #f57c00;
}
.mobileui .platform-ios input[type=radio].orange-800::before {
  border-color: #ef6c00;
}
.mobileui .platform-ios input[type=radio].orange-900::before {
  border-color: #e65100;
}
.mobileui .platform-ios input[type=radio].deep-orange::before {
  border-color: #ff5722;
}
.mobileui .platform-ios input[type=radio].deep-orange-50::before {
  border-color: #fbe9e7;
}
.mobileui .platform-ios input[type=radio].deep-orange-100::before {
  border-color: #ffccbc;
}
.mobileui .platform-ios input[type=radio].deep-orange-200::before {
  border-color: #ffab91;
}
.mobileui .platform-ios input[type=radio].deep-orange-300::before {
  border-color: #ff8a65;
}
.mobileui .platform-ios input[type=radio].deep-orange-400::before {
  border-color: #ff7043;
}
.mobileui .platform-ios input[type=radio].deep-orange-500::before {
  border-color: #ff5722;
}
.mobileui .platform-ios input[type=radio].deep-orange-600::before {
  border-color: #f4511e;
}
.mobileui .platform-ios input[type=radio].deep-orange-700::before {
  border-color: #e64a19;
}
.mobileui .platform-ios input[type=radio].deep-orange-800::before {
  border-color: #d84315;
}
.mobileui .platform-ios input[type=radio].deep-orange-900::before {
  border-color: #bf360c;
}
.mobileui .platform-ios input[type=radio].brown::before {
  border-color: #795548;
}
.mobileui .platform-ios input[type=radio].brown-50::before {
  border-color: #efebe9;
}
.mobileui .platform-ios input[type=radio].brown-100::before {
  border-color: #d7ccc8;
}
.mobileui .platform-ios input[type=radio].brown-200::before {
  border-color: #bcaaa4;
}
.mobileui .platform-ios input[type=radio].brown-300::before {
  border-color: #a1887f;
}
.mobileui .platform-ios input[type=radio].brown-400::before {
  border-color: #8d6e63;
}
.mobileui .platform-ios input[type=radio].brown-500::before {
  border-color: #795548;
}
.mobileui .platform-ios input[type=radio].brown-600::before {
  border-color: #6d4c41;
}
.mobileui .platform-ios input[type=radio].brown-700::before {
  border-color: #5d4037;
}
.mobileui .platform-ios input[type=radio].brown-800::before {
  border-color: #4e342e;
}
.mobileui .platform-ios input[type=radio].brown-900::before {
  border-color: #3e2723;
}
.mobileui .platform-ios input[type=radio].grey::before {
  border-color: #9e9e9e;
}
.mobileui .platform-ios input[type=radio].grey-50::before {
  border-color: #fafafa;
}
.mobileui .platform-ios input[type=radio].grey-100::before {
  border-color: #f5f5f5;
}
.mobileui .platform-ios input[type=radio].grey-200::before {
  border-color: #eee;
}
.mobileui .platform-ios input[type=radio].grey-300::before {
  border-color: #e0e0e0;
}
.mobileui .platform-ios input[type=radio].grey-400::before {
  border-color: #bdbdbd;
}
.mobileui .platform-ios input[type=radio].grey-500::before {
  border-color: #9e9e9e;
}
.mobileui .platform-ios input[type=radio].grey-600::before {
  border-color: #757575;
}
.mobileui .platform-ios input[type=radio].grey-700::before {
  border-color: #616161;
}
.mobileui .platform-ios input[type=radio].grey-800::before {
  border-color: #424242;
}
.mobileui .platform-ios input[type=radio].grey-900::before {
  border-color: #212121;
}
.mobileui .platform-ios input[type=radio].blue-grey::before {
  border-color: #607d8b;
}
.mobileui .platform-ios input[type=radio].blue-grey-50::before {
  border-color: #eceff1;
}
.mobileui .platform-ios input[type=radio].blue-grey-100::before {
  border-color: #cfd8dc;
}
.mobileui .platform-ios input[type=radio].blue-grey-200::before {
  border-color: #b0bec5;
}
.mobileui .platform-ios input[type=radio].blue-grey-300::before {
  border-color: #90a4ae;
}
.mobileui .platform-ios input[type=radio].blue-grey-400::before {
  border-color: #78909c;
}
.mobileui .platform-ios input[type=radio].blue-grey-500::before {
  border-color: #607d8b;
}
.mobileui .platform-ios input[type=radio].blue-grey-600::before {
  border-color: #546e7a;
}
.mobileui .platform-ios input[type=radio].blue-grey-700::before {
  border-color: #455a64;
}
.mobileui .platform-ios input[type=radio].blue-grey-800::before {
  border-color: #37474f;
}
.mobileui .platform-ios input[type=radio].blue-grey-900::before {
  border-color: #263238;
}
.mobileui .platform-ios input[type=radio].black::before {
  border-color: #000;
}
.mobileui .component-tab {
  display: block;
}
.mobileui .tab {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
.mobileui .tab-content {
  display: none;
}
.mobileui .tab-content.active {
  display: block;
}
.mobileui .tab button {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  font-size: 13px !important;
  text-transform: uppercase;
  opacity: 0.6;
  margin: 0;
}
.mobileui .footer.tab button.icon {
  margin-top: 0;
}
.mobileui .header.tab.shadow {
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
}
.mobileui .platform-ios .tab button {
  text-transform: none;
}
.mobileui .tab button.active {
  opacity: 1;
}
.mobileui .tab button.icon {
  font-size: 14px;
  line-height: 16px;
}
.mobileui .tab button::before {
  display: block;
  font-size: 25px;
}
.mobileui .tab button.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #fff;
}
.mobileui .tab button .badge {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  border-radius: 50%;
  margin-top: 10px;
  position: absolute;
  line-height: 18px;
  padding-top: 1px;
}
.mobileui .tab.footer button.active::after {
  top: 0;
}
.mobileui .header.tab.footer.shadow {
  box-shadow: -2px -2px 3px rgba(0, 0, 0, 0.2);
}
.mobileui .platform-ios .tab button.active::after {
  display: none;
}
.mobileui .component-swiper {
  display: block;
}
.mobileui .swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.mobileui .swiper-container-fullscreen {
  height: 100%;
}
.mobileui .swipper-gallery {
  height: 300px;
}
.mobileui .swiper-container-no-flexbox .swiper-slide {
  float: left;
}
.mobileui .swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.mobileui .swiper-wrapper {
  position: relative;
  width: 100%;
  z-index: 1;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.mobileui .swiper-container-android .swiper-slide,
.mobileui .swiper-wrapper {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate(0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.mobileui .swiper-container-multirow > .swiper-wrapper {
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.mobileui .swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto;
}
.mobileui .swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex: 0 0 auto;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
}
.mobileui .swiper-slide.vertical-align-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  text-align: center;
}
.mobileui .swiper-slide i {
  font-size: 120px;
}
.mobileui .swiper-slide h1 {
  font-size: 40px;
  font-weight: 600;
  width: 100%;
}
.mobileui .swiper-slide h2 {
  font-size: 30px;
  font-weight: 600;
  width: 100%;
}
.mobileui .swiper-slide .margin-bottom {
  margin-bottom: 25px;
}
.mobileui .swiper-container-autoheight,
.mobileui .swiper-container-autoheight .swiper-slide {
  height: auto;
}
.mobileui .swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-transition-property: -webkit-transform, height;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform, height;
}
.mobileui .swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}
.mobileui .swiper-wp8-horizontal {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}
.mobileui .swiper-wp8-vertical {
  -ms-touch-action: pan-x;
  touch-action: pan-x;
}
.mobileui .swiper-button-next,
.mobileui .swiper-button-prev {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  -moz-background-size: 27px 44px;
  -webkit-background-size: 27px 44px;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat;
}
.mobileui .swiper-button-next.swiper-button-disabled,
.mobileui .swiper-button-prev.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}
.mobileui .swiper-button-prev,
.mobileui .swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  left: 10px;
  right: auto;
}
.mobileui .swiper-button-prev.swiper-button-black,
.mobileui .swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}
.mobileui .swiper-button-prev.swiper-button-white,
.mobileui .swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}
.mobileui .swiper-button-next,
.mobileui .swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  right: 10px;
  left: auto;
}
.mobileui .swiper-button-next.swiper-button-black,
.mobileui .swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}
.mobileui .swiper-button-next.swiper-button-white,
.mobileui .swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}
.mobileui .swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10;
}
.mobileui .swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}
.mobileui .swiper-container-horizontal > .swiper-pagination-bullets,
.mobileui .swiper-pagination-custom,
.mobileui .swiper-pagination-fraction {
  bottom: 10px;
  left: 0;
  width: 100%;
}
.mobileui .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: 0.2;
}
.mobileui button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
.mobileui .swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}
.mobileui .swiper-pagination-white .swiper-pagination-bullet {
  background: #fff;
}
.mobileui .swiper-pagination-bullet-active {
  opacity: 1;
  background: #007aff;
}
.mobileui .swiper-pagination-white .swiper-pagination-bullet-active {
  background: #fff;
}
.mobileui .swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000;
}
.mobileui .swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0, -50%, 0);
  -moz-transform: translate3d(0, -50%, 0);
  -o-transform: translate(0, -50%);
  -ms-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}
.mobileui .swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 5px 0;
  display: block;
}
.mobileui .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 5px;
}
.mobileui .swiper-pagination-progress {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
}
.mobileui .swiper-pagination-progress .swiper-pagination-progressbar {
  background: #007aff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  transform-origin: left top;
}
.mobileui .swiper-container-rtl .swiper-pagination-progress .swiper-pagination-progressbar {
  -webkit-transform-origin: right top;
  -moz-transform-origin: right top;
  -ms-transform-origin: right top;
  -o-transform-origin: right top;
  transform-origin: right top;
}
.mobileui .swiper-container-horizontal > .swiper-pagination-progress {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0;
}
.mobileui .swiper-container-vertical > .swiper-pagination-progress {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
}
.mobileui .swiper-pagination-progress.swiper-pagination-white {
  background: rgba(255, 255, 255, 0.5);
}
.mobileui .swiper-pagination-progress.swiper-pagination-white .swiper-pagination-progressbar {
  background: #fff;
}
.mobileui .swiper-pagination-progress.swiper-pagination-black .swiper-pagination-progressbar {
  background: #000;
}
.mobileui .swiper-container-3d {
  -webkit-perspective: 1200px;
  -moz-perspective: 1200px;
  -o-perspective: 1200px;
  perspective: 1200px;
}
.mobileui .swiper-container-3d .swiper-cube-shadow,
.mobileui .swiper-container-3d .swiper-slide,
.mobileui .swiper-container-3d .swiper-slide-shadow-bottom,
.mobileui .swiper-container-3d .swiper-slide-shadow-left,
.mobileui .swiper-container-3d .swiper-slide-shadow-right,
.mobileui .swiper-container-3d .swiper-slide-shadow-top,
.mobileui .swiper-container-3d .swiper-wrapper {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.mobileui .swiper-container-3d .swiper-slide-shadow-bottom,
.mobileui .swiper-container-3d .swiper-slide-shadow-left,
.mobileui .swiper-container-3d .swiper-slide-shadow-right,
.mobileui .swiper-container-3d .swiper-slide-shadow-top {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
}
.mobileui .swiper-container-3d .swiper-slide-shadow-left {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -moz-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.mobileui .swiper-container-3d .swiper-slide-shadow-right {
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.mobileui .swiper-container-3d .swiper-slide-shadow-top {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -moz-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.mobileui .swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.mobileui .swiper-container-coverflow .swiper-wrapper,
.mobileui .swiper-container-flip .swiper-wrapper {
  -ms-perspective: 1200px;
}
.mobileui .swiper-container-cube,
.mobileui .swiper-container-flip {
  overflow: visible;
}
.mobileui .swiper-container-cube .swiper-slide,
.mobileui .swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
}
.mobileui .swiper-container-cube .swiper-slide .swiper-slide,
.mobileui .swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none;
}
.mobileui .swiper-container-cube .swiper-slide-active,
.mobileui .swiper-container-cube .swiper-slide-active .swiper-slide-active,
.mobileui .swiper-container-flip .swiper-slide-active,
.mobileui .swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}
.mobileui .swiper-container-cube .swiper-slide-shadow-bottom,
.mobileui .swiper-container-cube .swiper-slide-shadow-left,
.mobileui .swiper-container-cube .swiper-slide-shadow-right,
.mobileui .swiper-container-cube .swiper-slide-shadow-top,
.mobileui .swiper-container-flip .swiper-slide-shadow-bottom,
.mobileui .swiper-container-flip .swiper-slide-shadow-left,
.mobileui .swiper-container-flip .swiper-slide-shadow-right,
.mobileui .swiper-container-flip .swiper-slide-shadow-top {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}
.mobileui .swiper-container-cube .swiper-slide {
  visibility: hidden;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
}
.mobileui .swiper-container-cube.swiper-container-rtl .swiper-slide {
  -webkit-transform-origin: 100% 0;
  -moz-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0;
}
.mobileui .swiper-container-cube .swiper-slide-active,
.mobileui .swiper-container-cube .swiper-slide-next,
.mobileui .swiper-container-cube .swiper-slide-next + .swiper-slide,
.mobileui .swiper-container-cube .swiper-slide-prev {
  pointer-events: auto;
  visibility: visible;
}
.mobileui .swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0;
}
.mobileui .swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.mobileui .swiper-container-fade .swiper-slide {
  pointer-events: none;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
}
.mobileui .swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}
.mobileui .swiper-container-fade .swiper-slide-active,
.mobileui .swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}
.mobileui .swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  text-align: center;
}
.mobileui .swiper-zoom-container > canvas,
.mobileui .swiper-zoom-container > img,
.mobileui .swiper-zoom-container > svg {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.mobileui .swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1);
}
.mobileui .swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%;
}
.mobileui .swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%;
}
.mobileui .swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0;
}
.mobileui .swiper-scrollbar-cursor-drag {
  cursor: move;
}
.mobileui .swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  -moz-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  -moz-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  animation: swiper-preloader-spin 1s steps(12, end) infinite;
}
.mobileui .swiper-lazy-preloader:after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  -webkit-background-size: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
}
.mobileui .swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}
@-webkit-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg);
  }
}
.mobileui .component-mobileuijs {
  display: block;
}
.mobileui [data] {
  display: none !important;
}
.mobileui .component-timeline {
  display: block;
}
.mobileui .timeline {
  font-size: 14px;
  padding: 2px;
}
.mobileui .timeline .marker {
  position: relative;
}
.mobileui .timeline .marker::before {
  background: rgba(0, 0, 0, 0.3);
  content: "";
  height: 100%;
  width: 2px;
  margin: 0 auto;
  position: absolute;
  margin-top: 10px;
  z-index: 0;
  left: 18px;
}
.mobileui .timeline .row:last-child .marker::before {
  display: none;
}
.mobileui .timeline .icon-circle,
.mobileui .timeline .icon-circle-big,
.mobileui .timeline .icon-circle-small {
  margin: 0 auto;
  margin-top: 10px;
  position: absolute;
  margin-left: 7px;
}
.mobileui .timeline .icon-circle i::before,
.mobileui .timeline .icon-circle-big i::before,
.mobileui .timeline .icon-circle-small i::before {
  margin-top: 4px;
}
.mobileui .timeline .avatar-group {
  -webkit-transform: translateX(10px);
  transform: translateX(10px);
}
.mobileui .timeline .avatar-photo {
  background: #eaeaea;
  width: 30px;
  height: 30px;
  overflow: hidden;
  text-align: center;
  border: 1px solid #d7d2d2;
}
.mobileui .timeline .avatar-photo small {
  margin-top: 100px;
}
.mobileui .timeline .circle-more {
  background: #eaeaea;
  width: 30px;
  height: 30px;
  overflow: hidden;
  text-align: center;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #bdbbbb;
  border: 1px solid #d7d2d2;
  font-size: 12px;
}
.mobileui .component-alert {
  display: block;
}
.mobileui .alert-mobileui {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.mobileui .alert-mobileui .alert {
  font-family: Roboto, Noto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  border-radius: 2px;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.4);
  max-width: 270px;
}
.mobileui .platform-ios .alert-mobileui .alert {
  font-family: -apple-system, "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  border-radius: 8px;
  background-color: #fff !important;
  box-shadow: none;
}
.mobileui .alert-mobileui .alert h1 {
  text-align: left;
  font-size: 20px;
  font-weight: 500;
  padding: 22px 22px 0 24px;
}
.mobileui .platform-ios .alert-mobileui .alert h1 {
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  color: #000 !important;
  padding: 0;
  padding-top: 22px;
}
.mobileui .alert-mobileui .alert p {
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  padding: 0 24px;
  margin-top: 24px;
  margin-bottom: 24px;
  min-height: 0;
  opacity: 0.8;
}
.mobileui .platform-ios .alert-mobileui .alert p {
  font-size: 14px;
  text-align: center;
  color: #000 !important;
}
.mobileui .alert-mobileui .alert .buttons {
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
}
.mobileui .alert-mobileui .alert .buttons button {
  text-transform: uppercase;
  display: inline-block;
  width: auto;
  min-width: 70px;
  float: right;
  background: 0 0;
  border-top: none;
  font-size: 14px;
  font-weight: 600;
  outline: 0;
  margin-right: 5px;
  text-transform: uppercase;
}
.mobileui .platform-ios .alert-mobileui .alert .buttons button {
  width: 100%;
  text-align: center;
  text-transform: none;
  font-weight: 400;
  font-size: 16px;
  border-top: 1px solid #ddd;
  padding: 0 8px;
  margin-right: 0;
  color: rgba(24, 103, 194, 0.81) !important;
  background: 0 0 !important;
}
.mobileui .component-toast {
  display: block;
}
.mobileui .toast {
  z-index: 999999;
  position: absolute;
  left: 0;
  padding: 20px;
  text-align: center;
  width: 100%;
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
  -webkit-transition: opacity 0.25s ease-in-out;
}
.mobileui .toast.show {
  opacity: 1;
}
.mobileui .toast.toast-bottom {
  bottom: 10px;
}
.mobileui .toast.toast-top {
  top: 10px;
}
.mobileui .toast.toast-center {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 100%;
  top: 0;
}
.mobileui .toast div {
  padding: 10px;
  display: inline-block;
}
.mobileui .toast .full {
  display: block;
  width: 100%;
}

@font-face {
  font-family: Ionicons;
  src: url(assets/fonts/ionicons.woff?v=2.0.0) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "DIN Pro";
  src: local("DIN Pro Regular"), local("DIN-Pro-Regular"), url(assets/fonts/DINPro.woff2) format("woff2"), url(assets/fonts/DINPro.woff) format("woff"), url(assets/fonts/DINPro.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Libel Suit";
  font-style: normal;
  font-weight: 400;
  src: local("Libel Suit"), local("LibelSuit-Regular"), url(assets/fonts/libel-suit.woff) format("woff"), url(assets/fonts/libel-suit.ttf) format("truetype");
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(assets/fonts/robotolight.woff2) format("woff2");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  src: local("Roboto Black"), local("Roboto-Black"), url(assets/fonts/robotoblack.woff2) format("woff2");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}
@font-face {
  font-family: Ionicons;
  src: url(assets/fonts/ionicons.woff?v=2.0.0) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "PT Root UI";
  src: url("/assets/fonts/PTRootUI-Medium.eot");
  src: local("PT Root UI Medium"), local("/assets/fonts/PTRootUI-Medium"), url("/assets/fonts/PTRootUI-Medium.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/PTRootUI-Medium.woff2") format("woff2"), url("/assets/fonts/PTRootUI-Medium.woff") format("woff"), url("/assets/fonts/PTRootUI-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "PT Root UI";
  src: url("/assets/fonts/PTRootUI-Light.eot");
  src: local("PT Root UI Light"), local("/assets/fonts/PTRootUI-Light"), url("/assets/fonts/PTRootUI-Light.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/PTRootUI-Light.woff2") format("woff2"), url("/assets/fonts/PTRootUI-Light.woff") format("woff"), url("/assets/fonts/PTRootUI-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "PT Root UI";
  src: url("/assets/fonts/PTRootUI-Regular.eot");
  src: local("PT Root UI"), local("/assets/fonts/PTRootUI-Regular"), url("/assets/fonts/PTRootUI-Regular.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/PTRootUI-Regular.woff2") format("woff2"), url("/assets/fonts/PTRootUI-Regular.woff") format("woff"), url("/assets/fonts/PTRootUI-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "PT Root UI";
  src: url("/assets/fonts/PTRootUI-Bold.eot");
  src: local("PT Root UI Bold"), local("/assets/fonts/PTRootUI-Bold"), url("/assets/fonts/PTRootUI-Bold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/PTRootUI-Bold.woff2") format("woff2"), url("/assets/fonts/PTRootUI-Bold.woff") format("woff"), url("/assets/fonts/PTRootUI-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
body {
  padding: 5px;
  background-color: #f3ffe7;
}

.split-pane-visible > .split-pane-side {
  min-width: 245px !important;
  max-width: 245px !important;
}

.rtc-button {
  border: 1px solid #ddd;
  font-size: 16px;
  display: table;
  padding: 3px 6px;
  border-radius: 6px;
  margin-top: 5px;
}
.rtc-button:hover {
  cursor: pointer;
}

.button-add-small-comment:hover {
  cursor: pointer;
}

.pressed-botton {
  background-color: #e7ffe7;
}

.inactive-botton {
  opacity: 0.5;
}

.like-museum {
  margin-top: 10px;
}

.num-likes-museum {
  font-size: 0.6em;
  margin-top: 5px;
}

.list.shadow.radius.white:nth-child(2n+1) {
  border-left-color: #9c27b0;
}

.list.shadow.radius.white:nth-child(2n+2) {
  border-left-color: #4caf50;
}

.list.shadow.radius.white {
  /* border-left: 1px solid #9c27b0; */
  border-left-width: 8px;
  border-left-style: solid;
}

.read-more-text {
  display: none;
}

ion-select-popover {
  overflow-y: auto !important;
}

.box {
  max-height: 0px;
  overflow-y: hidden;
  transition: ease-in-out 400ms max-height;
  padding-top: 0;
  padding-bottom: 0;
}

.box.opened {
  max-height: 500px;
  transition: ease-in-out 600ms max-height;
  overflow-y: auto;
}

.stars-row {
  color: #43a73f;
}

.list .color-border-text:nth-child(4n+1) {
  border: 1px solid #4caf50;
}
.list .color-border-text:nth-child(4n+1) .text-color-border-text {
  color: #4caf50;
}
.list .color-border-text:nth-child(4n+1) .same-color {
  background-color: #4caf50;
}
.list .color-border-text:nth-child(4n+1) .same-color-text {
  color: #4caf50;
}
.list .color-border-text:nth-child(4n+2) {
  border: 1px solid #03a9f4;
}
.list .color-border-text:nth-child(4n+2) .text-color-border-text {
  color: #03a9f4;
}
.list .color-border-text:nth-child(4n+2) .same-color {
  background-color: #03a9f4;
}
.list .color-border-text:nth-child(4n+2) .same-color-text {
  color: #03a9f4;
}
.list .color-border-text:nth-child(4n+3) {
  border: 1px solid #9c27b0;
}
.list .color-border-text:nth-child(4n+3) .text-color-border-text {
  color: #9c27b0;
}
.list .color-border-text:nth-child(4n+3) .same-color {
  background-color: #9c27b0;
  color: white;
}
.list .color-border-text:nth-child(4n+3) .same-color-text {
  color: #9c27b0;
}
.list .color-border-text:nth-child(4n+4) {
  border: 1px solid #ff9800;
}
.list .color-border-text:nth-child(4n+4) .text-color-border-text {
  color: #ff9800;
}
.list .color-border-text:nth-child(4n+4) .same-color {
  background-color: #ff9800;
}
.list .color-border-text:nth-child(4n+4) .same-color-text {
  color: #ff9800;
}